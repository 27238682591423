"use strict";
import { tree } from 'd3';
import gglobal from '../GarlicanLibrary/gvars'
import Opcode from './opcode'

const templates = window.templates

var GService =  {
    gi18n : {},
    split_cond : "#####EXIT CONDITION#####\n",
    split : "#####EXIT STRATEGY#####\n",
    dummy_code : { name : 'var_' , body: ' = close'},
    replace : {
        input : 1,
        variable : 'close'
    },
    cpp_sliter : '\n<--GALRICAN SPLITER-->\n',
    defaults : ['close', 'open', 'low', 'high', 'volume', 'avgprice', 'medianprice', 'typicalprice', 'weightedclose' , 'entryprice', 'entrytime' , 'barssinceentry', 'marketposition', 'betsize','true', 'false', 'condition_long', 'condition_short', "betsizeonhold", "maxbetsize","allowmultiplebet" ],
    operators : [ "BINARY_ADD" , "BINARY_SUBTRACT","BINARY_MULTIPLY", "BINARY_TRUE_DIVIDE", "BINARY_AND" ,"BINARY_OR", "BINARY_POWER"],
    elses : ['else', 'elif' ],
    breaks : ['break'],
    exit : ['entryprice', 'entrytime' , 'barssinceentry', 'marketposition' ],
    structure : {
        "normal" : {stm : "var_<normal> = close", display:false},
        "normal_structure" : {stm : "var_<normal> = <value>", display:false},
        "normal_var" : {stm : "<normal> = close", display:false},
        "normal_var_structure" : {stm : "<normal> = <value>", display:false},
        "normal_condition" : {stm : "<normal> = (close>0)", display:false},
        "if" : {stm : "if (close>0):\n\tvar_if = 0\nelse:\n\tvar_if = 1", display:true},
        "if_var" : {stm : "if (close>0):\n\t<normal> = 0\nelse:\n\t<normal> = 1", display:false},
        "if_condition_long" : {stm : "if (close>0):\n\tcondition_long = true\nelse:\n\tcondition_long = false" , display:false},
        "if_condition_short" : {stm : "if (close>0):\n\tcondition_short = true\nelse:\n\tcondition_short = false" , display:false},
        "for" : {stm : "for i in range(0,5):\n\tvar_cnt=close[i]", display:true},
        "for_var" : {stm : "for i in range(0,5):\n\t<normal>=close[i]", display:false},
        "for_condition_long" : {stm : "for i in range(0,5):\n\tcondition_long=close>close[i]", display:false},
        "for_condition_short" : {stm : "for i in range(0,5):\n\tcondition_short=close>close[i]", display:false},
        "elif" : {stm : "elif (close > 0):\n\tvar_else = 1", display:false},
        "else" : {stm : "else:\n\tvar_else = 1", display:false},
        "elif_var" : {stm : "elif (close > 0):", display:false},
        "else_var" : {stm : "else:", display:false},
    },
    //placement : "price=close\nCondition_long=(price>0)#####EXIT CONDITION#####\n#####EXIT STRATEGY#####\nupper_profit_exit = (close >= (entryprice+10) )\nlower_profit_exit=(close <= (entryprice-5) ) #Exit\nCondition_short =  (upper_profit_exit | lower_profit_exit)"

    createHashKey : function() {
        var _r = []
        for (var i in [0,1]) {
            _r.push( Math.random().toString(36).substr(2).toUpperCase() )
        }
        return _r[0] + _r[1]
    },

    createFirstCode : (lang) => {
        var bo = templates['long_strategy'][0]
        var so = templates['short_strategy'][0]
        var arr = [bo, so]
        for (var i in arr) {
            for (var v in arr[i].vars ) {
                arr[i].code = arr[i].code.replace( "<"+ v +">", arr[i].vars[v]   )
            }
            for (var c in arr[i].comments ) {
                arr[i].code = arr[i].code.replace( "<"+ c +">", arr[i].comments[c][ lang  ]   )
            }
        }
        return (arr[0].code + "\n" + GService.split_cond + arr[1].code)
    },

    createStrategyObject : function(code, name, desc, overrides) {
        //var id = arr[ arr.length ].id + 1
        name= name?name:( GService.gi18n[gglobal.language]['new_strategy'] + "_" + GService.getTimestamp() )
        //name = name?name:'new_strategy'
        var obj={}
        obj.id = "S_" + GService.createHashKey()
        //obj.id = null
        obj.name = name
        obj.description = desc?desc:""
        obj.code = code?code:""
        obj.created_at = new Date()
        obj.likes_ct = 0
        obj.comment_ct = 0
        obj.filters = [{key: "constituent", type: "type", opr: "in", value: "Hang Seng Index"}]
        //obj.filters = []
        obj.last_modified = new Date()
        obj.enable = true
        obj.overrides=overrides?overrides:{}
        obj.partition="0"
        return obj
    },

    getTimestamp() {
        var d = new Date()
        d= d.getTime()
        return Math.floor(d/1000)
    },

    createGroupObject : function(group, name, desc) {
        name= name?name:( GService.gi18n[gglobal.language]['new_portfolio'] + "_" + GService.getTimestamp() )
        var obj={}

        for (var g in group) {
            obj[g] = group[ g ]
        }

        obj.id = "G_" + GService.createHashKey()
        obj.name = name
        if( !('initial_capital' in obj) ) {
            obj.initial_capital = 1000000
        }
        if( !('transaction_fee' in obj) ) {
            obj.transaction_fee = 0
        }
        if( !('description' in obj) ) {
            obj.description = desc?desc:""
        }
        //obj.strategy_list = group
        if( !('partition' in obj) ) {
            obj.partition = {}
        }
        obj.created_at = new Date()
        if( !('input' in obj) ) {
            obj.input={}
        }

        obj.backtest_report = null
        obj.forwardtest_report = null
        obj.profit_rate = 0
        obj.sharpe_ratio = 0
        obj.win_rate = 0

        obj.access_mode = 'private'
        obj.last_modified = new Date()
        return obj
    },
    
    download : function(filename, text, ext) {
        ext = ext?ext:'g'
        var element = document.createElement('a');

        filename = filename.replace(/ /g, "_")

        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', (filename+"."+ext));
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    },

    encodeObject : function(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
    },

    decodeObject : function(str) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    },

    isCN : function() {
        return (navigator.languages.length>0)?((navigator.languages[0].toLowerCase().indexOf('en') > -1)?true:false):false
    },

    isCrome : function() {
        //var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        //const isFirefox = typeof InstallTrigger !== 'undefined';
        //var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));

        //var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        //return isChrome || isOpera || isOpera
        return true
    },

    createCompGp: function(code, tp, res, isBuy) {
        var _lines = Object.keys(tp)
        var response = this.returnObj( tp, code, isBuy  )
        var _res = response['response']
        var line = parseInt(response['line'], 10)
        var ro = { }
        for (var i in _res) {
          ro[ parseInt(i, 10) ] = true
        }
        var lg = this.getLineGroup( tp, line, code, res, {lines : ro } )
  
        var comp_gp = [], comments={}
        for (var i in lg.group ) {
          var obj = lg.group[i]
          response = this.getLineComp(i, obj, res, tp, true )
          var _stm = "", _str = "", _st
          var _cnt = obj.if + obj.for
          var _t = Array.from( {length:  (_cnt - (( obj.start_condition|| (obj.start_else)|| obj.start_loop)?1:0))  }, (x,i) => "\t" )
          _stm += _t.join("")
          if( obj.store ) {
            _str = (obj.comment?obj.comment:(  (obj.store in GService.gi18n[gglobal.language])?GService.gi18n[gglobal.language][obj.store]:obj.store)   )
            _stm += _str
            comments[ obj.store ] = _str
          }
          for (var j in response['comp']) {
            _st = response['comp'][j].store
            _str = (_st in comments)?comments[ _st ]:( (_st in GService.gi18n[gglobal.language])?GService.gi18n[gglobal.language][_st]:_st  )
            _stm += _str
          }
          comp_gp.push( _stm  )
        }


        return comp_gp.join("\n")
    },

    reshape : function(obj, line ,cb, type) {
        
        var res = {}        
        //var levels = {}
        var _allVars = {}
        var key = null, index = null
        var _rl = {},o = {}, pif =-1, pfor = 0,t = {}, i=null, _i = null,valid =false, f= null, _key = null, err = null, v=null, lv=null

        function check(o, ii, idx, r, k) {
            if ( o.start_condition && !k && !o.start_else && o.if > 0 ) {
                k = 'if'
                idx = ii
                r[ idx ] = o
                r[ idx ]['op'] = []
                r[ idx ]['lines'] = {}
                r[ idx ]['lines'][ ii ] = true
            } else if ( o.start_loop && !k && o.for > 0 ) {
                k = 'for'
                idx = ii
                r[ idx ] = o
                r[ idx ]['op'] = []
                r[ idx ]['lines'] = {}
                r[ idx ]['lines'][ ii ] = true    
            } else if ( o.if > 0 || o.for > 0   ) {
                r[ idx ]['lines'][  ii ] = true
                r[ idx ]['operation'] = r[ idx ]['operation'].concat(   o['operation']     )
                //if (o['store']) {
                    r[ idx ]['variables'] = r[ idx ]['variables'].concat(  o['variables']  )
                    r[ idx ]['variables'] = r[ idx ]['variables'].filter(function(item, pos) {
                        return r[ idx ]['variables'].indexOf(item) === pos;
                    })
                    r[ idx ]['inputs'] = r[ idx ]['inputs'].concat( o['inputs']  )
                    r[ idx ]['inputs'] = r[ idx ]['inputs'].filter(function(item, pos) {
                        return r[ idx ]['inputs'].indexOf(item) === pos;
                    })
                if (o['store']) {
                    var _a = o['store'].split(",")

                    for (var ij in _a) {
                        //r[ idx ]['op'].push( { 'store':o['store'], 'line': i  } )
                        r[ idx ]['op'].push( { 'store': _a[ij], 'line': ij  } )
                    }

                }
            }
            return {'key':k, 'index':idx, 'res' : r }
        }

        pif = -1
        pfor = 0
        i = null
        _i = null
        for (i in obj) {
            o = JSON.stringify(obj[i])
            o = JSON.parse(o)
            t = {}
            if (  (o.if === 0 && o.for === 0) ||  (o.if > pif && o.for !== pfor) || (o.for > pfor && o.if !== pif) ||  ( o.for === 0 && o.if ===1 && o['start_condition'] && !o['start_else'])   ) {
                key = null
                index = i
                res[ i ] = o
                res[index]['level']  = 1
                t = check(o, i, index, res,key)
                key = t['key']
                res = t['res']
                index = t['index']
            } else {
                t = check(o, i, index, res, key)
                key = t['key']
                res = t['res']
                index = t['index']
            } 

            pif = o.if
            pfor = o.for

            if (o.start_loop) {
                _allVars[ o['variables'][o['variables'].length -1] ] = index
            }

            for (_i in o['variables']) {


                if ( !(o['variables'][_i]  in _allVars) ) {
                    valid = false

                    if( o['store'] ) { 
                        var _a = o['store'].split(",")
                        for ( var ik in _a) {
                            //if (o['store'] === o['variables'][_i]) {
                            if ( _a[ik] === o['variables'][_i]) {
                                valid = true
                            }
                        }
                    }

                    if (o['start_loop']) {
                        valid = true
                    }
                    _key = "|"+this.defaults.join("|")+"|"
                    if (   _key.indexOf( "|" + o['variables'][_i] + "|" ) > -1   ) {
                        valid=true
                    }

                    
                    /*if ( o['store'] === "IMPORT" ) {
                        valid = true
                        _allVars[  o['variables'][_i]  ] = index
                        console.log( _allVars )
                    } */

                    //if( o['store']) {
                    //    console.log( _allVars )
                    //}

                    for (f in o['function'])   {
                        _key = Object.keys(o['function'][f] )
                        if(  _key[0] === o['variables'][_i]   ) {
                            valid = true
                            break
                        } 
                    }
                    if (!valid) {
                        err = GService.gi18n[gglobal.language]['error_undefined']
                        err = err.replace( "<key>",  i )

                        //var keys = Object.keys( obj )
                        var name = this.check(obj, o['variables'][_i],  i  )

                        //err = err.replace( "<val>",  o['variables'][_i] )
                        if (name !== o['variables'][_i] ) {
                            err = err.replace( "<val>", o['variables'][_i]+" ("+name+")" )
                        } else {
                            err = err.replace( "<val>", o['variables'][_i] )
                        }


                        //cb( err,  i, _i )
                        cb( err,  i, _i, type )
                        //break
                        return
                    } 
                } else {
                    

                }
            }
            if (o.store) {
                //_allVars[ o.store ] = i
                if ( o['store'] === "IMPORT" ) {
                    for (var v in o['operation']) {
                        for (var v1 in o['operation'][v]['vars'] ) {
                            _allVars[  o['operation'][v]['vars'][v1]  ] = i
                        }
                    }
                } else {
                    _a = o.store.split(",")
                    for (var i in _a) {
                        //_allVars[ o.store ] = i
                        _allVars[ _a[i] ] = i
                    }
                }

            }
        }

        i=null
        v = null
        for (i in res) {
            o = res[i]
            for (v in o.variables) {

                if( o.variables[v] in _rl  ) {
                    lv =  _rl[  o.variables[v]  ]
                    lv = res[lv].level+ ((lv!==i)?1:0)
                    o.level = Math.max(  o.level,  lv )
                    _rl[ o.variables[v] ] = i
                }
            }
            if ('op' in o) {
                v = null
                for (v in o.op) {
                    _rl[ o.op[v].store  ] = i
                }
            }
            if (o.store) {
                _rl[ o.store ] = i
            }
            res[i] = o
        }

        //console.log( _rl )

        return res
    },

    checkVarExistFromLine : function(tp, ln, v) {
        var exist = false
        for (var i in tp) {
            if(  parseInt(i, 10) < parseInt(ln, 10)  ) {
                var o = tp[i]
                if( o.store  ) {
                    if( o.store === v ) {
                        exist = true
                        break
                    }
                } else if ( !o.body ) {
                    if( typeof(o.variables) === 'object' ) {
                        if (o.variables[  o.variables.length - 1 ] === v) {
                            exist = true
                            break
                        }
                    }
                }
            }
        }
        return exist
    },

    checkResFromLine : function(ol, res) {
        var ro = {}
        for (var j in res) {
            if ( 'lines' in res[j] ) {
                /*if( parseInt(ol,10) in res[j]['lines'] ){
                    ro = Object.keys(res[j]['lines'])
                    break
                }*/
                var keys = Object.keys(  res[j].lines  )
                var start = parseInt(keys[0], 10)
                var end = parseInt(keys[ keys.length-1 ], 10)
                if( parseInt(ol,10) >= start && parseInt(ol, 10) <= end  ){
                    ro = Object.keys(res[j]['lines'])
                    break
                }
            } 
        }
        return ro
    },

    checkRLineFromLine : function(ol, res) {
        var l = null
        for (var j in res) {
            if ( 'lines' in res[j] ) {
                var keys = Object.keys(  res[j].lines  )
                var start = parseInt(keys[0], 10)
                var end = parseInt(keys[ keys.length-1 ], 10)

                if( parseInt(ol,10) >= start && parseInt(ol, 10) <= end  ){
                    l = j
                    break
                }
            } 
        }
        return l
    },

    checkObjectFromLine : function(ol, res) {
        var o = {}

        for (var j in res) {
            if ( 'lines' in res[j] ) {
                var keys = Object.keys(  res[j].lines  )
                var start = parseInt(keys[0], 10)
                var end = parseInt(keys[ keys.length-1 ], 10)

                if( parseInt(ol,10) >= start && parseInt(ol, 10) <= end  ){
                    o = res[j]
                    break
                }
            } 
        }
        return o
    },

    getFuzzyObject : function(treemap, ln, next) {
        var keys = Object.keys( treemap )
        var obj = {}, i = 0
        var matches = "|" + keys.join("|") + "|"
        if(!next) {
            for (i = parseInt(keys[keys.length-1], 10) ; i > 0; i--  ) {
                if(  (  matches.indexOf( "|"+i+"|" ) > -1 ) && ( i  < ln)  ) {
                    //console.log( "i : " +  i + " ln : " + ln )
                    obj = treemap[  parseInt(i, 10)  ]
                    break
                }
            }
        } else {
            for (i = 0 ; i <parseInt(keys[keys.length-1], 10) ; i++  ) {
                if(  ( matches.indexOf( "|"+i+"|" ) > -1   ) && ( i  > ln)  ) {
                    //console.log( "i : " +  i + " ln : " + ln )
                    //console.log( treemap )
                    //console.log( keys )
                    obj = treemap[  parseInt(i, 10)  ]
                    break
                }
            }
        }
        return obj
    },

    getLineGroup : function(tp, line, code, r, ro) {
        if(!code) {
            return null
        }
        var cl = code.split('\n')
        var treemap = JSON.stringify(tp)
        var start = line
        treemap = JSON.parse(treemap)
        var _rlines =[], res = {}, n = line, _str = null, obj = {}
        ro=ro?ro:r[line]
        var _ro = []
        if (ro) {
            _ro = ('lines' in  ro)?Object.keys( ro.lines ):[ line ]
             _rlines = ('lines' in  ro)?Object.keys( ro.lines ):[ line ]
        } else {
            _ro = [ line ]
            _rlines = [ line ]
        }

        _rlines = "|" + _rlines.join("|") + "|"
    
        var cl = code.split('\n')
        
        function checker(ln) {
            //console.log(cl)
            //console.log(ln)
            if(  (!ln-1 in cl)) {
                return ln
            }
            if(  cl[ln-1].replace(/\t/g, "").indexOf('else:') > -1 ) {
                return ln
            } else {
                return checker(ln-1)
            }
        }

        
        if(  !( line.toString() in treemap )  ) {
            var obj = this.getFuzzyObject(treemap, line, true)
            ro = this.getElses( treemap, line+1, obj   )
            _rlines = "|" + ro.join("|") + "|"
        }
        for (var i in treemap) {
            if (parseInt(i, 10) >= parseInt(line, 10)  ) {
                if (  _rlines.indexOf( "|" + i  + "|" ) === -1  ) {
                    n = parseInt(_ro[ _ro.length-1 ], 10)+1
                    //console.log(n)
                    break
                } else {
                    obj = treemap[i]
                    _str = JSON.stringify(obj)
                    obj = JSON.parse( _str )
                    if (   obj.start_else && (!obj.start_condition || obj.next)   ) {
                        var index = checker(parseInt(i, 10)-1 )                  
                        res[index] = obj
                        res[index]['if'] = (obj.if - (obj.next?1:0) )
                        res[index]['inputs'] = []
                        res[index]['operation'] = [{op: "G_ELSE", expression: "else:", vars: [], line: (i-1), value: null}]
                        res[index]['stm'] = "else:"
                        res[index]['store'] = null
                        res[index]['variables'] = []
                        res[index]['function'] = []
                        res[index]['comment'] = ""
                        res[index]['body'] = "else:"
                        res[i] = JSON.parse(_str) 
                        res[i]['start_else'] = false
                        res[i]['start_condition'] = obj.next
                    } else {
                        obj = treemap[i]
                        _str = JSON.stringify(obj)
                        res[i] = JSON.parse( _str )
                    }
                    n = i
                }
            }
        }
        //} else {
        //    n=line
        //}
        //console.log(tp)
        return { group:res, start: start, end : parseInt(n, 10) }
    },

    removeLinkage : function(code, obj, treemap, index, _in_var, _in_idx, _out_var, _out_idx, BOOL) {
        var _stm = "", _tvars = [], keys={},_op="", i = null
        var _line_num = null
        var _lines = code.split("\n")
        index = parseInt( index, 10 )
        treemap = JSON.stringify(treemap)
        treemap = JSON.parse(treemap)

        obj = JSON.stringify(obj)
        obj = JSON.parse(obj)

        try {
            if (obj['operation'].length === 0 ) {
                _stm = this.replace['variable']
            } else {
                for (var idx in obj['operation']) {
                    _tvars = []
                    _op = obj['operation'][idx]
                    if ((!_line_num)  || ( _op['line'] === _line_num )) {
                        for (i in _op['vars'] ) {
                            var _match = idx+"|"+i
                            var _str = Object.keys(keys)
                            _str = "|" + _str.join("|") + "|"
                            if ( _match === _in_idx ) {
                                _line_num = _op['line']


                                if(  _op['op'] === 'CALL_FUNCTION'  ) {
                                    var _fn =  window.func[ _op['value'] ]
                                    if (_fn && _fn.display) {
                                        var _a = _fn['args'][i]
                                        _a = _fn['default'][_a]
                                        _tvars.push(  _a  )
                                    } else {
                                        _tvars.push(  5  )
                                    }


                                } else {
                                    for (var j in treemap) {
                                        if (treemap[j]['store'] === _in_var  ) {
                                            if ( treemap[j]['variables'].length > 0) {
                                                _tvars.push( this.replace['variable'] )
                                            } else {
                                                if ( treemap[j]['inputs'].length > 1) {
                                                    _tvars.push( this.replace['variable'] )
                                                } else if ( treemap[j]['inputs'].length === 1 ) {
                                                    if (   Number.isInteger(treemap[j]['inputs'][0]) ) {
                                                        _tvars.push( this.replace['input'] ) 
                                                    } else {
                                                        _tvars.push( this.replace['variable']  ) 
                                                    }
                                                } else {
                                                    _tvars.push( this.replace['input'] )
                                                }

                                            }
                                            break
                                        } else {
                                            var k = Object.keys(treemap)
                                            if( j === k[ k.length-1 ]  ) {
                                                _tvars.push( this.replace['variable']  ) 
                                                //console.log( treemap[j]['store'] + "|" +_in_var )
                                            }
                                        }
                                    }
                                }
                            } else{
                                if ( _str.indexOf("|" + _op['vars'][i]  +"|" ) > -1 ) {
                                    _tvars.push(  keys[ _op['vars'][i]  ]    )
                                } else {
                                    _tvars.push(_op['vars'][i])
                                }
                            }
                        }
                    
                        
                        if(  _op['op'] === 'CALL_FUNCTION'  ) {                        
                            _stm = (_op['value'] + "(" )
                            _stm +=  _tvars.join(",")
                            _stm +=  ")" 
                        } else  {
                            _stm = Opcode.operations[ _op['op'] ]['gython']
                            console.log(  _op  )
                            console.log(_stm)
                            console.log( _tvars )
                            for (i in _tvars) {
                                var value = parseInt(i, 10)+1
                                value =  "<v" + value  +">"
                                _stm = _stm.replace( value, _tvars[i]  )
                            }
                            _stm = _stm.replace( "<opr>", _op['value']  )
                        }
                        keys[ _op['expression']  ] =  _stm 


                        


                        _op.vars = _tvars
                        _op['expression'] = _stm
                        obj['operation'][idx] = _op

                        //treemap[ parseInt((_line_num)?(_line_num):(index), 10) ] = obj


                    }
                }
            }

            var _obj = obj
            if ( 'op' in obj ) {
                _obj = treemap[  parseInt((_line_num)?(_line_num):(index), 10) ]
            } 


            //_op.vars = _tvars
            //_op['expression'] = _stm
            //obj['operation'][idx] = _op
            //treemap[ parseInt((_line_num)?(_line_num):(index), 10) ] = obj
            //console.log( index + " : " + _stm )
            //console.log( _tvars )

            //var _cnt = _obj['if'] + _obj['for']


            var _cnt =  _obj['if'] + _obj['for']
            var _t = Array.from( {length:  (_cnt - ((_obj.start_condition||(_obj.start_condition&&_obj.start_else)||_obj.start_loop)?1:0))  }, (x,i) => "\t" )
            _stm = _t.join("") + _stm
            
            _stm += ((treemap[_line_num].comment.length > 0)?(" #"+ treemap[_line_num].comment):"")
            _lines[  parseInt(  ((_line_num)?(_line_num-1) :(index-1)), 10) ] = _stm


        }
        catch(e) {
            //ERROR
        }

        if(BOOL) {
            return { lines : _lines, tp : _obj, treemap : treemap }
        } else {
            return _lines
        }
    },

    updateLine : function(code, obj, treemap, index, _in_var, _in_idx, _out_var, _out_idx, value) {
        //var _stm = "", _tvars = [], keys={},_op={}, i = null, valid = false, _str=null, ct=0
        var _stm = "", _tvars = [], keys={},_op={}, i = null, _str=null, ct=0
        var _line_num = null
        var _lines = code.split("\n")

        var build_splice = false, build_stores = [],  build_value = null

        try {
            if ( _in_idx === "-1" ) {
                _stm = _out_var
            } else {
                for (var idx in obj['operation']) {
                    _op = obj['operation'][idx]
                    if( _op['op'] === 'BUILD_SLICE') {
                        build_splice = true
                    }
                    if (  (  !_line_num  )  || ( _op['line'] === _line_num )  ) {
                        _tvars = [] 
                        _stm = ""   
                        _str = Object.keys(keys)
                        _str = "|" + _str.join("|") + "|"
                        for (i in _op['vars'] ) {
                            var _match = idx+"|"+i
                            if (_match === _in_idx) {
                                _line_num = _op['line']
                                _tvars.push(   _out_var  ) 
                            } else if (  _str.indexOf( "|"+ (_op['vars'][i] + "___" + _line_num + "_"+ (idx-1) ) +"|") > -1   ) {  
                                _tvars.push(   keys[ _op['vars'][i] + "___" + _line_num + "_"+ (idx-1)  ]   )  
                            } else if ( _op['op'].indexOf( 'STORE_' ) > -1 && _str.indexOf( "|"+ _op['vars'][i] ) > -1  ) {
                                 _line_num  = _line_num?_line_num:_op['line']
                                 
                                 if( !build_splice ) {
                                    var _tstr = keys[ _op['vars'][i] + "___" + _line_num + "_"+ (idx-1)   ]?keys[ _op['vars'][i] + "___" + _line_num + "_"+ (idx-1)   ]:( _op['vars'][i] )
                                    _tvars.push(   _tstr   ) 
                                 } else {
                                    var _tstr = keys[ _op['vars'][i] + "___" + _line_num + "_"+ (idx-2)   ]?keys[ _op['vars'][i] + "___" + _line_num + "_"+ (idx-2)   ]:( _op['vars'][i] )
                                    if(!build_value) {
                                        build_value = _tstr.replace(/ /g, "")
                                        _tvars.push(   build_value   ) 
                                    }
                                    _tvars.push(   build_value   ) 
                                 }

                                

                            } else {
                                //console.log( _op['vars'][i] )
                                var saves = _op['vars'][i]
                                if( build_splice ) {
                                    if(_op['op'].indexOf( 'STORE_' ) > -1 ) {
                                        var _sVal = _op['vars'][i].replace("=", "").replace(/ /g, "")
                                        build_stores.push( _sVal )
                                        saves = "(" +build_stores.join(",") + ") = "
                                    }
                                    //console.log()
                                }
                                _tvars.push( saves  )
                            }    
                        }


                        if(  _op['op'] === 'CALL_FUNCTION'  ) {
                            _stm = ( value?value:_op['value'] + "(" )
                            _stm +=  _tvars.join(",")
                            _stm +=  ")" 
                        } else  {
                            _stm = Opcode.operations[ _op['op'] ]['gython']

                            if( build_splice ) {
                                if(  _op['op'] === 'BINARY_SUBSCR'  ) {
                                    _stm = Opcode.operations[ _op['op'] + "_SPLICE" ]['gython']
                                    if( _tvars.length === 2 ) {
                                        _tvars.push("")
                                    }
                                }      
                            }

                            for (i in _tvars) {
                                value = parseInt(i, 10)+1
                                value =  "<v" + value  +">"
                                _stm = _stm.replace( value, _tvars[i]  )
                            }
                            _stm = _stm.replace( "<opr>", _op['value']  )
                            _stm = _stm.replace(/  /g, " ")
                        }

                        

                        keys[ _op['expression'] + "___" + _line_num + "_" + ct  ] =  _stm 


                    } else {
                        break
                    }
                    ct += 1

                }

            } 



            var o = treemap[ parseInt(_line_num, 10) ]
            var _cnt = o['if'] + o['for']

            if ( o[ 'start_loop' ] || o['start_condition']    ) {
                    _cnt -= 1
            }
            for (i=0; i< _cnt ; i++ ) {
                _stm = "\t" + _stm
            }



            var _new_line = -1
            for (i in treemap) {
                if (treemap[i].store) {
                    if (treemap[i].store.toLowerCase() === _out_var ) {
                        _new_line = (i>((_line_num)?(_line_num-1) :(index-1)))?i:-1
                        break
                    } 
                }
            }

            _stm += ((treemap[_line_num].comment.length > 0)?(" #"+ treemap[_line_num].comment):"")



            _lines[  (_line_num)?(_line_num-1) :(index-1) ] = _stm
            
            if (_new_line !== -1 ) {
                var _t = _lines.splice( (_new_line-1) ,1 )
                _lines.splice( (_line_num)?(_line_num-1) :(index-1) ,0 , _t[0] )
            }


        }
        catch(e) {
            //ERROR
            //console.log(e)
        }
        return _lines
    },

    getLineComp : function(line, obj,res, treemap, isWord, id, isIn ) {
        var _import = false
        if (!obj)
            return
        var idx = -1, var_no= -1, line_no=-1, k = null
        if (id) {
            if (isIn) {
                id = id.split("__")
                id = id[1]
                id = id.split("|")
                idx = parseInt(id[0], 10)
                var_no = parseInt(id[1], 10)
                var _line_no = obj['operation'][ idx  ][ 'line'  ]
                var count = 0
                for (k in obj.operation) {
                    if( parseInt(idx, 10) === parseInt(k, 10)  ) {
                        idx = count
                        break
                    }
                    if( parseInt(line, 10) === parseInt(obj.operation[k].line, 10) ) {
                        count += 1 
                    }
                }
                line = _line_no
                obj = treemap[ _line_no  ]
            } else {
                id = id.split("__")
                id = id[1]
                var lines = id.split("|") 
                line_no = parseInt(lines[0], 10)
                idx = parseInt(lines[1], 10)
                obj = treemap[  line_no ]
                line = line_no
            }
        }
        var _stores = {}, _comp = [], _vars={}, _o ={}
        for (k in res) {
            if ( parseInt(k, 10) < line ) {
                _o = treemap[k]
                if( _o['store'] ) {
                    var _st = _o['store'].split(",")
                    if( _st.length > 1) {
                        for (var i in _st ) {
                            _stores[  _st[i] ] = parseInt(k, 10)
                        }
                    } else {
                        _stores[  _o['store'] ] = parseInt(k, 10)
                    }
                }
            } else {
                break
            }
        }

        if (obj.store) {
            if (  id   ) {
                _comp.push( {  store : obj.store, line : line, highlight: (isIn)?false:true   }   )
            }
            _comp.push( {  store : "=", line : line,  highlight:false    }   )
        }
    
        var o = {}, _expr = null, op = [], _t = [], _fn = null, v = null,isOpr = false, _s = null
        k = null
        var _build_splice = false
        for (var i in obj.operation) {
            op = obj.operation[i]
            _t = []
                if ( op['op'] === 'CALL_FUNCTION') { 
                    _fn = op['value']
                    if (isWord) {

                        if ( _fn in  window.func ) {

                            if ( _fn.toLowerCase() === 'range' && op['vars'].length === 2  ) {
                                _expr =  window.func[_fn]['desc'][gglobal.language]
                            } else if ( _fn.toLowerCase() === 'range' && op['vars'].length === 1  ) {
                                _expr = GService.gi18n[ gglobal.language ]['loop_replacement']
                            } else {
                                _expr =  window.func[_fn]['desc'][gglobal.language]
                            }
                               if(  _fn.toLowerCase() !== 'range') {
                                    o = { op_line:i, var_line:-1, in:true, store : "([", line : line,  highlight:false, isFunction:false, isOpr:false} 
                                    _t.push( o )
                                    o = { op_line:i, var_line:-1, in:true, store : _fn, line : line,  highlight:(id)?false:true, isFunction:true, isOpr:false} 
                                    _t.push( o )
                                    o = { op_line:i, var_line:-1, in:true, store : "] : ", line : line,  highlight:false, isFunction:false, isOpr:false} 
                                    _t.push( o )
                                }

                                for (v in op['vars']) {
                                    k =  window.func[_fn]['args'][v]
                                    _s = _expr.indexOf( "<"+k+">" ) 
                                    if (_s > 0) {
                                        o = { op_line:i, var_line:v, in:true, store : _expr.substring(0,_s), line : line,  highlight:false, isOpr:false} 
                                        _t.push( o )
                                        _expr = _expr.substring(_s,  _expr.length  )
                                    }
                                    if(  !(op['vars'][v] in _vars)  ) {
                                        o = { op_line:i, var_line:v, in:true, store : op['vars'][v], line : (op['vars'][v] in _stores)?_stores[op['vars'][v]]:line,  highlight: (id&&isIn)?((parseInt(i, 10)===parseInt(idx, 10))&&parseInt(v, 10)===parseInt(var_no, 10)):(id?false:true), isFunction:false, isOpr:false } 
                                        _t.push( o )
                                    } else {
                                        _t = _t.concat(  _vars[ op['vars'][v] ]    )
                                    }
                                    _expr = _expr.substring( k.length+2 , _expr.length )
                                    if(  _expr.length  > 0 && parseInt(v, 10) === op['vars'].length-1   ) {
                                        o = { op_line:i, var_line:v, in:true, store : _expr , line : line,  highlight:false , isFunction:false, isOpr:false} 
                                        _t.push( o )
                                    }
                                }

                                if ( _fn.toLowerCase() !== 'range'  ) {
                                    o = { op_line:i, var_line:-1, in:true, store : ")", line : line,  highlight:false, isFunction:false, isOpr:false} 
                                    _t.push( o )
                                }

                            //}

                        } else {

                            _expr = Opcode.operations[ op['op'] ]['desc'][ gglobal.language ]
                            
                            _expr = _expr.replace( "<f>", _fn )
                            _expr = _expr.split('<v>')

                            o = { op_line:i, var_line:-1, in:true, store : "([", line : line,  highlight:false, isFunction:false, value:op['value'], isOpr:false} 
                            _t.push( o )
                            o = { op_line:i, var_line:-1, in:true, store : _fn, line : line,  highlight:(id)?false:true, isFunction:true, value:op['value'], isOpr:false} 
                            _t.push( o )
                            o = { op_line:i, var_line:-1, in:true, store : "] : ", line : line,  highlight:false, isFunction:false, value:op['value'], isOpr:false} 
                            _t.push( o )
                            o = { op_line:i, var_line:-1, in:true, store : _expr[0], line : line,  highlight: false, isFunction:false, value:op['value'], isOpr:false} 
                            _t.push( o )

                            for (v in op['vars'] ) {
                                if(  !(op['vars'][v] in _vars)  ) {
                                    o = { op_line:i, var_line:v, in:true, store : op['vars'][v], line : (op['vars'][v] in _stores)?_stores[op['vars'][v]]:line,  highlight: (id&&isIn)?((parseInt(i, 10)===parseInt(idx, 10))&&parseInt(v, 10)===parseInt(var_no, 10)):(id?false:true), isFunction:false, value:op['value'], isOpr:false } 
                                    _t.push( o )
                                } else {
                                    _t = _t.concat(  _vars[ op['vars'][v] ]    )
                                }
                                if (v < op['vars'].length-1 ) {
                                    o = { op_line:i, var_line:v, in:true, store : ", ", line : (op['vars'][v] in _stores)?_stores[op['vars'][v]]:line,  highlight: false, isFunction:false, value:op['value'], isOpr:false } 
                                    _t.push( o )
                                }
                            }
                            
                            o = { op_line:i, var_line:-1, in:true, store : _expr[1], line : line,  highlight: false, isFunction:false, value:op['value'], isOpr:false} 
                            _t.push( o )
                            o = { op_line:i, var_line:-1, in:true, store : ")", line : line,  highlight: false, isFunction:false, value:op['value'], isOpr:false} 
                            _t.push( o )

                        }

                    } else {
                        _t.push( {  op_line:i, var_line:-1, in:true ,store : _fn, line : line,  highlight: (id)?false:true, isFunction:true, value:op['value'], isOpr:false    }   )
                        _t.push( {  op_line:i, var_line:-1, in:true  ,store : "(", line : line,  highlight:false, isFunction:false, value:op['value'], isOpr:false    }   )
                        for (v  in op['vars']) {
                            if(  !(op['vars'][v] in _vars)  ) {
                                _t.push( { op_line:i, var_line:v, in:true, store : op['vars'][v] , line : (op['vars'][v] in _stores)?_stores[op['vars'][v]]:line ,  highlight:  (id&&isIn)?((parseInt(i, 10)===parseInt(idx, 10))&&parseInt(v, 10)===parseInt(var_no, 10)):(id?false:true), isFunction:false, value:op['value'], isOpr:false   }   )
                            } else {
                                _t = _t.concat(  _vars[ op['vars'][v] ]    )
                            }
                            if ( v < op['vars'].length-1 ) {
                                _t.push( { op_line:i, var_line:v, in:true, store : "," , line : line ,  highlight:false, isFunction:false , value:op['value'], isOpr:false  }   )
                            }
                        }
                        _t.push( {  op_line:i, var_line:v, in:true, store : ")", line : line,  highlight:false, isFunction:false , value:op['value'], isOpr:false   }   )
                    }


                } else {
                    if( op['op'].indexOf("STORE_") === -1  ) {

                        if(  (op['op'].indexOf("IMPORT_") === -1)   ) {

                            
                            if(  op['op'] === "BUILD_SLICE") {
                                _build_splice = true
                            }

                            _expr = Opcode.operations[ op['op'] ]['gython']
                            
                            if (obj.start_condition && obj.start_else) {
                                if(  op['op'] === "POP_JUMP_IF_FALSE" ) {
                                    _expr = Opcode.operations['G_ELIF']['gython']
                                } else if(  op['op'] === "POP_JUMP_IF_TRUE" ) {
                                    _expr = Opcode.operations['G_ELIFN']['gython']
                                }
                            }
                        
                            isOpr = false
                            isOpr = (_expr.indexOf("<opr>") > -1) || ( 'operator' in Opcode.operations[ op['op'] ] )
                            var operator = isOpr?( (_expr.indexOf("<opr>") > -1)?op['value']:Opcode.operations[ op['op'] ]['operator']  ):null
                            if (isWord) {
                                _expr = Opcode.operations[ op['op'] ]['desc'][ gglobal.language ]
                                
                                
                                if(  op['op'] === "BINARY_SUBSCR") {
                                    if(  _build_splice ) {
                                        _expr = Opcode.operations[ op['op'] + "_SPLICE" ]['desc'][ gglobal.language ]
                                        if( op['vars'].length ===  2 ) {
                                            _expr = _expr.replace("<v3>", this.gi18n[gglobal.language]['now'] )
                                        } 
                                    } 
                                    _build_splice = false
                                } 

                                if (obj.start_condition && obj.start_else) {
                                    if(  op['op'] === "POP_JUMP_IF_FALSE" ) {
                                        _expr = Opcode.operations['G_ELIF']['desc'][ gglobal.language ]
                                    } else if(  op['op'] === "POP_JUMP_IF_TRUE" ) {
                                        _expr = Opcode.operations['G_ELIFN']['desc'][ gglobal.language ]
                                    }
                                }
                                var _or = (op['value'] in Opcode.operator)?Opcode.operator[op['value']][gglobal.language]:op['value']
                                _expr = op['value']?_expr.replace( "<opr>", _or  ):_expr
                            } else{
                                _expr = op['value']?_expr.replace( "<opr>", op['value']  ):_expr
                            }
                            for (v in op['vars'] ) {
                                _s = _expr.indexOf( "<v" + (parseInt(v, 10)+1) +">" ) 
                                if ( _s >= 0 ){
                                    if (_s > 0) {
                                        o = { op_line:i, var_line:v, in:true, store : _expr.substring(0,_s), line : line,  highlight:(v>0)?isOpr:false , isFunction:false, value:(v>0)?operator:null, isOpr:(v>0)?isOpr:false, opcode:isOpr?op['op']:null } 
                                        _t.push( o )
                                        _expr = _expr.substring(_s,  _expr.length  )
                                    }
                                    if(  !(op['vars'][v] in _vars)  ) {
                                        o = { op_line:i, var_line:v, in:true, store : op['vars'][v], line : (op['vars'][v] in _stores)?_stores[op['vars'][v]]:line,  highlight: (id&&isIn)?((parseInt(i, 10)===parseInt(idx, 10))&&parseInt(v, 10)===parseInt(var_no, 10)):(id?false:true), isFunction:false, value:null, isOpr:false } 
                                        _t.push( o )
                                    } else {
                                        _t = _t.concat(  _vars[ op['vars'][v] ]    )
                                    }
                                    _expr = _expr.substring(4, _expr.length )
                                }
                                if(  _expr.length  > 0 && parseInt(v, 10) === op['vars'].length-1   ) {
                                    o = { op_line:i, var_line:v, in:true, store : _expr , line : line,  highlight:false, isFunction:false, value:null, isOpr:false} 
                                    _t.push( o )
                                }
                            }
                            //console.log(_t)
                        } else {
                            _expr = Opcode.operations[ op.op ]['desc'][ gglobal.language ]
                            if( obj['stm'].indexOf('from') === 0 ) {
                                if( op.vars.length > 1 ) {
                                    _expr = Opcode.operations[ "IMPORT_FROM" ]['desc'][ gglobal.language ]
                                } else {
                                    _expr = Opcode.operations[ "IMPORT_STAR" ]['desc'][ gglobal.language ]
                                }
                            } else {
                                if( op.vars.length > 1 ) {
                                    if(  op.op === "IMPORT_NAME") {
                                        _expr = Opcode.operations[ op.op+"_2" ]['desc'][ gglobal.language ]
                                    }
                                }
                            }
                            
                            for (var i in op.vars) {
                                _expr = _expr.replace(  ("<v" + (parseInt(i)+1) + ">") , op.vars[i] )
                            }
                              
                            o = { op_line:i, var_line:0, in:true, store : _expr , line : line,  highlight:false, isFunction:false, value:null, isOpr:false} 
                            _t.push( o )
                            _import = true

                        }
                    } 

                }
                if ( obj.operation.length === 1 && ( obj.operation[0]['op'].indexOf("STORE_") > -1) ) {
                    _t.push( {  op_line:0, var_line:1, in:true, store : op.vars[ op.vars.length-1 ] , line : line ,  highlight: !id||isIn?true:false, isFunction:false, value:op['value'], isOpr:isOpr   }   )
                    _comp = _comp.concat(_t)
                    break
                } else if ( obj.operation.length === 1 && obj.operation[0]['op'] === 'BREAK_LOOP') {
                    _t.push( {  op_line:0, var_line:0, in:true, store : "break", line : line ,  highlight: true, isFunction:false, value:'BREAK_LOOP', isOpr:false  }   )
                    _comp = _comp.concat(_t)
                    break
                } else if ( obj.operation.length === 1 && obj.operation[0]['op'] === 'G_ELSE') {
                    _t.push( {  op_line:0, var_line:0, in:true, store : "else"  , line : line ,  highlight: false, isFunction:false, value:"G_ELSE", isOpr:false, isElse:true   }   )
                    _t.push( {  op_line:0, var_line:0, in:true, store : ":"  , line : line ,  highlight: false, isFunction:false, value:":", isOpr:false, isElse:false   }   )
                    _comp = _comp.concat(_t)
                    break
                } else if ( obj.operation.length === 1 && ( obj.operation[0]['op'].indexOf("IMPORT_") > -1) ) {
                    _comp = []
                    _comp = _comp.concat(_t)
                    break
                }

                var _match = obj['body']?obj['body']:obj['stm']
                if (op['expression'] !== _match) {
                    _vars[  op['expression']   ] = _t 
                } else {
                    _comp = _comp.concat(_t)
                    break
                }
        }
        _stores = {}
        k = null
        for (k in treemap) {
            if ( parseInt(k, 10) < line ) {
                _o = treemap[k]
                if( _o['store'] ) {
                    if( _o['store'] === "IMPORT" ) {
                        for (var v in _o.operation) {
                            var opr = _o.operation[v]
                            for (var v1 in opr.vars) {
                                _stores[  opr.vars[v1] ] = parseInt(k, 10)
                            }
                        }
                    } else {
                        var _a = _o['store'].split(",")
                        for (var i in _a) {
                            _stores[  _a[i]  ] = parseInt(k, 10)
                        }
                        //_stores[  _o['store'] ] = parseInt(k, 10)
                    }
                }
            } else {
                break
            }
        }
        return { comp: _comp, stores : _stores, line:line , import : _import}
    },

    getStores : function(tp) {
        var _s = {}
        tp = JSON.stringify(tp)
        tp = JSON.parse(tp)
        for (var i in tp) {
            if(  tp[i].store  ) {
                var saves = tp[i].store.split(",")
                var _o = {}
                if(  saves.length === 1) {
                    var _k = tp[i].comment?tp[i].comment:tp[i].store
                    _o = {value:tp[i].store, line:i.toString() }
                    _s[_k] = _o
                } else {
                    for ( var j in saves ) {
                        _o = {value: saves[j] , line:i.toString() }
                        _s[ saves[j] ] = _o 
                    }
                }
            }
        }
        return _s
    },

    check : function(tp, t, line) {
      var _comment = "", o={}
      for ( var i in tp) {
        if(   parseInt(i, 10) <= parseInt(line, 10) ) {
          o = tp[i]
          if( o.store) { 
            if (o.store === t  ) {
              _comment = o.comment
            }
          }
        } else {
          break
        }
      }
      return (_comment.length>0)?_comment:(GService.gi18n[gglobal.language][t]?GService.gi18n[gglobal.language][t]:t)
    },

    getElses : function(tp, ln) {
        var ro = []

        function checker(t, ln) {
            if(  ln.toString() in t ) {
                ro.push( parseInt( ln, 10 )  )
                return { line : parseInt(  ln, 10 ), obj : t[ln.toString()] }
            } else {
                ro.push( parseInt( ln, 10 )  )
                return checker( t, parseInt(  ln, 10 )+1  ) 
            }
        }

        var r = checker(tp, ln)
        ln = r.line
        var obj = r.obj
        for (var i in tp) {
            if ( parseInt(i,10) > ln ) {
                var o = tp[i]
                if (    ( (o.if === obj.if) && (!o.start_else  )) || (o.if > obj.if)    ) {
                    ro.push( parseInt(i,10) )
                } else{
                    break
                }
            }
        }
        return ro
    },

    getIfs : function(tp, ln, obj) {
        var ro = [ln]
        var first = ln
        
        for (var i in tp) {
            var o = tp[i]
            if ( parseInt(i,10) > ln ) {

                if (    ( (o.if === obj.if) && ( (!o.start_else&&!o.start_condition) || (o.start_else))  ) || (o.if > obj.if)    ) {
                    var dif = parseInt(i,10)-first
                    for (var j = 1; j<dif; j++) {
                        ro.push( first+j )
                        
                    }
                    ro.push( parseInt(i,10) )
                } else{
                    break
                }

            }
            first = parseInt(i, 10)
        }
        return ro
    },

    getFors : function(tp, ln, obj) {
        var ro = [ln]
        for (var i in tp) {
            var o = tp[i]
            if ( parseInt(i,10) > ln ) {
                if (  (o.for === obj.for) && (!o.start_loop)    ) {
                    ro.push( parseInt(i,10) )
                } else{
                    break
                }
            }
        }
        return ro
    },

    returnObj : function(obj, code, buy) {      
        var codes = code.split( GService.split_cond  )
        codes = codes[0].split("\n")
        //var split_line = codes.length +1
        var split_line = codes.length 
        var _lines = Object.keys( obj )
        var res = {}
        for (var i in obj) {
            var valid = buy?(i<=split_line):(i>split_line)
            if( valid ) {
                res[i] = obj[i]
            }
        }
        var keys = Object.keys(  res  )
        //return { response: res, line:(  buy?_lines[0]:split_line  ) }
        return { response: res, line: (keys.length>0)?parseInt(keys[0]):0   }
    },

    getBSCode : function(code) {
        var bs = code.split( GService.split_cond  )
        var ss = bs[0].split("\n").length+2
        //console.log( ss )
        return { buy : bs[0], sell : bs[1], code : code, start_buy : 1, start_sell : ss }
    },

    updateExitStrategy : function(buy_code, sell_code) {
        var code = "", i = "", _str = ""
        //sell_code = sell_code?sell_code:templates.exit_strategy[0]
        sell_code = (sell_code!=null)?sell_code:templates.short_strategy[0]
        var _sell_code = "", _buy_code = ""

        if( typeof( buy_code ) === 'object' ) {
            _buy_code = buy_code.code
            for (i in buy_code.vars) {
                _str = "<" + i + ">"
                _buy_code = _buy_code.replace( _str, buy_code.vars[i]   )
            }
            _buy_code = this.updateStrategyComment( _buy_code, buy_code   )
            buy_code = _buy_code
        } /*else if (buy_code!=null) {
            code = buy_code +  "\n" + GService.split_cond  + "\n"  + sell_code
            buy_code = _buy_code
        }*/

        if( typeof( sell_code ) === 'object' ) {
            _sell_code = sell_code.code
            for (i in sell_code.vars) {
                _str = "<" + i + ">"
                _sell_code = _sell_code.replace( _str, sell_code.vars[i]   )
            }
            _sell_code = this.updateStrategyComment( _sell_code, sell_code   )

            code = buy_code +  "\n" + GService.split_cond  + "\n" + GService.split  + "\n"  + _sell_code

        } else if (sell_code!=null) {
            code = buy_code +  "\n" + GService.split_cond  + "\n"  + sell_code
            _sell_code = sell_code
        }
        return {  code : code, buy : buy_code, sell : _sell_code  }
    },

    updateStrategyComment : function(code, obj) {
        for (var c in obj.comments ) {
            code = code.replace( "<"+c+">" , obj.comments[c][gglobal.language] )
        }
        return code 
    },

    updateStrategyVariables : function(code, obj) {
        for (var i in obj) {
            var k = obj[i].key
            var v = obj[i].value
            code = code.replace( "<"+k+">" , v )
        }
        return code 
    },

    checkCondition : function (res, ln) {
        if(  ln in res) {
          var o = res[ln]
          for( var i in o.operation) {
            if(  o.operation[i].op==="STORE_NAME" ) {
              var e = o.operation[i].expression.split("=")
              if( e[0].toLowerCase() === 'condition_long' || e[0].toLowerCase() === 'condition_short' ) {
                
                return e[0].toLowerCase()
              }
            }
          }
          return false
        }
    },

    updatedChangeNames : function(treemap, line, code, oval, nval) {
        var _lines = code.split("\n")
        treemap = JSON.stringify(  treemap )
        treemap = JSON.parse( treemap )

        for (var i in treemap) {
            if ( parseInt(i, 10) > parseInt(line, 10) ) {
                var oprs = treemap[i]['operation']
                
                if( treemap[i]['store'] ) {
                    if( treemap[i]['store'] === oval  ) {
                        break;
                    }
                }
                for (var o in oprs) {
                    for (var _k in oprs[o].vars) {
                        if (oval === oprs[o].vars[_k]) {
                            var _in_idx = ( o+"|"+_k )
                            _lines = this.updateLine( _lines.join("\n"), treemap[i] , treemap, i, null, _in_idx,  nval , null)
                            oprs[o].vars[_k] = nval
                            treemap[i]['operation'] = oprs
                        }
                    }
                }
            }
        }
        return { code : _lines.join("\n") , treemap : treemap  }
    },


}


export default GService