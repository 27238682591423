import React from "react";
import gglobal from '../../GarlicanLibrary/gvars'

import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faCode, faSquareRootAlt, faTimesCircle, faFont, faPlus } from '@fortawesome/free-solid-svg-icons'


import GService  from '../../GarlicanLibrary/gservice'
import Opcode  from '../../GarlicanLibrary/opcode'

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/python";

import CustomNode from './customNode.js'


const templates = window.templates
var id = "GarlicanN"
var match= {
  'keyword' : faFont, 
  'format' : faCode,
  'math' : faPlus,
  'func' : faSquareRootAlt ,
}


var treemap = {}
var res = {}
var code = "", isBuy=true
var newLine = -1, oldLine = -1
var options ={}



var format =[], op_func=[], func=[], elses=[],elses_var=[], longs=[], shorts=[], defaults_var=[], func_var=[], op_func_var=[], opr_var=[], exits_var=[], op_only=[]


export default class TEXT extends React.Component {

  constructor(props) {
    super(props);  

    var keys = ['normal_var', 'if_var', 'for_var']
    for( var i in keys) {
      var val = GService.structure[ keys[i] ].stm 
      var display = (keys[i] in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][keys[i]]:val
      format.push(  {  display: display , value : val  }   )
    }
    for( var i in Opcode.op_func) {
      var rightL = Opcode.op_func[i].value
      var val = GService.structure[ "normal_var_structure"  ].stm.replace("<value>", rightL  )
      var display = Opcode.op_func[i].name[gglobal.language]
      op_func_var.push(  {  display: display , value : rightL   }   )
      op_func.push(  {  display: display , value : val  }   )
      op_only.push(  {  display: display , value : i  }   )
    }
    for( var i in  window.func) {
      var rightL =  window.func[i].format
      var val = GService.structure[ "normal_var_structure"  ].stm.replace("<value>", rightL  )
      var name =  window.func[i].name
      var desc =  window.func[i].desc[gglobal.language]
      for (var k in  window.func[i].defaults) {
        display = display.replace( "<"+k+">",  window.func[i].defaults[k] );
      }
      func_var.push(  {  display: name, value : rightL , desc : desc   }   )
      func.push(  {  display: name , value : val  , desc : desc   }   )
    }
    keys = ['else', 'elif']
    for( var i in keys) {
      var val = GService.structure[ keys[i]  ].stm
      display = this.props.gi18n[gglobal.language]['elses']
      elses.push(  {  display: display , value : val  }   )
    }
    keys = ['else_var', 'elif_var']
    for( var i in keys) {
      var val = GService.structure[ keys[i]  ].stm
      display = this.props.gi18n[gglobal.language]['elses']
      elses_var.push(  {  display: display , value : val  }   )
    }
    keys = ['normal_condition', 'if_condition_long', 'for_condition_long']
    for( var i in keys) {
      var display = keys[i].replace('_condition', "").replace('_long', "")
      var val = GService.structure[ keys[i]  ].stm.replace('<normal>', 'condition_long' )
      display = (display in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][display]:val
      longs.push(  {  display: display , value : val  }   )
    }
    keys = ['normal_condition', 'if_condition_short', 'for_condition_short']
    for( var i in keys) {
      var display = keys[i].replace('_condition', "").replace('_short', "")
      var val = GService.structure[ keys[i]  ].stm.replace('<normal>', 'condition_short' )
      display = (display in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][display]:val
      shorts.push(  {  display: display , value : val  }   )
    } 
    for( var i in GService.defaults) {
      var val = (GService.defaults[i] in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][GService.defaults[i]]:GService.defaults[i]
      defaults_var.push(  {  display: val , value : GService.defaults[i]  }   )
    }
    for( var i in GService.exits) {
      var val = (GService.exits[i] in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][GService.exits[i]]:GService.exits[i]
      exits_var.push(  {  display: val , value : GService.exits[i]  }   )
    }
    options = {
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      showLineNumbers: true,
      tabSize: 2,
      firstLineNumber: 1
    }
    this.editorN= React.createRef()

    this.state = {
      elemX : null,
      elemY : null,
      controllerY : null,
      positions : {},
      words : "",
      isError : false,
      stores : {},
      component : [],
      selected : false,
      onlyFunction : false,
      isEdited : false,
      isWord : true,
      type : null,
      dropdown : [],
      variables : [],
      operators : [],
      functions : [],
      elses: [],
      breaks: [],
      error_msg : null,
      selected_var : null,
      types : [],
      comp_gp : [],
      dragged : null,
      pageX : -1,
      pageY : -1,
      currentLine: null,
      isSinpet : false,
      currentObj : null,
      copyObj:null,
      filters : [ ],
      filterPicker : [],
      showFiter : false,
      templateLine : null,
      lineController : false,
      line : -1,
      index : -1,
      cfilters : [ ],
      cfilterPicker : [],
      ofilters : [ ],
      ofilterPicker : [],
    } 
    this.updateLines = this.updateLines.bind(this)
    this.getParent = this.getParent.bind(this)
    this.selectedLine = this.selectedLine.bind(this)
    this.replaceLine = this.replaceLine.bind(this)
    this.insertLine = this.insertLine.bind(this)
    this.removeCode = this.removeCode.bind(this)
    this.showError = this.showError.bind(this)
    this.changeCode = this.changeCode.bind(this)
    this.pasteCode = this.pasteCode.bind(this)
    this.onCursorChange = this.onCursorChange.bind(this)
    this.scrollWindow  = this.scrollWindow.bind(this)
    this.selectedObj = this.selectedObj.bind(this)
    this.insertElses = this.insertElses.bind(this)
    this.updateVariables = this.updateVariables.bind(this)

  }

  componentWillMount() {
    res = this.props.res?JSON.stringify( this.props.res ):"{}"
    treemap = this.props.treemap?JSON.stringify( this.props.treemap ):"{}"
    code = this.props.code
    isBuy = this.props.isBuy
  }

  componentDidMount() {
    this.updateLines(this.props)
    if(this.editorN.current) {
      const customMode = new CustomNode();
      this.editorN.current.editor.getSession().setMode(customMode);
      this.selectedLine()
    }
  }

  componentWillUnmount() {

  }

  scrollWindow(e) {
    this.setState({ isSinpet:false, lineController : false })
  }

  updateLines(props) {
    //console.log( props )
    if( !props.res || !props.treemap ) {
      return
    }
    res = JSON.stringify( props.res )
    treemap = JSON.stringify( props.treemap )
    res = JSON.parse( res )
    treemap = JSON.parse( treemap )
    var response = GService.returnObj( treemap, props.fullcode, props.isBuy  )
    var _res = response['response']
    var line = response['line']
    if( Object.keys(_res).length === 0 ) {
      var codes = props.code.split("\n")
      line = codes.length + 1
    } 
    var ro = { }, first = 0
    for (var i in _res) {
      ro[ parseInt(i, 10) ] = true
    }
    var lg = {}
    //console.log( Object.keys(_res)  )
    //console.log( treemap )
    //console.log(line)
    //console.log(code)
    //console.log(res)
    //console.log(ro)
    if( Object.keys(_res).length === 0 ) {
      lg = { end: line,start:line, group: {} }
    } else {
      //lg = GService.getLineGroup( treemap, parseInt(line, 10), code,res, {lines : ro } )
      lg = GService.getLineGroup( treemap, parseInt(line, 10), this.props.fullcode,res, {lines : ro } )
    }

    code = props.code
    res = JSON.stringify( props.res )
    res = JSON.parse( res )

    this.createCompGp( lg.group, res )

    //var lineNum= parseInt( (( Object.keys(response.response).length>0)?( Object.keys(response.response)[0]  ):0) , 10)
    //console.log( response )
    var lineNum= line
    if(!this.props.isBuy)
      lineNum += 1

    if(  this.editorN.current ) {
      var opt = this.editorN.current.editor.getOptions()
      opt.firstLineNumber =  lineNum
      this.editorN.current.editor.setOptions(opt)
      const customMode = new CustomNode();
      this.editorN.current.editor.getSession().setMode(customMode);
    }

  }

  componentWillReceiveProps( props ) {  

    var _this = this
    if(  _this.editorN.current ) {    
      this.selectedLine()
      if( props.error_line !== null ) {      
        var a =[
          {
            row: ( parseInt( props.error_line  , 10)-1 ),
            column: 10, // must be 0 based
            text: props.error_msg,
            type: "error"
          }
        ]
        _this.editorN.current.editor.getSession().setAnnotations(a)
      } else {
        _this.editorN.current.editor.getSession().setAnnotations([])
      }

      if ( 'start' in props.selectedLineNumber ) {
        var pos = _this.editorN.current.editor.session.selection.toJSON();
        var options = _this.editorN.current.editor.getOptions()
        pos.start.row = props.selectedLineNumber.start.row+(props.selectedLineNumber.firstLineNumber-options.firstLineNumber)
        pos.start.column = 0
        pos.end.row = props.selectedLineNumber.end.row+(props.selectedLineNumber.firstLineNumber-options.firstLineNumber) - ((props.selectedLineNumber.end.column===0)?1:0)
        pos.end.column = 999
        _this.editorN.current.editor.session.selection.fromJSON(pos);
        _this.props.updateValue({ selectedLineNumberT : {} })
        _this.setState({  isSinpet:false, lineController:false, showDropdown:false })
      }

    }

    this.setState({
      isError : this.props.error_msg
    })

    if( (props.locked !== "text") || (props.code!==code) ) {
      treemap = props.treemap
      code = props.code
      isBuy = props.isBuy
      setTimeout( (e)=>{ 
        _this.updateLines(props)  
      }, 100)
    }

  }

  createCompGp(tp, r) {
      gglobal.stores={}
      var comp_gp = [], words="", _t = [], j=0, dif = 0, l=0, ts=0, lastline=0;ts={}
      var len = (Object.keys( tp )).length
      var response = {}, _ot={}, stores={}, positions={}, obj = {}, group ={} , s= "", text="", start=0, end=0, nW="", dv=""
      var components = this.props.code.split("\n")
      var first = parseInt((Object.keys(tp).length>0)?Object.keys(tp)[0]:0)
      
      for (var i in tp ) {
        nW = ""
        if( parseInt(i, 10) !== (first) && !tp[i].start_else ) {
          dif = parseInt(i, 10)-first
          nW += (Array.from( {length: dif  }, (x,i) => "\n" )).join('')
          Array.from( {length: dif  }, (x,i) => comp_gp.push(null) )
        }
        
        obj = tp[i]

        positions[i]=[]

        response = GService.getLineComp(i, obj, res, treemap, true )

        group = parseInt((i in r)?i:GService.checkRLineFromLine( i, res ), 10)
        nW += (Array.from( {length:  ( (obj.if+obj.for)- (( obj.start_condition|| (obj.start_else)||obj.start_loop)?1:0))  }, (x,i) => "\t" )).join('')
        s = response.store 

        if( obj.store && !response.import ) {
          start = ((nW.replace(/\n/g, "")).length+1)
          dv = (obj.comment?obj.comment:((obj.store in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][obj.store]:obj.store) )
          var saves = obj.store.split(",")
          if ( saves.length > 1) {
            for (var j in saves) {
              gglobal.stores[ saves[j] ] = {value : saves[j] , line:i }
            }
            //console.log(gglobal.stores)
          } else {
            gglobal.stores[ dv ] = {value : obj.store , line:i }
          }
          

          nW += dv
          end= ( (nW.replace(/\n/g, "")).length)
          positions[i].push(   { ts:start, te:( (nW.replace(/\n/g, "").replace(/ /g,"")).length),   s:start, e:end, obj:{ store:obj.store, isDefine:true, comment:obj.comment }  } )     
        } 

        _t= response['comp']
        text =""
        for ( j in  _t) {
          text = (_t[j].store in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][_t[j].store ]:_t[j].store
          stores = JSON.parse( JSON.stringify(response.stores).toLowerCase() )
          start = (nW.replace(/\n/g, "").length  +1)
          ts = (nW.replace(/ /g, "").replace(/\n/g, "").length+1)
          if (  _t[j].store.toLowerCase() in stores  ) {
            _ot = treemap[  parseInt( stores[ _t[j].store.toLowerCase() ] , 10)  ]
            var a = _ot.store.split(",")
            if( a.length === 1 ) {
              text = (_ot.store?(_ot.comment?_ot.comment:_ot.store):text)
            } 
          }
          nW += text
          
          end= (start+text.length-1)
          positions[i].push(   { ts:ts ,te:(ts +(text.replace(/ /g,"").length)-1) , s:start, e:end, obj:_t[j]  } )
        }

        //console.log( positions )
        
        if (parseInt(i, 10) === (first) && tp[ i].start_else)  {
          nW = ( l < len-1 )?(nW+"\n"):nW
          words += nW
          //console.log(  i )
          //console.log(   obj  )
          comp_gp.push( {comp: response['comp'], line: parseInt(i, 10) , obj:obj , group: group} )
        } else if( parseInt(i, 10) !== (first) && tp[ i].start_else ) {
          var g = components.splice(  first-1, parseInt(i,10) -first+1  )
          var nG = nW.split("\n")
          var index =  (first)

          for (var ct in g) {
            var ln = g[ct].replace(/\/t/g, "").replace(/	/g, "")
            if (ln === '') {
              if( parseInt(ct,10) !== (g.length-1) ) {
                words += "\n"
              } else {
                words += "\n\n"
              }
              comp_gp.push( null )
            } else {

              //positions[index] = positions[i]
              //delete positions[i]
              words += (nG[0] )
              words += ((parseInt(ct,10) === (g.length-1) )?"\n":"")
              nG.pop()
              comp_gp.push( {comp: response['comp'], line: parseInt(i, 10) , obj:obj , group: group} )
            }
            index += 1
          }
        } else if( parseInt(i, 10) === first ) {
          nW = ( l < len-1 )?(nW+"\n"):nW
          words += nW
          comp_gp.push( {comp: response['comp'], line: parseInt(i, 10) , obj:obj , group: group} )
        } else {
        
          nW = ( l < len-1 )?(nW+"\n"):nW
          words += nW
          comp_gp.push( {comp: response['comp'], line: parseInt(i, 10) , obj:obj , group: group} )
        }


        l++
        first = (parseInt(i,10)+1)

      }

      //console.log(  comp_gp )
      this.setState({
        comp_gp : comp_gp,
        words : words,
        positions : positions
      })

      //console.log( comp_gp )

      if( this.editorN.current  ) {
        const customMode = new CustomNode();
        this.editorN.current.editor.getSession().setMode(customMode);
      }
      //console.log(words)
  }

  _dumError(msg) {
  }

  checkErrorObj(msg, line) {
    /*if ( parseInt(line, 10) === parseInt( this.state.line, 10) && msg !== 'null'  ) {
      this.setState({  error_msg : msg, isError:true  })
    } else {
      //console.log(msg)
    }*/
  }

  showError() {    
    var _this = this
    if(  !this.props.error_line  ) {
      var r = window.confirm(  this.props.error_msg  )
      if (r === false || r) {
        //console.log('RETORE')
        this.props.updateCode( this.props.restoreCode, true  )
      }
    } else {
      var r = window.confirm(  this.props.error_msg + "\n\n" +  this.props.gi18n[gglobal.language]['error_control'] )
      if (r === false) {
        
        //console.log('RETORE')
        this.props.updateCode( this.props.restoreCode, true  )

      } else {

        var ln = parseInt(newLine , 10)

        var _t = JSON.stringify( this.props.treemap )
        var r = JSON.stringify( this.props.res )
        
        _t = JSON.parse(_t)
        r = JSON.parse(r)
        var robj = r[ln] 
        var s = "", c= this.props.code


        function toRemove(s, c, _t, ln) {
          var lns = {}
            if ("op" in robj) {
              for (i in robj.op) {
                lns[  parseInt( robj.op[i].line, 10)   ] = true
              }
            }
            for ( var l in _t ) {
              if ( parseInt(l, 10) >= parseInt(ln, 10) ) {
                var o = _t[l]
                for (var i in o.operation ) {
                  for (var v in o.operation[i].vars) {
                    if( s  === o.operation[i].vars[v]  ){
                      var _fo = GService.removeLinkage(c, o , _t, ln, s , i+"|"+v, null, null, true)
                      var lines = _fo.lines
                      o = _fo.tp
                      _t[ l ] = o
                      c = lines.join("\n")
                    }
                  }
                }
                if (o.store && o.store === s && !(l in lns)) {
                  break
                }
              }
            }
          return {code : c, treemap: _t }
        }
   
        var tobj = _t[this.props.error_line], _fo = {}
        
        if( robj && (!robj.start_condition && !robj.start_loop) ) {
            //SINGLE LINE 
            s = tobj['variables'][  this.props.error_opvar  ]
            _fo = toRemove( s, c, _t, oldLine )
            c = _fo.code


          this.props.updateCode( c, true )

        } else {

          //MOVE GROUP
          robj = robj?robj:GService.getFuzzyObject(r, this.props.error_line )

            s = tobj['variables'][  this.props.error_opvar  ]

            _fo = toRemove( s, c, _t, this.props.error_line )
            c = _fo.code
            this.props.updateCode( c , true)

        }
      }
    } 
  }
  
  insertLine(up, v) {
    console.log("INSERT")
    var tL = this.state.templateLine.value
    //console.log(tL)
    if(up) {
      this.props.insertLine(  v.line , up, tL )
    } else {
      this.props.insertLine(  v.line+1, false, tL  )
    }
    this.selectedLine()
  }

  removeCode( lo ) {
    var tp = JSON.stringify(this.props.treemap)
    tp = JSON.parse(tp)
    var code = this.props.code
    var o = tp[  parseInt( lo.line , 10) ]
    var lines = [], _vars = [], _var = ""
    var index = lo.line
    if ( index in this.props.res) {
      var r = this.props.res[lo.line] 
      if (  "lines" in  r ) {
        var _keys = Object.keys(r.lines)
        index = _keys[ _keys.length-1 ]
        for ( var _l in _keys   ) {
          var _obj = tp[ _keys[_l]  ]
          _vars.push(  _obj.store?_obj.store:"NONE"   )
        }
      } else {
        _vars = [ r.store?r.store:"NONE" ]
      }
    } else {
      _vars = [ lo.obj.store?lo.obj.store:"NONE" ]
    }

    var response = GService.returnObj( treemap, this.props.code, this.props.isBuy  )
    tp = JSON.stringify(response['response'])
    //console.log(response)

    for (var _v in _vars) {
      _var = _vars[_v]
      var _tp = JSON.parse(tp)

      for ( var l in _tp ) {

        if ( parseInt(l, 10) > parseInt(index, 10) ) {
          o = _tp[l]  

          for (var _i in o.operation ) {

            for (var v in o.operation[_i].vars) {

              if( _var === o.operation[_i].vars[v]  ){
                console.log(code)
                var l_obj = GService.removeLinkage(code, o , _tp, l, _var, _i+"|"+v, null, null, true)
              
                _tp = JSON.parse(tp)
                _tp[l] = l_obj.tp
                tp = JSON.stringify(  _tp )

                code = l_obj.lines.join("\n")
                

              }
            }
          } 
          if (o.store && o.store === _var   ) {
            break
          }      
        }
      }
    }


    lines = code.split("\n")

    var o = lo.obj, ro = null, lg=[], _obj, ln = null


    if( o.start_condition && !o.start_else  ) {
      ro = GService.getIfs( _tp, parseInt(lo.line,10) , o )
    } else if (o.start_else) {
      ro = GService.getElses( _tp, parseInt(lo.line,10) )
    } else if (o.start_loop) {
      ro = GService.getFors( _tp, parseInt(lo.line,10) , o )
    }


    var cond_name = null
    if (  lo.line in this.props.res  ) {
      o = this.props.res[  lo.line  ]
      if ( 'op' in o ) {
        var _k = []
        for ( var i in o.op ) {
          _k.push( o.op[i].store )
        }

        _k = "|" + _k.join("|") + "|"
        _k = _k.toLowerCase()
        if(  _k.indexOf( "|"+'condition_long'+"|") > -1  ) {
          cond_name = "condition_long"
        } else if(  _k.indexOf("|"+'condition_short'+"|") > -1  ) {
          cond_name = "condition_short"
        }
      }

    }


    
    //if(ro) {
    if (lo.line in this.props.res) {
      if (!( 'lines' in this.props.res[ lo.line ] )) { 

          lines.splice( lo.line-1, 1  )

      } else{
          var _idxs =  Object.keys(this.props.res[ lo.line ]['lines'])

          //console.log( code )

          var _s = parseInt(_idxs[0], 10)-1
          var _l = parseInt(_idxs[ _idxs.length -1 ], 10)
          for ( var i=_s; i<_l;i++ ){
            lines.splice( _s, 1  )
          }

          console.log( lines.join("\n") )
          /*var _cnt = lo.obj.if + lo.obj.for
          var _t = Array.from( {length:  (_cnt - (( lo.obj.start_condition|| (lo.obj.start_else)|| lo.obj.start_loop)?1:0))  }, (x,i) => "\t" )
          var stm = ""

          if ( !cond_name ) {
            stm = _t.join("") + "" +GService.structure['normal']['stm']
            stm = stm.replace("<normal>",  Math.round(Math.random(1)*1000)   )
          } else {
            stm = _t.join("") + cond_name + " = false"
          }
          
    
          lines.splice( _s, 0 , stm )*/
      }
    } else {
      
      if(ro) {
          var _start = parseInt(lo.line,10)
          //var _end = (ro.length>0)?parseInt(ro[ro.length-1], 10):( _start+1 )
        
          /*for (var i = _start;i <= _end; i++ ) {
            lines.splice( _start-1, 1  ) 
          }*/
          lines.splice( ro[0]-1, ro[ro.length-1]-ro[0]+1  ) 

          if( !lo.obj.start_else ) {
            var _cnt = lo.obj.if + lo.obj.for
            var _t = Array.from( {length:  (_cnt - (( lo.obj.start_condition|| (lo.obj.start_else)|| lo.obj.start_loop)?1:0))  }, (x,i) => "\t" )
            var stm = _t.join("") + "" +GService.structure['normal']['stm']
            stm = stm.replace("<normal>",  Math.round(Math.random(1)*1000)   )
            lines.splice( _start-1, 0 , stm )
          }


      } else {
        lines.splice( lo.line-1, 1  )
      }
    }

    
    //this.props.getTreemmapFromCode(  lines.join("\n")  )
    //this.props.updateValue( { overwrite : true } )

  }
  
  getParent(ele) {
    try {
      var line = ele.getAttribute('line')
      //console.log( line )
      if(line) {
        return line
      } else {
        //console.log(ele)
        ele = ele.parentElement
        return this.getParent(ele)
      }
    }
    catch(e) {
      //console.log(e)
      return null
    }
  }

  selectedLine(e) {

    if( !this.props.treemap ) {
      return
    }


    function cfilter(name, isCond) {
      var filters = []
      if( isCond === 'condition_long') {
        var ft = JSON.parse(JSON.stringify(longs).replace(/<normal>/g, name  ))
        var ofc = JSON.parse(JSON.stringify(op_func).replace(/<normal>/g, name  ))
        ofc.splice( 0,4 )
        filters = [   
          { key : 'format' , value : (ft.concat( ofc ))  },
        ]
      } else if( isCond === 'condition_short') {
        var ft = JSON.parse(JSON.stringify(shorts).replace(/<normal>/g, name  ))
        var ofc = JSON.parse(JSON.stringify(op_func).replace(/<normal>/g, name  ))
        ofc.splice( 0,4 )
        filters = [   
          { key : 'format' , value : (ft.concat( ofc ))  },
        ]
      } else if( isCond === 'else') {
        var ft = JSON.parse(JSON.stringify(elses_var).replace(/<normal>/g, name  ))
        filters = [   
          { key : 'format' , value : ft  },
        ]
      } else {
        var ft = JSON.parse(JSON.stringify(format).replace(/<normal>/g, name  ))
        var ofc = JSON.parse(JSON.stringify(op_func).replace(/<normal>/g, name ))
        var fc = JSON.parse(JSON.stringify(func).replace(/<normal>/g, name   ))
        filters = [   
          { key : 'format' , value : (ft.concat( ofc )) },
          { key : 'func' , value: fc },  
        ]
      }
      return filters 
    }

    var sR = this.editorN.current.editor.getSelectionRange();

    if(!e) {
      var options = this.editorN.current.editor.getOptions()
      var obj = this.props.treemap[ options.firstLineNumber ]
      var name = "var_"+Math.round(Math.random(1)*1000) 
      var filters = cfilter(name)
      var o = {  showDropdown:false, filters:filters, filterPicker:filters[0].value }
      //this.setState({  showDropdown:false, filters:filters, filterPicker:filters[0].value, templateLine :  filters[0].value[0]  })
      if( !this.state.templateLine ) {
        o.templateLine = filters[0].value[0]
      }
      this.setState(  o )
      return
    }

    //if( this.props.locked === "text" ) {
      var options = this.editorN.current.editor.getOptions()
      sR.firstLineNumber = options.firstLineNumber
      
      this.props.updateValue({ selectedLineNumberC : sR})
      
      var index = sR.start.row
      var line = options.firstLineNumber + sR.start.row
      var  obj = this.props.treemap[  line ]
      var name = "var_"+Math.round(Math.random(1)*1000) 
      var filters = cfilter(name)

      var pg = this.state.positions[line], valid = false

      for (var i in pg) {
        if(    (pg[i].s <= (sR.start.column+1)) &&  (pg[i].e >= (sR.end.column+1))    ) {
          valid = (sR.start.row === sR.end.row)
          break
        }
      }
      
      if(  pg && pg.length > 0 && !valid) {
        if(  (this.state.line !== line) || (sR.start.column !== sR.end.column) || (sR.start.row!== sR.end.row)   ) {
          this.selectedObj(pg[0], false)
          var _this = this
          _this.setState({ lineController : true })
          
        } else {
          this.onCursorChange()
        }
      } else {
        this.onCursorChange()
      }

      var conFilters=[]
      if( index in this.state.comp_gp   ) {
        if(  this.state.comp_gp[ index]  ) {
          if(  this.state.comp_gp[ index ].obj.store  ) {
            if (  (this.state.comp_gp[ index].obj.store.toLowerCase() === 'condition_long')  || (this.state.comp_gp[ index].obj.store.toLowerCase() === 'condition_short')  ) {
              conFilters=cfilter( this.state.comp_gp[index].obj.store,  this.state.comp_gp[index].obj.store  )
            } else {
              conFilters=cfilter( this.state.comp_gp[index].obj.store)
            }
          } else {
            if( this.state.comp_gp[ index].obj.start_else  ) {
              var _obj = GService.getFuzzyObject(treemap, line, true)
              var ro = GService.getElses( this.props.treemap, line )
              conFilters=cfilter( this.state.comp_gp[index].obj.store,  "else"  )
            } else if( this.state.comp_gp[ index].obj.start_condition  ) {
              var isC = GService.checkCondition( res, line )
              if( isC) {
                conFilters=cfilter( isC,  isC  )
              } else {
                conFilters=filters
              }
            } else if( this.state.comp_gp[ index].obj.start_loop  ) {
              var isC = GService.checkCondition( res, line )
              if( isC) {
                conFilters=cfilter( isC,  isC  )
              } else {
                conFilters=filters
              }
            }
          }
        } else {
          
        }
      }
      this.setState({  showDropdown:false  , filters:filters, filterPicker: (filters.length>0)?filters[0].value:[], cfilters:conFilters, cfilterPicker: (conFilters.length>0)?conFilters[0].value:[]  })
    //}
  }

  changeCode(e) {
    /*if( this.props.locked === "text" ) {
      var _this = this;
      console.log(e)
      if(  e.indexOf( GService.split_cond )>-1 ) {
        //_this.props.updateCode(  e , false )
        //_this.props.updateValue( { error_msg : null, error_line : null, error_opvar : null  } )
      } 
    }*/
    //console.log(e)
  }

  pasteCode(e) {
    var txt = e.text
    if( this.props.locked === "text" ) {
      var _this = this;
      
      var _t = txt.split("\\n")
      txt = _t.join("\n")
      _t = txt.split("\\t")
      txt = _t.join("\t")
      _t = txt.split("\\r")
      txt = _t.join("\r")

      if(  txt.indexOf( GService.split_cond )>-1 ) {
        _this.props.updateCode(  txt , false )
        _this.props.updateValue( { error_msg : null, error_line : null, error_opvar : null } )
      } else {
        if( txt.toLowerCase().indexOf( 'condition_short' )>-1 )  {
          var code = this.props.buy + "\n" + GService.split_cond + "\n" + txt
          _this.props.updateCode(  code , false )
          _this.props.updateValue( { error_msg : null, error_line : null, error_opvar : null  } )
        } else if( txt.toLowerCase().indexOf( 'condition_long' )>-1 )  {
          var code = txt + "\n" + GService.split_cond + "\n" + this.props.sell
          _this.props.updateCode(  code , false )
          _this.props.updateValue( { error_msg : null, error_line : null, error_opvar : null  } )
        }
      }
    }

  }

  onCursorChange(e) {
    if( (e) && (e.cursor.coloum === 0) ) {
      this.selectedLine(e)
      return
    }
    if( this.editorN.current ) {
        this.setState({ isSinpet : false, elemX:null, elemY : null, currentObj :null, copyObj:null, lineController:false })
        var sR = this.editorN.current.editor.getSelectionRange();
        var options = this.editorN.current.editor.getOptions()
        if ( sR.start.row === sR.end.row ) {
          var line = options.firstLineNumber+sR.start.row
          if( !this.state.dragged ) {
            var pg = this.state.positions[line]
            for (var i in pg) {
              if( ( (pg[i].s-1)<= sR.start.column) &&( (pg[i].e-1) >= sR.end.column)  ) {
                var o = pg[i].obj
                if(  'highlight' in o  ) {
                  if( o.highlight ) {
                    //console.log(o)
                    this.selectedObj(pg[i], true)
                  } else {
                    this.setState({ isSinpet : false, elemX:null, elemY : null, currentObj:null, copyObj:null })
                  }
                } else if(  'isDefine' in o  ) {
                  //console.log( 'Name' )
                  this.selectedObj(pg[i], true)
                } else {
                  //console.log('Nothing')
                  this.setState({ isSinpet : false, elemX:null, elemY : null, currentObj :null, copyObj:null })
                }
              }  
            }
          } else {
            this.setState({currentLine:line  })
          }
        }
      }
  }

  selectedObj(obj, BOOL) {
    try {
      var ofilters = []
      var sR = this.editorN.current.editor.getSelectionRange();
      var line = sR.start.row
      var options = this.editorN.current.editor.getOptions()
      var sLine = options.firstLineNumber+sR.start.row
      var lo = this.state.comp_gp[ line ]

      //var _s = GService.getStores(  treemap  )

      function filter(type) {
        var stores = GService.getStores(  treemap  )
        var s = [], f=[]
        if(type === 'normal') {
          for (var k in stores) {
            if( parseInt( stores[k].line, 10 ) < sLine  ) {
              if( stores[k].value !== "IMPORT" )  
                s.push({ display : ( "["+stores[k].line+"] "+k), value : stores[k].value  })
            }
          }
          f = [   
            { key : 'keyword' , value : s.concat(defaults_var) },
            { key : 'math' , value : op_func_var },
            { key : 'func' , value: func_var  },  
          ]
        } else if(type === 'operator')  {
          f = [   
            { key : 'format' , value : op_only },
          ]
        }
        return f
      }

      if(!lo) {
        return 
      }
      var offset = lo.obj.if+lo.obj.for - ((lo.obj.start_condition || lo.obj.start_else || lo.obj.start_else)?1:0)
      var k =1
      var nW = ""
      var tele = document.getElementById(id).getElementsByClassName("ace_line_group")[0]
      var cele = document.getElementById(id).getElementsByClassName("ace_content")[0]
      var th = cele.style.transform.replace( "translate(", "" ).replace(/px/g, "").replace(")", "")
      th = th.split(",")
      if( th.length>1 ) {
        th = parseInt(th[1], 10 )
      } else {
        th = 0
      }
      var top = 0
      if( tele ) {
        top = (parseInt(tele.style.top.replace("px", "") , 10) - th)
      }
      var ele = document.getElementById(id).getElementsByClassName("ace_gutter-cell")[0]
      var fIndex = parseInt(ele.innerText) - options.firstLineNumber
      if( fIndex !== 0 ) {
        line -= fIndex
      }

      if( document.getElementById(id).getElementsByClassName("ace_line_group") && (line>=0) ) {
        if(   !(line in  document.getElementById(id).getElementsByClassName("ace_line_group"))  ) {
          return
        }
        var felems = document.getElementById(id).getElementsByClassName("ace_line_group")[line].getElementsByClassName('ace_line')
        var elems = []
        for (var j in felems) {
          if( felems[j].children ) {
            for (var e in felems[j].children ) {
              if( felems[j].children[e].innerText !== undefined ) {
                elems.push( felems[j].children[e]   )
              }
            }
          }
        }

        for (var i in elems) {

          if( elems[i].innerText !== undefined) {

            nW += elems[i].innerText
            k = nW.length+offset

            if(   obj.ts<=k && k <=obj.te ) {


              if(obj.obj.isOpr) {
                ofilters=filter('operator')
              } else {
                ofilters=filter('normal')
              }


              var x = elems[i].offsetLeft + elems[i].parentElement.parentElement.offsetLeft
              var y = elems[i].offsetTop + elems[i].parentElement.parentElement.offsetTop + elems[i].offsetHeight + (elems[i].parentElement.parentElement.parentElement?elems[i].parentElement.parentElement.parentElement.offsetTop:0)
              var gutter = document.getElementById(id).getElementsByClassName("ace_gutter")[0]
              var elemX = x + document.getElementById(id).offsetLeft + gutter.offsetWidth
              var elemY = y + document.getElementById(id).offsetTop -top 

              
              if( elemX > document.getElementById(id).clientWidth - 160 ) {
                elemX = document.getElementById(id).clientWidth- 160
              }
              if( elemX > document.getElementById(id).clientWidth - 160 ) {
                elemX = document.getElementById(id).clientWidth- 160
              }

              var opt = {isSinpet : BOOL, currentObj : obj, copyObj : obj, line : sLine, index : line }
              
              if( !obj.obj.isDefine  ) {
                opt.ofilters = ofilters
                opt.ofilterPicker = ofilters[0].value
              } else {
                opt.ofilters = null
                opt.ofilterPicker = null
              }
              
              if(!BOOL) {
                opt.controllerY = elemY
              } else {
                opt.elemY = elemY
                opt.elemX = elemX
                opt.lineController = false
              }


              this.setState(opt)
              break
            }

            //nW += elems[i].innerText
            //k = nW.length+offset
            
            
          }
        }
      }
    }
    catch(e) {
      console.log(e)
    }
  }

  replaceLine(v) {
    //this.setState( {  templateLine : v  }  )
    var sR = this.editorN.current.editor.getSelectionRange();
    var options = this.editorN.current.editor.getOptions()
    var line = options.firstLineNumber + sR.start.row
    var index = sR.start.row
    var obj = this.state.comp_gp[ index ]
    if( obj.obj.start_else ) {
      var code = this.props.code
      var lg = this.props.code.split("\n")
      var _t = obj.obj.if + obj.obj.for -1
      var _jumps = Array.from( {length: ( obj.obj.if + obj.obj.for -1 )  }, (x,i) => "\t" )
      var txt = _jumps.join("") + v.value 
      lg.splice( line-1, 1   )
      lg.splice( line-1, 0, txt   )
      code = lg.join("\n")
      //this.props.getTreemmapFromCode( code )

    } else if( obj.obj.start_condition ) {
      
      var ro = GService.getIfs( this.props.treemap, line, obj.obj )
      var code = this.props.code
      var lines = code.split("\n")
      var _jumps = Array.from( {length: ( obj.obj.if + obj.obj.for -1 )  }, (x,i) => "\t" )
      var txt = _jumps.join("") + v.value 

      lines.splice( ro[0]-1, (ro[ro.length-1]-ro[0]+1)    )
      lines.splice( line-1, 0, txt   )
      code = lines.join("\n")

      this.props.updateCode( code, true )


    } else if( obj.obj.start_loop ) {
      var ro = GService.getFors( this.props.treemap, line, obj.obj )
      var code = this.props.code
      var lines = code.split("\n")
      var _jumps = Array.from( {length: ( obj.obj.if + obj.obj.for -1 )  }, (x,i) => "\t" )
      var txt = _jumps.join("") + v.value 

      lines.splice( ro[0]-1, (ro[ro.length-1]-ro[0]+1)    )
      lines.splice( line-1, 0, txt   )
      code = lines.join("\n")


      this.props.updateCode( code, true )

    } else {

      this.props.insertLine(line, true, v.value, true) 


    }
  }

  insertElses(obj){
    var ro = [], index = -1;
    var _elif = GService.structure['elif'].stm
    if( obj.obj.start_condition ) {
      ro = GService.getElses( treemap, obj.line )
      index = ro[ ro.length-1 ]
      
    } else {
      ro = GService.getElses( treemap, obj.line )
      index = ro[ 0 ] -1
    }
    _elif = _elif.split("\n").reverse()
    var _cnt = obj.obj.if + obj.obj.for
    var _a = [1,1]

    var _lg = code.split("\n")

    for (var k in _elif) {
      var _t = Array.from( {length:  _cnt - _a[k] }  , (x,i) => "\t" )
      var _stm = _t.join("") + _elif[k]
      _lg.splice( index, 0, _stm )
    }
    this.props.updateCode( _lg.join("\n") , true  )

  }

  updateVariables(e) {
    if(  (this.state.copyObj.obj.store === "") || (this.state.copyObj.obj.value === "")  ) {
      alert( this.props.gi18n[ gglobal.language ]['error_name']  )
      return
    }
    var sR = this.editorN.current.editor.getSelectionRange();
    var options = this.editorN.current.editor.getOptions()
    var line = sR.start.row + options.firstLineNumber
    var a = this.state.positions[ line  ]
    var stm = "", lines = [], l={},_in_idx="", v=""
    var tp = JSON.stringify( treemap )
    tp = JSON.parse( tp )
    for (var i in a) {
      var  o = a[i]
      if( (o.ts === this.state.copyObj.ts) && (o.te === this.state.copyObj.te) ) {                      
        if( !this.state.copyObj.obj.isOpr ) {
          if( !this.state.copyObj.obj.isDefine ) {
            _in_idx = this.state.copyObj.obj.op_line+"|"+ this.state.copyObj.obj.var_line
            lines = GService.updateLine( this.props.code, treemap[ line ] , treemap, line, null, _in_idx,  this.state.copyObj.obj.store , null)
          } else {
            l = tp[line]
            l.comment = this.state.copyObj.obj.comment
            l.store = this.state.copyObj.obj.store
            tp[line] = l

            for (var opr in l.operation  ) {
              if(  l.operation[opr].op.indexOf('STORE_') > -1  ) {
                for (var idx in l.operation[opr].vars ) {
                  if(  l.operation[opr].vars[idx].indexOf("=") > -1 ) {
                    l.operation[opr].vars[idx] = (l.store+"=")
                    tp[line] = l
                    v = (l.store+" =")
                    _in_idx = opr+"|"+ idx
                    lines = GService.updateLine( this.props.code, tp[ line ] , tp, line, null, _in_idx,  v , null)
                    break
                  }
                }
              }
            }
            
            if(  this.state.currentObj.obj.store !== l.store ) {
              var t = GService.updatedChangeNames(tp, line, lines.join("\n"), this.state.currentObj.obj.store ,l.store ) 
              tp = t['treemap']
              lines = t['code'].split("\n")
            }
            
         }
        } else {             
          var opr_obj = Opcode.op_func[  this.state.copyObj.obj.value   ]
          l = tp[line].operation[     parseInt(this.state.copyObj.obj.op_line, 10)     ]
          l.opcode = opr_obj.code
          l.value = this.state.copyObj.obj.value
          tp[line].operation[     parseInt(this.state.copyObj.obj.op_line, 10)     ] = l
          v = tp[line].operation[     parseInt(this.state.copyObj.obj.op_line, 10)     ].vars[  parseInt(this.state.copyObj.obj.var_line,10)   ]
          _in_idx = this.state.copyObj.obj.op_line+"|"+ this.state.copyObj.obj.var_line
          lines = GService.updateLine( this.props.code, tp[ line ] , tp, line, null, _in_idx,  v , null)
        }
      }
    }        
    
    this.props.updateCode(  lines.join("\n"), true   )
    //this.props.updateValue({ overwrite : true  })

  }

  render() {
    var _this = this
    return (
      <div 
        onMouseEnter={  
          (e)=> {
            if( this.props.locked !== "text" ){
              this.props.updateValue({
                locked : "text"
              })
            }
          }
        }
        onMouseLeave={  
          (e)=> {
              //this.props.updateValue({
              //  locked : null
              //})
          }
        }
        id={'Garlican-Text-Frame'} 
        className={['Garlican-Text-Frame','white-color', !this.props.isIDEShow?'Garlican-Text-Frame-Show':'Garlican-Text-Frame-Hide'  ].join(' ')} >
          
          {this.state.lineController &&     
              <div 
                style={ {'top': (this.state.controllerY+"px"), 'right': "0px" } }
                className={["Garlican-Text-Line-Controller" ].join(' ')}>
                    <span> {  this.props.gi18n[this.props.language][ 'lineno' ].replace("<line>", this.state.line) } </span>

                    {
                      (
                        (this.state.index in this.state.comp_gp)
                        && ( this.state.comp_gp[ this.state.index ] ) 
                        && ( 
                            (!this.state.comp_gp[ this.state.index ].obj.store && !GService.checkCondition(res, this.state.line) )   
                            || 
                            ( (((this.state.comp_gp[ this.state.index ].obj.if>0) || (this.state.comp_gp[ this.state.index ].obj.for>0)) && !GService.checkCondition(res, this.state.line)     ) || (this.state.comp_gp[ this.state.index ].obj.store && ((this.state.comp_gp[ this.state.index ].obj.store.toLowerCase()  !== 'condition_long') && (this.state.comp_gp[ this.state.index ].obj.store.toLowerCase()  !== 'condition_short'))) )  
                            )
                      ) &&
                      <div
                        onClick={  (e)=>{
                          if(  this.state.index in this.state.comp_gp ) {
                            if ( this.state.comp_gp[ this.state.index ].obj.store ) {
                              if ( (this.state.comp_gp[ this.state.index ].obj.store.toLowerCase()  === 'condition_long') || (this.state.comp_gp[ this.state.index ].obj.store.toLowerCase()  === 'condition_short') ) {
                                return 
                              } else {
                                this.removeCode(  this.state.comp_gp[ this.state.index ]  )
                             }
                            } else {
                              this.removeCode(  this.state.comp_gp[ this.state.index ]  )
                            }
                         
                          } else {
                          }
                        } }
                        className={[
                          "Garlican-Text-Line-Controller-Remove", 
                          ].join(' ')}>
                          {  this.props.gi18n[gglobal.language]['delete'] }
                      </div>
                    }
                    {
                        (this.state.index in this.state.comp_gp) &&
                        (this.state.comp_gp[this.state.index])&&
                        (this.state.comp_gp[this.state.index].obj.start_condition || this.state.comp_gp[this.state.index].obj.start_else ) &&
                       <div
                          onClick={ (e)=>this.insertElses( this.state.comp_gp[this.state.index] ) }
                          className={[
                          "Garlican-Text-Line-Controller-Add-Elses", 
                          ].join(' ')}>
                          {  this.props.gi18n[gglobal.language]['insert_elses'] }
                      </div>
                    }


                    
                    <div className={["Garlican-Text-Line-Controller-Board" ].join(' ')}>
                      
                      <div className={["Garlican-Text-Line-Controller-Board-Content"].join(' ')}>
                        { 
                          this.state.cfilterPicker.map( (v, k)=> {
                            return(
                              <div 
                                onClick={  (e)=>{  this.replaceLine(v)}}
                                key={k} className={["Garlican-Text-Line-Controller-Board-Content-Line"].join(' ')}>
                                 <div className={["Garlican-Text-Line-Controller-Board-Content-Line-Name" ].join(" ")}> { v.display } </div>
                                 <div className={["Garlican-Text-Line-Controller-Board-Content-Line-Desc" ].join(" ")}> { ('desc' in v)?((v.desc.length>0)?v.desc:v.value):v.value  } </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className={["Garlican-Text-Line-Controller-Board-Controller" ].join(' ')}>
                          { 
                            this.state.cfilters.map( (v,k) => {
                              return(
                                <FontAwesomeIcon icon={  match[v.key]  } 
                                  key={k} 
                                  onClick={ (e)=>{ this.setState({ cfilterPicker : v.value    }) }  }
                                  className={["Garlican-Text-Line-Group-Line-Board-Controller-Select-Fa"].join(' ')}/>
                              )
                            } )
                          }
                      </div>
                    </div>
              </div>
          }
          
          { this.state.isSinpet && 
            <div 
              style={ {'top': (this.state.elemY+"px"), 'left': (this.state.elemX+"px") } }
              className={["Garlican-Text-Sinpet" ].join(' ')}>
               <input className={["Garlican-Text-Sinpet-Title" ].join(' ')} 
                  placeholder={ this.props.gi18n[gglobal.language]['name_placeholder'] }
                  onChange={ (e)=>{
                      var o = JSON.stringify(this.state.copyObj)
                      o = JSON.parse( o )
                      o.obj.store = e.target.value
                      o.obj.value = e.target.value
                      var of = null
                      if( !o.obj.isDefine ) {
                        of = []
                        for (var l in this.state.ofilters) {
                          var f = this.state.ofilters[l]
                          for (var i in f.value) {
                            if ((  f.value[i].desc&&(f.value[i].desc.toLowerCase().indexOf( e.target.value  ) > -1) )||(f.value[i].value.toLowerCase().indexOf( e.target.value  ) > -1)||(f.value[i].display.toLowerCase().indexOf( e.target.value  ) > -1) ) {
                              of.push(  f.value[i] )
                            }
                          }
                        }
                      }

                      this.setState( { copyObj : o,  ofilterPicker:of  }  )

                    

                  } }
                  value={   this.state.copyObj.obj.value?this.state.copyObj.obj.value:this.state.copyObj.obj.store   }  
                  readOnly={ ( (this.state.copyObj.obj.store.toLowerCase()==='condition_long') || (this.state.copyObj.obj.store.toLowerCase()==='condition_short') ) }  />

              { this.state.copyObj.obj.isDefine && 
               <input 
                onChange={ (e)=> {
                  if( this.state.copyObj.obj.isDefine ) {
                    var o = JSON.stringify(this.state.copyObj)
                    o = JSON.parse( o )
                    o.obj.comment = e.target.value
                    this.setState( { copyObj : o  }  )
                  }
                }}
                placeholder={this.props.gi18n[gglobal.language][ this.state.copyObj.obj.isDefine?'comment_placeholder':'search_function_placeholder']  } 
                className={["Garlican-Text-Sinpet-Title-Input" ].join(' ')} 
                value={ this.state.copyObj.obj.isDefine?(this.state.copyObj.obj.comment?this.state.copyObj.obj.comment:""):''} />
              }
                
                { this.state.ofilterPicker && (this.state.ofilterPicker.length > 0) &&
                    <div style={ {'top' : !this.state.copyObj.obj.isDefine?"30px":"60px"  } } className={["Garlican-Text-Sinpet-Content"].join(' ')}>
                          { 
                            this.state.ofilterPicker.map( (v,k)=> {
                              return(
                                <div key={k} 
                                  onClick={ (e)=>{ 
                                    var o = JSON.stringify(this.state.copyObj)
                                    o = JSON.parse( o )
                                    o.obj.store = v.value
                                    o.obj.value = v.value
                                    this.setState( { copyObj : o  }  )
                                  } }
                                  className={["Garlican-Text-Sinpet-Content-Line"].join(' ')} >
                                  <div className={["Garlican-Text-Sinpet-Content-Line-Title"].join(' ')} >{ v.display   }</div>
                                  <div className={["Garlican-Text-Sinpet-Content-Line-Desc"].join(' ')} >{('desc' in v)?((v.desc.length>0)?v.desc:v.value):v.value}</div>
                                </div>
                              )
                            })
                          }
                    </div>
                }


                <div className={["Garlican-Text-Sinpet-Lower" ].join(' ')} >
                    { this.state.ofilters && 
                      this.state.ofilters.map( (v,k) => {
                        return(
                          <FontAwesomeIcon icon={  match[v.key]  } 
                            key={k} 
                            onClick={ (e)=>{ this.setState({ ofilterPicker : v.value    }) }  }
                            className={["Garlican-Text-Sinpet-Select-Fa"].join(' ')}/>
                           )
                      } )
                    }
                  

                  <div 
                    onClick={ (e)=>this.updateVariables(e) }
                    className={["Garlican-Text-Sinpet-Lower-Save" ].join(' ')} > { this.props.gi18n[gglobal.language]['save']} </div>
                </div>
            </div>
          }

          <div className={["Garlican-Text-Top" ].join(' ')} onClick={ (e)=>{ this.setState({ isSinpet:false, lineController:false }) } } >
                  {this.state.isError && 
                    <div 
                      className={["Garlican-Text-Line-Group-Line", "Garlican-Text-Line-Group-Line-Insert", !this.state.isError?'':'alert'   ].join(' ')  }
                      onClick={ (e) => this.showError() } > 
                      { this.props.error_msg } 
                    </div> 
                  }

                   <div
                      onClick={ (e)=>{ 
                          if(!this.state.isError) {
                            this.setState({ showDropdown:!this.state.showDropdown, lineController:false, isSinpet:false })
                          }
                      }}     
                      className={["Garlican-Text-Line-Group-Line-Insert-Select", !this.state.isError?'':'alert' ].join(' ')} >
                      <FontAwesomeIcon icon={ this.state.showDropdown?faChevronUp:faChevronDown} className={["Garlican-Text-Line-Group-Line-Insert-Select-Fa"].join(' ')}/>
                  </div>
                    
                  { this.state.showDropdown && this.state.filterPicker &&
                    <div 
                      className={["Garlican-Text-Line-Group-Line-Dropbox"].join(' ')} >
                        <div className={["Garlican-Text-Line-Group-Line-Dropbox-Content"].join(' ')}>
                          { 
                            this.state.filterPicker.map( (v,k)=> {
                              return(
                                <div key={k} 
                                  onClick={ (e)=>{ this.setState({ templateLine : v, showDropdown:false })  } }
                                  className={["Garlican-Text-Line-Group-Line-Dropbox-Content-Line"].join(' ')} >
                                  <div className={["Garlican-Text-Line-Group-Line-Dropbox-Content-Line-Title"].join(' ')} >{ v.display   }</div>
                                  <div className={["Garlican-Text-Line-Group-Line-Dropbox-Content-Line-Desc"].join(' ')} >{('desc' in v)?((v.desc.length>0)?v.desc:v.value):v.value}</div>
                                </div>
                              )
                            })
                          }
                        </div>
                        <div className={["Garlican-Text-Line-Group-Line-Dropbox-Control"].join(' ')}>
                          {
                            this.state.filters.map( (v,k) => {
                              return(
                                <FontAwesomeIcon icon={  match[v.key]  } 
                                  key={k} 
                                  onClick={ (e)=>{ this.setState({ filterPicker : v.value    }) }  }
                                  className={["Garlican-Text-Line-Group-Line-Insert-Select-Fa"].join(' ')}/>
                              )
                            } )
                          }
                        </div>
                    </div>
                  }


                  {!this.state.isError && 
                   <div 
                      id={"Garlican-Text-Line-Group-Line-Insert"}
                      draggable = { !this.state.isError }
                      className={["Garlican-Text-Line-Group-Line", "Garlican-Text-Line-Group-Line-Insert"].join(' ')} 
                      onMouseEnter={ 
                        (e)=> {
                          this.setState({
                            lineController:false, 
                            isSinpet:false 
                          })
                        }
                      }
                      onClick={   
                        (e)=>{
                            if( !this.state.isError ) {
                              var sR = this.editorN.current.editor.getSelectionRange();
                              var options = this.editorN.current.editor.getOptions()
                              sR.firstLineNumber = options.firstLineNumber
                              this.props.updateValue({ selectedLineNumberC : sR})
                               var line = options.firstLineNumber + sR.start.row
                              _this.insertLine(true,  { line: line } ) 
                            }
                          }
                      }
                      onDragStart={ (e)=>{
                        e.stopPropagation();
                        //e.preventDefault();
                        e.dataTransfer.setData('text', 'text');
                        if( GService.isCrome() ) {
                          _this.setState( { dragged :  e.currentTarget  } )
                        } 
                      } }
                      onDragOver={ (e)=>{
                        e.stopPropagation();
                        e.preventDefault();
                      } }
                      onDrop={
                        (e)=> {
                          e.preventDefault();
                          e.stopPropagation();                 
                        }
                      }
                      onDragEnd={
                        (e)=> {
                          e.preventDefault();
                          e.stopPropagation();
                          var o = { dragged : null, currentLine : null, isSinpet:false, showDropdown:false, lineController:false  }
                          if( this.state.currentLine )  {
                            this.insertLine( true , { line: this.state.currentLine  } )
                            o.currentLine = null
                            this.setState(o)
                          }
                        }
                      }
                      onTouchStart={ (e) => {
                          var cln = e.currentTarget.cloneNode(true);
                          cln.classList.add("Garlican_Selected_Drag");
                          cln.setAttribute('id', 'Garlican_Selected_Drag')
                          var x = e.targetTouches[0].pageX - e.target.clientWidth/2;
                          var y = e.targetTouches[0].pageY - document.getElementById("Garlican-Container").offsetTop - e.target.clientHeight/2;
                          var str = "left:" + x + "px;top:"+y+"px;opacity:0.9;" 
                          cln.setAttribute("style", str);
                          document.getElementById("Garlican-Text-Frame").appendChild(cln);
                          this.setState( { dragged : e.currentTarget } ) 
                        }
                      }
                      onTouchMove={ (e)=>{
                        if( this.state.dragged ) {
                          var x = e.targetTouches[0].pageX - e.target.clientWidth/2;
                          var y = e.targetTouches[0].pageY - document.getElementById("Garlican-Container").offsetTop - e.target.clientHeight/2;
                          var cln = document.getElementById('Garlican_Selected_Drag')
                          if(cln) {
                            var str = "left:" + x + "px;top:"+y+"px;" 
                            cln.setAttribute("style", str);
                          }
                        }
                      } }
                      onTouchEnd={ (e)=>{  
                        var cln = document.getElementById('Garlican_Selected_Drag')
                        var parent = document.getElementById('Garlican-Text-Frame')
                        parent.removeChild( cln )
                      }}
                      key="K_INDERT">
                        { this.props.gi18n[gglobal.language]['insert_line'] }
                        <div className={['Garlican-Text-Line-Group-Insert-Sub'].join(" ")}> {this.state.templateLine?this.state.templateLine.display:"" } </div>
                    </div>
                  }
            </div>
    
          <AceEditor
            wrapEnabled={true}
            width="auto"
            height="auto"
            id={"Garlican-Text-Line-Group"} 
            onLoad={ (e) => {
              e.renderer.setScrollMargin( 0,280,0,0 );      
            }}
            onSelectionChange={ (e)=>{
              if( this.props.locked === "text") {
                //console.log("Selection")
                this.selectedLine(e) 
              }
            }}
            onPaste={ (e)=>{  
              if( this.props.locked === "text") {
                this.pasteCode(e)
              }
            }  }
            onScroll={ (e)=>{  
              if( this.props.locked === "text") {
                this.scrollWindow(e)
              }
            }}
            onCopy={ (e)=>{ console.log('copy') }  }
            onBlur={ (e)=>{
              this.setState({showDropdown:false })
              //this.props.updateValue({ locked : null })
            } }
            onFocus={ (e)=>{                
                //var o = { dragged : null, currentLine : null, isSinpet:false, showDropdown:false, lineController:false  }
                //if( this.state.currentLine )  {
                  //this.insertLine( true , { line: this.state.currentLine  } )
                  //o.currentLine = null
                //}
                //this.props.updateValue({ locked : "text" })
                this.selectedLine()
                //this.setState( o  )
            } }
            onCursorChange={ (e)=> { 
              if( this.props.locked === "text") {
                //console.log("Cursor")
                this.onCursorChange(e)
              }
            } }
            onChange={  (e)=>{ 
              if( this.props.locked === "text") {
                //console.log("Change")
                this.changeCode(e)
              }
            } }
            ref={this.editorN} 
            fontSize={12}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            mode="python"
            theme="xcode"
            className={ ["Garlican-Text-Line-Group"].join(' ') } 
            name={id}
            value={ this.state.words }  
            editorProps={{ $blockScrolling: true }}
            setOptions={options}
            annotations={[]}
          />




      </div>
    );
  }
}


