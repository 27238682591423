import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import logoB from '../public/img/garlican_blue_logo.png'

import service from '../public/service'
//import i18n from '../public/i18n'
import { Link } from 'react-router-dom'

import { Modal, Image, Form } from 'react-bootstrap';

const login_frame = [
  { value : "username", type : 'email'  },
  { value : "password", type : 'password', length : 8  },
]

const retrieve_frame = [
  { value : "username", type : 'email'  },
  { value : "password", type : 'password', length : 8  },
  { value : "password", type : 'confirm_password', length : 8  },
]

const register_frame = [
  { value : "username", type : 'email'  },
  { value : "password", type : 'password', length : 8  },
  { value : "confirm_password" , type : 'password', length : 8 },
  { value : "last_name",type : 'normal'  },
  { value : "first_name",type : 'normal'  }
]

const links = [
  {url : '/privacy', name : 'privacy'},
  {url : '/terms', name : 'terms'},
  {url : '/support', name : 'support'}
]

class Login extends Component {

    constructor(props) {
        super(props)

        this.state = { 
          isLogin : !this.props.showSignup,
          username : "",
          password : "",
          confirm_password : "",
          email : "",
          first_name : "",
          last_name : ""
        };

        window.document.login = this;
        this.forget = this.forget.bind(this)
        this.restore = this.restore.bind(this)

        //console.log(props)
    }

    componentWillUnmount() {

    }



    change(key, value) {
      var obj = {}
      obj[key] = value
      this.setState(obj)
    }

    login() {
      service.login(this.state.username, this.state.password).then(
        (res) => {
          if ( !( res["detail"] ) ) {
            if ( !('password' in res) ) {
              service.token = res.access
              //service.getEjabberdToken().then(
              //  (r) => {

                  service.getUserProfile().then(
                    (user) => {

                      var tokens = res
                      //tokens.e_token = r.token
                      tokens.username = this.state.username
                      var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
                      storage.setItem("garlican@tokens", JSON.stringify(tokens) )
                      
                      service.user = user

                      this.props.updateValue( { 
                        isLogin : true ,
                        roomname : this.state.username,
                        username : this.state.username,
                        jid : user.user_id,
                        token : res.access,
                        refresh_token : res.refresh,
                        showLogin : false,
                        showLoading : true,
                        selectedUser : user,
                      })
                      //this.props.hasLogin( this.state.username, r.token )
                      this.props.hasLogin( this.state.username )
                  })



               // })
            } else {
              var obj = { header : 'Error', body : this.props.gi18n[  this.props.language ]['login_error'] }
              //alert(  this.props.gi18n[  this.props.language ]['login_error']   )
              this.props.openAlert(  obj  )
            }
        } else {
          var obj = { header : 'Error', body : this.props.gi18n[  this.props.language ]['login_error'] }
          //alert(  this.props.gi18n[  this.props.language ]['login_error']   )
          this.props.openAlert(  obj  )
        }

        }
      ).catch(
        (err) => {
          var obj = { header : 'Error', body : this.props.gi18n[  this.props.language ]['login_error'] }
          //alert(  this.props.gi18n[  this.props.language ]['login_error']   )
          this.props.openAlert(  obj  )
        }
      )
    }

    register() {
      //username, password, firstname, lastname, email

      if( service.validateEmail( this.state.username ) !== true  ) {
        var obj = { header : this.props.gi18n[  this.props.language ]['error'] , body : this.props.gi18n[  this.props.language ]['username_error'] }
        this.props.openAlert(  obj  )
        return
      }

      if(  (this.state.password === this.state.confirm_password) && (this.state.first_name.length > 0) && (this.state.last_name.length > 0) && (this.state.password.length >= 8)  ) {
        service.register(this.state.username, this.state.password, this.state.first_name, this.state.last_name, "").then(
          (res) => {
            //console.log(res)
            //var obj = { header : this.props.gi18n[  this.props.language ]['register_successful'] , body : this.props.gi18n[  this.props.language ]['register_message'].replace("<email>", this.state.username) }
            //this.props.openAlert(  obj  )
            this.login( this.state.username, this.state.password )
          }
        ).catch(
          (err) => {
            //console.log(err)
            var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['register_error'].replace("<email>", this.state.username) }
            this.props.openAlert(  obj  )
          }
        )
      } else if (this.state.password !== this.state.confirm_password) {
        //alert( this.props.gi18n[this.props.language]['password_unmatch_error'] )
        var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['password_unmatch_error'] }
        this.props.openAlert(  obj  )
        
      } else {
        //alert( this.props.gi18n[this.props.language]['length_unmatch_error'] )
        var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['length_unmatch_error'] }
        this.props.openAlert(  obj  )
      }
    }

    forget( username ){
      if( service.validateEmail( username ) !== true  ) {
        var obj = { header : this.props.gi18n[  this.props.language ]['error'] , body : this.props.gi18n[  this.props.language ]['username_error'] }
        this.props.openAlert(  obj  )
        return
      } else {
        service.forget(username).then( 
          (res) => {
            var obj = { header : this.props.gi18n[  this.props.language ]['resend'] , body : this.props.gi18n[  this.props.language ]['username_resend'].replace("<email>", this.state.username) }
            this.props.openAlert(  obj  )
          }
        )
      }

    }

    restore() {
      if( service.validateEmail( this.state.username ) !== true  ) {
        var obj = { header : this.props.gi18n[  this.props.language ]['error'] , body : this.props.gi18n[  this.props.language ]['username_error'] }
        this.props.openAlert(  obj  )
        return
      }
      if(  (this.state.password === this.state.confirm_password) && (this.state.password.length >= 8)  ) {
        service.restore(this.props.retrieve_key, this.state.username, this.state.password).then(
          (res) => {
            //this.login( this.state.username, this.state.password )
            var obj = { header : this.props.gi18n[  this.props.language ]['restore'], body : this.props.gi18n[  this.props.language ]['restore_successful'].replace("<email>", this.state.username) }
            this.props.openAlert(  obj  )
            this.props.updateValue({
              showLogin : true,
              retrieve_key : false
            })
          }
        ).catch(
          (err) => {
            console.log(err)
            var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['retore_error'].replace("<email>", this.state.username) }
            this.props.openAlert(  obj  )
          }
        )
      } else if (this.state.password !== this.state.confirm_password) {
        //alert( this.props.gi18n[this.props.language]['password_unmatch_error'] )
        var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['password_unmatch_error'] }
        this.props.openAlert(  obj  )
        
      } else {
        //alert( this.props.gi18n[this.props.language]['length_unmatch_error'] )
        var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['length_unmatch_error'] }
        this.props.openAlert(  obj  )
      }
    }

    render() {
        return (
          <Modal
              size="lg"
              show={this.props.showLogin}
              onHide={() => this.props.updateValue( {  showLogin  : false, retrieve_key : null  }  )}
              aria-labelledby="example-modal-sizes-title-lg"
              className={ ['Login-Holder'].join( " " ) }
          >
              <Modal.Header className={ ['Login-Title'].join( " " ) } closeButton>
                <div className={['Login-Title-Frame'].join(" ")}>
                  <Image className={['Login-Title-Logo'].join(" ")} src={logoB} />
                  <div className={['Login-Title-Title'].join(" ")} >GARLICAN</div>
                </div>
              </Modal.Header>
              
              <Modal.Body className={ ['Login-Body'].join( " " ) }>
                  <Modal.Title className={ ['Login-Body-Title'].join( " " ) }>
                    { this.props.retrieve_key?this.props.gi18n[this.props.language]['restore']:(this.state.isLogin?this.props.gi18n[this.props.language]['login']:this.props.gi18n[this.props.language]['register']) }
                  </Modal.Title>
                  
                  { this.state.isLogin && !this.props.retrieve_key &&
                    <Form>
                      {
                      login_frame.map(  (v,k) => {
                        return (
                          <Form.Control 
                            key={k} 
                            type={ v.type }
                            placeholder={  this.props.gi18n[this.props.language][v.value] }
                            value={ this.setState[v.value] } 
                            className={ ['Login-Input'].join( " " ) } 
                            onChange={ (e)=>this.change( v.value  ,e.target.value) } />
                        )
                      } )
                    }
                    </Form>
                  }
                  { !this.state.isLogin && !this.props.retrieve_key &&
                    <Form>
                      {
                        register_frame.map(  (v,k) => {
                          return (
                            <Form.Control key={k} 
                              type={ v.type  }
                              placeholder={ this.props.gi18n[this.props.language][v.value] }
                              value={ this.setState[v.value] } 
                              className={ ['Login-Input'].join( " " ) } 
                              onChange={ (e)=>this.change( v.value  ,e.target.value) } />
                          )
                        } )
                      }
                    </Form>
                  }

                  { this.props.retrieve_key &&
                    <Form>
                      {
                        retrieve_frame.map(  (v,k) => {
                          return (
                            <Form.Control key={k} 
                              type={ v.type  }
                              placeholder={ this.props.gi18n[this.props.language][v.value] }
                              value={ this.setState[v.value] } 
                              className={ ['Login-Input'].join( " " ) } 
                              onChange={ (e)=>this.change( v.value  ,e.target.value) } />
                          )
                        } )
                      }
                    </Form>
                  }


                  <div className={ ['Login-Stm-Frame', 'forget'].join( " " ) } onClick={()=>{
                    this.forget(  this.state.username )
                  }}>
                    {   this.props.gi18n[this.props.language]['password_forget']  }
                  </div>

                  <div className={ ['Login-Btn-Frame'].join( " " ) }>
                    { this.state.isLogin && !this.props.retrieve_key &&
                      [
                        <div key={0} className={ ['Login-Btn', 'btn'].join( " " ) } onClick={ (e)=>this.login() } > { this.props.gi18n[this.props.language]['login'] }</div>,
                        <div key={1} className={ ['Login-Btn', 'btn'].join( " " ) } onClick={(e)=>this.setState({isLogin : false})}>{this.props.gi18n[this.props.language]['to_register']}</div>
                      ]
                    }
                    { !this.state.isLogin && !this.props.retrieve_key &&
                      [
                        <div key={0} className={ ['Login-Btn', 'btn'].join( " " ) } onClick={ (e)=>this.register() } >{this.props.gi18n[this.props.language]['register']}</div>,
                        <div key={1} className={ ['Login-Btn', 'btn'].join( " " ) } onClick={(e)=>this.setState({isLogin : true})} >{this.props.gi18n[this.props.language]['back']}</div>
                      ]
                    }
                    { this.props.retrieve_key &&
                        <div className={ ['Login-Btn', 'btn'].join( " " ) } onClick={ (e)=>this.restore() } >{this.props.gi18n[this.props.language]['restore']}</div>
                    }
                  </div>

                  <div className={ ['Login-Link'].join( " " ) } >
                    {
                      links.map( (v,k) => {
                        return(
                          [ (k!==0)?(<span key={99 }>|</span>):null , 
                            <Link key={k} to={v.url} className={ ['Login-Link-a'].join( " " ) } >
                              { this.props.gi18n[this.props.language][v.name] }
                            </Link>
                          ]
                        )
                      } )
                    }
                  </div>


            </Modal.Body>
          </Modal>
        )
    }
}


export default Login;

//onHide={() => this.props.updateValue( {  showLogin  : false, retrieve_key : null  }  )}