import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import logoB from '../../../public/img/garlican_blue_logo.png'

import { InputGroup, FormControl, Image, Button, ButtonGroup } from 'react-bootstrap'


import service from '../../../public/service'
import Api from '../../../Garlican/GarlicanLibrary/apis'



class TLogin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            companyname : "GARLICAN"
        }

    }

    componentWillMount() {

    }

    componentDidMount() {    
    }

    componentWillReceiveProps(props) {
      
    }

    componentWillUnmount() {

    }

    render() {
        return (
          <div className={['trade-login'].join(" ")} >
              <div className={['trade-top'].join(" ")}> 
                <Image src={logoB} className={['trade-logo'].join(" ")} />
                <span className={['trade-title'].join(" ")}>{ this.state.companyname }</span>
              </div>

              <InputGroup className={["mb-3", "group"].join(" ")}>
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1" className={["label"].join(" ")} >{  this.props.gi18n[this.props.language]["t_username"]   }</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    className={["input"].join(" ")}
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={this.props.username}
                    onChange={ (e)=>{
                        this.props.updateValue( {
                            username : e.target.value
                        })
                    } }
                />
            </InputGroup>
            <InputGroup className={["mb-3", "group"].join(" ")}>
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1" className={["label"].join(" ")}>{   this.props.gi18n[this.props.language]["t_password"]   }</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    className={["input"].join(" ")}
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                    value={this.props.password}
                    type="password"
                    onChange={ (e)=>{
                        this.props.updateValue( {
                            password : e.target.value
                        })
                    } }
                />
            </InputGroup>


            <Button 
                onClick={  (e)=> {
                    this.props.login( this.props.username, this.props.password )
                }  }
                className={["tbtn"].join(" ")}>
                { this.props.gi18n[this.props.language]['login'] }
            </Button>


          </div>
        )
    }
}


export default TLogin;
