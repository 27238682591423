import React from "react";
//import gglobal from '../GarlicanLibrary/gvars'

import './index.scss'

import GService from '../../GarlicanLibrary/gservice'
import Api from '../../GarlicanLibrary/apis'
import service from '../../../public/service'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser } from '@fortawesome/free-solid-svg-icons'
import {  Button, Image, Badge, InputGroup, FormControl, Toast, Modal   } from 'react-bootstrap';

const SIZE = 30

export default class Content extends React.Component {

  constructor(props) {
    super(props);  
    
    this.state = {
      serachTemplate : '',
      templates : [],
      page : 0,
      obj : {},
      hasNext : false,
    } 
    
    this.reset = this.reset.bind(this)
    this.search = this.search.bind(this)
    this.getTemplates = this.getTemplates.bind(this)
    this.more = this.more.bind(this)

    //this.remove = this.remove.bind(this)
    //this.addStrategy = this.addStrategy.bind(this)
    
  }

  componentWillMount() {
  }

  componentDidMount() {
    //if(this.props.add) {
      this.getTemplates()
    //}
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(props) {
    //console.log(  props.showTemplates  )
    if( props.showTemplates === true ) {    
        //this.getTemplates()
    }
  }

  getTemplates(obj, append) {
    service.getTemplates( obj ).then(
      (res) => {

        var list = res.list, hasNext = false
        if( list.length >= SIZE ) {
          hasNext = true
        }
        if(append) {
          var templates = JSON.parse( JSON.stringify( this.state.templates ) )
          list = templates.concat( list )
        }
        this.setState({
          templates : list,
          page : res.page,
          hasNext : hasNext,
        })
      }
    )
  }

  reset() {
    this.setState({
      obj : {},
      serachTemplate : "",
    })
    this.getTemplates()
  }

  search(str){
    var obj = JSON.parse(  JSON.stringify( this.state.obj ) )
    obj.page = 0
    if( str.length > 0) {
      obj['search'] = str
    }
    else {
      delete obj['search']
    }
    this.setState( { obj : obj} )
    this.getTemplates(obj)
  }


  more(){
    var page = this.state.page
    var obj = JSON.parse(  JSON.stringify( this.state.obj ) )
    obj.page = page +1
    this.setState({
      obj : obj,
      page : (page +1)
    })
    this.getTemplates(obj,true)

  }

  render() {
    return (

        <div className={['Garlican-Strategy-Template-Container' ,  "show"].join(' ')}>   
            <h4 className={['Garlican-Strategy-List-Title'  ].join(' ')}> 
              { this.props.gi18n[this.props.language]['insert_template'] }
            </h4>
            
            { this.props.add && 
              <Button className={['Garlican-Strategy-List-Add'  ].join(' ')} onClick={
                (e)=>{
                  //console.log(   )
                  //var code = GService.createFirstCode(this.props.language  )
                  //var obj = GService.createStrategyObject(code, this.props.gi18n[ this.props.language ]['new_strargey'])
                  this.props.addStrategy(  )
                  this.props.updateValue( {  showTemplates : false   } )
                }
              }>{ this.props.gi18n[this.props.language]['blank'] }</Button>
            }

            <InputGroup className={["Garlican-Strategy-Template-Search", "mb-3"].join(' ')}>
							<FormControl
								placeholder={ this.props.gi18n[this.props.language]['search_template'] }
								aria-label={ this.props.gi18n[this.props.language]['search_template'] }
								aria-describedby="basic-addon2"
								value={ this.state.serachTemplate }
								onChange={ (e)=>{
										this.setState({
											serachTemplate : e.target.value
										})
								}}
							/>
								<Button variant="outline-secondary" 
									className={["fa", 'reset'].join(' ')}
										onClick={
											(e) => {
												this.reset(  )
											}
										}>
									{ this.props.gi18n[this.props.language]['reset_template'] }
								</Button>
								<Button variant="outline-secondary" 
									onClick={
										(e) => {
											this.search( this.state.serachTemplate)
										}
									}>
									<FontAwesomeIcon 
											className={["fa"].join(' ')}
											icon={ faSearch } 
									/>
								</Button>
						</InputGroup>
            
            <div className={['Garlican-Strategy-List-Panel'  ].join(' ')}>
                <Badge pill onClick={ (e)=> {
                  var obj = JSON.parse(  JSON.stringify( this.state.obj ) )
                  obj['user_id'] = service.user.user_id
                  obj.page = 0
                  if( "user_id" in obj) {
                    delete obj['user_id']
                    this.setState({ obj : obj })
                  }
                  
                  this.getTemplates(obj)
                  
                }}> {  this.props.gi18n[this.props.language]['all_template']  } </Badge>
                <Badge pill onClick={ (e)=> {
                  var obj = JSON.parse(  JSON.stringify( this.state.obj ) )
                  obj['user_id'] = service.user.user_id
                  obj.page = 0
                  this.setState({ obj : obj })
                  this.getTemplates(obj)
                }}> {  this.props.gi18n[this.props.language]['my_template']  }  </Badge>
            </div>
                

            <div className={['Garlican-Strategy-List-Container'  ].join(' ')}>
              { (this.state.templates.length > 0) &&
                this.state.templates.map( (v,k) => {
                  return(
                    <Toast
                      className={[ this.props.inBoard?'Garlican-Strategy-List-Full':"" , 'Garlican-Strategy-List'  ].join(' ')}
                      key={k}
                      onClose={
                        (e)=>{
                          this.props.remove( v )
                          this.getTemplates( this.state.obj )
                          //service.isOwner( v.user_id )
                        }
                      }
                    > 
                      <Toast.Header closeButton={ service.isOwner( v.user_id ) }> 
                        <strong className="me-auto">{  v.name }</strong> 
                      </Toast.Header>
                      <Toast.Header className={['name'].join(" ")} closeButton={false}> 
                        <strong className={["me-auto"].join(" ")} >
                          <span> {this.props.gi18n[this.props.language]['powered']  }</span>
                          <span>
                              { v.user.avatar &&
                                <Image variant="top" src={  v.user.avatar  } className={['pic'].join(' ')} />
                              }
                              { !v.user.avatar && 
                                <FontAwesomeIcon  icon={faUser} className={['pic'].join(' ')}/>
                              }
                          </span>
                          <span> { v.user.first_name } </span>
                        </strong> 
                      </Toast.Header>
                      <Toast.Body>
                          <Badge className={[ ('badge-'+((v.access_mode==="private")?'protected':v.access_mode) )   ].join(' ')} > {   this.props.gi18n[this.props.language][ ((v.access_mode==="private")?'protected':v.access_mode) +"_mode"]    } </Badge>
                          {  v.description  }
                      </Toast.Body>
                      <Toast.Body>
                          <Button onClick={ (e)=>{
                            v.strategy_template_id = v.id
                            //console.log( this.props.add )
                            if(this.props.add) {
                              this.props.addStrategy(v)
                            } else {
                              this.props.convertToTemplate( this.props.id, v )
                              this.props.updateValue({
                                showTemplates : false
                              })
                            }
                            
                          }  } >{  this.props.gi18n[this.props.language]['insert']  } </Button>
                      </Toast.Body>
                    </Toast>
                  )
                })
              }
              {
                (this.state.templates.length === 0) &&
                <div>{  this.props.gi18n[this.props.language]['no_template']  } </div>
              }
              {
                (this.state.templates.length !== 0) && (this.state.hasNext) &&
                <Button 
                  onClick={
                    (e)=>{
                      this.more()
                    }
                  }
                  className={['Garlican-Strategy-List-More'].join(' ')}>
                    { this.props.gi18n[this.props.language]['more'] }
                </Button>
              }
            </div>
        </div>

    );
  }
}



