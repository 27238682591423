import React from "react";
import gglobal from '../GarlicanLibrary/gvars'

import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import GService  from '../GarlicanLibrary/gservice'
import Opcode from '../GarlicanLibrary/opcode'
import apis from '../GarlicanLibrary/apis'

import {  Alert, Modal, InputGroup, Button, FormControl, Toast, Container  } from 'react-bootstrap';


var options = {}, dictionary=[], tempOpt=[]

const templates = window.templates

function myLang(lang) {
  if (lang === 'eng') {
        return "en"
  } else if (lang === 'zhTC') {
        return "tc"
  } else {
        return "sc"
  }
}


export default class GarlicanPicker extends React.Component {

  constructor(props) {
    super(props);  
    this.editor= React.createRef()
    
    this.state = {
      code : "",
      templates : [],
      tempTemplates : [],
      showEditor : false,
      text : "",
      line : -1,
      inputs : [],
      trueInputs : [],
      treeobj : {},
      object : {},
      error_msg : null,
      code : "",
      lastCode : '',
      serachTemplate : '',
    } 
    this.treemaping = this.treemaping.bind(this)
    this.error = this.error.bind(this)
    this.changeCode = this.changeCode.bind(this)
    this.updateCode = this.updateCode.bind(this)
    this.initialCode = this.initialCode.bind(this)

    this.reset = this.reset.bind(this)
    this.search = this.search.bind(this)
  }

  componentWillMount() {
    var obj = {}
    if( this.props.type === 'buy' ) {
      obj = { templates : window.templates['long_strategy'],tempTemplates : window.templates['long_strategy']}
    } else {
      obj = { templates : window.templates['short_strategy'],tempTemplates : window.templates['short_strategy']}
    }
    this.setState( obj )
  }

  componentDidMount() {
    this.initialCode( this.props )
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props) {
  }

  componentWillReceiveProps(props) {
    this.initialCode(props)
  }

  initialCode(props) {
    if( props.selectedStrategy && props.showEditor) {
      if( props.selectedStrategy.code && (props.parentType === 'basic') ) {
        var obj = props.selectedStrategy.code.split( GService.split_cond )
        if (obj.length === 2) {
            if( this.state.code !== props.selectedStrategy.code ) {
              this.treemaping(props.selectedStrategy.code)
            }
        }
      }
    }
  }

  selectTemplate(v) {
    this.setState({
      selectedTemplate : JSON.parse( JSON.stringify(v) ), 
      showEditor : true
    })
    /*this.props.updateValue({
      showEditor : true
    })*/
  }

  hideAll() {
    this.setState({
      selectedTemplate : null, 
      showEditor : false
    })
    this.props.updateValue({
      showBuyTemplate : false,
      showSellTemplate : false,
      showSellSTemplate : false,
      showBuySTemplate : false,
    })
  }

  changeCode(e) {
    this.setState({
      error_msg : null,
    })
    this.updateCode(e, true)
  }

  updateCode(e, update) {
    e = e?e:this.state.code
    this.setState(  { error_msg : null }  )
    var code = ""
    if( this.props.type === 'buy' ) {
      code = this.props.selectedStrategy.code.split( GService.split_cond )[1]
      if( encodeURI(code).substr( (encodeURI(code).length-3) , 3) !== "%0A" ) {
        code =  code + "\n"
      }
      code = (e + GService.split_cond + code)
    } else if( this.props.type === 'sell' ) {
      code = this.props.selectedStrategy.code.split( GService.split_cond )[0]
      if( encodeURI(code).substr( (encodeURI(code).length-3) , 3) !== "%0A" ) {
        code =  code + "\n"
      }
      code = (code + GService.split_cond + e)
    }

    for (var i in this.props.group.strategy_list ) {
      if( this.props.group.strategy_list[i].id === this.props.selectedStrategy.id ) {
        this.props.editStrategy( i, 'code', code )
      }
    }
  }

  error(e) {
    /*this.props.updateParentValue(
      { showEditor : true }
    )
    this.props.updateValue( {
      selected : 'sell_s'
    })*/

    this.setState(  { error_msg : e, }  )
    //this.treemaping( this.state.lastCode )
    for (var i in this.props.group.strategy_list ) {
      if( this.props.group.strategy_list[i].id === this.props.selectedStrategy.id ) {
        this.props.editStrategy( i, ['code', 'input'], [this.state.lastCode, this.state.trueInputs] ) 
      }
    }
  }

  treemaping(code) {
    apis.getTreemapNewFromCode(code).then(
      (res) => {
        if(res) {
          if( typeof(res) === 'object' ) {
            var tp = JSON.parse( JSON.stringify( res['treeobj'] ) )
            var a = GService.reshape(res['treeobj'] , null, this.error )
            if( typeof(a) === 'object' ) {
              var t = {}
              if( this.props.type === 'buy' ) {
                t = GService.createCompGp( code, res['treeobj'], a, true  )
              } else {
                t = GService.createCompGp( code, res['treeobj'], a, false  )
              }
              var codes = code.split( GService.split_cond )
              var buy = codes[0], sell = codes[1]
              var arr = buy.split("\n")
              var line = arr.length + 1
              var inputs = []
              if( this.props.type === 'buy' ) {
                for (var i in res.input) {
                  var o = res.input[i]
                  o.key = i
                  if( parseInt(o.line, 10) < parseInt(line, 10) ) {
                    inputs.push( o )
                  }
                }
              } else {
                for (var i in res.input) {
                  var o = res.input[i]
                  o.key = i
                  if( parseInt(o.line, 10) >= parseInt(line, 10) ) {
                    inputs.push( o )
                  }
                }
              }
              this.setState({
                line : line,
                text : t,
                inputs : inputs,
                trueInputs : JSON.parse( JSON.stringify(res['input']) ),
                treeobj : tp,
                object : res,
                code : code,
                lastCode : code,
              })
            }
          } else {
            this.setState(  { error_msg : res}  )
          }
        }
      } 
    )
  }

  reset() {
    this.setState({
      serachTemplate : "",
      tempTemplates : this.state.templates
    })
  }

  search(str) {
    var t = JSON.parse(  JSON.stringify( this.state.templates )  )
    var _arr = []
    for (var i in t) {
      
      var nmatch = Object.values( t[i].name )
      var dmatch = Object.values( t[i].description )
      nmatch = nmatch.join("|")
      dmatch = dmatch.join("|")
      if( (nmatch.toLowerCase().indexOf(str.toLowerCase()) > -1 ) || (dmatch.toLowerCase().indexOf(str.toLowerCase()) > -1 ) ) {
        _arr.push(  t[i]   )
      }
    }
    this.setState({
      tempTemplates : _arr
    })
  }

  render() {

    return (
      <div className={['Garlican-Picker'  ].join(' ')} >
        { (this.state.error_msg !== null) &&
          <Alert className={ ['Garlican-Picker-Error'].join(' ') } >
            {this.state.error_msg }
          </Alert>
        }
        <Modal 
          className={['Garlican-Picker-Modal'  ].join(' ')}
          show={this.state.showEditor} onHide={ (e)=>this.setState({  showEditor : false }) }>
          <Modal.Header closeButton>
            <Modal.Title>  {  this.state.selectedTemplate?this.state.selectedTemplate.name[this.props.language]:'NAME' }   </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{  this.state.selectedTemplate?this.state.selectedTemplate.description[this.props.language]:'NAME' } </p>
              { this.state.selectedTemplate &&
                Object.keys(this.state.selectedTemplate.vars).map(  (v,k) => {
                  return(
                    <InputGroup className="mb-3" key={k}>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">   {v}  </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder=""
                        aria-label="Value"
                        aria-describedby="basic-addon1"
                        value={ this.state.selectedTemplate.vars[v] }
                        onChange={
                          (e) => {
                            var o = this.state.selectedTemplate
                            o.vars[ v ] = e.target.value
                            this.setState( {  selectedTemplate : o } )
                          }
                        }
                      />
                    </InputGroup>
                  )
                } )
                
              }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={ (e) => {
              var code = this.state.selectedTemplate.code
              for (var i in this.state.selectedTemplate.vars) {
                code = code.replace( "<" + i + ">"  ,  this.state.selectedTemplate.vars[i]  )
              }

              for (i in this.state.selectedTemplate.comments) {
                code = code.replace( "<" + i + ">"  ,  this.state.selectedTemplate.comments[i][this.props.language]  )
              }
              this.changeCode(code)
              this.hideAll()

            } } >
              {  (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['save']:'SAVE'  }
            </Button>
          </Modal.Footer>
        </Modal>



        <div className={['Garlican-Picker-Control-Container'].join(" ") } >
						<Button variant="secondary" onClick={
								(e)=>{
									var obj = {  }
                  if( this.props.type === "sell" ) {
                    obj = { showSellSTemplate : !this.props.showSellSTemplate }
                  } else {
                    obj = { showBuySTemplate : !this.props.showBuySTemplate }
                  }
                  this.props.updateValue(obj)
								}
						}>{  (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['sample']:'' }</Button>
        </div>

        <div className={['Garlican-Picker-Template-Container' ,  ((this.props.type==="sell")?this.props.showSellSTemplate:this.props.showBuySTemplate)?"show":"hide"].join(' ')}>
          
          <InputGroup className={["Garlican-Picker-Template-Search", "mb-3"].join(' ')}>
							<FormControl
								placeholder={ this.props.gi18n[this.props.language]['search_template'] }
								aria-label={ this.props.gi18n[this.props.language]['search_template'] }
								aria-describedby="basic-addon2"
								value={ this.state.serachTemplate }
								onChange={ (e)=>{
										if( e.target.value.length === 0 ) {
											this.reset()
										} else {
											this.search( e.target.value )
										}
										this.setState({
											serachTemplate : e.target.value
										})
								}}
							/>
								<Button variant="outline-secondary" 
									className={["fa", 'reset'].join(' ')}
										onClick={
											(e) => {
												this.reset(  )
											}
										}>
									{ this.props.gi18n[this.props.language]['reset_template'] }
								</Button>
								<Button variant="outline-secondary" 
									onClick={
										(e) => {
											this.search( this.state.serachTemplate)
										}
									}>
									<FontAwesomeIcon 
											className={["fa"].join(' ')}
											icon={ faSearch } 
									/>
								</Button>
						</InputGroup>
            
            {
              this.state.tempTemplates.map( (v,k) => {
                return(
                  <Toast
                    className={['Garlican-Picker-List'  ].join(' ')}
                    key={k}
                    onClick={ (e)=>{
                      this.props.updateValue({
                        showBuySTemplate : false,
                        showSellSTemplate : false,
                      })
                      this.selectTemplate(v)
                    }  } 
                  > 
                    <Toast.Header closeButton={false}> 
                      <strong className="me-auto">{  v.name[ this.props.language ]  }</strong> 
                    </Toast.Header>
                    <Toast.Body>
                        {  v.description[ this.props.language ]  }
                    </Toast.Body>
                  </Toast>
                )
              })
            }
        </div>

          
        <Container className={['Garlican-Picker-Container'  ].join(' ')}>
            <div className={ ['Garlican-Picker-Right'].join(' ') } >
              {
                this.state.inputs.map( (v,k) => {
                  return(
                    <InputGroup className="mb-3" key={k}>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">{ (v.name.length > 0)?v.name:v.key }</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Value"
                          aria-label="Value"
                          aria-describedby="basic-addon1"
                          value={v.value} 
                          onChange={
                            (e)=> {
                              this.setState({
                                error_msg : null
                              })
                              var o = this.state.inputs
                              o[k].value = e.target.value
                              this.setState({  inputs : o  })
                            }
                          } 
                          onBlur={
                            (e)=>{
                              for (var i in this.props.group.strategy_list ) {
                                if( this.props.group.strategy_list[i].id === this.props.selectedStrategy.id ) {
                                  var obj = this.state.treeobj[ v.line ]
                                  var stm = obj.store + "=" + e.target.value +" #" + obj.comment
                                  var code = this.props.selectedStrategy.code
                                  var codes = code.split("\n")
                                  codes[ v.line -1 ] = stm
                                  code = codes.join('\n')
                                  this.setState( { code : code} )
                                  if( 'input' in this.props.group ) {
                                    var _in = JSON.parse( JSON.stringify(this.props.group.input) )
                                    if( this.props.selectedStrategy.id  in _in) {
                                      var input = _in[ this.props.selectedStrategy.id ]
                                      if( v.key in input ) {
                                        input[ v.key ].value = e.target.value
                                        this.props.editStrategy( i, ['code', 'input'], [code, input] )  
                                      } else {
                                        this.props.editStrategy( i, 'code', code ) 
                                      }
                                    } else {
                                      this.props.editStrategy( i, 'code', code ) 
                                    }
                                  } else {
                                    this.props.editStrategy( i, 'code', code ) 
                                  } 
                                  break;
                                }
                              }
                            }
                          }
                          />
                     </InputGroup>
                    
                  )
                } )
              }
            </div>
            <FormControl 
              disabled
              as="textarea" 
              className={ ['Garlican-Picker-Left'].join(' ') } 
              value={this.state.text}/>
        </Container>


      </div>
    );
  }
}



/*

        <OverlayTrigger
						trigger="click"
						placement={'bottom'}
						show={  (this.props.type==="sell")?this.props.showSellSTemplate:this.props.showBuySTemplate }
						overlay={
							<Popover id={`popover-positioned-bottom`}>
								<Popover.Title as="h3">{(this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['template']:''}</Popover.Title>
									<Popover.Content>
                    <ListGroup variant="flush">
                      {
                        this.state.templates.map( (v,k) => {
                          return(
                            <ListGroup.Item 
                              className={['Garlican-Picker-List'  ].join(' ')}
                              key={k} action 
                              onClick={ (e)=>{
                                this.props.updateValue({
                                  showBuySTemplate : false,
                                  showSellSTemplate : false,
                                })
                                this.selectTemplate(v)
                              }  } 
                            > 
                              {  v.name[ this.props.language ]  } 
                            </ListGroup.Item>
                          )
                        } )
                      }
                    </ListGroup>
									</Popover.Content>
							</Popover>
					  }
				>
          <Button 
              className={ ['Garlican-Picker-Template-Trigger'].join(' ') } 
              variant="secondary" onClick={
              (e)=>{
                var obj = {  }
                if( this.props.type === "sell" ) {
                  obj = { showSellSTemplate : !this.props.showSellSTemplate }
                } else {
                  obj = { showBuySTemplate : !this.props.showBuySTemplate }
                }
                this.props.updateValue(obj)
              }
            }>
              {  (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['template']:'' }
          </Button>
			  </OverlayTrigger>
*/
