import React from "react";
import gglobal from '../GarlicanLibrary/gvars'

import './index.scss'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCog } from '@fortawesome/free-solid-svg-icons'

import GService  from '../GarlicanLibrary/gservice'
import Opcode from '../GarlicanLibrary/opcode'
import apis from '../GarlicanLibrary/apis'

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/python";
import CustomNode from './customNode.js'
import { setCompleters } from 'ace-builds/src-noconflict/ext-language_tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {  Button, OverlayTrigger, Popover, Alert, ListGroup, Modal, InputGroup, FormControl, Toast   } from 'react-bootstrap';
//import { i } from "mathjs";

var options = {}, dictionary=[], tempOpt=[], isPaste = false

const templates = window.templates

function myLang(lang) {
  if (lang === 'eng') {
        return "en"
  } else if (lang === 'zhTC') {
        return "tc"
  } else {
        return "sc"
  }
}


export default class GarlicanIDE extends React.Component {

  constructor(props) {
    super(props);  
    this.editor= React.createRef()
    
    options = {
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      showLineNumbers: true,
      tabSize: 2,
    }

    this.state = {
      code : "",
      fullcode : "",
      options : options,
      templates : [],
      tempTemplates : [],
      error_msg : null,
      showEditor : false,
      selectedTemplate : null,
      selectedText : '',
      tempCode : "",
      seletcedText : "",
      serachTemplate : '',
    } 

    this.editor = React.createRef()
    this.updateDistionary = this.updateDistionary.bind(this)
    this.updateAC = this.updateAC.bind(this)
    this.changeCode = this.changeCode.bind(this)
    this.treemaping = this.treemaping.bind(this)
    this.error = this.error.bind(this)
    this.selectTemplate = this.selectTemplate.bind(this)
    this.pasteCode = this.pasteCode.bind(this)
    this.initialCode = this.initialCode.bind(this)
    this.onSelectionChange = this.onSelectionChange.bind(this)

    this.reset = this.reset.bind(this)
    this.search = this.search.bind(this)
  }

  componentWillMount() {
    //console.log( window.templates )
    var temp = []
    if(  this.props.type === 'buy' ) {
      temp = window.templates['long_strategy']
    } else {
      temp = window.templates['short_strategy']
    }
    this.setState( {
      templates : temp,
      tempTemplates : temp,
    })
  }

  componentDidMount() {
    //this.updateDistionary()
    this.initialCode(this.props)
  }

  updateDistionary() {
    const customMode = new CustomNode();
    this.editor.current.editor.getSession().setMode(customMode);

    dictionary=[]
    var obj = {}, i = 0;
    var cases = GService.defaults.concat( GService.exit  )
    for (i in cases) {
      obj={}
      obj.name = GService.defaults[i]
      obj.value = GService.defaults[i]
      obj.caption = GService.defaults[i] + " [" + ((GService.defaults[i] in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][GService.defaults[i]]:GService.defaults[i]) + "]"
      obj.meta = null
      obj.score = 1000  
      dictionary.push( obj )
    }
    for (i in GService.structure) {
      if(GService.structure[i].display) {
        obj={}
        obj.name = i
        obj.value = GService.structure[i].stm
        obj.caption = i + " [" + ((i in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][i]:i) + "]"
        obj.meta = null
        obj.score = 1000  
        dictionary.push( obj )
      }
    }
    for (i in  window.func) {
      if( window.func[i].display) {
        obj={}
        obj.name =  window.func[i].name
        obj.value =  window.func[i].format
        obj.caption =  window.func[i].name + " [" +  window.func[i]['desc'][gglobal.language] + "]"
        obj.meta = null
        obj.score = 1000 
        dictionary.push( obj )
      }
    }

    for (i in Opcode.op_func ) {
      obj={}
      obj.name = i
      obj.value = Opcode.op_func[i].value
      obj.caption = i + " [" +  Opcode.op_func[i]['desc'][gglobal.language] + "]"
      obj.meta = null
      obj.score = 1000  
      dictionary.push( obj )
    }
    this.updateAC( dictionary )
    //this.setTempOptions()
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props) {
  }

  componentWillReceiveProps(props) {
    this.initialCode(props)
  }

  initialCode(props) {
    if( this.props.selectedStrategy && props.showEditor ) {
      if( props.selectedStrategy.code && (props.parentType === 'advance') ) {
        var obj = props.selectedStrategy.code.split( GService.split_cond )
          if (obj.length === 2) {
            var code = ""
            if( props.type === 'buy' ) {
              code = props.selectedStrategy.code.split( GService.split_cond )[0]
            } else if( props.type === 'sell' ) {
              code = props.selectedStrategy.code.split( GService.split_cond )[1]
            }
            if( this.state.code !== code ) {
              this.setState(  { error_msg : null }  )
            }
            this.setState({
              fullcode : props.selectedStrategy.code,
              code : code,
              tempCode : code
            })
          }
      }
    }
  }

  updateAC(dict) {
    setCompleters([{
      getCompletions: function(editor, session, pos, prefix, callback) {
          callback(null, dict);
      },
    }]);
  }

  changeCode(e) {
    if( !isPaste ) {
      this.setState({
        error_msg : null,
      })
      this.updateCode(e, true)
    }
  }

  updateCode(e, update) {
    isPaste = false
    e = e?e:this.state.code
    this.setState(  { error_msg : null }  )
    var code = ""
    if( this.props.type === 'buy' ) {
      code = this.props.selectedStrategy.code.split( GService.split_cond )[1]
      if( encodeURI(e).substr( (encodeURI(e).length-3) , 3) !== "%0A" ) {
        e =  e + "\n"
      }
      code = (e + GService.split_cond + code)
    } else if( this.props.type === 'sell' ) {
      code = this.props.selectedStrategy.code.split( GService.split_cond )[0]
      if( encodeURI(code).substr( (encodeURI(code).length-3) , 3) !== "%0A" ) {
        code =  code + "\n"
      }
      code = (code + GService.split_cond + e)
    }
    if( update ) {
      this.setState({
        code : e,
        fullcode : code,
        tempCode : e,
        show : true,
      })
      for (var i in this.props.group.strategy_list ) {
        if( this.props.group.strategy_list[i].id === this.props.selectedStrategy.id ) {
          this.props.editStrategy( i, 'code', code )
        }
      }
      this.treemaping(code) 
    } else {
      this.treemaping(code) 
    }
  }

  error(e, _i, o, type) {
    var _this = this
    setTimeout( ()=> {
      _this.props.updateValue( {
        selected : type,
      })
      _this.setState(  { error_msg : e }  )
      _this.props.updateParentValue(
        { showEditor : true, hasError : true, }
      )
    }, 500  )

  }

  treemaping(code) {
    var type = this.props.type
    apis.getTreemapNewFromCode(code).then(
      (res) => {
        if( 'message' in res ) {
          this.error( this.props.gi18n[this.props.language]['error_msg'], null, null, type )
        } else{ 
          var a = GService.reshape(res['treeobj'] , null, this.error , type )
          if(  typeof(a) === 'object' ) {
            for (var i in this.props.group.strategy_list ) {
              if( this.props.group.strategy_list[i].id === this.props.selectedStrategy.id ) {
                this.props.editStrategy( i, ['input', 'code'], [res.input, code] )
                this.props.updateParentValue({ hasError : false })
                break;
              }
            }
          }
        }
      } 
    ).catch(
      (err)=>{
        this.error( this.props.gi18n[this.props.language]['error_msg'], null, null , type )
      }
    )
  }

  selectTemplate(v) {
    //console.log(v)
    this.setState({
      selectedTemplate : JSON.parse( JSON.stringify(v) ), 
      showEditor : true,
    })
  }

  hideAll() {
    this.setState({
      selectedTemplate : null, 
      showEditor : false,
      seletcedText : "",
      tempCode : "",
    })
    this.props.updateValue({
      showBuyTemplate : false,
      showSellTemplate : false,
    })
  }

  pasteCode(e) {

  }

  onSelectionChange(e) {
    var v = this.editor.current.editor.getSelectedText()
    this.setState({ seletcedText : v })
  }

  reset() {
    this.setState({
      serachTemplate : "",
      tempTemplates : this.state.templates
    })
  }

  search(str) {
    var t = JSON.parse(  JSON.stringify( this.state.templates )  )
    var _arr = []
    for (var i in t) {
      
      var nmatch = Object.values( t[i].name )
      var dmatch = Object.values( t[i].description )
      nmatch = nmatch.join("|")
      dmatch = dmatch.join("|")
      if( (nmatch.toLowerCase().indexOf(str.toLowerCase()) > -1 ) || (dmatch.toLowerCase().indexOf(str.toLowerCase()) > -1 ) ) {
        _arr.push(  t[i]   )
      }
    }
    this.setState({
      tempTemplates : _arr
    })
  }


  render() {

    return (
      <div className={['Garlican-IDE'  ].join(' ')} >
        { (this.state.error_msg !== null) &&
          <Alert className={ ['Garlican-IDE-Error'].join(' ') } >
            {this.state.error_msg }
          </Alert>
        }

        <Modal 
          className={['Garlican-IDE-Modal'  ].join(' ')}
          show={this.state.showEditor} onHide={ (e)=>this.setState({  showEditor : false }) }>
          <Modal.Header closeButton>
            <Modal.Title>  {  this.state.selectedTemplate?this.state.selectedTemplate.name[this.props.language]:'NAME' }   </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{  this.state.selectedTemplate?this.state.selectedTemplate.description[this.props.language]:'NAME' } </p>
              { this.state.selectedTemplate &&
                Object.keys(this.state.selectedTemplate.vars).map(  (v,k) => {
                  return(
                    <InputGroup className="mb-3" key={k}>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">   {v}  </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder=""
                        aria-label="Value"
                        aria-describedby="basic-addon1"
                        value={ this.state.selectedTemplate.vars[v] }
                        onChange={
                          (e) => {
                            var o = this.state.selectedTemplate
                            o.vars[ v ] = e.target.value
                            this.setState( {  selectedTemplate : o } )
                          }
                        }
                      />
                    </InputGroup>
                  )
                } )
                
              }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={ (e) => {
              var code = this.state.selectedTemplate.code
              for (var i in this.state.selectedTemplate.vars) {
                code = code.replace( "<" + i + ">"  ,  this.state.selectedTemplate.vars[i]  )
              }

              for (i in this.state.selectedTemplate.comments) {
                code = code.replace( "<" + i + ">"  ,  this.state.selectedTemplate.comments[i][this.props.language]  )
              }
              var _this = this
              this.setState({
                show : false
              })
              this.hideAll()
              setTimeout(
                (e)=>{
                  _this.changeCode(code)
                }, 500
              )
              //this.changeCode(code)
              

            } } >
              {  (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['save']:'SAVE'  }
            </Button>
          </Modal.Footer>
        </Modal>




        <div className={['Garlican-IDE-Control-Container'].join(" ") } >
						<Button variant="secondary" onClick={
								(e)=>{
									var obj = {  }
                  if( this.props.type === "sell" ) {
                    obj = { showSellTemplate : !this.props.showSellTemplate }
                  } else {
                    obj = { showBuyTemplate : !this.props.showBuyTemplate }
                  }
                  this.props.updateValue(obj)
								}
						}>{  (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['sample']:'' }</Button>
        </div>

        <div className={['Garlican-IDE-Template-Container' ,  ((this.props.type==="sell")?this.props.showSellTemplate:this.props.showBuyTemplate)?"show":"hide"].join(' ')}>
          
          <InputGroup className={["Garlican-IDE-Template-Search", "mb-3"].join(' ')}>
							<FormControl
								placeholder={ this.props.gi18n[this.props.language]['search_template'] }
								aria-label={ this.props.gi18n[this.props.language]['search_template'] }
								aria-describedby="basic-addon2"
								value={ this.state.serachTemplate }
								onChange={ (e)=>{
										if( e.target.value.length === 0 ) {
											this.reset()
										} else {
											this.search( e.target.value )
										}
										this.setState({
											serachTemplate : e.target.value
										})
								}}
							/>
								<Button variant="outline-secondary" 
									className={["fa", 'reset'].join(' ')}
										onClick={
											(e) => {
												this.reset(  )
											}
										}>
									{ this.props.gi18n[this.props.language]['reset_template'] }
								</Button>
								<Button variant="outline-secondary" 
									onClick={
										(e) => {
											this.search( this.state.serachTemplate)
										}
									}>
									<FontAwesomeIcon 
											className={["fa"].join(' ')}
											icon={ faSearch } 
									/>
								</Button>
						</InputGroup>
            
            {
              this.state.tempTemplates.map( (v,k) => {
                return(
                  <Toast
                    className={['Garlican-IDE-List'  ].join(' ')}
                    key={k}
                    onClick={ (e)=>{
                      this.props.updateValue({
                        showBuySTemplate : false,
                        showSellSTemplate : false,
                      })
                      this.selectTemplate(v)
                    }  } 
                  > 
                    <Toast.Header closeButton={false}> 
                      <strong className="me-auto">{  v.name[ this.props.language ]  }</strong> 
                    </Toast.Header>
                    <Toast.Body>
                        {  v.description[ this.props.language ]  }
                    </Toast.Body>
                  </Toast>
                )
              })
            }
        </div>




        { this.props.show &&
          <AceEditor
            width="auto"
            onSelectionChange={ (e)=>{}}
            wrapEnabled={false}
            onLoad={ (e) => {
              var _this = this
              setTimeout(
                (e)=>{
                  _this.updateDistionary()
                }, 300
              )
            }}
            onSelectionChange={
              this.onSelectionChange
            }
            ref={this.editor} 
            fontSize={12}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            mode="python"
            theme="tomorrow"
            className={ ['Garlican-Textarea'].join(' ') } 
            onChange={ (e)=>{this.setState( { tempCode : e }  ) } }
            onBlur={
              (e)=> {
                  this.changeCode(this.state.tempCode)
              }
            }
            onPaste={ this.pasteCode }
            name="GARLICAN"
            value={ this.state.tempCode }  
            editorProps={{ $blockScrolling: true }}
            setOptions={this.state.options}
          />
        }
      </div>
    );
  }
}



//            onChange={ this.changeCode }
