import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import service from '../public/service'
import gservice from '../Garlican/GarlicanLibrary/gservice';
import gcolor from '../public/style.scss';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter ,faFunnelDollar, faSortAmountDown, faInfoCircle, faUser } from '@fortawesome/free-solid-svg-icons'

import { Button, ButtonGroup, OverlayTrigger, Tooltip, Table, Image } from 'react-bootstrap'
import PortfolioCard from "../Components/PortfolioCard"
import ActivitiesLog from "../Components/ActivitiesLog"

import Confirm from "../Components/Confirm"
import Picker from "../Components/Picker"

//import LazyLoad from 'react-lazyload';

const scores = [
  { key :  "profit_rate_rank", value : "profit_rate" },
  { key :  "sharpe_ratio_rank", value : "risk_rate" },
  { key :  "win_rate_rank", value : "win_rate" },
  {key :  "turnover_rate_rank", value : "quantity_rate" },
]

const selections = [
  { key : 'all' , value : 'all'},
  { key : 'public' , value : 'ispublic'},
  { key : 'private' , value : 'isprivate'},
]

const pickeroptions = [
  { key : 'initial_capital' , value : 'initial' , range : [0, 1000000], step : 10000 ,label : "$<value>+" , demostrator : 1},
  { key : 'profit_rate' , value : 'profit_rate', range : [0, 100], step : 1, label : "<value>%+" , demostrator : 100 },
  { key : 'win_rate' , value : 'win_rate', range : [0, 100], step : 1, label : "<value>%+" , demostrator : 100 },
  { key : 'sharpe_ratio' , value : 'risk_rate', range : [0, 500], step : 1, label : "<value>%+" , demostrator : 100 },
  { key : 'turnover_rate' , value : 'quantity_rate', range : [0, 1000], step : 1, label : "<value>%+" , demostrator : 100 },
]


class Overview extends Component {
    constructor(props) {
        super(props)
        var filter = [
          {key : 'all' , value : 'all', 'type' : 'all'},
          {key : 'bookmark' , value : 'bookmarked' , 'type' : 'bookmarked' },
        ]
        this.state = { 
          page : 0,
          card : [],
          temp_card : [],
          activities : [],
          a_page : 0,
          selectedUser : null,
          selectedType : filter[0].value,
          filter : filter,
          isMe : false,
          selectedObj : { 'order_by' : '-profit_rate' },
          isMore : false,
          myportType : selections[0].key,

          msg : null,
          showConfirm : false,
          secondary_btn_text : "",
          callback : null,
          params : [],
          replacement_func : null,

          showFilter : false,
          filtertype : 'profit',

          initial_capital_range : {},
          profit_rate_range : {},
          win_rate_range : {},
          sharpe_ratio_range : {},
          turnover_rate_range : {},

          rank_message : [],

          isUser : false,
          user : {},
          hasRead : false,
        };
        this.updateValue = this.updateValue.bind(this)
        this.deletePortfolio = this.deletePortfolio.bind(this)
        this.updateFilter = this.updateFilter.bind(this)
        this.getPortfoilioList = this.getPortfoilioList.bind(this)
        this.getPortfolio = this.getPortfolio.bind(this)
        this.selectPublicType = this.selectPublicType.bind(this)
        this.showDeletePorfolio = this.showDeletePorfolio.bind(this)
        this.close = this.close.bind(this)
        this.getTrade = this.getTrade.bind(this)
        this.searchPortfolios = this.searchPortfolios.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.reset = this.reset.bind(this)
        this.getRankInfo = this.getRankInfo.bind(this)

    }

    componentDidMount() {
      window.document.overview = this
      this.getPortfolio(this.props)
      //this.getTrade()

      //var o = service.user.user_id.toString() 
      //var a = gservice.encodeObject(  o )
      //var b = gservice.decodeObject(  a )
      //console.log(  o + "|" + a + "|" + b )


    }

    componentWillUnmount() {
    }

    componentWillReceiveProps(props) {
      if( (props.showGarlican === false) && (props.showPortfolio === false) && (props.showUser === false) && ( props.showCanvas===false) ) {
        this.getPortfolio(props) 
      }
    }

    getRankInfo() {
      var message = []
      var arr = []
      //arr.push( this.props.gi18n[this.props.language]['score'] )
      //arr.push( this.props.gi18n[this.props.language]['rank'] )
      //message.push( arr )
      var pscore = 101
      for (var s in service.score_scale ) {
        arr = []
        var msg = ""
        msg = (pscore-1) + " - " + service.score_scale[s].score
        pscore = service.score_scale[s].score
        arr.push( msg )
        arr.push( service.score_scale[s].rank )
        message.push( arr )
      }
      return(
        <Table 
          className={["g-table"].join(" ")} 
          size="sm"
          striped bordered hover variant="dark" >
          <thead>
            <tr> 
              <th> {this.props.gi18n[this.props.language]['score']} </th>
              <th> {this.props.gi18n[this.props.language]['rank']} </th>
            </tr>
          </thead>
          <tbody>
            {
              message.map( (v,k) => {
                return(
                  <tr key={k}>
                    <th> {v[0]} </th>
                    <th> {v[1]} </th>
                  </tr>
                )
              } )
            }
          </tbody>
        </Table>
      )

    }

    getTrade(obj) {
      service.getRecentTradeList(obj).then(
        (res) => {
          if( "list" in res ) {

            var arr = res.list.filter( (v,k) => {
              return v.unit !== 0
            }  )
            //console.log(arr)
            this.setState( {
              activities : arr,
              a_page : res.page
            })
          }

        }
      )
    }

    getPortfolio(props) {
      props = props?props:this.props
      this.updateFilter( props )
      //console.log(window.location)
      if( props.match && (Object.keys(props.match.params).length > 0) && !this.state.hasRead ) {
        var user_id =  gservice.decodeObject(   props.match.params.user_id   ).replace("user", "")
        this.setState({
          hasRead : true
        })
        service.getUserProfile( user_id ).then(
          (res) => {
            this.setState({
              user : res
            })
          }
        )
        var isShow = false
        if( props.match.params.portfolio_id  ) {
          isShow = true
          var portfolio_id =  gservice.decodeObject(   props.match.params.portfolio_id   ).replace("portfolio", "")
          service.getPortfoilioById(  portfolio_id ).then(
            (res) => {
              //console.log( "S" )
              this.props.updateValue({ selectedPortfolio : res, showPortfolio :true }) 
            }
          )
        }
        var obj = { user_id : user_id }
        this.getPortfoilioList( obj, false, isShow  )
        this.setState({
          isUser : true,
          selectedObj : obj,
        })
        this.getTrade( { user_id : user_id } )
      } else if( window.location.pathname === "/forget_password" ) {
          var obj = JSON.parse(  JSON.stringify( this.state.selectedObj  ) )
          this.getPortfoilioList( obj, true )
          var key = window.location.search?window.location.search.replace("?key=", ""):""
          this.props.updateValue( { showLogin : true, retrieve_key : key  }  )
          this.getTrade( { action : "sold"} )
      } else if( window.location.pathname === "/me")  {
          if(props.isLogin) {
            this.reset()
            this.setState({ selectedType : 'myportfolio' , isMe : true })
            this.getPortfoilioList(  {  user_id : 'me' }  )
            this.getTrade( { user_id : service.user.user_id } )
          }
      } else if( window.location.pathname === "/bookmark")  {
        if(props.isLogin) {
          this.reset()
          this.setState({ selectedType : 'bookmarked' , isMe : false })
          this.getPortfoilioList(  {  bookmarked : 'true' }  )
          this.getTrade( {  bookmarked : 'true' }  )
        }
      } else {
        var obj = JSON.parse(  JSON.stringify( this.state.selectedObj  ) )
        this.getPortfoilioList( obj, true )
        this.getTrade( { action : "sell"} )
      }
    }

    updateFilter(props) {
      var filter = []
      if( props.isLogin) {
        filter = [
          {key : 'all' , value : 'all', 'type' : 'all'},
          {key : 'myportfolio' , value : 'myportfolio', 'type' : 'user_id' },
          {key : 'bookmark' , value : 'bookmarked' , 'type' : 'bookmarked' },
        ]
      } else {
        filter = [
          {key : 'all' , value : 'all', 'type' : 'all'},
          {key : 'bookmark' , value : 'bookmarked' , 'type' : 'bookmarked' },
        ]
      }
      this.setState({
        filter : filter
      })
    }

    getPortfoilioList(obj, append, isShow=false){
      var _this = this
      service.getPortfoilioList(obj).then(
        (res) => {    
            if('list' in res) {
              if( append ) {
                var list = this.state.card
                list = list.concat(  res['list'])
                if(list.length > 0 ) {
                  list = Array.from(new Set(list.map(a => a.id)))
                    .map(id => {
                      return list.find(a => a.id === id)
                  })
                }

              } else {
                var list = res['list']
              }
              var isMore = (  (list.length % 30 ) === 0  )
              if(  res.list.length === 0 ) {
                isMore = false
              }
              
              console.log( list )
              var tempname = gservice.encodeObject(  "TEMPBOTFOLIO:" + service.user.user_id )
              list = list.filter( (v,k) => {
                return v.name !== tempname
              } )
              //_this.setState({ temp_card : list, card : list,  page : parseInt(res['page'], 10), isMore : isMore  })
              _this.setState({ card : list,  page : parseInt(res['page'], 10), isMore : isMore  })
              _this.selectPublicType( this.state.myportType )
              _this.props.updateValue({ showPortfolio : isShow })


            }
        }
      )
    }

    updateValue(obj) {
      this.setState(obj)
    }

    showDeletePorfolio(obj) {
      this.setState({
        msg : this.props.gi18n[this.props.language]["confirm_remove_portfolio"] ,
        showConfirm : true,
        secondary_btn_text : this.props.gi18n[this.props.language]["remove"] ,
        callback : 'deletePortfolio',
        params : [obj],
        replacement_func : null
      })
    }

    deletePortfolio(obj) {
      this.props.updateValue({
        showLoading : true
      })
      service.deletePortfolio( obj.id ).then(
        (res) => {
          this.close()
          this.getPortfoilioList( {user_id : service.user.user_id} )
          //this.selectPublicType( this.state.myportType )
          this.props.updateValue({
            showLoading : false
          })
        }
      )
    }

    selectPublicType(type) {
      var newArray = JSON.parse(  JSON.stringify( this.state.card ) )

      if( type!=='all' ) {
        newArray = newArray.filter(
          (x) => {
            return (x.access_mode === type)
          }
        ) 
      } 
      //console.log( type )
      this.setState( { myportType : type, temp_card : newArray } ) 
    }

    close() {
      this.setState({
        msg : null,
        showConfirm : false,
        secondary_btn_text : "",
        callback : null,
        params : [],
        replacement_func : null
      })
    }

    searchPortfolios() {
      var keys = ["initial_capital", "profit_rate", "win_rate", "sharpe_ratio","turnover_rate"]
      var obj = this.state.selectedObj?JSON.parse( JSON.stringify(this.state.selectedObj) ):{}
      for (var i in keys) {
        if( "max" in this.state[keys[i] + "_range"] ) {
          for (var j in pickeroptions) {
            if( pickeroptions[j].key === keys[i] ) {
              if( "min" in this.state[keys[i] + "_range"] ) {
                obj[ keys[i] + "_min" ] = this.state[keys[i] + "_range"].min
              }
              if ( pickeroptions[j].range[1] > this.state[keys[i] + "_range"].max ) {
                obj[ keys[i] + "_max" ] = (this.state[keys[i] + "_range"].max)
              } else {
                if (   keys[i] + "_max" in obj ) {
                  delete obj[ keys[i] + "_max" ]
                }
              }
              break
            }
          }
        }
      }
      obj.page = 0
      this.setState({ selectedObj : obj })
      this.getPortfoilioList( obj )
    }

    resetFilter() {
      var obj = this.reset()
      if(obj) {
        this.getPortfoilioList( obj )
      }
    }

    reset() {
      var obj = JSON.parse( JSON.stringify(this.state.selectedObj) )
      var keys = ['initial_capital_min', 'initial_capital_max', 'win_rate_min', 'win_rate_max', 'sharpe_ratio_min', 'sharpe_ratio_max', 'profit_rate_min', 'profit_rate_max', 'turnover_rate_min' , 'turnover_rate_max']
      if( obj ) {
        for (var i in keys) {
          if( keys[i] in obj ) {
            delete obj[ keys[i] ]
          }
        }
        this.setState({
          page : 0,
          selectedObj : obj,
          initial_capital_range : {},
          profit_rate_range : {},
          win_rate_range : {},
          sharpe_ratio_range : {},
          turnover_rate_range : {},
        })
      }
      return obj
    }

    render() {
        return (
          <div className={ ['Overview-Holder'].join( " " ) } >

            <Confirm msg={this.state.msg} show={this.state.showConfirm} secondary_btn_text={this.state.secondary_btn_text} scope={'overview'} callback={this.state.callback} params={this.state.params} language={this.props.language} gi18n={this.props.gi18n} replacement_func={this.state.replacement_func} close={this.close}  />
            <Picker show={this.state.showFilter} pickeroptions={pickeroptions} language={this.props.language} gi18n={this.props.gi18n} updateValue={this.updateValue} initial_capital_range={this.state.initial_capital_range} profit_rate_range={this.state.profit_rate_range} win_rate_range={this.state.win_rate_range} turnover_rate_range={this.state.turnover_rate_range} sharpe_ratio_range={this.state.sharpe_ratio_range} searchPortfolios={this.searchPortfolios} resetFilter={this.resetFilter} />
              <div className={ ['Overview-Right'].join( " " ) }>
                {
                  <div className={ ['Overview-Right-Top-Panel'].join( " " ) } >
                      <div className={ ['Overview-Right-Top-Title'].join( " " ) } > { this.props.gi18n[this.props.language]['portfolio_activities'] } </div>
                  </div>
                }
                { this.props.isLogin &&
                  <div className={ ['Overview-Right-Content'].join( " " ) } >
                      { (this.state.activities.length > 0 ) &&
                        this.state.activities.map(  (v,k) => {
                          return(
                            <ActivitiesLog key={k} obj={v} updateValue={this.updateValue} language={this.props.language} updateParentValue={this.props.updateValue} enableClick={true} gi18n={this.props.gi18n} />
                          )
                        }  )
                      }
                      { (this.state.activities.length == 0 ) &&
                        <div> {"No recent activities."} </div>
                      }
                  </div>
                }
                { !this.props.isLogin &&
                  <Button onClick={
                    (e)=> {
                      this.props.updateValue({
                        showLogin : true
                      })
                    }
                  }> 
                    {  this.props.gi18n[this.props.language]['login']  } 
                  </Button>
                }
              </div>
            <div className={ ['Overview-Left'].join( " " ) }>
                <div className={ ['Overview-Left-Top-Panel'].join( " " ) } >
                    { !this.state.isUser &&  (this.state.selectedType === 'bookmarked') &&
                      <div className={ ['Overview-Left-Top-Title'].join( " " ) } > { this.props.gi18n[this.props.language]['bookmark'] } </div>
                    }
                    { !this.state.isUser &&  (this.state.selectedType === 'all') &&
                      <div className={ ['Overview-Left-Top-Title'].join( " " ) } >
                        <span onClick={
                              (e) => {
                                this.setState({
                                  showFilter : true,
                                })
                              }
                            }>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">{ this.props.gi18n[this.props.language]['filter_favorite_portfolio'] }</Tooltip>}
                          >
                              <FontAwesomeIcon  
                                icon={  (("min" in this.state.initial_capital_range) || ("min" in this.state.profit_rate_range) ||  ("min" in this.state.win_rate_range) || ("min" in this.state.sharpe_ratio_range) || ("min" in this.state.turnover_rate_range ) )?faFunnelDollar:faFilter } 
                                className={['Overview-Left-Top-Fa'].join(' ')}/>

                          </OverlayTrigger>
                        </span>
                        <span onClick={
                            (e) => {
                              this.setState({
                                filtertype : (this.state.filtertype==="date")?"profit":"date"
                              })
                              
                              var _this = this
                              setTimeout( (e) => {
                                var obj = {}
                                var str = ( (_this.state.filtertype==="date")?"-modification_time":"-profit_rate" )
                                obj['order_by'] = str
                                _this.setState({ selectedObj : obj, page : 0 })
                                _this.getPortfoilioList(  obj  )
                              } )
                            }
                          }>
                          <FontAwesomeIcon  
                            icon={faSortAmountDown} 
                            className={['Overview-Left-Top-Fa'].join(' ')}
                          />
                          <span>{ this.props.gi18n[this.props.language][ "sort_"+this.state.filtertype] }</span>

                          <span >
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 250 }}
                              overlay={<Tooltip id="button-tooltip-2" className={["Over-Table"].join(" ")}>{ this.getRankInfo() }</Tooltip>}
                            >
                                <FontAwesomeIcon  
                                  icon={ faInfoCircle  } 
                                  className={['Overview-Left-Top-Fa'].join(' ')}/>

                            </OverlayTrigger>
                        </span>

                        </span>
                      </div>
                    }
                    { !this.state.isUser && (this.state.selectedType === 'myportfolio') &&
                      <ButtonGroup aria-label="" className={ ['Overview-Left-Top-Button-Gp'].join( " " ) }>
                        {
                          selections.map( (v, k) => {
                            return(
                              <Button 
                                onClick={ (e)=>{
                                  this.selectPublicType( v.key  )
                                }}
                                className={[ (v.key!==this.state.myportType)?"noselect":""].join(' ') } variant="secondary" key={k}  > 
                                { this.props.gi18n[this.props.language][v.value] } 
                              </Button>
                            )
                          } )
                        }
                      </ButtonGroup>
                    }
                    { this.state.isUser &&
                      <div className={ ['Overview-Left-Top-Title'].join( " " ) } >
                        { !('avator' in this.state.user) && 
                          <FontAwesomeIcon  icon={faUser} className={['fa'].join(' ')}/>  
                        }
                        { ('avator' in this.state.user) && 
                            <Image className={['fa'].join(' ')} src={ this.state.user.avatar  } />
                        }
                        { ('first_name' in this.state.user) && 
                          <span className={ ['name'].join( " " ) } >{ 
                             this.props.gi18n[ this.props.language ][ 'user_title' ].replace("<user>", this.state.user.first_name )
                          }</span> 
                        }
                      </div>
                    }

                    <Button className={ ['Overview-Left-Top-Create'].join( " " ) }
                      onClick={
                        (e)=>{
                          if( this.props.isLogin ){
                            this.props.updateValue( {
                              showSelection : true
                            } )
                          } else {
                            this.props.updateValue( {showLogin : true, showSignup : false} )
                          }
                        }
                      }
                    > { this.props.gi18n[this.props.language]['create'] } </Button>
                </div>
                
                
                
                <div className={ ['Overview-Left-Content'].join( " " ) } >
                  <div className={["lazyload-wrapper"].join(" ")}  > 
                    <div className={['Portfolio-Card', "title"].join(" ")} >
                      <div className={['box', 'profit' ].join(" ")} >
                        { this.props.gi18n[this.props.language][ "t_profit_p" ] }
                      </div>
                      <div className={['box', 'initial' ].join(" ")} >
                        { this.props.gi18n[this.props.language][ "initial" ] }
                      </div>
                      <div className={['box', 'name' ].join(" ")} >
                        { this.props.gi18n[this.props.language][ "group_name" ] } 
                      </div>
                      {
                        scores.map( (v,k) => {
                          var rank = "--"
                          return(
                            <div className={['box', 'score' ].join(" ")} key={k} 
                              style={ { backgroundColor : rank.color } }>
                              { this.props.gi18n[this.props.language][ v.value ] } 
                            </div>
                          )
                        } )
                      }
                      <div className={['box', 'onhold' ].join(" ")} >
                        { this.props.gi18n[this.props.language][  "t_onhold" ] }
                      </div>
                      { this.state.isMe &&
                        <div style={{opacity : 1}} className={['box','remove' ].join(" ")} >
                          { this.props.gi18n[this.props.language][  "remove" ] }
                        </div>
                      }
                    </div>
                  </div>
                    {
                      this.state.temp_card.map( (v,k) => {
                        return(
                          <div className={["lazyload-wrapper"].join(" ")} key={k}  >
                            <PortfolioCard obj={v}  updateValue={this.updateValue} language={this.props.language} updateParentValue={this.props.updateValue} selectedType={this.state.selectedType} deletePortfolio={this.deletePortfolio} isMe={this.state.isMe} gi18n={this.props.gi18n} showDeletePorfolio={this.showDeletePorfolio} colors={this.props.colors} scores={scores} selectedPortfolio={this.props.selectedPortfolio} onhold={this.props.onhold} />
                          </div>
                        )
                      })
                    }
                    { this.state.isMore && 
                      <Button 
                        className={ ['Overview-Left-More'].join(" ") } 
                        onClick={ (e)=>{

                          var _page = parseInt(this.state.page, 10)
                          var selectedObj = this.state.selectedObj 
                          
                          if(!selectedObj) {
                            selectedObj = {}
                          }
                          selectedObj['page'] = (_page + 1) 
                          this.setState({ selectedObj : selectedObj })
                          this.getPortfoilioList( selectedObj, true )     


                        } }> 
                        {  this.props.gi18n[ this.props.language ]['more']  } 
                      </Button>
                    }
                </div>
                
            </div>

          </div>
        )
    }
}


export default Overview;




/*
                    { !this.state.isUser && 
                      <div className={ ['Overview-Left-Top-Filter'].join( " " ) } > 
                        <div className={ ['Overview-Left-Top-Filter-Filter'].join( " " ) }>
                        <Dropdown >
                          <Dropdown.Toggle variant="success" id="dropdown-basic" className={['Overview-Left-Top-Filter-Dropdown'].join(' ')} >
                            { this.props.gi18n[this.props.language]['portfolio_filter'] + " : " +  this.props.gi18n[this.props.language][ this.state.selectedType ] } 
                          </Dropdown.Toggle>
                          <Dropdown.Menu align={'right'}>
                            { 
                              this.state.filter.map(  (v,k) => {
                                return(
                                  <Dropdown.Item 
                                    eventKey={k} 
                                    value={v.key} 
                                    key={v.key} 
                                    onClick={ (e)=>{
                                      var  obj = {}
                                      if( v.key === 'myportfolio' ) {
                                        obj[ v.type ] = "me"
                                        //this.reset()
                                      } else if ( v.key === 'bookmark' ) {
                                        obj[ v.type ] = "true"
                                        //this.reset()
                                      } 
                                      this.setState({ selectedType : v.value, selectedObj : obj, myportType : selections[0].key, page : 0 })
                                      var _this = this
                                      setTimeout((e)=> { 
                                        //_this.setState({ selectedObj : selectedObj })
                                        _this.getPortfoilioList(  this.state.selectedObj )
                                      }, 100 )
                                    } }
                                    className={['Overview-Left-Top-Filter-Dropdown-Item'].join(' ')} >{  this.props.gi18n[this.props.language][v.value] }
                                  </Dropdown.Item>
                                )
                              } )
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                      </div>
                    }
*/