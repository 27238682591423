import "ace-builds/src-noconflict/snippets/python";
import GService  from '../../GarlicanLibrary/gservice'

import gglobal from '../../GarlicanLibrary/gvars'
import Opcode from '../../GarlicanLibrary/opcode'

export class CustomHighlightRules extends window.ace.acequire("ace/mode/text_highlight_rules").TextHighlightRules {
	constructor() {
		super();
		var k = "and|break|continue|elif|else|for|from|if|in|not|or|pass|" + GService.defaults.join("|") + "|" + GService.exit.join("|")
		k+=("|"+GService.gi18n[gglobal.language]["="].replace(/ /g, "").replace(/[~!@#$%^&*()_+=\-`\[\]{}';:".,<>/?]/g, ""))
		k+=("|"+GService.gi18n[gglobal.language]["true"].replace(/ /g, "").replace(/[~!@#$%^&*()_+=\-`\[\]{}';:".,<>/?]/g, ""))
		k+=("|"+GService.gi18n[gglobal.language]["false"].replace(/ /g, "").replace(/[~!@#$%^&*()_+=\-`\[\]{}';:".,<>/?]/g, ""))
		var kk=[]
		for ( var i in GService.operators) {
			kk.push(Opcode.operations[ GService.operators[i] ].desc[gglobal.language].replace("<v1>", "").replace("<v2>", "").replace(/[~!@#$%^&*()_+=\-`\[\]{}';:".,<>/?]/g, "").replace(/ /g, ""))
		}
		k+= ("|"+kk.join("|"))
		for ( var i in Opcode.operator) {
			kk.push( Opcode.operator[i][gglobal.language] )
		}
		k+= ("|"+kk.join("|"))
		for ( var i in GService.defaults) {
			kk.push(   (GService.defaults[i] in GService.gi18n[gglobal.language])?GService.gi18n[gglobal.language][ GService.defaults[i] ]:GService.defaults[i] )
		}
		k+= ("|"+kk.join("|"))
		
		var keywords = k	

		var builtinConstants = (
			"True|False|None"
		);
		var f = []

		for( var i in  window.func) {
			if(  window.func[i].display ) {
				f.push(  window.func[i].name  )
			}
		}
		if(Object.keys(gglobal.stores).length > 0) {
			//k+= (Object.keys(gglobal.stores).join("|") + "|")
			//k+= Object.values(gglobal.stores).join("|")
			f = f.concat( Object.keys(gglobal.stores) )
			var v = Object.values(gglobal.stores), vv=[]
			for (var i in v) {
				vv.push(  v[i].value  )
			}

			f = f.concat(vv)
		}


		var builtinFunctions = f.join("|")
		builtinFunctions += ("|"+ f.join("|").toLowerCase())

		var keywordMapper = this.createKeywordMapper({
			"invalid.deprecated": "debugger",
			"support.function": builtinFunctions,
			"variable.language": "self|cls",
			"constant.language": builtinConstants,
			"keyword": keywords
		}, "identifier");
	

		var strPre = "[uU]?";
		var strRawPre = "[rR]";
		var strFormatPre = "[fF]";
		var strRawFormatPre = "(?:[rR][fF]|[fF][rR])";
		var decimalInteger = "(?:(?:[1-9]\\d*)|(?:0))";
		var octInteger = "(?:0[oO]?[0-7]+)";
		var hexInteger = "(?:0[xX][\\dA-Fa-f]+)";
		var binInteger = "(?:0[bB][01]+)";
		var integer = "(?:" + decimalInteger + "|" + octInteger + "|" + hexInteger + "|" + binInteger + ")";
	
		var exponent = "(?:[eE][+-]?\\d+)";
		var fraction = "(?:\\.\\d+)";
		var intPart = "(?:\\d+)";
		var pointFloat = "(?:(?:" + intPart + "?" + fraction + ")|(?:" + intPart + "\\.))";
		var exponentFloat = "(?:(?:" + pointFloat + "|" + intPart + ")" + exponent + ")";
		var floatNumber = "(?:" + exponentFloat + "|" + pointFloat + ")";
	
		var stringEscape = "\\\\(x[0-9A-Fa-f]{2}|[0-7]{3}|[\\\\abfnrtv'\"]|U[0-9A-Fa-f]{8}|u[0-9A-Fa-f]{4})";


		this.$rules = {
			"start" : [ {
				token : "comment",
				regex : "#.*$"
			}, {
				token : "string",           // multi line """ string start
				regex : strPre + '"{3}',
				next : "qqstring3"
			}, {
				token : "string",           // " string
				regex : strPre + '"(?=.)',
				next : "qqstring"
			}, {
				token : "string",           // multi line ''' string start
				regex : strPre + "'{3}",
				next : "qstring3"
			}, {
				token : "string",           // ' string
				regex : strPre + "'(?=.)",
				next : "qstring"
			}, {
				token: "string",
				regex: strRawPre + '"{3}',
				next: "rawqqstring3"
			}, {
				token: "string", 
				regex: strRawPre + '"(?=.)',
				next: "rawqqstring"
			}, {
				token: "string",
				regex: strRawPre + "'{3}",
				next: "rawqstring3"
			}, {
				token: "string",
				regex: strRawPre + "'(?=.)",
				next: "rawqstring"
			}, {
				token: "string",
				regex: strFormatPre + '"{3}',
				next: "fqqstring3"
			}, {
				token: "string",
				regex: strFormatPre + '"(?=.)',
				next: "fqqstring"
			}, {
				token: "string",
				regex: strFormatPre + "'{3}",
				next: "fqstring3"
			}, {
				token: "string",
				regex: strFormatPre + "'(?=.)",
				next: "fqstring"
			},{
				token: "string",
				regex: strRawFormatPre + '"{3}',
				next: "rfqqstring3"
			}, {
				token: "string",
				regex: strRawFormatPre + '"(?=.)',
				next: "rfqqstring"
			}, {
				token: "string",
				regex: strRawFormatPre + "'{3}",
				next: "rfqstring3"
			}, {
				token: "string",
				regex: strRawFormatPre + "'(?=.)",
				next: "rfqstring"
			}, {
				token: "keyword.operator",
				regex: "\\+|\\-|\\*|\\*\\*|\\/|\\/\\/|%|@|<<|>>|&|\\||\\^|~|<|>|<=|=>|==|!=|<>|="
			}, {
				token: "punctuation",
				regex: ",|:|;|\\->|\\+=|\\-=|\\*=|\\/=|\\/\\/=|%=|@=|&=|\\|=|^=|>>=|<<=|\\*\\*="
			}, {
				token: "paren.lparen",
				regex: "[\\[\\(\\{]"
			}, {
				token: "paren.rparen",
				regex: "[\\]\\)\\}]"
			}, {
				token: "text",
				regex: "\\s+"
			}, {
				include: "constants"
			}],
			"qqstring3": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string", // multi line """ string end
				regex: '"{3}',
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"qstring3": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",  // multi line ''' string end
				regex: "'{3}",
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"qqstring": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",
				regex: "\\\\$",
				next: "qqstring"
			}, {
				token: "string",
				regex: '"|$',
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"qstring": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",
				regex: "\\\\$",
				next: "qstring"
			}, {
				token: "string",
				regex: "'|$",
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"rawqqstring3": [{
				token: "string", // multi line """ string end
				regex: '"{3}',
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"rawqstring3": [{
				token: "string",  // multi line ''' string end
				regex: "'{3}",
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"rawqqstring": [{
				token: "string",
				regex: "\\\\$",
				next: "rawqqstring"
			}, {
				token: "string",
				regex: '"|$',
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"rawqstring": [{
				token: "string",
				regex: "\\\\$",
				next: "rawqstring"
			}, {
				token: "string",
				regex: "'|$",
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"fqqstring3": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string", // multi line """ string end
				regex: '"{3}',
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"fqstring3": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",  // multi line ''' string end
				regex: "'{3}",
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"fqqstring": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",
				regex: "\\\\$",
				next: "fqqstring"
			}, {
				token: "string",
				regex: '"|$',
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"fqstring": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",
				regex: "'|$",
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"rfqqstring3": [{
				token: "string", // multi line """ string end
				regex: '"{3}',
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"rfqstring3": [{
				token: "string",  // multi line ''' string end
				regex: "'{3}",
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"rfqqstring": [{
				token: "string",
				regex: "\\\\$",
				next: "rfqqstring"
			}, {
				token: "string",
				regex: '"|$',
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"rfqstring": [{
				token: "string",
				regex: "'|$",
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"fqstringParRules": [{//TODO: nested {}
				token: "paren.lparen",
				regex: "[\\[\\(]"
			}, {
				token: "paren.rparen",
				regex: "[\\]\\)]"
			}, {
				token: "string",
				regex: "\\s+"
			}, {
				token: "string",
				regex: "'(.)*'"
			}, {
				token: "string",
				regex: '"(.)*"'
			}, {
				token: "function.support",
				regex: "(!s|!r|!a)"
			}, {
				include: "constants"
			},{
				token: 'paren.rparen',
				regex: "}",
				next: 'pop'
			},{
				token: 'paren.lparen',
				regex: "{",
				push: "fqstringParRules"
			}],
			"constants": [{
				token: "constant.numeric", // imaginary
				regex: "(?:" + floatNumber + "|\\d+)[jJ]\\b"
			}, {
				token: "constant.numeric", // float
				regex: floatNumber
			}, {
				token: "constant.numeric", // long integer
				regex: integer + "[lL]\\b"
			}, {
				token: "constant.numeric", // integer
				regex: integer + "\\b"
			}, {
				token: ["punctuation", "function.support"],// method
				regex: "(\\.)([a-zA-Z_]+)\\b"
			}, {
				token: keywordMapper,
				regex: "/"+builtinFunctions.replace(/[~!@#$%^&*()_+=\-`\[\]{}';:".,<>/?]/g, "_")+ "|"+builtinConstants+"|"+ keywords+"/"
			}]
		}
		//console.log(keywords)
		//console.log( builtinFunctions )
		//console.log( keywords.replace(/[~!@#$%^&*()_+=\-`\[\]{}';:".,<>/?]/g, "_"))
		//[a-zA-Z_$][a-zA-Z0-9_$]*\\b
		this.normalizeRules();
	}
}

export default class CustomNode extends window.ace.acequire("ace/mode/python").Mode {
	constructor(props) {
		super(props);
		this.HighlightRules = CustomHighlightRules
	}
}

