import React from "react";
//import Global from '../../Shared/Vars'
import Terms from './Terms'
import './TermsScreen.scss'
//import '../../App.css';
import {Link} from 'react-router-dom'


export default class TermsScreen extends React.Component {

  constructor(props) {
    super(props); 
    this.state= {
      isShow : false,
      language : this.props.language
    } 
    this.show = this.show.bind(this)
  }

  show() {
    this.setState( { isShow:true  } )
  }


  componentWillMount() {
    //console.log("HAHA")
    this.props.updateValue({
      showUser : false,
    })
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  
  render() {

      return (
          <div className={['terms-container'].join(' ') }>

            <div className={['terms-header'].join(' ') }> { Terms[this.props.language]['header']  }  </div>

            <div className={['terms-sub-header'].join(' ') }> { Terms[this.props.language]['disclaimer_title']  }  </div>
            <div className={['terms-content'].join(' ') }> { Terms[this.props.language]['disclaimer_content']  }  </div>

            <div className={['terms-sub-header'].join(' ') }> { Terms[this.props.language]['payment_title']  }  </div>
            <div className={['terms-content'].join(' ') }> { Terms[this.props.language]['payment_content']  }  </div>

            { 
              Terms[this.props.language]['payment_sub_content'].map(i => {
                return <li className={['terms-sub-content'].join(' ') } key={i} > { i }  </li>
              })
            }

            <br/>
            <br/>
            <br/>
            <div className={['terms-content', 'red-color-text' ].join(' ') }> { Terms[this.props.language]['last_message']  }  </div>

          </div>
      )

  }
}






