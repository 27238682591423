import React, { Component } from 'react';
import './index.scss';

//import service from '../../public/service'
//import logoB from '../../public/img/garlican_blue_logo.png'

import { Button,Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faTimes, faChartLine } from '@fortawesome/free-solid-svg-icons'
import gservice from '../../../Garlican/GarlicanLibrary/gservice'

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Api from '../../../Garlican/GarlicanLibrary/apis'
import service from '../../../public/service';
import { server } from 'websocket';
import { symbol } from 'd3-shape';

class Code extends Component {

    constructor(props) {
      super(props)
      this.state = {
        botfolio : {},
        isLogin : this.props.isLogin,
        templates : [],
        page : 0,
        showModal : false,
        selectedTemplate : {},
        name : "",
        description : "",
      }
      this.create = this.create.bind(this)
      this.forwardtest = this.forwardtest.bind(this)
      //this.get = this.get.bind(this)
      this.getStrategy = this.getStrategy.bind(this)
      this.selected = this.selected.bind(this)

      this.callBack = this.callBack.bind(this)

      //console.log( "here" )

      
      
    }

    componentDidMount() {
      //this.get(this.props)
      //this.getStrategy()
    }

    componentWillUnmount() {
    
    }

    componentWillReceiveProps(props) {
      if( !("id" in this.state.botfolio) ) {
        if( props.list.length > 0 ) {
          if( props.isFilter ) {
            this.get(props)
          }
          this.getStrategy()
        }
      }
      if(   (props.isLogin !== this.state.isLogin) && (props.isLogin)  ) {
        //console.log( props.isLogin )
        this.setState({
          isLogin : props.isLogin
        })
        
        
      }
    }

    get(props) {
      if( props.isLogin ) {
            var code = "price=close #Targeted Price/指標價\nscycle = 5 #MACD Short cycles/採用短周期\nlcycle = 10 #MACD Long cycles/採用長周期\nsignal = 9 #MACD Signal Line/訊號線\nvar1 = macd(price, scycle, lcycle, signal)\n(c3, c2, c1) = close[-3:]\n(r3, r2, r1) = var1[-3:]\ncond1 = (c3 > c2) & (c3 > c1)\ncond2 = (r3 > r2) & (r3 > r1)\ncondition_long = (cond1 & cond2)\n#####EXIT CONDITION#####\nup_value=0.10 #止賺(%)\ndown_value=0.05 #止損(%)\nupper_profit_exit = (close >= (entryprice*( 1+up_value) ) ) #止賺賣出\nlower_profit_exit = (close <= (entryprice*( 1-down_value)) ) #止損賣出\nCondition_short =  (upper_profit_exit | lower_profit_exit)"
            var a = gservice.createStrategyObject( code, "tempstrategy" , "", {} )
            a.id = "temp_1"
            a.filters = [ {key: 'data', type: 'symbol', opr: 'in', value:'0700.HK'} ]
            var name = gservice.encodeObject(  "TEMPBOTFOLIO:" + service.user.user_id )
            var temp = props.list.filter( (v,k) => {
              return v.name === name
            } )
            if( temp.length === 0 ) {
              var gp = gservice.createGroupObject( null, name , "" )
              gp.strategy_list = [a]
              gp.partition = { "temp_1" : "100" }
              gp.input = { "temp_1" : {} }
              this.create(gp )
            } else {
              service.getPortfoilioById( temp[0].id ).then(  
                (res) => {
                  this.setState( {
                     botfolio : res
                  })
                }
              ) 
            }
      }
    }

    getStrategy( obj ) {
      service.getTemplates(obj).then(
        (res) => {
          if( 'code' in res ) {
            this.props.updateValue({
              isLogin : false
            })
          } else {
            this.setState({
              templates : res.list,
              page : res.page,
            })
          }
          
        }
      )
    }

    forwardtest( gp ) {
      Api.forwardtestingS( gp, {} , 0 ).then(
        (res) => {
            var fsyms = res.value.map( (v,k) => {
              return v.symbol 
            })
            this.props.updateFilterValue( { forward : fsyms } )
            this.props.updateValue({
              showLoading : false
            })
        }
      ).catch( (e) => {
        this.props.updateValue({
          showLoading : false
        })
      } )
    }

    create(v, cb) {
        var b = JSON.parse( JSON.stringify(v)  )
        var g = JSON.parse( JSON.stringify(v) )
        this.props.updateValue({
          showLoading : true
        })
        delete g.input
        delete g.partition
        g.input = {}
        g.partition = {}
        g.access_mode = "private"
        var oldKey = Object.keys(  b.partition  )
        var _this = this
        function _call(newObj) {
          newObj.name = g.name
          newObj.description = g.description
          newObj.initial_capital = g.initial_capital
          newObj.transaction_fee = g.transaction_fee
          newObj.access_mode = "private"
          service.updatePortfolio( g.id,newObj ).then(
            (r) => {
              g.input = newObj[ 'input' ]
              g.partition = newObj[ 'partition' ]
              service.getPortfoilioById( g.id ).then(
                (res) => {
                  _this.props.updateValue({
                    showLoading : false
                  })
                  _this.setState({
                    botfolio : res
                  })
                  if(cb)
                    cb( res )
                }
              )
            }
          )
        }
        function _create(s, id,  newObj ) {
          var strat = {}
          strat.portfolio_id = id
          var keys = ["id" ,"name", "description", "code", "filters", "strategy_template_id" ]
          for(var k in keys) {
            if( g.strategy_list[s][ keys[k] ] !== null ) {
              strat[ keys[k] ] = g.strategy_list[s][ keys[k] ]
            }
          }
          service.createStrategy( strat ).then(
            (response) => {
              var oid = oldKey[s]
              newObj.input[ response.id ] = b.input[ oid ]	
              newObj.partition[ response.id ] = b.partition[ oid ]
              g.strategy_list[ s ].id = response.id
              g.strategy_list[ s ].portfolio_id = id
              g.strategy_list[ s ].user_id = service.user.user_id
              s+=1
              if( s === g.strategy_list.length ) {
                _call( newObj )
              } else {
                _create(s, id, newObj)
              }
            }
          ).catch( (e)=> {
            _this.props.updateValue({
              showSelection : false,
              showLoading : false
            })
          })	
          
        }
        service.createPortfolio( g ).then(
          (res)=> {
            g.user = service.user
            g.id = res.id
            var newObj ={ input : {}, partition : {} }
            _create(0, res.id, newObj)
          }
        ).catch( (e)=> {
            _this.props.updateValue({
              showLoading : false
            })
        })     
      
    }

    selected(v) {
      var syms = []
      var seen = new Set()
      for( var k in this.props.read_sym) {
        if( syms.length == 0 ) {
          syms = this.props.read_sym[k]
        } else {
          syms = syms.concat( this.props.read_sym[k] )
        }
      }
      syms = syms.filter(el => {
        const duplicate = seen.has(el);
        seen.add(el);
        return !duplicate;
      });
      syms = [...seen]
      if( (syms.length > 0) && (syms.length < 300) ) {
        var _this = this
        var p = JSON.parse( JSON.stringify( this.state.botfolio ) )
        v = JSON.parse( JSON.stringify( v ) )
        this.props.updateValue({
          showLoading : true,
        })
        service.deleteStrategy(p.strategy_list[0].id  ).then(
          (res) => {
            delete p.partition[p.strategy_list[0].id ]
            delete p.input[p.strategy_list[0].id ]
            delete p.strategy_list[0]
            p.input = {}
            p.partition = {}
            v.strategy_template_id = v.id 
            v.portfolio_id = p.id
            service.createStrategy(  v   ).then( (response) => {
              var fobj = {filters : [ { key : 'data', type : "symbol", opr: "in" , value : syms.join(",") } ] }
              service.updateStrategy( response.id  ,fobj).then(
                (res) => {
                  p.input[ response.id ] = (v.input?v.input:{})
                  p.partition[ response.id ] = "100"
                  service.updatePortfolio( p.id, p ).then(  (res) => {
                    service.getPortfoilioById( p.id ).then(
                      (res) => {
                        
                        _this.setState({
                          botfolio : res,
                          selectedTemplate : v
                        })
                        _this.forwardtest( res )
                        //_this.props.updateValue({
                        //  showLoading : false,
                        //})
                        
                      }
                    )
                  } )
                }
              )

            } )
          }
        ).catch( (e) => {
          console.log(e)
          _this.props.updateValue({
            showLoading : false,
          })
        })
      } else {
        var obj = {}
        obj.header = ""
        obj.body = "No filter is found"
        this.props.updateValue({
          showAlert : true,
          alertMessage : obj,
        })
      }

    }

    callBack() {
      this.props.updateParent({
        selected : "botfolio",
        selctedBoard : "botfolio",
      })
      this.props.getList()
    }

    render() {
        return (
          <div className={['Code', this.props.showboard?"appear":"" ].join(" ") }>
            { this.props.isFilter &&
              <div className={['panel'].join(" ") }>
                <Button onClick={
                  (e)=> {
                    this.setState({
                      showModal : true
                    })
                  }
                }>  { this.props.gi18n[this.props.language]['create'] }  </Button>
              </div>
            }
            
            <div className={['board'].join(" ") } >
              <div className={["title"].join(" ")} >{"User a Templates" }</div>
              { this.props.isLogin &&
                this.state.templates.map( (v,k) => {
                  return (
                    <div className={['item', (this.state.selectedTemplate.id===v.id)?"selected":"" ].join(" ") } key={k} > 
                      <div className={['upper'].join(" ") } onClick={
                        (e)=> {
                          this.selected( v )
                        }
                      }> 
                       <span>{ v.name } </span>
                      </div>
                      <div className={['middle'].join(" ") } onClick={
                        (e)=> {
                          this.selected( v )
                        }
                      }> 
                       <span>{ this.props.gi18n[this.props.language]["powered"] + v.user.first_name } </span>
                      </div>
                      <div className={['lower'].join(" ") } onClick={
                        (e)=> {
                          this.selected( v )
                        }
                      }> 
                       <span>{ v.description } </span>
                      </div>
                      {
                        (this.state.selectedTemplate.id===v.id) && 
                        <div className={['panel'].join(" ") }> 
                          <Form>
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Strategy Name</Form.Label>
                              <Form.Control type="text" placeholder="Please type a name" onChange={ (e)=>{
                                this.setState( {
                                  name : e.target.value
                                } )
                              } } />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Description</Form.Label>
                              <Form.Control as="textarea" rows={5} onChange={ (e)=>{
                                this.setState( {
                                  description : e.target.value
                                } )
                              } }/>
                            </Form.Group>
                            <Button variant="primary" onClick={
                              (e) => {
                                if(  this.state.name.length > 0) {
                                  var p = JSON.parse(  JSON.stringify(  this.state.botfolio ) )
                                  p.name = this.state.name
                                  p.description = this.state.description
                                  console.log(p)
                                  this.create(p, this.callBack)
                                }
                              }
                            }>
                                { this.props.gi18n[this.props.language]['create'] } 
                            </Button>
                          </Form>  
                             
                        </div>
                      }
                    </div>
                  )
                } )
              }
              {
                !this.props.isLogin &&
                <Button 
                  onClick={ (e)=>{
                    this.props.updateValue({
                      showLogin : true,
                    })
                  }}
                  className={['login'].join(" ") } >  
                  {this.props.gi18n[this.props.language]['login']} 
                </Button>
              }
            </div>

            

          </div>
        )
    }
}


export default Code;
