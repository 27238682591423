import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import logoB from '../../../public/img/garlican_blue_logo.png'

import { Dropdown, DropdownButton, Button } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import {   } from '@fortawesome/free-solid-svg-icons'

import service from '../../../public/service'
import Api from '../../../Garlican/GarlicanLibrary/apis'
import sptrader from '../service/sp'
import { indexDependencies } from 'mathjs';


class TInsert extends Component {

    constructor(props) {
        super(props)
        this.state = {
            new_symbol : [],
            botfolios : [],
            bot : [],
            selectedBotfolio : {},
            selectedBot : {},
            //value : "1234"
        }
        this.select = this.select.bind(this)
        this.insert = this.insert.bind(this)
    }

    componentWillMount() {
        //console.log( this.props )

    }

    componentDidMount() {    
    }

    componentWillReceiveProps(props) {
        //console.log( props )
        var botfolios = []
        var obj = {}
        obj.id = -1
        obj.name = props.gi18n[props.language]['t_none']
        obj.index = -1
        botfolios.push( obj ) 
        //console.log( this.props.forwardGroup)
        for( var i in this.props.forwardGroup) {
            if(this.props.forwardGroup[i].group.id!== -1) {
                obj = {}
                obj.id = this.props.forwardGroup[i].group.id
                obj.name = this.props.forwardGroup[i].group.name
                obj.index = i
                botfolios.push( obj )  
            }
        }

        this.setState({
            botfolios : botfolios,
            //bot : bot,
            //selectedBotfolio : selectedBotfolio
        })
    }

    componentWillUnmount() {

    }

    select(index) {

        if( index !== 0 ) {
            //index += 1
            var botfolios = this.state.botfolios
            var selectedBotfolio = null, bot=[]
            if( botfolios.length > 0 ) {
                selectedBotfolio = botfolios[index]
                var sl = this.props.forwardGroup[ botfolios[index].index  ].group.strategy_list
                for (var i in  sl ) {
                    var obj = {}
                    obj.id = sl[i].id
                    obj.name = sl[i].name
                    obj.index = i
                    bot.push( obj )
                }
            }
            var _o = {
                bot : bot,
                selectedBotfolio : selectedBotfolio,
            }
            if( bot.length > 0 ) {
                _o.selectedBot = bot[i]
            }

            this.setState(_o)
        } else {
            var _o = {
                bot : [],
                selectedBotfolio : {},
                selectedBot : {}
            }
            this.setState(_o)
        }
    }

    insert(){
        var o = {
            symbol : this.state.new_symbol,
            botfolio : this.state.selectedBotfolio,
            bot : this.state.selectedBot
        }
        this.props.insert( o )
        this.setState({
            new_symbol : [],
            //botfolios : [],
            //bot : [],
            //selectedBotfolio : {},
            //selectedBot : {},
        })
    }

    render() {
        //console.log("b")
        return (
          <div className={['trade-insert', this.props.isAdd?'iShow':""].join(" ")} >
            <div className={ ['holder'].join(" ") }>
                <span className={ ['title'].join(' ') } >{  this.props.gi18n[this.props.language]['t_manage_botfolio']  }</span>
                { (this.state.bot.length > 0) &&
                    <span className={ ['title'].join(' ') } >{ this.props.gi18n[this.props.language]['t_select_bot'] }</span>
                }
            </div>
            <div className={ ['holder'].join(" ") }>
                <DropdownButton
                        variant="outline-secondary"
                        title={  this.state.selectedBotfolio.name?this.state.selectedBotfolio.name:this.props.gi18n[this.props.language]['t_pick_botfolio']  }
                        id="input-group-dropdown-1"
                        className={ ['dropdown'].join(' ') } 
                >
                    {
                            this.state.botfolios.map((v, k) => {
                            return(
                                <Dropdown.Item key={k} href="#"
                                onClick={ (e)=>{
                                    this.select( k )
                                    //this.setState( { selectedBotfolio : v.id })
                                }}
                                >{ v.name}</Dropdown.Item>
                                )
                            })
                    }
                </DropdownButton>
                { (this.state.bot.length > 0) &&
                    <DropdownButton
                            variant="outline-secondary"
                            title={  this.state.selectedBot.name?this.state.selectedBot.name:this.props.gi18n[this.props.language]['t_pick_bot']   }
                            id="input-group-dropdown-1"
                            className={ ['dropdown'].join(' ') } 
                    >
                        {
                                this.state.bot.map((v, k) => {
                                return(
                                    <Dropdown.Item key={k} href="#"
                                    onClick={ (e)=>{
                                        this.setState( { selectedBot : v })
                                    }}
                                    >{ v.name}</Dropdown.Item>
                                    )
                                })
                        }
                    </DropdownButton>
                }
            </div>
            <Typeahead
                id="symbol_picker"
				className={ ['input'].join(" ") }
				labelKey={this.props.gi18n[this.props.language]['t_symbol']}
                multiple={  false }
                paginate={ true }
                onChange={(e)=>{ 
                    //console.log(  e  )
                    this.setState({
                        new_symbol : e
                    })
                } }
                selected={ this.state.new_symbol  }
				options={  ("symbol" in this.props.filterconfig)?this.props.filterconfig.symbol.value:[] }
                placeholder={ this.props.gi18n[this.props.language]['choose_symbol'] }
			/>
            <div className={ ['holder'].join(" ") }>
                <Button disabled={ (this.state.new_symbol.length === 0) } className={["btn"].join(" ")} onClick={ (e)=>this.insert() } >{ this.props.gi18n[this.props.language]['t_insert'] }</Button>
            </div>
          </div>
        )
    }
}

export default TInsert;


/*
<Typeahead
                id="symbol_picker"
				className={ ['input'].join(" ") }
				labelKey={this.props.gi18n[this.props.language]['t_symbol']}
                multiple={  false }
                paginate={ true }
                onChange={(e)=>{ 
                    console.log(  e  )
                    //this.setState({
                    //    new_symbol : e
                    //})
                } }
                selected={ this.state.new_symbol  }
				options={  ("symbol" in this.props.filterconfig)?this.props.filterconfig.symbol.value:[] }
                placeholder={ this.props.gi18n[this.props.language]['choose_symbol'] }
			/>
*/