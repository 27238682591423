import React, { Component } from 'react';
import './index.scss';

import service from '../public/service'
import logoB from '../public/img/garlican_blue_logo.png'

import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faRobot, faRocket } from '@fortawesome/free-solid-svg-icons'

import gservice from '../Garlican/GarlicanLibrary/gservice'

import Api from '../Garlican/GarlicanLibrary/apis'
import Filter from './Filter'
import Botfolio from './Botfolio'
import Detail from './Detail'

var icons = [
  { key : 'quick_start', value : 'quick_start', icon : faRocket },
  { key : 'botfolio', value : 'my_botfolio', icon : faRobot },
]

const tree = {
  quick_start : [
    { key : 'strategy', value : 'quick_start' },
  ],
  botfolio : [
    { key : 'botfolio', value : 'botfolio' },
    { key : 'detail', value : 'detail' },
    { key : 'strategy', value : 'strategy' },
  ]
}

class Manager extends Component {

    constructor(props) {
        super(props)

        this.state = { 
          icons : [],
          list : [],
          selected : "quick_start",
          selectedBoard : 'strategy',
          selectedBotfolio : {},
          selectedStrategy : {},
          isLogin : props.isLogin,
          tree : tree["quick_start"],
        };
        this.updateParent = this.updateParent.bind(this)
        this.selectIcon = this.selectIcon.bind(this)
        this.getList = this.getList.bind(this)
        this.getList(this.props)
    }

    componentDidMount() {
      if (this.props.isLogin) {
        this.setState({
          icons : icons,
          selected : "quick_start",
          selectedBoard : 'strategy',
          tree : tree["quick_start"],
        })
      } else {
        this.setState({
          icons : [ icons[0] ],
          selected : "quick_start",
          selectedBoard : 'strategy',
          tree : tree["quick_start"],
        })
      }
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(props) {
      if( this.state.isLogin !== props.isLogin ) {
        if (props.isLogin) {
          this.setState({
            icons : icons
          })
        } else {
          this.setState({
            icons : [ icons[0] ]
          })
        }
        this.getList( props )
        this.setState({ isLogin : true })
      }


    }

    updateParent(obj) {
      this.setState( obj )
    }

    selectIcon( v ) {
      var tr = tree[ v.key ]
      this.setState({
        selected : v.key,
        selectedBoard : tr[0].key,
        tree : tr,
        selectedBotfolio : {},
        selectedStrategy : {},
      })
      //console.log( tree[this.state.selected] )
    }

    getList(props) {
      props = props?props:this.props
      if( props.isLogin ) {
        service.getPortfoilioList( {user_id : "me"} ).then(
          (res) => {
            this.setState({
              page : res.page,
              list : res.list 
            })
          }
        )
      }
    }

    render() {
        return (
          <div className={['GManager'].join(" ") }>

            <div className={['Left'].join(" ") }>
              { this.state.icons.map( (v,k) => {
                return(
                  <OverlayTrigger
                    key={k}
                    placement={"right"}
                    overlay={
                      <Tooltip >
                        <strong>{ v.value  }</strong>
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon 
                      icon={ v.icon } 
                      onClick={
                        (e) => {
                          this.selectIcon(v)
                        }
                      }
                      className={['icon', (this.state.selected===v.key)?"selected":""  ].join(' ')} >
                    </FontAwesomeIcon>
                  </OverlayTrigger>
                )
              } )
              }
            </div>

            { 
              (this.state.selected!=="quick_start")  && 
              <div className={['Middle', ((this.state.selectedBoard==="botfolio")|| this.state.selectedBoard==="detail"  )?"appear":"" ].join(" ") }>
                <Botfolio {...this.props} updateParent={this.updateParent} list={this.state.list} selectedBotfolio={this.state.selectedBotfolio} />
              </div>
            }
            { 
              (this.state.selected!=="quick_start")  && 
              <div className={['Middle2', ((this.state.selectedBoard==="detail")?"appear":"") ].join(" ") }>
                <Detail {...this.props} updateParent={this.updateParent} selectedBotfolio={this.state.selectedBotfolio} selectedStrategy={this.state.selectedStrategy} />
              </div>
            }
            
            <div className={['Ext'].join(" ") }>
                { 
                  this.state.tree.map( (v,k) => {
                    var attr = "", enable = false
                    if( "id" in this.state.selectedBotfolio ) {
                      if(  this.state.selectedBoard !== "botfolio" ) {
                        attr =  ">" + this.state.selectedBotfolio.name
                      } 
                      if( (v.key=== "detail") || (v.key=== "botfolio")   ) {
                        enable = true
                      }
                      if( "id" in this.state.selectedStrategy ) {
                        enable = true
                      }

                    } else {
                      if(  (v.key === "strategy" ) && (this.state.selected === "quick_start" )  ) {
                        enable = true
                      } else if(  (v.key === "botfolio") && (this.state.selected === "botfolio" )  ) {
                        enable = true
                      }
                    }

                    return(
                      <Button 
                        disabled={ !enable  }
                        className={['btn', (this.state.selectedBoard===v.key)?"selected":"", enable?"":'disable' ].join(" ")} key={k} onClick={
                        (e)=>{
                          var obj = {
                            selectedBoard : v.key
                          }

                          if( v.key === "botfolio" ) {
                            obj.selectedBotfolio = {}
                            obj.selectedStrategy = {}
                          }

                          this.setState( obj )
                        }
                      }> 
                        <span>{ v.value  }</span>
                        { 
                          (v.key === "botfolio") &&
                            <span className={["name"].join(" ")}>{  attr  }</span>
                        }
                        
                      </Button>
                    )
                  } )  
                }
            </div>

            <div className={['Right'].join(" ") }>
              { ((this.state.selected==="quick_start") || ( (this.state.selected==="botfolio") && ( this.state.selectedBoard === 'strategy')   )) && 
                <Filter {...this.props} updateParent={this.updateParent} list={this.state.list}  isFilter={ this.state.selected==="quick_start"} selectedBoard={this.state.selectedBoard} getList={this.getList} selectedBotfolio={this.state.selectedBotfolio} selectedStrategy={this.state.selectedStrategy} />
              }
            </div>



          </div>
        )
    }
}


export default Manager;
