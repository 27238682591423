import React, { Component } from 'react';
import './index.scss';

import service from '../../public/service'
import logoB from '../../public/img/garlican_blue_logo.png'

import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faRobot, faChartLine } from '@fortawesome/free-solid-svg-icons'

import gservice from '../../Garlican/GarlicanLibrary/gservice'

import Api from '../../Garlican/GarlicanLibrary/apis'


class Botfolio extends Component {

    constructor(props) {
        super(props)

        this.state = { 
          list : []
        };

    }

    componentDidMount() {
      var name = gservice.encodeObject(  "TEMPBOTFOLIO:" + service.user.user_id )
      var list = this.props.list.filter( (v,k) =>{
        return name !== v.name
      } )
      this.setState({
        list : list
      })
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(props) {
      var name = gservice.encodeObject(  "TEMPBOTFOLIO:" + service.user.user_id )
      var list = props.list.filter( (v,k) =>{
        return name !== v.name
      } )
      this.setState({
        list : list
      })
    }

    render() {
        return (
          <div className={['Botfolio'].join(" ") }>
            <div className={['panel'].join(" ") }>

            </div>

            <div className={['holder'].join(" ") }>
            {
              this.state.list.map(  (v,k) => {
                return(
                  <div className={['list', (this.props.selectedBotfolio.id === v.id)?'selected':"" ].join(" ") } key={k} onClick={ (e)=>{
                    this.props.updateParent( {
                      selectedBoard : 'detail',
                      selectedBotfolio : v,
                    } )
                  } } >
                    <div className={['upper'].join(" ") }> { v.name } </div>
                    <div className={['middle'].join(" ") }> 
                      
                    </div>
                    <div className={['lower'].join(" ") }> { v.description }</div>
                  </div>
                )
              })
            }
            </div>
          </div>
        )
    }
}


export default Botfolio;
