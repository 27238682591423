import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import logoB from '../../../public/img/garlican_blue_logo.png'

import {  } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faTimes, faCaretSquareRight  } from '@fortawesome/free-solid-svg-icons'

//import service from '../../../public/service'
//import Api from '../../../Garlican/GarlicanLibrary/apis'
//import sptrader from '../service/sp'

import List from '../list'

class Docker extends Component {

    constructor(props) {
        super(props)

        var title = {}
        title.action =   props.gi18n[ props.language ][ 'd_action' ]
        title.symbol = props.gi18n[ props.language ][ 'd_symbol' ]
        title.price = props.gi18n[ props.language ][ 'd_price' ]
        title.board_lot = props.gi18n[ props.language ][ 'd_boardlot' ]
        title.total_cost = props.gi18n[ props.language ][ 'd_total_cost' ]
        title.timestamp = props.gi18n[ props.language ][ 'd_timestamp' ]
        title.buy = props.gi18n[ props.language ][ 'd_buy' ]
        title.change = null
        this.state = {
            title : title,
            show : true,
        }

        this.removeDocker = this.removeDocker.bind(this)

    }

    componentWillMount() {
    }

    componentDidMount() {    
    }

    componentWillReceiveProps(props) {
        var title = {}
        title.action =   props.gi18n[ props.language ][ 'd_action' ]
        title.symbol = props.gi18n[ props.language ][ 'd_symbol' ]
        title.price = props.gi18n[ props.language ][ 'd_price' ]
        title.board_lot = props.gi18n[ props.language ][ 'd_boardlot' ]
        title.total_cost = props.gi18n[ props.language ][ 'd_total_cost' ]
        title.timestamp = props.gi18n[ props.language ][ 'd_timestamp' ]
        title.buy = props.gi18n[ props.language ][ 'd_buy' ]
        title.change = null
        this.setState({
            title : title,
            //show : true,
        })
    }

    componentWillUnmount() {

    }

    removeDocker() {
        this.props.updateGroup( this.props.gindex, null , true )
    }



    render() {
        if( !this.props.isReading ) {
            return (
                <div className={['trade-docker'].join(" ")} >
                    <div className={['ttitle'].join(" ")} >
                        <FontAwesomeIcon  icon={  faTimes  } className={['cross', 'gp'].join(' ')} onClick={
                            (e)=>{
                                this.removeDocker()
                            }
                        }/>
                        { (this.props.type === "action") &&
                            <FontAwesomeIcon  icon={  faCaretSquareRight  } className={['trun', 'gp'].join(' ')} onClick={
                                (e)=>{
                                    //RUN
                                    this.props.run( this.props.gindex )
                                }
                            }/>
                        }
                        <span className={['tname'].join(" ")} onClick={ (e)=>{
                            this.setState( {
                                show : !this.state.show
                            } )
                        } }>{ this.props.botfolio.name + " (" + this.props.list.length + ")" }</span>
                        <FontAwesomeIcon  icon={  this.state.show?faChevronUp:faChevronDown  } className={['tarrow'].join(' ')} onClick={ (e)=>{
                            this.setState( {
                                show : !this.state.show
                            } )
                        } }/>
                    </div>
                    <div className={['tholder', this.state.show?"tshow":"thide"].join(" ")} >
                        { (this.props.list.length > 0) &&
                            [
                                <List key={"label"} obj={this.state.title} title={true} {...this.props} />,
                                    this.props.list.map(  (v,k) => {
                                    return(
                                        <List key={k} obj={v} index={k} {...this.props}  />
                                    )
                                    } )
                            ]
                        }
                        {
                            (this.props.list.length === 0) &&
                            <span>    {  this.props.gi18n[this.props.language]['d_no_action'] }   </span>
                        }
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

//<FontAwesomeIcon  icon={faClone} className={['clone', 'icon'].join(' ')} onClick={ (e)=>this.props.pop() }/> 
export default Docker;
