import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import logoB from '../../public/img/garlican_blue_logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTimes } from '@fortawesome/free-solid-svg-icons'
import service from '../../public/service'
import GService from '../../Garlican/GarlicanLibrary/gservice';
import { Modal, ButtonGroup, Button, Image, Jumbotron, Form, ListGroup, InputGroup, FormControl } from 'react-bootstrap';

import Content from '../../Garlican/GarlicanStrategyPicker/Content'
import Confirm from "../../Components/Confirm"

import gcolor from '../../public/style.scss';

const MAX = 5
class CreatePanel extends Component {

    constructor(props) {
        super(props)

        //var timestamp = GService.getTimestamp()
        this.state = { 
          option : {} ,
          strategy_list : [],
          selected : {},
          partition : {},
          input : {},
          //name : (props.gi18n[ props.language]['my_new_portfolio'].replace("<name>",service.user.first_name) + "_" + timestamp) ,
          name : '',
          showAlert : false,
			    alertMsg : "",
          showAlertBtn : null,
          params : [],
          callBack : null,
			
        };        
        this.create = this.create.bind(this)
        this.select = this.select.bind(this)
        this.remove = this.remove.bind(this)

        //this.addStrategy = this.addStrategy.bind(this)
        this.insertTemplate = this.insertTemplate.bind(this)
        this.removeTemplate = this.removeTemplate.bind(this)
        this.changePartition = this.changePartition.bind(this)

        this.close = this.close.bind(this)
        this.closeAlert = this.closeAlert.bind(this)
    }

    componentDidMount() {

    }

    componentWillReceiveProps(props) {
      var opt = {}
      if(  window.templates.selection.length > 0 ) {
        opt = window.templates.selection[0]
      }
      var timestamp = GService.getTimestamp()
      var name = ""
      //console.log( service.user )
      if( props.isLogin ) {
        name = (props.gi18n[ props.language]['my_new_portfolio'].replace("<name>",service.user.first_name) + "_" + timestamp) 
      }

      this.setState({
          option : opt,
          partition : opt.object.partition,
          input : opt.object.input,
          strategy_list : opt.object.strategy_list,
          name : name
          //botfolio : opt.object,
      })

    }

    componentWillUnmount() {

    }

    create(v) {

      var b = JSON.parse( JSON.stringify( v )  )
      b = b.object
      b.strategy_list = this.state.strategy_list
      b.input = this.state.input
      b.partition = this.state.partition
      b.name = this.state.name
      
      var timestamp = GService.getTimestamp()
      //var name = this.props.gi18n[this.props.language]['my_new_portfolio'].replace("<name>",service.user.first_name) + "_" + timestamp
      var g = GService.createGroupObject(b, b.name, null)
      var strategy = []
      this.props.updateValue({
        showLoading : true
      })
      g = JSON.parse( JSON.stringify(g) )
      delete g.input
      delete g.partition
      g.input = {}
      g.partition = {}
      g.access_mode = "private"
      var oldKey = Object.keys(  b.input  )
      var _this = this

      function _call(newObj) {
        newObj.name = g.name
        newObj.description = g.description
        newObj.initial_capital = g.initial_capital
        newObj.transaction_fee = g.transaction_fee
        newObj.access_mode = "private"
        service.updatePortfolio( g.id,newObj ).then(
          (r) => {
            g.input = newObj[ 'input' ]
            g.partition = newObj[ 'partition' ]

            service.getPortfoilioById( g.id ).then(
              (res) => {
                _this.props.updateValue({
                  showSelection : false,
                  editedPortfolio : res,
                  showGarlican : true,
                  showLoading : false
                })
              }
            )



          }
        )
      }
      function _create(s, id,  newObj ) {
        var strat = {}
        strat.portfolio_id = id
        var keys = ["id" ,"name", "description", "code", "filters", "strategy_template_id" ]
        for(var k in keys) {
          if( g.strategy_list[s][ keys[k] ] ) {
            strat[ keys[k] ] = g.strategy_list[s][ keys[k] ]
          }
        }
        //strat['name'] = strat['name'] + "_" + service.user.first_name + "_" + timestamp
        service.createStrategy( strat ).then(
          (response) => {
            var oid = oldKey[s]
            newObj.input[ response.id ] = b.input[ oid ]	
            newObj.partition[ response.id ] = b.partition[ oid ]
            g.strategy_list[ s ].id = response.id
            g.strategy_list[ s ].portfolio_id = id
            g.strategy_list[ s ].user_id = service.user.user_id
            //g.strategy_list[ s ].name = g.strategy_list[ s ].name + "_" + service.user.first_name + "_" + timestamp
            s+=1
            if( s === g.strategy_list.length ) {
              _call( newObj )
            } else {
              _create(s, id, newObj)
            }
          }
        ).catch( (e)=> {
          _this.props.updateValue({
            showSelection : false,
            showLoading : false
          })
        })																						}
      service.createPortfolio( g ).then(
        (res)=> {
          g.user = service.user
          //g.user_id = 
          g.id = res.id
          var newObj ={ input : {}, partition : {} }
          _create(0, res.id, newObj)
        }
      ).catch( (e)=> {
          _this.props.updateValue({
            showSelection : false,
            showLoading : false
          })
      })

      if(  this.props.refs) {
        var keys = ['overview', 'overview2']
        for (var i in keys) {
          if( keys[i] in this.props.refs ) {
            if( this.props.refs[ keys[i] ].current ) {
              this.props.refs[ keys[i] ].current.getPortfoilioList( {'user_id' : service.user.user_id}  )
              this.props.refs[ keys[i] ].current.updateValue( {selectedType : 'myportfolio'}  )
            }
          }
        }
      }
      
    }

    select(v) {
      var b = JSON.parse( JSON.stringify( v )  )
      b = b.object
      
      var timestamp = GService.getTimestamp()
      var name = ""
      if( this.props.isLogin ) {
        name = (this.props.gi18n[ this.props.language]['my_new_portfolio'].replace("<name>",service.user.first_name) + "_" + timestamp) 
      }
      this.setState({
        option : v,
        partition : b.partition,
        input : b.input,
        strategy_list : b.strategy_list,
        name : name
        //botfolio : v.object,
      })
      //console.log(v)
    }

    remove(v) {
      var list = JSON.parse( JSON.stringify( this.state.strategy_list )  )
      list.splice( v, 1 )
      var p = JSON.parse( JSON.stringify( this.state.partition )  )
      delete p[ v.id ]
      var i = JSON.parse( JSON.stringify( this.state.input )  )
      delete i[ v.id ]

      this.setState( {
        strategy_list : list,
        partition : p,
        input : i,
        //botfolio : b,
      })
    }

    updateValue() {

    }

    insertTemplate(id, v) {
      var list = JSON.parse( JSON.stringify( this.state.strategy_list )  )

      if( list.length < MAX ) {
        v = JSON.parse( JSON.stringify( v  )  )
        v.strategy_template_id = v.id
        for( var i in list ) {
          if( list[i].id === v.id ) {
            return
          }
        }
        list.push(  v )
        var p = JSON.parse( JSON.stringify( this.state.partition )  )
        p[ v.id ] = "0"
        var i = JSON.parse( JSON.stringify( this.state.input )  )
        i[ v.id ] = v.input

        this.setState( {
          strategy_list : list,
          input : i,
          partition : p,
          //botfolio : b,
        })

      } else {
          this.setState({
            params : [],
            callback : null,
            showAlertBtn : null,
            showAlert : true,
            alertMsg :  ((this.props.language in this.props.gi18n)?this.props.gi18n[ this.props.language ]['backtesting_limit_error'].replace("<limit>", MAX):"Excceed Strategy Limit" ) ,
          })
      }
      //console.log(  this.state.option  )
    }

    removeTemplate(v) {
      console.log(v)
    }

    changePartition(v, value) {
      var p = JSON.parse( JSON.stringify( this.state.partition )  )
      p[ v.id ] = value
      this.setState( {
        partition : p,
      })
    }

    close() {

    }
    
    closeAlert() {
      this.setState({
        params : [],
        callback : null,
        showAlertBtn : null,
        showAlert : false,
        alertMsg :  null ,
      })
    }

    render() {
        return (
          <Modal 
            centered 
            size="lg"
            show={this.props.showSelection} 
            onHide={(e)=>{
              this.setState({
                strategy_list : [],
                selected : {},
                partition : {},
                input : {},
              })
              this.props.updateValue({
                showSelection : false
              })
            }}
            className={ ['app-Selection'].join(' ') }>
            <Modal.Header className={ ['app-Selection-Header'].join( " " ) } closeButton>
              <div className={['app-Selection-Header-Frame'].join(" ")}>
                <Image className={['app-Selection-Header-Logo'].join(" ")} src={logoB} />
                <div className={['app-Selection-Header-Title'].join(" ")} > { this.props.gi18n[this.props.language]['create_portfolio']  }  </div>
              </div>
            </Modal.Header>
            <Modal.Body>

              <div className={ ['left'].join(' ') }>
                <ButtonGroup vertical>
                { window.templates &&
                  window.templates.selection.map(  (v,k) => {
                    return(
                      <Button 
                        className={ ['group-btn', (this.state.option.id === v.id)?'selected':""  ].join(' ') }
                        key={k} onClick={
                        (e)=>{
                          //console.log(v)
                          this.select(v)
                        }
                      }>
                        {v.name[this.props.language]}
                      </Button>
                    )
                  })
                }
              </ButtonGroup>
              </div>
                
              { ("id" in this.state.option) &&
                <div className={ ['right'].join(' ') } >
                  <Jumbotron className={ ['desc'].join(' ') }> 
                    { this.state.option.description[this.props.language] } 
                  </Jumbotron>
                  { ("id" in this.state.option) && ( this.state.option.id === 0 ) &&
                    <div className={ ['panel'].join(" ") }>
                      <div className={ ['panel1'].join(" ") }>
                          <Form.Group controlId="exampleForm.ControlInput1" className={ ['name'].join(" ") }  >
                            <Form.Label> { this.props.gi18n[ this.props.language ]['group_name'] } </Form.Label>
                            <Form.Control 
                              onChange={
                                (e)=>{
                                  this.setState({
                                    name : e.target.value
                                  })
                                }
                              }
                              placeholder={ this.props.gi18n[ this.props.language ]['group_name'] } 
                              value={this.state.name} />
                          </Form.Group>
                          <div className={ ['strategy'].join(" ") }>
                            <ListGroup as="ul">
                              {  this.state.strategy_list.map( (v,k) => { 
                                return (
                                  <ListGroup.Item as="li"
                                    key={k}
                                    active={  (v.id === this.state.selected.id) }  >
                                    <InputGroup className="mb-3">
                                      <InputGroup.Prepend>
                                        <FormControl
                                          value={ this.state.partition[v.id] }
                                          placeholder="0"
                                          aria-label="Percent"
                                          aria-describedby="basic-addon2"
                                          onChange={
                                            (e)=>{
                                              this.changePartition( v, e.target.value )
                                            }
                                          }
                                        />
                                        <InputGroup.Text className={['percent'].join(' ')}>%</InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <InputGroup.Text className={['strategy-name'].join(' ')}>{v.name}</InputGroup.Text>
                                      <InputGroup.Append>
                                        <Button 
                                          onClick={ (e)=>this.remove(k) }
                                          variant="outline-secondary" 
                                          className={['remove'].join(' ')}>
                                          <FontAwesomeIcon  icon={ faTimes } className={['fa'].join(' ')} />
                                        </Button>
                                      </InputGroup.Append>
                                    </InputGroup>
                                  </ListGroup.Item>
                                )
                              } 
                            ) }

                            </ListGroup>
                          </div>
                          <Button 
                            onClick={
                              (e)=>this.create( this.state.option )
                            }
                            className={ ['generate'].join(" ") }>
                              { this.props.gi18n[this.props.language][ "generate"]  }
                          </Button>
                      </div>
                      <div className={ ['panel2'].join(" ") }>
                        <Content 
                          inBoard={true}
                          addStrategy={this.insertTemplate} 
                          remove={ this.removeTemplate  } 
                          add={false} 
                          convertToTemplate={ this.insertTemplate }
                          updateValue={this.updateValue} 
                          showTemplates={true}  
                          language={ this.props.language }
                          gi18n={ this.props.gi18n }
                        />
                      </div>
                    </div>
                  }

                  { ("id" in this.state.option) && ( this.state.option.id === 1 ) &&
                    <div className={ ['panel'].join(" ") }>
                      <div className={ ['panel1'].join(" ") }>
                          <Form.Group controlId="exampleForm.ControlInput1" className={ ['name'].join(" ") }  >
                                                    <Form.Label> { this.props.gi18n[ this.props.language ]['group_name'] } </Form.Label>
                                                    <Form.Control 
                                                      onChange={
                                                        (e)=>{
                                                          this.setState({
                                                            name : e.target.value
                                                          })
                                                        }
                                                      }
                                                      placeholder={ this.props.gi18n[ this.props.language ]['group_name'] } 
                                                      value={this.state.name} />
                           </Form.Group>
                          <Button 
                            onClick={
                              (e)=>this.create( this.state.option )
                            }
                            className={ ['generate'].join(" ") }>
                              { this.props.gi18n[this.props.language][ "generate"]  }
                          </Button>
                    </div>
                  </div>
                  }

                </div>
              }


            </Modal.Body>

            <Confirm msg={this.state.alertMsg} show={this.state.showAlert} secondary_btn_text={this.state.showAlertBtn} scope={'create'} callback={this.state.callback} params={this.state.params} language={this.props.language} gi18n={this.props.gi18n} replacement_func={ this.close } close={ this.closeAlert }  />	
          
          </Modal>
        )
    }
}


export default CreatePanel;


/*
                      <Jumbotron key={k}>
                        <h3>{v.name[this.props.language]}</h3>
                        <p>
                          {v.description[this.props.language]}
                        </p>
                        <p>
                          <Button 
                            onClick={
                              (e)=>{
                                //console.log(v)
                                this.create(v)
                              }
                            }
                            variant="primary">
                            { this.props.gi18n[this.props.language]['create']}
                          </Button>
                        </p>
                      </Jumbotron>
*/