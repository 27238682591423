import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTimesCircle, faArrowCircleUp, faArrowCircleDown, faMinusCircle} from '@fortawesome/free-solid-svg-icons'
import service from '../../public/service'

import { Button } from 'react-bootstrap';

//import { VictoryChart,VictoryArea, VictoryAxis, VictoryScatter} from 'victory';
import gcolor from '../../public/style.scss';

class PortfolioCard extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          showBoard : false,
          //data : [],
          //isOwner : service.isOwner(  this.props.obj.user.user_id   ),
          isOwner : false,
        };        
        this.getRank = this.getRank.bind(this)
        //this.getNumberFormat = this.getNumberFormat.bind(this)
    }

    componentDidMount() {
      //console.log( this.props.onhold )
      var showBoard = this.props.obj.backtest_report?( (Object.keys(this.props.obj.backtest_report).length > 0)?true:false ):false
      this.setState( { 
        showBoard : showBoard,
        isOwner : service.isOwner(  this.props.obj.user.user_id   ),
      } )
    }

    componentWillReceiveProps(props) {

      var showBoard = props.obj.backtest_report?( (Object.keys(props.obj.backtest_report).length > 0)?true:false ):false
      this.setState({
        showBoard : showBoard,
        isOwner : service.isOwner(  props.obj.user.user_id   ),
      })
    }

    componentWillUnmount() {

    }

    getRank(score) {

      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }

      function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
      
      function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
      }

      var s = "F-"
      var maxColor = hexToRgb( gcolor.gnGreenColor )
      var minColor = hexToRgb( gcolor.gnRedColor )

      var obj = { r: 0, g:0, b:0}
      for (var c in obj) {
        obj[c] = Math.round( (maxColor[c] - minColor[c] ) * score/100 + minColor[c] )
      }

      var color = rgbToHex( obj.r, obj.g, obj.b )

      for( var i in service.score_scale ) {
        if(  score > service.score_scale[i].score ) {
          s = service.score_scale[i].rank
          break;
        }
      }
      
      return { rank : s, color : color }
    }
    


    render() {
        return (
          <div className={['Portfolio-Card',  this.props.selectedPortfolio?((this.props.selectedPortfolio.id === this.props.obj.id)?"selected":""):faMinusCircle  ].join(" ")} onClick={ (e)=>{ 
              try {
                if(e.target.className) {
                  if( e.target.className.indexOf('remove') === -1 ) {
                    this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true }) 
                  }
                } else {
                  this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true }) 
                }
              }
              catch(e) {
                
              }
            } }>
            { this.props.obj.backtest_report && (this.props.selectedPortfolio?(this.props.selectedPortfolio.id !== this.props.obj.id):true) &&
              <div className={['box', 'profit' , (this.props.obj.backtest_report.profit > 0)?'up':((this.props.obj.backtest_report.profit < 0)?'down':faMinusCircle) ].join(" ")} >
                  <div className={['holder'].join(" ")}>
                    <FontAwesomeIcon  icon={  (this.props.obj.backtest_report.profit>0)?faArrowCircleUp:((this.props.obj.backtest_report.profit<0)?faArrowCircleDown:faMinusCircle)  } className={[  ].join(' ')}/> 
                    <span> { Math.abs(Math.round(this.props.obj.backtest_report.profit/this.props.obj.backtest_report.initial_capital * 10000)/100) + "%" } </span>
                  </div>
              </div>
            }
            { this.props.obj.backtest_report && (this.props.selectedPortfolio?(this.props.selectedPortfolio.id === this.props.obj.id):false) &&
              <div className={['box', 'profit' , (this.props.obj.backtest_report.profit > 0)?'up':((this.props.obj.backtest_report.profit < 0)?'down':'') ].join(" ")} >

                <div className={['main'].join(" ")}>
                    <FontAwesomeIcon  icon={  (this.props.obj.backtest_report.profit>0)?faArrowCircleUp:((this.props.obj.backtest_report.profit<0)?faArrowCircleDown:faMinusCircle)  } className={[  ].join(' ')}/> 
                    <span> { "$" + Math.abs(Math.round(this.props.obj.backtest_report.profit)) } </span>
                </div>
                <div className={['sub'].join(" ")}>
                    <span>(</span>
                    <FontAwesomeIcon  icon={  (this.props.obj.backtest_report.profit>0)?faArrowCircleUp:((this.props.obj.backtest_report.profit<0)?faArrowCircleDown:faMinusCircle)  } className={[  ].join(' ')}/> 
                    <span> { Math.abs(Math.round(this.props.obj.backtest_report.profit/this.props.obj.backtest_report.initial_capital * 10000)/100) + "%" } </span>
                    <span>)</span>
                </div>
               { //<span className={['main'].join(' ')} >{  ((this.props.obj.backtest_report.profit>0)?"+$":"-$") +  (Math.round( Math.abs(this.props.obj.backtest_report.profit)) ) }</span> 
               }
               {
                //<span className={['sub'].join(' ')}>{   "("+((this.props.obj.backtest_report.profit>0)?"+":"") +  (Math.round(this.props.obj.backtest_report.profit/this.props.obj.backtest_report.initial_capital * 10000)/100) + "%)"   }</span>
               }
              </div>
            }
            { !this.props.obj.backtest_report &&
              <div className={['box', 'profit' ].join(" ")} >
                {  "--" }
              </div>
            }

            <div className={['box', 'initial' ].join(" ")} >
               {"$" + ((this.props.obj.backtest_report)? service.getNumberFormat(this.props.obj.backtest_report.initial_capital):service.getNumberFormat(this.props.obj.initial_capital)   )  } 
            </div>

            <div className={['box', 'name' ].join(" ")} >
               { this.props.obj.name } 
            </div>

            {
              this.props.scores.map( (v,k) => {
                var rank = "--"
                if( this.props.obj[v.key] !== 0 ) {
                  var score = (this.props.obj.total_portfolios - (this.props.obj[v.key]-1))/this.props.obj.total_portfolios * 100
                  rank = this.getRank( score )
                } else {
                  rank = { rank : rank, color : gcolor.gnGreyColor }
                }
                return(
                  <div className={['box', 'score' ].join(" ")} key={k} 
                    style={ { backgroundColor : rank.color } }>
                    { rank.rank } 
                  </div>
                )
              } )
            }

            <div className={['box', 'onhold',  (((this.props.obj.id in this.props.onhold)?(this.props.onhold[this.props.obj.id].length):0) > 0)?"hold":"" ].join(" ")} >
                <span>  {   (this.props.obj.id in this.props.onhold)?(this.props.onhold[this.props.obj.id].length):0      } </span>
            </div>

              { this.props.isMe &&
                <Button className={['box', 'remove' ].join(" ")}  onClick={ (e)=> {
                  if(((this.props.obj.id in this.props.onhold)?(this.props.onhold[this.props.obj.id].length):0) === 0) { 
                    this.props.showDeletePorfolio( this.props.obj )
                  } else {
                    var obj ={
                      "header" : this.props.gi18n[this.props.language]['empty_title'] ,
                      "body" : this.props.gi18n[this.props.language]['empty_message'],
                    }
                    this.props.updateParentValue( {
                      showAlert : true,
                      alertMessage : obj,
                    })
                  }
                } }>
                  <FontAwesomeIcon  icon={faTimesCircle} className={['fa'].join(' ')}/>
                </Button>
              }

          </div>
        )
    }
}


export default PortfolioCard;




