import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { Modal, Image, Button } from 'react-bootstrap';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"

import logoB from '../../public/img/garlican_blue_logo.png'

class Picker extends Component {

    constructor(props) {
        super(props)

        this.state = { 
          value : []
        };

    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(props) {

    }

    render() {
      return (
        <Modal
            onHide={() => {
              //this.props.close()  
              this.props.updateValue( { showFilter : false } )
            }}
            centered
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="Picker-Container"
            className={['Picker-Frame'].join(" ")} 
            show={this.props.show} >
          <Modal.Header className={['Picker-Header'].join(" ")}  closeButton> 
            <div className={['Picker-Header-Frame'].join(" ")}>
              <Image className={['Picker-Header-Logo'].join(" ")} src={logoB} />
              <div className={['Picker-Header-Title'].join(" ")} > { this.props.gi18n[this.props.language]["picker"] }  </div>
            </div>
          </Modal.Header>
          <Modal.Body className={['Picker-Body'].join(" ")} >
            {
              this.props.pickeroptions.map((v,k)=> {
                return(
                  <div key={k} className={ ['Picker-Switch-Container'].join(' ')} >
                    <div className={ ['Picker-Title'].join(' ')}> 
                      { this.props.gi18n[this.props.language][v.value] } 
                    </div>
                    <div className={ ['Picker-Switch'].join(' ')} >
                      <InputRange
                        step={ v.step }
                        draggableTrack={true}
                        formatLabel={ (value) => v.label.replace("<value>", value)  }
                        minValue={ v.range[0] }
                        maxValue={ v.range[1] }
                        value={ {
                          min:this.props[v.key+"_range"].min?this.props[v.key+"_range"].min:v.range[0], 
                          max:this.props[v.key+"_range"].max?this.props[v.key+"_range"].max:v.range[1]
                        } }
                        onChange={ (value) => {
                          var obj = {}
                          obj[ v.key+"_range" ] = value
                          this.props.updateValue(obj)
                        }} 
                        onChangeComplete={
                          (value) => {
                            this.props.searchPortfolios()
                          }
                        }/>
                        
                    </div>
                  </div>
                )
              })
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e)=>{
              this.props.resetFilter()
            }}>{ this.props.gi18n[this.props.language]['reset_filter'] }</Button>
          </Modal.Footer>
        </Modal>
      )
    }
}


export default Picker;
