import React, { Component } from 'react';
import './index.scss';

import service from '../public/service'
import logoB from '../public/img/garlican_blue_logo.png'

import { Modal, Image, Form, Button, InputGroup, FormControl} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUser } from '@fortawesome/free-solid-svg-icons'
import gservice from '../Garlican/GarlicanLibrary/gservice'

import getCroppedImg from './cropImage'

import Cropper from 'react-easy-crop'

import { Link } from 'react-router-dom'

const links = [
  {url : '/privacy', name : 'privacy'},
  {url : '/terms', name : 'terms'},
  //{url : '/support', name : 'support'}
]


const settings = [
  { key : 'first_name', value : 'first_name', type : 'input' , size:'half', input_type : "input"},
  { key : 'last_name', value : 'last_name',type : 'input', size:'half', input_type : "input" },
  //{ key : 'email_name', value : 'email', type : 'input' , size:'full' , input_type : "email"},
  { key : 'description', value : 'description', type : 'textarea', size:'full'},
  
]

class User extends Component {

    constructor(props) {
        super(props)

        this.state = { 
          image : null,
          zoom : 1,
          crop : { x: 0, y: 0 },
          rotation : 0,
          isCrop : false,
          tempImage : null,
          first_name : ("first_name" in props.user)?props.user.first_name:"",
          last_name : ("last_name" in props.user)?props.user.last_name:"",
          description : ("description" in props.user)?props.user.description:"",
        };

        window.document.user = this;
        this.handleChange = this.handleChange.bind(this)
        this.onCropComplete = this.onCropComplete.bind(this)
        this.updateInfo = this.updateInfo.bind(this)

        this.hiddenFileInput = React.createRef()
    }

    componentDidMount() {
      
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(props) {
      //console.log( props.user.avatar )
      var obj = {}
      if( props.user.avatar ) {
          obj.image =props.user.avatar
      }
      obj.first_name = props.user.first_name
      obj.last_name = props.user.last_name
      //console.log(obj)
      this.setState( obj )
    }

    async useCallback() {
      try {
        const croppedImage = await getCroppedImg(
          this.state.tempImage,
          this.state.croppedAreaPixels,
          this.state.rotation
        )
        service.postUserPicture( croppedImage ).then(
          (res)=> {
            this.setState({
              image : croppedImage,
              isCrop : false,
              tempImage : null,
            })
          }
        )
      } catch (e) {
        console.error(e)
      }
    }

    handleChange(e) {
      try {
        //console.log( URL.createObjectURL(e.target.files[0]) )
        this.setState({
          isCrop : true,
          tempImage: URL.createObjectURL(e.target.files[0])
        })
      }
      catch(e){

      }
    }

    onCropComplete(e, pix) {
      this.setState({
        croppedAreaPixels : pix
      })
    }

    updateInfo() {
      service.postUserProfile(this.state.first_name, this.state.last_name, this.state.description, "").then(
        (res)=> {
          //console.log("Update")
        }
      )
    }


    render() {
        return (
          <Modal
              centered
              size="lg"
              show={this.props.showUser}
              onHide={() => this.props.updateValue( {  showUser  : false  }  )}
              aria-labelledby="example-modal-sizes-title-lg"
              className={ ['User-Holder'].join( " " ) }>
              <Modal.Header className={ ['User-Title'].join( " " ) } closeButton>
                <div className={['User-Title-Frame'].join(" ")}>
                  <Image className={['User-Title-Logo'].join(" ")} src={logoB} />
                  <div className={['User-Title-Title'].join(" ")} >{  this.props.gi18n[this.props.language]['controller_greeting'].replace('<user>', service.user.first_name)  }</div>
                </div>
              </Modal.Header>
  
              <Modal.Body className={ ['User-Body'].join( " " ) }>

              { this.state.tempImage && this.state.isCrop && 
                <div className={['User-Body-Image-Container'].join(' ')}>
                  <Cropper
                    image={this.state.tempImage}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    rotation={this.state.rotation}
                    aspect={  4/4 }
                    cropSize={ { width: 100, height: 100 } }
                    minZoom={0.1}
                    onRotationChange={(e) => {
                      this.setState({ rotation : e })   
                    } }
                    onCropChange={  (e) => {
                      this.setState({ crop : e })   
                    } }
                    onCropComplete={   this.onCropComplete   }
                    onZoomChange={  (e) =>  {
                      this.setState({ zoom : e })  
                    }}
                  />
                  <Button 
                    className={['User-Body-Image-Btn'].join(' ')}
                    onClick={ (e)=>{
                        this.useCallback()
                    }}>
                          {  this.props.gi18n[this.props.language]["save"] }
                  </Button>
                </div>
              }

              { (this.state.image && !this.state.isCrop) && 
                <Image src={ this.state.image} className={['User-Body-Image'].join(' ')}/>
              }
              { (!this.state.image && !this.state.isCrop) && 
                <FontAwesomeIcon  icon={faUser} className={['User-Body-Image'].join(' ')}/>
              }                       

                  <div className={['User-Body-Upload'].join(' ')}>
                    <Form >
                        <Button onClick={ (e)=>{
                          this.hiddenFileInput.current.click()
                        }}>
                            {this.props.gi18n[this.props.language]["browser"]}
                        </Button>
                        <Form.File id="upload" label="" onChange={this.handleChange} ref={this.hiddenFileInput} style={{ display : 'none'}} accept="image/png, image/jpg, image/jpeg"/>
                    </Form>
                  </div>

                  <div className={['User-Body-Info'].join(' ')}>              
                    <Form>
                      {
                        settings.map( (v,k) => {
                          if( v.type === "textarea") {
                            return(
                              <Form.Group key={k} className={["mb-3", (v.size==="half")?"half":""].join(" ")} >
                                <Form.Label>{ this.props.gi18n[this.props.language][v.value] }</Form.Label>
                                <Form.Control 
                                  className={["text"].join(" ")}
                                  as="textarea" rows={3} value={ this.state[ v.key ] } placeholder={ this.props.gi18n[this.props.language]['description_msg'] }  
                                  onChange={
                                    (e)=> {
                                      var obj = {}
                                      obj[v.key] = e.target.value
                                      this.setState( obj )
                                    }
                                  }
                                />
                              </Form.Group>
                            )
                          } else {
                            return(
                              <InputGroup key={k} className={["mb-3", 'half'].join(" ")} >
                                <InputGroup.Text className={["mb-3", "input-label"].join(" ")} >{this.props.gi18n[this.props.language][v.value] } </InputGroup.Text>
                                <FormControl
                                  type={v.input_type} value={ this.state[ v.key ] }  
                                  placeholder={  this.props.gi18n[this.props.language]['input_msg'].replace("<name>",   this.props.gi18n[this.props.language][v.value]  ) } 
                                  onChange={
                                    (e)=> {
                                      var obj = {}
                                      obj[v.key] = e.target.value
                                      this.setState( obj )
                                  }}
                                  aria-label="Default"
                                  aria-describedby="inputGroup-sizing-default"
                                />

                            </InputGroup>
                            )
                          }
                        }  )
                      }
                    </Form>
                  </div>

                  <InputGroup className={ ['link'].join( " " ) }>
                    <Form.Control id={"mylink"} className={ ['grey'].join( " " ) } readOnly={true} value={  window.location.origin + "/" + gservice.encodeObject( "user" + this.props.user.user_id ) }  
                      onChange={
                        (e)=> {
                        }
                      }
                    />
                    <Button onClick={ (e)=>{
                      //var link = window.location.origin + "/" + gservice.encodeObject( "user" + this.props.user.user_id )
                      var copyText = document.getElementById("mylink");
                      copyText.select();
                      copyText.setSelectionRange(0, 99999);
                      document.execCommand("copy");

                    }  }>  { this.props.gi18n[this.props.language]['copy'] }   </Button>
                  </InputGroup>

                  <div className={ ['User-Btn-Frame'].join( " " ) }>
                    <Button className={ ['User-Btn'].join( " " ) } onClick={ (e)=>this.updateInfo() } > { this.props.gi18n[this.props.language]['update']  }</Button>
                    <Button className={ ['User-Btn'].join( " " ) } onClick={ (e)=>this.props.logout() } > { this.props.gi18n[this.props.language]['logout']  }</Button>
                  </div>


                  <div className={ ['User-Bottom-Frame'].join( " " ) }>
                    {
                      links.map( (v,k) => {
                        return(
                          [ (k!==0)?(<span key={99 }>|</span>):null , 
                            <Link key={k} to={v.url} className={ ['Footer-Frame-Control-a'].join( " " ) } >
                              { this.props.gi18n[this.props.language][v.name] }
                            </Link>
                          ]
                        )
                      } )
                    }
                  </div>


            </Modal.Body>
          </Modal>
        )
    }
}


export default User;

/*                              
<Form.Group key={k}  className={["mb-3", (v.size==="half")?"half":""].join(" ")} >
                                <Form.Label>{ this.props.gi18n[this.props.language][v.value] }</Form.Label>
                                <Form.Control type={v.input_type} value={ this.state[ v.key ] }  placeholder={  this.props.gi18n[this.props.language]['input_msg'].replace("<name>",   this.props.gi18n[this.props.language][v.value]  ) } 
                                  onChange={
                                  (e)=> {
                                    var obj = {}
                                    obj[v.key] = e.target.value
                                    this.setState( obj )
                                  }
                                }/>
                              </Form.Group>
*/             

//onHide={() => this.props.updateValue( {  showUser  : false  }  )}