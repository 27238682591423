import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import service from '../../public/service'
import gservice from '../../Garlican/GarlicanLibrary/gservice'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUser,faArrowCircleUp, faArrowCircleDown, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { Jumbotron, Button, Container, Accordion, Card, Col, Row, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap';

import { VictoryChart, VictoryTheme , VictoryPolarAxis, VictoryLabel, VictoryArea, VictoryPie, VictoryLegend } from 'victory';
import gcolor from '../../public/style.scss';

import ActivitiesLog from "../../Components/ActivitiesLog"
import GarlicanReport from '../../Garlican/GarlicanReport';

import Api from '../../Garlican/GarlicanLibrary/apis'
import sptrader from '../../Components/Trader/service/sp';

const defaultColor = gcolor.gnBadgeColor
var colors = []
const MAX_ROW = 5

const types = [
  { key : 'backtest', value : 'backtest' },
  { key : 'forwardtest', value : 'forwardtest' }
]

const frame = {
  "risk_rate" : 100,
  'win_rate' : 100,
  "quantity_rate" : 100 ,
  "profit_rate" : 100 
}

const portfolio_frame = [
  {key : 'view_count' , value : 'view_ct'},
  {key : 'bookmark_count' , value : 'subscribe_ct'},
]

const gd = [
  {  x: "risk_rate" , y: 0 },
  {  x: 'win_rate' , y: 0 },
  {  x: "quantity_rate" , y: 0 },
  {  x: "profit_rate" , y: 0  },
]
const gdOj = {
  'risk_rate' : 0,
  'win_rate' :  0,
  'quantity_rate' : 0,
  'profit_rate' : 0
}

class Detail extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          obj : null,
          data: gd,
          maxima: frame,
          partition : [],
          selected : null,
          strategy_list : [],
          activities : {},
          selectedStrategy : {},
          isOwner : false,
          pieArea : 0,
          showReport : false,
          gdOj : gdOj,
          total_portfolio : 0,
          type : 'backtest',
          isShare : false,
          
        };
        
        this.updateValue = this.updateValue.bind(this)
        this.selectedStrategy = this.selectedStrategy.bind(this)

        this.getPortfolioDetail = this.getPortfolioDetail.bind(this)
        this.getReport = this.getReport.bind(this)

        this.forward = this.forward.bind(this)
        this.forwardtesting = this.forwardtesting.bind(this)

        
    }

    componentDidMount() {
      //service.rebuildAsset().then( (res)=>{
      //  console.log(res)
      //} )
    }

    componentWillUnmount() {
    }

    updateValue(obj) {
      this.setState(obj)
    }

    selectedStrategy(obj) {
      if( obj.id === this.state.selectedStrategy.id  ) {
        this.setState(  { selectedStrategy : {}, selected:null }  )
      } else {
        this.setState(  { selectedStrategy : obj, selected : obj.id }  )
      }
    }

    componentWillReceiveProps(props) {
      var _this = this
      setTimeout( (e) => {
          if( document.getElementById("tab") ) {
            var rect = document.getElementById("tab").getBoundingClientRect()
            console.log(rect)
            var height = rect.height
            var width = rect.width
            _this.setState({
              pieArea :  Math.min( height, width ) ,
            })
          }
      } , 500 )
      if(   (props.selectedBotfolio !== null) && (props.showGarlican === false) ) {
        if( !this.state.obj || (props.selectedBotfolio.id !== this.state.obj.id) ) {
          this.getPortfolioDetail(props)
        }
      } 
    }


    getPortfolioDetail(props) {
      var _this = this
      //console.log(  props.selectedBotfolio )
      if(props.selectedBotfolio ) {
        function _call( res ) {
          res.user = props.selectedBotfolio.user
          if( res.forwardtest_report ) {
            _this.getReport(  res.forwardtest_report.portfolio_id, res.forwardtest_report.id, res  )
          }   
          var checkers = [  
              { key : 'strategy_list', value : [] }, 
              { key : 'input', value : {} }, 
              { key : 'partition', value : {} },
              { key : 'transaction_fee', value : 0 },
          ]
          for (var c in checkers) {
              if( !( checkers[c].key in res) ) {
                res[  checkers[c].key   ] = checkers[c].value
              }
          }
          var _gd = [], _gdOj={}
          if(res['total_portfolios']!==0) {
            _gd = [
              {  x: "risk_rate" , y: Math.round( (res['total_portfolios'] - res['sharpe_ratio_rank'] + 1)/res['total_portfolios']*10000)/100 },
              {  x: 'win_rate' , y: Math.round( (res['total_portfolios'] - res['win_rate_rank'] + 1)/res['total_portfolios']*10000)/100 },
              {  x: "quantity_rate" , y: Math.round( (res['total_portfolios'] - res['turnover_rate_rank'] + 1)/res['total_portfolios']*10000)/100 },
              {  x: "profit_rate" , y: Math.round( (res['total_portfolios'] - res['profit_rate_rank'] + 1)/res['total_portfolios']*10000)/100  },
            ]
            _gdOj = {
                'risk_rate' : Math.round( (res['total_portfolios'] - res['sharpe_ratio_rank'] + 1)/res['total_portfolios']*10000)/100,
                'win_rate' :  Math.round( (res['total_portfolios'] - res['win_rate_rank'] + 1)/res['total_portfolios']*10000)/100,
                'quantity_rate' : Math.round( (res['total_portfolios'] - res['turnover_rate_rank'] + 1)/res['total_portfolios']*10000)/100,
                'profit_rate' : Math.round( (res['total_portfolios'] - res['profit_rate_rank'] + 1)/res['total_portfolios']*10000)/100  
            }
          } else {
              _gd = gd
              _gdOj = gdOj
          }
        
          var isOwner = service.isOwner( parseInt(res.user.user_id)  )
          var partition = []
          var cash = 100, strategy_list = []
          for (var i in res.strategy_list) {
              var obj = {}
              obj.name = res.strategy_list[i].name
              obj['gid'] = res.id
              obj['id'] = res.strategy_list[i].id
              obj['x'] = res.strategy_list[i].name
              obj['y'] = parseInt(res.partition[  res.strategy_list[i].id  ])/100
              cash -= parseInt(res.partition[  res.strategy_list[i].id  ])
              partition.push( obj )
              var _obj = JSON.parse(   JSON.stringify( res.strategy_list[i] )  )
              _obj['partition'] = res.partition[  res.strategy_list[i].id  ]
              strategy_list.push(_obj)
          }
          var _o = {}
          _o.name = _this.props.gi18n[ _this.props.language]['cash']
          _o.partition = cash.toString()					
          strategy_list.splice( 0, 0, _o)
          partition.splice( 0, 0, {id : "-1" , x : _this.props.gi18n[ _this.props.language]['cash'], y : cash / 100, name: _this.props.gi18n[ _this.props.language]['cash']} )
          console.log(res)
          _this.setState( { 
              isOwner: isOwner, 
              bookmarked : res.bookmarked,
              obj : res, 
              data : _gd,  
              gdOj : _gdOj,
              partition : partition, 
              strategy_list : strategy_list,
              total_portfolio : res['total_portfolios'],
          } )
        }

        //if( props.isLogin ) {
          service.getPortfoilioById( props.selectedBotfolio.id ).then(
            (res) => {
              _call(res)
            }
          )
        //} else {
        //  _call(props.selectedBotfolio)
        //}
      }
    }

    getReport(pid, rid, portfolio) { 
      //HERE
          var obj = { portfolio_id : pid }
          //service.getAssetList( obj ).then(
          service.getRecentTradeList(obj).then(
            (res) => {
              var k = JSON.parse(  JSON.stringify( this.props.selectedBotfolio ) )
              k.forwardtest_report.asset_list = res.list
              var act = []
              for (var i in res.list) {
                if( res.list[i].unit !== 0 ) {
                  res.list[i].action = "buy"
                  res.list[i].portfolio = portfolio
                  res.list[i].user = portfolio.user
                  act.push(  res.list[i]  )
                }

              }
              this.setState( {
                activities : act,
                selectedID : this.props.selectedBotfolio.id
              } )
            }
          )

    }

    forward() {
      var _this = this
      function _run(res, group) {
        var params = {}
        for (var i in res.asset_list) {
          res.asset_list[i].unit = 1
          if(  "unit" in  res.asset_list[i] ) {
            res.asset_list[i].strategy = res.asset_list[i].strategy_id.toString()
            //console.log( res.asset_list[i]   )
            if(  !(res.asset_list[i].symbol in params)  ){
              params[ res.asset_list[i].symbol ] = {}
            }
            if(  !( res.asset_list[i].strategy_id in params[ res.asset_list[i].symbol ])  ) {
              params[ res.asset_list[i].symbol ][   res.asset_list[i].strategy_id   ] = {}
            }
            if(  "unit" in  res.asset_list[i] ) {
              var d = '27070740', duration = 0
              if( res.asset_list[i].last_purchase_time ) {
                d = service.formatDate( res.asset_list[i].last_purchase_time, null, true  )
                d = d.getTime()/1000
                var now = new Date()
                now = now.getTime()/1000
                duration = (now - d)
              }
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy] = {}
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['betsizeonhold'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['betsize'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['shortbetsize'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['onstock'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['marketposition'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['onmarket'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['entryprice'] = res.asset_list[i].price.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['entrytime'] = d/60/60/24
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['last_purchase_time'] = res.asset_list[i].last_purchase_time
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['barssinceentry'] = duration.toString()
            }
          }
        }
        //console.log(res)
        _this.forwardtesting( group, params, group.transaction_fee, res )
      }

      var group = JSON.parse(  JSON.stringify(this.state.obj) )
      //console.log( group )

      if( group.forwardtest_report ) {
        /*service.getReport( group.id , group.forwardtest_report.id, true  ).then(
          (res) => {
            console.log(res)
            _run(res, group)
          }
        )*/
        var obj = { portfolio_id : group.id }
        //var obj = {}
        service.getAssetList( obj ).then(
          (res) => {
            //console.log(res)
            var k = JSON.parse(  JSON.stringify( group ) )
            k.forwardtest_report.asset_list = res.list
            _run( k.forwardtest_report , group)
          }
        ).catch( (e) => {
          this.props.updateValue({
            showLoading : false,
            showAlert : true,
            alertMessage : {
              header :  this.props.gi18n[this.props.language]['error'] ,
              body : this.props.gi18n[this.props.language]['backtesting_error']
            },
          })
        })
      } else {
        var  list = { asset_list : [] }
        group.forwardtest_report = list
        _run( list, group)
      }
      
    }

    forwardtesting(group, params, transaction_fee, asset_list ) {
      var strategy = {}, partition = 100
      for (var s in group.strategy_list) {
        strategy[ group.strategy_list[s].id ] = group.strategy_list[s]
        partition -= parseFloat(  group.partition[ group.strategy_list[s].id ] , 10  )
      }
      if( partition > 0) {
        strategy['cash'] = { id:'cash', name:this.props.gi18n[ this.props.language ]['cash']}
      }
      var _obj = {}
      //console.log( this.props.forwardGroup )
      //_obj.forwardGroup = (this.props.forwardGroup.length > 0)?this.props.forwardGroup:[]
      _obj.showLoading = true
      _obj.tpage = "action"
      this.props.updateValue(_obj)
      Api.forwardtestingS( group, params, parseFloat(transaction_fee, 10) ).then(
        (res) => {
          var obj = {}
          obj['action'] = []
          if( typeof(res) !== "string" ) {
            if( 'value' in res ) {
              for (var s in res['value']) {
                res['value'][s]['tmp_decision'] = res['value'][s]['decision']
              }
              obj['action'] = res['value']
            } 
          }
          obj['group'] = group
          obj['onhold'] = asset_list.asset_list
          
          var valid = true
          var fg = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )

          for( var i in fg) {
            if( fg[i].group.id === obj.group.id ) {
              fg[i] = obj
              valid = false
              break
            }
          }
          if( valid ) {
            fg.push( obj )
          }

          var alertMessage = {}
          if( res.value.length === 0 ) {
            alertMessage = {
              header : this.props.gi18n[this.props.language]['no_sym'],
              body : this.props.gi18n[this.props.language]['no_sym_msg']
            }
          }

          this.props.updateValue({
            forwardGroup : fg,
            showLoading : false,
            showTrader : true,
            showAlert : ("header" in alertMessage)?true:false,
            alertMessage :alertMessage
          })
          //this.props.rebuildAsset()
        }
      ).catch( (e) => {
        this.props.updateValue({
          showLoading : false,
          showAlert : true,
          alertMessage : {
            header :  this.props.gi18n[this.props.language]['error'] ,
            body : this.props.gi18n[this.props.language]['backtesting_error']
          },
        })
      } )
    }

    render() {
        return (
          <div className={['Detail'].join(' ')} >

              <div className={['Detail-Control']}>     
                        <Button 
                          onClick={ (e)=>{
                              if(this.props.isLogin) {
                                if( this.state.bookmarked || this.state.isOwner ) {
                                  if( this.state.obj) {
                                   if( this.state.obj.backtest_report ) {
                                      this.setState({  showReport : true })
                                   }
                                  }
                                } else {
                                  this.createBookmark()
                                }
                              } else {
                                this.props.updateValue( {showLogin : true, showSignup : false} ) 
                              }
                          } }
                          variant="primary" 
                          disabled={ !((this.state.obj)?(this.state.obj[ this.state.type + "_report"]):true)  }
                          className={['Detail-Jumbotron-Button', ].join(" ")}  >
                          {  ( (this.state.bookmarked || this.state.isOwner )?this.props.gi18n[this.props.language][  this.state.type + "ing"]:this.props.gi18n[this.props.language]["subscribe"])   }
                        </Button>                        
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">{ this.props.gi18n[this.props.language]['forward_msg'] }</Tooltip>}
                          >
                            <Button 
                              //disabled={  !this.state.isOwner }
                              onClick={ (e)=>{
                                if(this.state.isOwner) {
                                  //this.props.updateValue({ showCanvas : true })
                                  //this.props.updateValue({
                                  //  showTrader : true
                                  //})
                                  this.forward()
                                } else {
                                  this.props.updateValue( {
                                    showAlert : true,
                                    alertMessage : {
                                      header :  this.props.gi18n[this.props.language]['locked_function'] ,
                                      body : this.props.gi18n[this.props.language]['locked_function_msg']
                                    },
                                  } )
                                }
                              } }
                              variant="primary" 
                              style={ { opacity :  (!this.state.isOwner?0.5:1)  } }
                              className={['Detail-Jumbotron-Button-forward'].join(" ")}  >
                                {  this.props.gi18n[this.props.language]["forward"]   }
                            </Button>
                        </OverlayTrigger>
              </div>
              
              <div className={ ['Detail-Body'].join( " " ) }>
              

              <Tabs defaultActiveKey="detail" id="tab">
                <Tab eventKey="detail" title="detail">
                <Jumbotron className={['Detail-Jumbotron'].join(" ")} >
                  { (this.state.type === 'backtest') &&
                      <div className={['Detail-Radar-Holder'].join(' ') }>
                        <div className={['Detail-Radar-Title'].join(' ') }>
                          { this.props.gi18n[this.props.language]["aGlance"].replace("<num>", this.state.total_portfolio) }
                        </div>
                        { (this.state.data.length > 0) &&
                              <VictoryChart polar
                                width={300}
                                height={300}
                                style={{
                                  pointerEvents: 'none',
                                }}
                                className={['svgClass'].join(' ')}
                                animate={{
                                  duration: 1000,
                                  onLoad: { duration: 500 }
                                }}>
                                  <VictoryArea data={this.state.data}
                                    className={['svgClass'].join(' ')}
                                    style={{ data: { fill: gcolor.gnAccentColor+"50", stroke: gcolor.gnAccentColor, strokeWidth: 1.00,pointerEvents: 'none',  } }}/>
                                  {
                                      Object.keys(this.state.maxima).map((key, i) => {
                                        return (
                                          <VictoryPolarAxis key={i} 
                                            dependentAxis
                                            className={ ['Detail-Radar'].join(' ') }  
                                            style={{
                                                axisLabel: { padding: 10, fontSize : 10, fill : gcolor.gnLightColor },
                                                tickLabels: { fontSize : 5, fill : gcolor.gnLightColor },
                                                axis: { stroke: "none" },
                                                grid: { stroke: gcolor.gnLightColor, strokeWidth: 0.25, opacity: 0.5 }
                                            }}                           
                                            tickLabelComponent={
                                                <VictoryLabel labelPlacement="vertical" />
                                            }
                                            
                                            labelPlacement="perpendicular"
                                            axisValue={i + 1} 
                                            tickValues={[25, 50, 75, 100]}
                                            label={ this.props.gi18n[this.props.language][key] + "\n" +  ((this.state.gdOj)?( this.state.gdOj[key] ):"")  }
                                          />
                                        );
                                      })
                                  }
                                  <VictoryPolarAxis
                                    theme={VictoryTheme.material}
                                    labelPlacement="parallel"
                                    tickValues={[25, 50, 75, 100]}
                                    tickFormat={ (k)=>"" }
                                    style={{
                                          axis: { stroke: "none" },
                                          grid: { stroke: gcolor.gnLightColor, opacity: 0.5 }
                                    }}
                                  />
                              </VictoryChart>
                        }
                      </div>
                    }
                  <div className={['Detail-Chart-Holder'].join(' ') }>
                    <div className={['Detail-Chart-Title'].join(' ') }>
                      { this.props.gi18n[this.props.language]["quick_summary"] }
                    </div>
                    { this.state.obj && 
                      this.state.obj[ this.state.type + "_report"] && 
                      <div className={ ['Detail-Chart-Holder-Billboard'].join(' ') }>
                            <Col >
                              <Row sm className={['two'].join(' ')}>
                                <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['profit'] } </div>
                                <div className={['value' , (this.state.obj[ this.state.type + "_report"].profit > 0)?'up':((this.state.obj[ this.state.type + "_report"].profit < 0)?'down':'') ].join(' ')} > 
                                  
                                  {  
                                    //((this.state.obj[ this.state.type + "_report"].profit>0)?"+$":"-$") +  (Math.round(  Math.abs(this.state.obj[ this.state.type + "_report"].profit) ) )  
                                  }
                                  <span>
                                    <FontAwesomeIcon  icon={  (this.state.obj[ this.state.type + "_report"].profit>0)?faArrowCircleUp:((this.state.obj[ this.state.type + "_report"].profit>0)?faArrowCircleDown:faMinusCircle)  } className={[  ].join(' ')}/>
                                    <span>{ "$" + Math.abs((Math.round(  Math.abs(this.state.obj[ this.state.type + "_report"].profit) ) )) }</span>
                                  </span>
                                </div>
                                <div className={['sub' , (this.state.obj[ this.state.type + "_report"].profit > 0)?'up':((this.state.obj[ this.state.type + "_report"].profit < 0)?'down':'') ].join(' ')} > 
                                  {  
                                    //((this.state.obj[ this.state.type + "_report"].profit>0)?"+":"") +  (Math.round(this.state.obj[ this.state.type + "_report"].profit/this.state.obj[ this.state.type + "_report"].initial_capital * 10000)/100) + "%"  
                                  } 
                                  <span>
                                    <FontAwesomeIcon  icon={  (this.state.obj[ this.state.type + "_report"].profit>0)?faArrowCircleUp:((this.state.obj[ this.state.type + "_report"].profit>0)?faArrowCircleDown:faMinusCircle)  } className={[  ].join(' ')}/>
                                    <span>{ Math.abs(  (Math.round(this.state.obj[ this.state.type + "_report"].profit/this.state.obj[ this.state.type + "_report"].initial_capital * 10000)/100)) + "%"  }</span>
                                  </span>
                                </div>
                              </Row>
                              <Row sm className={['two'].join(' ')}>
                                <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['actions'] } </div>
                                <div className={['value'].join(' ')}> {  this.state.obj[ this.state.type + "_report"].trade_count } </div>
                              </Row>
                            </Col>
                            <Col>
                              <Row sm className={['three'].join(' ')}>
                                <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['initial'] } </div>
                                <div className={['value', 'capital'].join(' ')}> {  "$" + service.getNumberFormat(this.state.obj[ this.state.type + "_report"].initial_capital) } </div>
                              </Row>
                              <Row sm className={['three'].join(' ')}>
                                <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['win_rate'] } </div>
                                <div className={['value', 'capital'].join(' ')}> {  (Math.round(this.state.obj[ this.state.type + "_report"].win_count*10000/(this.state.obj[ this.state.type + "_report"].win_count + this.state.obj[ this.state.type + "_report"].even_count + this.state.obj[ this.state.type + "_report"].loss_count))/100) + "%" } </div>
                              </Row>
                              <Row sm className={['three'].join(' ')}>
                                <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['sharpe_ratio'] } </div>
                                <div className={['value', 'capital'].join(' ')}> {  Math.round(this.state.obj[ this.state.type + "_report"].sharpe_ratio * 10000)/100 + "%"} </div>
                              </Row>
                            </Col>
                      </div>
                    }
                    { !(this.state.obj &&
                      this.state.obj[ this.state.type + "_report"]) &&
                      <div className={ ['Detail-Chart-Holder-Billboard', 'empty'].join(' ') }>
                        { this.props.gi18n[this.props.language]["no_report"] }
                      </div>
                    }
                  </div>
                  <div className={['Detail-Chart-Supporter'].join(' ')}>
                      {
                        portfolio_frame.map( (v,k) => {
                          return(
                            <div className={['Detail-Chart-Supporter-Holder'].join(' ')} key={k}>
                              <div className={['Detail-Chart-Supporter-Title'].join(' ')}>
                                { this.props.gi18n[this.props.language][v.value] }
                              </div>
                              <div className={['Detail-Chart-Supporter-Content'].join(' ')}>
                                { this.state.obj?this.state.obj[ v.key ]:"" }
                              </div>

                            </div>
                          )
                        }  )
                      }
                  </div> 
                </Jumbotron>  
                { (this.state.type === 'backtest') && 
                  <Jumbotron className={['Detail-Jumbotron-Bottom'].join(" ")} >
                    <p className={['desc'].join(" ")}>
                        { (this.state.obj)?this.state.obj.description:this.props.gi18n[this.props.language]['noDesc'] }
                    </p>
                  </Jumbotron>  
                }
                </Tab>

                <Tab eventKey="edit" title="edit">
                { this.props.isLogin && (this.state.type === 'backtest') && 
                    <div className={ ['Detail-Pie-Holder'].join(' ') } id={"Detail-Pie-Holder"} >
                        <div className={['Detail-Pie-Title'].join(' ') }>
                          { this.props.gi18n[this.props.language]['component_message'].replace( "<portfolio>" ,(this.state.obj?this.state.obj.name:"")) }
                        </div>
                        
                        
                        <div style={{
                          position : 'relative',
                          //width : this.state.pieArea,
                          //height : this.state.pieArea
                        }}>
                        <VictoryChart 
                          style={{
                            parent: {
                              border: "0px solid #ccc"
                            }
                          }}
                          polar>
                          
                          <VictoryPolarAxis style={{ 
                            axis: {stroke: "transparent"}, 
                            ticks: {stroke: "transparent"},
                            tickLabels: { fill:"transparent"} 
                          }} />

                          <VictoryLegend 
                            standalone={false}
                            x={   180 - ( Math.ceil( this.state.partition.length/MAX_ROW) - 1 ) * 55    } 
                            y={  95   }
                            itemsPerRow={  MAX_ROW  }
                            orientation="vertical"
                            gutter={35}
                            rowGutter={{ top: 0, bottom: 0 }}
                            style={ {
                              title: {fontSize: 10, fill:gcolor.gnWhiteColor },
                              labels: {fontSize: 10, fill:gcolor.gnWhiteColor}
                            }}
                            colorScale={ this.props.colors  }
                            data={  this.state.partition }
                            events={[{
                              target: "data",
                              eventHandlers: {
                                onMouseDown : () => {
                                  return [
                                    {
                                      target: "data",
                                      mutation: ({datum}) => {
                                        if ("id" in this.state.selectedStrategy) {
                                          if( this.state.selectedStrategy.id === datum.id ) {
                                            this.setState({selectedStrategy:{}, selected : null})
                                            
                                          } else {
                                            this.selectedStrategy(datum)
                                          }
                                        } else {
                                          this.selectedStrategy(datum)
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            }, 
                            {
                              target: "labels",
                              eventHandlers: {
                                onMouseDown : () => {
                                  return [
                                    {
                                      target: "labels",
                                      mutation: ({datum}) => {
                                        if ("id" in this.state.selectedStrategy) {
                                          if( this.state.selectedStrategy.id === datum.id ) {
                                            this.setState({selectedStrategy:{}, selected : null})
                                          } else {
                                            this.selectedStrategy(datum)
                                          }
                                        } else {
                                          this.selectedStrategy(datum)
                                        }
                                      }
                                    }
                                  ]
                                },
                                onMouseEnter : () => {
                                  return [
                                    {
                                      target: "labels",
                                      mutation: ({datum}) => {
                                        if ("id" in this.state.selectedStrategy) {
                                          if( this.state.selectedStrategy.id === datum.id ) {
                                            this.setState({selectedStrategy:{}, selected : null})
                                          } else {
                                            this.selectedStrategy(datum)
                                          }
                                        } else {
                                          this.selectedStrategy(datum)
                                        }
                                      }
                                    },
                                    {
                                      target: "labels",
                                      mutation: (props) => ({ style: Object.assign({}, props.style, { fill: gcolor.gnFrameColor}) })
                                    }
                                  ]
                                },
                                onMouseLeave : () => {
                                  return [
                                    {
                                      target: "labels",
                                      mutation: ({datum}) => {
                                        this.setState({selectedStrategy:{}, selected : null})
                                      }
                                    }
                                  ]
                                }
                              }
                            }]}
                          />

                          <VictoryPie
                              standalone={false}
                              radius={ ({datum}) => (this.state.selected===datum.id)?170:145}
                              innerRadius={ ({datum}) => (this.state.selected===datum.id)?150:125}
                              style={{ 
                                labels: { 
                                  pointerEvents: 'none',
                                  fontFamily : gcolor.gnFont,
                                  fill: 'transparent', 
                                  fontSize: 10,
                                  fontWeight : 500
                                } ,
                                data : { 
                                  filter: "drop-shadow( 1px 1px 1px rgba(0, 0, 0, .25))"
                                }
                              }}
                              colorScale={ this.props.colors  }
                              animate={{
                                duration: 2000,
                                onLoad: { duration: 1000 }
                              }}
                              data={  this.state.partition }
                              cornerRadius={ 50 }
                              padAngle={ ({ datum }) => (this.state.selected===datum.id)?5:2.5  }                          
                          />
                        </VictoryChart>
                        </div>
                    </div>
                }
                { this.props.isLogin && (this.state.type === 'backtest') && 
                  <Container className={['Detail-Activities-Holder'].join(' ')}>
                    <Accordion 
                      activeKey={  (('id' in this.state.selectedStrategy)?this.state.selectedStrategy.id:"-1")  }
                      defaultActiveKey={ -1 }  >
                      {
                        this.state.strategy_list.map(  (v,k) => {
                          if( v.id ) {
                            return (
                              <Card key={k} >
                                  <Accordion.Toggle 
                                    as={Card.Header} 
                                    variant="link" eventKey={ v.id } className={['Detail-Activities-Header'].join(' ')} 
                                    onClick={ (e)=>{
                                      //this.selectedStrategy(v)  
                                      this.props.updateParent( {
                                        selectedStrategy : v,
                                        selectedBoard : 'strategy'
                                      } )
                                    }} >
                                    <div 
                                      style={ {  backgroundColor : this.props.colors[k] } }
                                      className={ ['Detail-Activities-Header-Partition'].join(' ') } >  
                                      {v.partition + " %"}
                                    </div>
                                    <div className={ ['Detail-Activities-Header-Name'].join(' ') }>
                                      {v.name}
                                    </div>

                                  </Accordion.Toggle>
                                <Accordion.Collapse eventKey={ v.id }>
                                  <Card.Body>
                                    <div className={['Detail-Activities-Desc'].join(' ')}>{ v.description }</div>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            )
                          } else {
                            return(null)
                          }
                        })
                      }
                    </Accordion>
                  </Container>
                }
                {
                  this.props.isLogin && (this.state.type !== 'backtest') && 
                  <Container className={['Detail-Activities-Holder'].join(' ')}>
                    <div className={ ['activities-title'].join(" ") }  >
                      {  this.props.gi18n[this.props.language]['portfolio_activities']  }
                    </div>
                    <div className={ ['activities'].join(" ") }  >
                      { (this.state.activities.length > 0) &&
                        this.state.activities.map(  (v,k) => {
                          return(
                            <ActivitiesLog key={k} obj={v} updateValue={this.updateValue} language={this.props.language} gi18n={this.props.gi18n} isPortfolio={true} />
                          )
                        })
                      }
                    </div>
                  </Container>
                }
                </Tab>
                </Tabs>


              </div>
            


          </div>
        )
    }
}


export default Detail;

