import React from "react";
import './index.scss'

import service from '../../public/service'
import GarlicanStrategyPicker from "../GarlicanStrategyPicker";
import {  Button, Form, OverlayTrigger, Tooltip, Modal   } from 'react-bootstrap';

export default class GarlicanStrategy extends React.Component {

  constructor(props) {
    super(props);  
    this.editor= React.createRef()
    
    this.state = {
      showTemplates : false,
      template : null,
      //isTemplateOwner : false,
    } 
    this.onchange = this.onchange.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this.hideAll = this.hideAll.bind(this)
  }

  componentWillMount() {
   
  }

  componentDidMount() {
    //this.getTemplates()
    if( this.props.selectedStrategy.strategy_template_id ) {
      //this.checkTemplate(  this.props.selectedStrategy.strategy_template_id  )
      this.setState({
        template : this.props.usedTemplate,
      })
    }

  }

  
  componentWillUnmount() {
  }

  componentWillReceiveProps(props) {
    //console.log( props )
    if( this.state.template ) {
      //this.setState({
      //  isTemplateOwner : service.isOwner( this.state.template.user_id )
      //})
      //this.setState({
      //  template : props.usedTemplate,
      //})
    } else {
      //console.log( props.selectedStrategy  )
      if( props.selectedStrategy.strategy_template_id ) {
        //this.checkTemplate(  this.props.selectedStrategy.strategy_template_id  )
        this.setState({
          template : props.usedTemplate,
        })
      }
    }

  }

  checkTemplate(id) {
    service.getTemplateById(id).then(
      (res) => {
        //console.log(res)
        this.setState({
          template : res,
          //isTemplateOwner : service.isOwner( res.user_id )
        })
      }
    )
  }

  onchange(type, value) {
    var s = JSON.parse( JSON.stringify(  this.props.group ) )
		for( var i in s.strategy_list) {
			if( s.strategy_list[i].id === this.props.id ) {
				this.props.editStrategy( i, type, value )
				break;
			}
		}
  }

  updateValue(obj) {
    this.setState(obj)
  }

  hideAll() {
    this.setState({
      showTemplates : false
    })
  }

  render() {

    return (
      <div className={['Garlican-Strategy'  ].join(' ')} >
        

        <div className={['Garlican-Strategy-Controller'  ].join(' ')} >
          

            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-2">{ this.props.gi18n[this.props.language]['template_msg'] }</Tooltip>}>
              <Button className={['Garlican-Strategy-Controller-Template'].join(' ')} onClick={
                (e) => {
                  this.setState({ showTemplates : !this.state.showTemplates })   
                  //this.props.updateParent
                }
              }>{  this.props.gi18n[this.props.language][ "template" ]  }</Button>
            </OverlayTrigger>



        </div>





        <div className={['Garlican-Strategy-Body'  ].join(' ')}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{ this.props.gi18n[this.props.language]['strategy_name'] }</Form.Label>
              <Form.Control type="input" placeholder="" value={this.props.selectedStrategy.name} onChange={ (e)=>{
                this.onchange( "name", e.target.value )
              } } />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>  {  this.props.gi18n[this.props.language]['strategy_desc'] }    </Form.Label>
              <Form.Control className={['Garlican-Strategy-Desc'  ].join(' ')} as="textarea" value={this.props.selectedStrategy.description} onChange={ (e)=>{
                this.onchange( "description", e.target.value )
              } } />
            </Form.Group>
          </Form>
        </div>


        <GarlicanStrategyPicker {...this.props} showTemplates={this.state.showTemplates} updateValue={this.updateValue} reload={this.props.reload} convertToTemplate={this.props.convertToTemplate} />



      </div>
    );
  }
}



/*
        <GarlicanStrategyPicker {...this.props} showTemplates={this.state.showTemplates} updateValue={this.updateValue} reload={this.props.reload} convertToTemplate={this.props.convertToTemplate} />
*/