import React from "react";
//import gglobal from '../GarlicanLibrary/gvars'

import './index.scss'

//import GService  from '../GarlicanLibrary/gservice'
import service from '../../public/service'
import apis from '../GarlicanLibrary/apis'
import gcolor from '../../public/style.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCheck, faTimes, faEdit , faEyeSlash , faEye} from '@fortawesome/free-solid-svg-icons'

import {  Modal, OverlayTrigger, Tooltip, Table, Button, ModalDialog, Spinner } from 'react-bootstrap';
import { VictoryLine, VictoryLegend, VictoryChart, VictoryAxis, VictoryZoomContainer , createContainer, VictoryScatter,VictoryTooltip, VictoryCandlestick } from 'victory';




const VictoryCustomContainer = createContainer("zoom", "voronoi");
const fix = 10
const maths = require('mathjs');

export default class GarlicanReport extends React.Component {

  constructor(props) {
    super(props);  
    this.state = {
      domain : [-1,1],
      w : 0,
      h : 0,
      data : [],
      symbolp : [],
      symbolpw : [],
      symbolpl : [],
      strategy : [],
      srange : {x1:0,x0:0,l:0, dxr:0, dxl:0},
      strange : {y1:0,y0:0,l:0, dyu:0, dyd:0, max : 0, min : 0},
      showDate : null,
      snames : {},
      selected : {key:null, value:null},
      showbacktest_report : true,
      showHOverbacktest_report : false,
      backtest_report : {},
      transaction_fee : 0,
      initial_capital : 0,
      bm : [],
      dates : [],
      branchmark : '^HSI',
      showConfirm : false,
      body : null,
      hasReport : false,
      isOwner : false,
      report : [],
      symData : [],
      points : [],
    } 


    //console.log(getData)

    this.calcpTemp = this.calcpTemp.bind(this)
    this.calcpReport = this.calcpReport.bind(this)
    this.hideAll = this.hideAll.bind(this)
    this.onsubmit = this.onsubmit.bind(this)
    this.saveReport = this.saveReport.bind(this)
    this.exit = this.exit.bind(this)
    this.getReport = this.getReport.bind(this)
    this.selectSym = this.selectSym.bind(this)
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(props) {
      var _this = this
      //console.log( props )
      if( props.showReport ) {
        setTimeout( (e)=> {
          if( document.getElementById('chart_container') ){
            var rect =  document.getElementById('chart_container').getBoundingClientRect()
            _this.setState({
              showConfirm : false,
              w : rect.width ,
              h : Math.max(rect.height - 40 , 0) ,
              transaction_fee : props.group.transaction_fee,
              initial_capital : props.group.initial_capital
            })
          }
          if( !_this.state.hasReport ) {
            if(props.report) {
              if( props.report.length > 0 ) {
                if( typeof( props.report ) == 'object' ) {
                  try {
                    //console.log("IN2?")
                    _this.calcpTemp(props.report, props.group.initial_capital, props.group.transaction_fee)
                    _this.setState({
                      transaction_fee : props.group.transaction_fee,
                      initial_capital : props.group.initial_capital,
                      hasReport : true,
                      isOwner : props.isOwner,
                      report : props.report,
                    })
                  }
                  catch(e) {
                    console.log(e)
                  }
                }
              }
            } else {
              //var _report = (this.props.type === "forwardtest")?props.group.forwardtest_report
              //console.log( props.type )
              if(  props.group[this.props.type + "_report"] && props.showReport ) {
                _this.getReport(props)
                _this.setState({
                  transaction_fee : props.group.transaction_fee,
                  initial_capital : props.group.initial_capital,
                  hasReport : true,
                  isOwner : props.isOwner,
                })
              }
            }
          } else {
            if(props.report) {
              if( props.report.length > 0 ) {
                //console.log("IN?")
                _this.calcpTemp( props.report, props.group.initial_capital ,props.group.transaction_fee)
              }
            }
          }
        } , 100)
      }

  }

  calcpTemp(sp, initial_capital, transaction_fee ) {
    // NEW BACKTEST REPORT
    initial_capital = parseFloat( initial_capital, 10 )
    transaction_fee = parseFloat( transaction_fee, 10 )
    var dmax=0, dmin=0, profit = 0, backtest_report={}
    var profits = []
    backtest_report.initial_capital = initial_capital
    backtest_report.trade_count = 0
    backtest_report.activeGroup = {}
    backtest_report.win_count = 0
    backtest_report.loss_count = 0
    backtest_report.even_count = 0
    backtest_report.symbol_count = 0
    backtest_report.buy = 0
    backtest_report.sell = 0
    backtest_report.overdraft = false
    backtest_report.overdraft_date = null
    backtest_report.maxp = 0
    backtest_report.minp = 0
    var group = JSON.parse(  JSON.stringify( this.props.group ) )

    var cash = initial_capital, onstock = {}, safety = {}
    var _objs={}, _objt={}, _objp={}, _objst={}
    var ttl = initial_capital
 
    for (var g in group['partition']) {
      _objst[ g ] = { profit : 0, onhold : 0, sold : 0 }
      onstock[g] = 0
      backtest_report.activeGroup[g]=0
    }
 
    for (var i in sp) {
      backtest_report.trade_count += 1
      backtest_report.activeGroup[ sp[i].group  ]  = 1
      var _p = ( sp[i].price * sp[i].boardlot) * Math.abs( sp[i].decision  )
      var profit = -(  transaction_fee )
      var ttl_fee =  transaction_fee
      if( (sp[i].symbol in _objs) && (sp[i].decision < 0) ) {
        profit = ( ( sp[i].price - _objs[sp[i].symbol][ sp[i].group].price ) ) * sp[i].boardlot * Math.abs( sp[i].decision  ) -  ttl_fee
      }
      if( !(sp[i].symbol in _objs) ) {
        _objs[sp[i].symbol] = {}
        _objp[sp[i].symbol] = {}
        for (var g in group['partition']) {
          _objs[sp[i].symbol][g] = { price : 0, boardlot : 0  }
          _objp[sp[i].symbol][g] = { profit : 0, quantity : 0  }
        }
      }
      if( sp[i].decision > 0 ) {
        backtest_report.buy += 1
        _objs[sp[i].symbol][ sp[i].group].price = ((_objs[sp[i].symbol][ sp[i].group].price * _objs[sp[i].symbol][ sp[i].group].boardlot ) + ( sp[i].price * sp[i].boardlot * Math.abs( sp[i].decision  )  ) )/( (sp[i].boardlot * Math.abs( sp[i].decision  )) +  _objs[sp[i].symbol][ sp[i].group].boardlot )
        _objs[sp[i].symbol][ sp[i].group].boardlot += ( sp[i].boardlot * Math.abs( sp[i].decision  ) )
        cash -= ( _p + ttl_fee )
        onstock[sp[i].group] += _p
        _objst[ sp[i].group ].onhold += 1
      } else {
        backtest_report.sell += 1
        _objs[sp[i].symbol][ sp[i].group].boardlot -= sp[i].boardlot * Math.abs( sp[i].decision  )
        onstock[sp[i].group] -= _objs[sp[i].symbol][ sp[i].group].price * sp[i].boardlot * Math.abs( sp[i].decision  )
        cash += ( _p - ttl_fee )


        if( profit > 0 ) {
          backtest_report.win_count += 1
        } else if( profit === 0 ) {
          backtest_report.even_count += 1
        } else {
          backtest_report.loss_count += 1
        }
        _objp[sp[i].symbol][g].profit += profit
        _objp[sp[i].symbol][g].quantity += 1
        _objst[ sp[i].group ].profit += profit
        _objst[ sp[i].group ].onhold -= 1
        _objst[ sp[i].group ].sold += 1

        profits.push( profit/ttl )
      }

      if( !(sp[i].date in _objt) ) { 
        _objt[ sp[i].date  ] = {'date': sp[i].date , 'profit':0, 'actions':[], 'cash':0, 'onstock':{} , 'valid' : {} } 
      } 
      _objt[ sp[i].date  ]['profit'] += profit
      sp[i].profit = profit
      _objt[ sp[i].date  ]['actions'].push(  sp[i] )
      _objt[ sp[i].date  ]['cash'] = cash
      //console.log(cash)

      //console.log(cash)
      var total = cash
      var tstock = JSON.parse(JSON.stringify(onstock))
      var tsatefy = JSON.parse(JSON.stringify(safety))
      for (var s in tstock) {
        total += tstock[s]
      }
      //ttl = total
      for (var s in group['partition']) {
        safety[s] = total * parseFloat(  group['partition'][s]  , 10)/100
      }

      var overdrafts = JSON.parse( JSON.stringify( {} ) )
      var overdraft = false
      for (var s in tsatefy) {
        if(  s in tstock) {
          if(  tstock[s] > tsatefy[s] ) {
            overdrafts[ s ] = true
            overdraft = true
            if( !backtest_report.overdraft ) {
              backtest_report.overdraft = true
              backtest_report.overdraft_date = sp[i].date
            }
            
          } else {
            overdrafts[ s ] = false
          }
        }
      }
      _objt[ sp[i].date  ]['onstock'] =  JSON.parse( JSON.stringify(  onstock  )   )
      _objt[ sp[i].date  ]['overdrafts'] = overdrafts
      _objt[ sp[i].date  ]['overdraft'] = overdraft
      ttl = total
    }
 
    var pcash = initial_capital
    var data = [], dates = []

    for (var t in _objt) {
      //t = service.formatDate( t )
      var d = service.formatDate( t, false, true )
      dates.push( d )
      
      _objt[t].date = d
      
      var total = _objt[t].cash
      for (var v in _objt[t].onstock) {
        total += _objt[t].onstock[v]
      }
      //total -= ( _objt[t].actions.length * transaction_fee  )

      _objt[t].rate = ((total-pcash) /pcash * 100 )
      
      if( total ) {
        dmax = Math.max( ((total-pcash) /pcash * 100 ), dmax )
        dmin = Math.min( ((total-pcash) /pcash * 100 ), dmin )
        var orimx = backtest_report.maxp, orimn = backtest_report.minp
        backtest_report.maxp = Math.max( (total-pcash), backtest_report.maxp )
        backtest_report.minp = Math.min( (total-pcash), backtest_report.minp )
        if( (backtest_report.maxp  === (total-pcash)) && ( backtest_report.maxp!==orimx ) ) {
          backtest_report.mxdate = _objt[t].date
        }
        if( (backtest_report.minp  === (total-pcash)) && ( backtest_report.minp!==orimn ) ) {
          backtest_report.mndate = _objt[t].date
        }
      }
      data.push(  _objt[t]  )
    }


    var std = maths.std(profits);
    var mean = maths.mean(profits);
    backtest_report.profit = (total - pcash)
    backtest_report.std_dev = std
    backtest_report.mean = mean


    var strategy = [], mx = 0, mn = 0, l = 0
    for (var st in _objst ) {
      _objst[st].group = st
      strategy.push( _objst[st]  )
      mx = Math.max( _objst[st].profit, mx )
      mn = Math.min( _objst[st].profit, mn )
    }
    l = mx - mn
    var strange = { 
      l : l,
      y0 : 0 , 
      y1:( mx - Math.min(mn, 0) ), 
      dyu : -Math.min(mn, 0),
      dyd : mx,
      max : mx,
      min : mn,
    }
    strategy = strategy.sort(function(a, b) {
      return parseFloat(b.profit) - parseFloat(a.profit);
    });
    var mx = 0, mn = 0, l = 0
    var symbolpw = [], symbolpl = [], _tp = {}
    for (var sym in _objp) {
      backtest_report.symbol_count += 1
      _tp = {symbol : sym, profit : 0, quantity : 0}
      for (var g in _objp[sym]) {
        _tp.profit += _objp[sym][g].profit
        _tp.quantity += _objp[sym][g].quantity 
      }
      mx = Math.max( _tp.profit, mx )
      mn = Math.min( _tp.profit, mn )
      l = Math.max(  Math.abs(_tp.profit) , l )
      if( _tp.profit >= 0) {
        symbolpw.push(  _tp  )
      } else {
        symbolpl.push(  _tp  )
      }
    }
    symbolpw = symbolpw.sort(function(a, b) {
      return parseFloat(b.profit) - parseFloat(a.profit);
    });
    symbolpl = symbolpl.sort(function(a, b) {
      return parseFloat(a.profit) - parseFloat(b.profit);
    });
    var srange = { 
      l : l,
      x0 : 0 , 
      x1:( mx - Math.min(mn, 0) ), 
      dxl : -Math.min(mn, 0),
      dxr : mx,
    }
    var snames = {}
    for (var idx in this.props.group.strategy_list) {
      snames[ this.props.group.strategy_list[idx].id ] = this.props.group.strategy_list[idx].name
    }

    backtest_report.total_trading_days = data.length
    backtest_report.strategy_count = 0
    for (var i in  backtest_report.activeGroup) {
      if( backtest_report.activeGroup[i] === 1 ) {
        backtest_report.strategy_count += 1
      }
    }

    if(  (dates.length > 0) && this.state.dates.length === 0 ) {
      var _dates = []
      for (var i in dates) {
        _dates.push(  service.formatDate(  dates[i]  )   )
      }
      apis.getdatafromsymbol( this.state.branchmark , _dates  ).then(
        (res) => {
          var arr = [], base = 0
          //console.log(res)
          for (var i in res) {
            if( parseInt(i, 10) === 0 ) {
              base = res[i].close
            }
            var obj = {}
            obj.x = new Date(res[i].date)
            obj.y = (res[i].close - base)/base * 100
            arr.push(obj)
          }
          this.setState({bm : arr})
        }
      )
    }

    //console.log(  backtest_report )
    this.setState({
        domain : [ dmin*1.25, dmax*1.25 ],
        data : data,
        strategy : strategy,
        symbolpw : symbolpw,
        symbolpl : symbolpl,
        srange : srange,
        strange : strange, 
        snames : snames,
        backtest_report : backtest_report,
        dates : dates,
    })

  }

  calcpReport( report ) {
    var sp = report.trade_list
    var initial_capital = parseFloat( report.initial_capital, 10 )
    var transaction_fee = parseFloat( report.transaction_fee, 10 )
    var dmax=0, dmin=0, profit = 0
    var backtest_report = report
    backtest_report.maxp = 0
    backtest_report.minp = 0
    backtest_report.buy = 0
    backtest_report.sell = 0
    var profits = []
    var group = JSON.parse(  JSON.stringify( this.props.group ) )

    var cash = initial_capital, onstock = {}, safety = {}
    var _objs={}, _objt={}, _objp={}, _objst={}
    var ttl = initial_capital
 
    for (var g in group['partition']) {
      _objst[ g ] = { profit : 0, onhold : 0, sold : 0 }
      onstock[g] = 0
    }
 
    for (var i in sp) {
      var _p = (( ( sp[i].profit ) * Math.abs( sp[i].decision  ) ) +  Math.abs( sp[i].decision  ) * transaction_fee ) 
      if( sp[i].symbol in _objs ) {
        if( sp[i].group in _objs[sp[i].symbol ] ) {
          _p = ( sp[i].price * sp[i].boardlot) * Math.abs( sp[i].decision  )
        } 
      }
      
      var profit = -(transaction_fee *  Math.abs( sp[i].decision ) )
      if( (sp[i].decision < 0) ) {
        profit = (( ( sp[i].profit ) * Math.abs( sp[i].decision  ) ) +  Math.abs( sp[i].decision  ) * transaction_fee ) -  (transaction_fee)
        //profit = sp[i].profit  +  (  Math.abs( sp[i].decision  ) * transaction_fee ) -   (Math.abs( sp[i].decision  ) * transaction_fee)
        //profit = sp[i].profit
      }

      if( !(sp[i].symbol in _objs) ) {
        _objs[sp[i].symbol] = {}
        _objp[sp[i].symbol] = {}
        for (var g in group['partition']) {
          _objs[sp[i].symbol][g] = { price : 0, boardlot : 0  }
          _objp[sp[i].symbol][g] = { profit : 0, quantity : 0  }
        }
      }
      if( sp[i].decision > 0 ) {
        backtest_report.buy += 1
        _objs[sp[i].symbol][ sp[i].group].price = ((_objs[sp[i].symbol][ sp[i].group].price * _objs[sp[i].symbol][ sp[i].group].boardlot ) + ( sp[i].price * sp[i].boardlot * Math.abs( sp[i].decision  )  ) )/( (sp[i].boardlot * Math.abs( sp[i].decision  )) +  _objs[sp[i].symbol][ sp[i].group].boardlot )
        _objs[sp[i].symbol][ sp[i].group].boardlot += ( sp[i].boardlot * Math.abs( sp[i].decision  ) )
        cash -= ( _p + ( Math.abs( sp[i].decision)* transaction_fee) )
        onstock[sp[i].group] += _p
        _objst[ sp[i].group ].onhold += 1
      } else {
        backtest_report.sell += 1
        _objs[sp[i].symbol][ sp[i].group].boardlot -= sp[i].boardlot * Math.abs( sp[i].decision  )
        onstock[sp[i].group] -= _objs[sp[i].symbol][ sp[i].group].price * sp[i].boardlot * Math.abs( sp[i].decision  )
        cash += ( _p - (Math.abs( sp[i].decision)* transaction_fee) )

        _objp[sp[i].symbol][g].profit += profit
        _objp[sp[i].symbol][g].quantity += 1
        _objst[ sp[i].group ].profit += profit
        _objst[ sp[i].group ].onhold -= 1
        _objst[ sp[i].group ].sold += 1
        profits.push( profit/ttl )
      }

      if( !(sp[i].date in _objt) ) { 
        _objt[ sp[i].date  ] = {'date': sp[i].date , 'profit':0, 'actions':[], 'cash':0, 'onstock':{} , 'valid' : {} } 
      } 
      
      _objt[ sp[i].date  ]['profit'] += parseFloat(profit, 10)
      _objt[ sp[i].date  ]['actions'].push(  sp[i] )
      _objt[ sp[i].date  ]['cash'] = cash
 
      var total = cash
      var tstock = JSON.parse(JSON.stringify(onstock))
      var tsatefy = JSON.parse(JSON.stringify(safety))
      for (var s in tstock) {
        total += tstock[s]
      }
      //ttl = total
      for (var s in group['partition']) {
        safety[s] = total * parseFloat(  group['partition'][s]  , 10)/100
      }

      /*var overdrafts = JSON.parse( JSON.stringify( {} ) )
      var overdraft = false
      for (var s in tsatefy) {
        if(  s in tstock) {
          if(  tstock[s] > tsatefy[s] ) {
            overdrafts[ s ] = true
            overdraft = true
            if( !backtest_report.overdraft ) {
              backtest_report.overdraft = true
              backtest_report.overdraft_date = sp[i].date
            }
            
          } else {
            overdrafts[ s ] = false
          }
        }
      }*/
      _objt[ sp[i].date  ]['onstock'] =  JSON.parse( JSON.stringify(  onstock  )   )
      //_objt[ sp[i].date  ]['overdrafts'] = overdrafts
      //_objt[ sp[i].date  ]['overdraft'] = overdraft
      //ttl = total
    }
 
    var pcash = initial_capital
    var data = [], dates = []

    for (var t in _objt) {
      var d = service.formatDate( t, false, true )
      _objt[t].date = d
      dates.push(d)
      var total = _objt[t].cash
        for (var v in _objt[t].onstock) {
          total += _objt[t].onstock[v]
        }

      _objt[t].rate = ((total-pcash) /pcash * 100 )

      if( total ) {
        dmax = Math.max( ((total-pcash) /pcash * 100 ), dmax )
        dmin = Math.min( ((total-pcash) /pcash * 100 ), dmin )
        var orimx = backtest_report.maxp, orimn = backtest_report.minp
        backtest_report.maxp = Math.max( (total-pcash), backtest_report.maxp )
        backtest_report.minp = Math.min( (total-pcash), backtest_report.minp )
        if( (backtest_report.maxp  === (total-pcash)) && ( backtest_report.maxp!==orimx ) ) {
          backtest_report.mxdate = _objt[t].date
        }
        if( (backtest_report.minp  === (total-pcash)) && ( backtest_report.minp!==orimn ) ) {
          backtest_report.mndate = _objt[t].date
        }
      }
      data.push(  _objt[t]  )
    }

    var strategy = [], mx = 0, mn = 0, l = 0
    for (var st in _objst ) {
      _objst[st].group = st
      strategy.push( _objst[st]  )
      mx = Math.max( _objst[st].profit, mx )
      mn = Math.min( _objst[st].profit, mn )
    }
    l = mx - mn
    var strange = { 
      l : l,
      y0 : 0 , 
      y1:( mx - Math.min(mn, 0) ), 
      dyu : -Math.min(mn, 0),
      dyd : mx,
      max : mx,
      min : mn,
    }
    strategy = strategy.sort(function(a, b) {
      return parseFloat(b.profit) - parseFloat(a.profit);
    });
    var mx = 0, mn = 0, l = 0
    var symbolpw = [], symbolpl = [], _tp = {}

    for (var sym in _objp) {
      //backtest_report.symbol_count += 1
      _tp = {symbol : sym, profit : 0, quantity : 0}
      for (var g in _objp[sym]) {
        _tp.profit += _objp[sym][g].profit
        _tp.quantity += _objp[sym][g].quantity 
      }
      mx = Math.max( _tp.profit, mx )
      mn = Math.min( _tp.profit, mn )
      l = Math.max(  Math.abs(_tp.profit) , l )
      if( _tp.profit >= 0) {
        symbolpw.push(  _tp  )
      } else {
        symbolpl.push(  _tp  )
      }
    }
    symbolpw = symbolpw.sort( (a, b) => {
      return parseFloat(b.profit) - parseFloat(a.profit);
    });
    symbolpl = symbolpl.sort( (a, b) => {
      return parseFloat(a.profit) - parseFloat(b.profit);
    });
    var srange = { 
      l : l,
      x0 : 0 , 
      x1:( mx - Math.min(mn, 0) ), 
      dxl : -Math.min(mn, 0),
      dxr : mx,
    }
    var snames = {}
    for (var idx in this.props.group.strategy_list) {
      snames[ this.props.group.strategy_list[idx].id ] = this.props.group.strategy_list[idx].name
    }

    if(  (dates.length > 0) && this.state.dates.length === 0 ) {
      var _dates = []
      for (var i in dates) {
        _dates.push(  service.formatDate(  dates[i]  )   )
      }
      apis.getdatafromsymbol( this.state.branchmark , _dates  ).then(
        (res) => {
          var arr = [], base = 0
          //console.log(res)
          for (var i in res) {
            if( parseInt(i, 10) === 0 ) {
              base = res[i].close
            }
            var obj = {}
            obj.x = new Date(res[i].date)
            obj.y = (res[i].close - base)/base * 100
            arr.push(obj)
          }
          this.setState({bm : arr})
        }
      )
    }


    this.setState({
        domain : [ dmin*1.25, dmax*1.25 ],
        data : data,
        strategy : strategy,
        symbolpw : symbolpw,
        symbolpl : symbolpl,
        srange : srange,
        strange : strange, 
        snames : snames,
        backtest_report : backtest_report,
        dates : dates,
    })
   
  }

  getReport(props) {
    var bool = (props.type==="forwardtest")
    service.getReport( props.group.id , props.group[props.type + "_report"].id  ).then(
      (res) => {
          this.setState( {
            report : res.trade_list
          } )
          this.calcpReport( res  )

      }
    )
  }

  sortdata(key, value) {
    this.setState({
      symData : [],
      points : [],
    })
    if(  (key !== this.state.selected.key) && (value !== this.state.selected.value) ) {
      //var arr = JSON.parse(  JSON.stringify( this.props.report )  )
      var arr = JSON.parse(  JSON.stringify( this.state.report )  )
      arr = arr.filter(function(a) {
        return a[key] === value
      })
      this.setState({
        selected : {key : key, value : value}
      })
            
      this.calcpTemp(arr, this.props.group.initial_capital, this.props.group.transaction_fee)

      if( key === "symbol" ){
        this.selectSym( value )
      }


    } else {
      this.setState({
        selected : {key : null, value : null}
      })
      this.calcpTemp(this.state.report, this.props.group.initial_capital, this.props.group.transaction_fee)


      //this.calcpTemp(this.props.report, this.props.group.initial_capital, this.props.group.transaction_fee)
    }
  }

  generateChild(v, k) {
    var keys = ['date', 'symbol', 'price', 'decision' ,'profit' , 'boardlot']
    return(
        v.actions.map( (v2,k2) => {
        return(
              <tr key={k +"_" +k2}>
                {
                  keys.map( (v3, k3) => {
                    var val2 = ""
                    if( v3 === 'date' ) {
                      val2 = service.formatDate( v2[v3] )
                    } else if( ( v3  === 'profit')  ) {
                      val2 = Math.round( v2[v3] * 1000 )/1000
                    } else if (v3 === 'decision') {
                      val2 = ((v2[v3]>0)?(this.props.gi18n[this.props.language]['t_buy'] + " : "  ):(this.props.gi18n[this.props.language]['t_sell'] + " : "  ) ) + Math.abs( v2[v3] )
                    } else {
                      val2 = v2[ v3  ]
                    }
                    return(
                      <td style={{ backgroundColor : gcolor.gnDarkColor, color : gcolor.gnWhiteColor}} 
                        key={k2+"_"+k3}>{ val2 }</td> 
                    )
                  })
                }
              </tr>
      )
      })
    )
  }

  hideAll() {
    this.setState({
      hasReport : false,
      domain : [-1,1],
      w : 0,
      h : 0,
      data : [],
      symbolp : [],
      symbolpw : [],
      symbolpl : [],
      strategy : [],
      srange : {x1:0,x0:0,l:0, dxr:0, dxl:0},
      strange : {y1:0,y0:0,l:0, dyu:0, dyd:0, max : 0, min : 0},
      showDate : null,
      snames : {},
      selected : {key:null, value:null},
      showbacktest_report : true,
      showHOverbacktest_report : false,
      backtest_report : {},
      transaction_fee : 0,
      initial_capital : 0,
      bm : [],
      dates : [],
      showConfirm : false,
      body : null,
    })
  }

  onsubmit() {
    if( this.props.submit) {
      this.saveReport()
    } else {
      this.setState( { showConfirm : true } )
    }
  }
  
  saveReport() {
    if( !this.state.backtest_report.overdraft ) {

      var report = JSON.parse(  JSON.stringify( this.state.report )  )
      report = report
      
      for (var i in report) {
        report[i].date = service.formatDate(  report[i].date, true  )
        report[i].group = report[i].group.toString()
      }
      

      var obj = {
        initial_capital : parseFloat(this.props.group.initial_capital, 10) ,
        transaction_fee : parseFloat(this.props.group.transaction_fee, 10),
        trade_list : report,
        report_type : 'backtest',
      }


      //console.log( this.props.group.id )
      //console.log( obj )

      service.createReport( this.props.group.id, obj ).then(
        (res) => {
          //console.log( res )
          this.props.editGroup( 'backtest_report' , res  )
          this.exit()
          this.props.updateValue({
            showAlert : true,
            showAlertBtn : false,
            alertMsg : this.props.gi18n[ this.props.language ]['submit_success']
          })
          this.props.save(null, false, false)
        }
      )
    } else {
      this.setState(
        {
          body : this.props.gi18n[this.props.language]['invalid_report']
        }
      )
    }
  }

  exit() {
    this.hideAll()
    this.props.updateValue( {  showReport  : false  }  )
  }

  selectSym(sym) {
    apis.getDataFromSymbol( sym ).then(
      (res) => {
        var prices = {}
        for (var i in res) {
          var _do = res[i].date.split("-")
          res[i].x = new Date( parseInt(_do[0], 10), parseInt(_do[1], 10)-1, parseInt(_do[2], 10) )
          prices[ _do[0] ] = res[i]
        }

        var first = res[0].x, last = res[ res.length-1 ].x

        var _pairs = []
        for (var j in this.state.data ) {
          var _d = this.state.data[j].actions[0].date.split(" ")
          _d = _d[0].split("-")
          _d = new Date(  parseInt( _d[0], 10 ) , parseInt( _d[1], 10 )-1 , parseInt( _d[2], 10 )  )
          if(  (_d >= first) && ( _d<=last )  ) {
            if ( this.state.data[j].actions[0].decision > 0  ) {
              var obj = {}
              obj.buy = this.state.data[j].actions[0].price
              obj.buydate = _d
              _pairs.push(  obj )
            } else {
              for (var k in _pairs) {
                if(  !_pairs[k].sell ) {
                  _pairs[k].sell = this.state.data[j].actions[0].price
                  _pairs[k].selldate = _d
                  _pairs[k].profit = (this.state.data[j].actions[0].price-_pairs[k].buy) * this.state.data[j].actions[0].boardlot * Math.abs(  this.state.data[j].actions[0].decision   )
                  break
                }
              }
            }
          }
        }
        //console.log(  _pairs )
        var _points = []
        for (var i in _pairs) {
          var _arr = []
          _arr.push(   { x : _pairs[i].buydate, y : _pairs[i].buy  }  )
          if( _pairs[i].sell ) {
            _arr.push(   { x : _pairs[i].selldate, y : _pairs[i].sell  }  )
            
          }
          _points.push(  {  data:_arr, profit : (_pairs[i].profit?_pairs[i].profit:0) } )
        }

        this.setState({
          symData : res,
          points : _points,
        })
      }
    )
  }

  render() {

    return (
     <Modal
        size="lg"
        show={this.props.showReport}
        onHide={() => {
          if( this.props.readonly ) {
            this.exit()
          } else {
            this.onsubmit()
          }
        }}
        dialogClassName="modal-95w"
        aria-labelledby="example-custom-modal-styling-title"
        className={['Garlican-Report'  ].join(' ')} >
      <Modal.Header className={ ['Garlican-Report-Title'].join( " " ) } closeButton={false}>
        <div className={['Garlican-Report-Frame'].join(" ")}>

          <div className={ ['app-tri-holder'].join(' ') } onClick={
							(e)=>{
								if( this.props.readonly ) {
                  this.exit()
                } else {
                  this.onsubmit()
                }
							}
          }>
						<div className={ ['app-tri'].join(' ') }> </div>
						<Button className={ ['app-btn'].join(' ') } variant="primary" > 
							{ this.props.gi18n[this.props.language]['back']  }
						</Button>
					</div>

          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">{
                this.state.showbacktest_report?
                ((this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['show_chart']:'Show chart'):
                ((this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['show_billboard']:'Show summary board')
              }</Tooltip>
            }
          >
          <FontAwesomeIcon 
            onMouseOver={ (e)=>{
              this.setState( { showHOverbacktest_report : true } ) 
            } }
            onMouseOut={ (e)=>this.setState( { showHOverbacktest_report : false} ) } 
            icon={ this.state.showbacktest_report?faEyeSlash:faEye } 
            onClick={(e)=>this.setState( { showbacktest_report : !this.state.showbacktest_report } ) }
            className={['Garlican-Report-Title-Icon'].join(' ')}/>
          </OverlayTrigger>
          <div className={['Garlican-Report-Title-Title'].join(" ")} >  {  (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language][ this.props.type+'ing']:'Report summary'  }  </div>

      </div>
      </Modal.Header>
        
        <div className={['Garlican-Report-Billboard-Arrow'].join(" ")} 
          style={ { 
          visibility : (this.state.showbacktest_report || this.state.showHOverbacktest_report)?"visible":"hidden", 
          opacity : (this.state.showbacktest_report || this.state.showHOverbacktest_report)?1:0 }}>
        </div>
        <div className={['Garlican-Report-Billboard'].join(" ")} 
          style={ { 
              visibility : (this.state.showbacktest_report || this.state.showHOverbacktest_report)?"visible":"hidden", 
              opacity : (this.state.showbacktest_report || this.state.showHOverbacktest_report)?1:0  
            } } >
            { (Object.keys(this.state.backtest_report).length > 0) &&
              <div className={['Garlican-Report-Billboard-Top']}>
                <div className={['Garlican-Report-Billboard-Row']}>
                    <div className={['Garlican-Report-Billboard-Col','Garlican-Report-Billboard-Col-Twin'].join(" ")}>
                      <div className={['Garlican-Report-Billboard-Col-Title'].join(" ")}> 
                        { this.props.gi18n[this.props.language]['initial'] + "($)" } 
                        <FontAwesomeIcon icon={ faEdit } className={['Garlican-Report-Billboard-Edit'].join(' ')}/>
                      </div>
                      <input className={['Garlican-Report-Billboard-Col-Content'].join(" ")} value={ this.state.initial_capital } 
                        disabled={!this.props.isOwner}
                        onChange={ (e)=>{
                          this.setState({ initial_capital : e.target.value })
                        } }
                        onBlur={(e)=>{
                          if( parseFloat(e.target.value, 10) > 0 ) {
                            //console.log("A")
                            this.props.editGroup( 'initial_capital', e.target.value  )
                          } else {
                            this.setState({ initial_capital: this.props.group.initial_capital })
                          }
                        }}
                      />
                    </div>
                    <div className={['Garlican-Report-Billboard-Col','Garlican-Report-Billboard-Col-Twin'].join(" ")}>
                      <div className={['Garlican-Report-Billboard-Col-Title'].join(" ")}> 
                        { this.props.gi18n[this.props.language]['transaction_fee'] + "($)" } 
                        <FontAwesomeIcon icon={ faEdit } className={['Garlican-Report-Billboard-Edit'].join(' ')}/>
                      </div>
                      <input className={['Garlican-Report-Billboard-Col-Content'].join(" ")} value={ this.state.transaction_fee } 
                        disabled={!this.state.isOwner}
                        onChange={ (e)=>{
                          this.setState({ transaction_fee : e.target.value })
                        } }
                        onBlur={
                          (e)=>{
                            if(e.target.value >= 0 ) {
                              this.props.editGroup( 'transaction_fee', e.target.value  )
                            } else {
                              this.setState({ transaction_fee : 0}) 
                            }
                          }
                        }
                      />
                    </div>
              </div>
              </div>
            }
            { (Object.keys(this.state.backtest_report).length > 0) &&
              <div className={['Garlican-Report-Billboard-Left'].join(" ")}>
                <div className={['Garlican-Report-Billboard-Box'].join(" ")}> 
                  <div className={['Garlican-Report-Billboard-Box-Main'].join(" ")}>
                    <div className={['Garlican-Report-Billboard-Box-Main-Title'].join(" ")}> { this.props.gi18n[this.props.language]['profit'] }  </div>
                    <div className={['Garlican-Report-Billboard-Box-Main-Content'].join(" ")} style={{ color : (this.state.backtest_report.profit>0)?gcolor.gnGreenColor:gcolor.gnRedColor  }} > { "$" + Math.round(this.state.backtest_report.profit*100)/100 + "   (" + ((this.state.backtest_report.profit>0)?"+":"")  + Math.round( this.state.backtest_report.profit/this.state.backtest_report.initial_capital*1000)/10  + "%)" } </div>
                    <div className={['Garlican-Report-Billboard-Box-Main-Sub'].join(" ")} style={{ color : (this.state.backtest_report.mean>0)?gcolor.gnGreenColor:gcolor.gnRedColor  }}> { this.props.gi18n[this.props.language]['avg_profit'] + " : " + Math.round( this.state.backtest_report.mean*100000)/1000  + "%" } </div>
                  </div>
                  <div className={['Garlican-Report-Billboard-Box-Secondary'].join(" ")}> 
                    <div className={['Garlican-Report-Billboard-Box-Secondary-Full'].join(" ")}> 
                      { this.props.gi18n[this.props.language]['win_rate'] + " : " + Math.round( this.state.backtest_report.win_count*100000/(this.state.backtest_report.win_count + this.state.backtest_report.even_count + this.state.backtest_report.loss_count) ) /1000 + "%" }
                    </div>
                  </div>
                </div>

                <div className={['Garlican-Report-Billboard-Box'].join(" ")}> 
                  <div className={['Garlican-Report-Billboard-Box-Main'].join(" ")}>
                    <div className={['Garlican-Report-Billboard-Box-Main-Title'].join(" ")}> { this.props.gi18n[this.props.language]['actions']} </div>
                    <div className={['Garlican-Report-Billboard-Box-Main-Content'].join(" ")} > { this.state.backtest_report.trade_count} </div>
                  </div>
                  <div className={['Garlican-Report-Billboard-Box-Secondary'].join(" ")}> 
                    <div className={['Garlican-Report-Billboard-Box-Secondary-Half'].join(" ")}> 
                      <div className={['Garlican-Report-Billboard-Box-Secondary-Title'].join(" ")}> { this.props.gi18n[this.props.language]['buyhand'] } </div>
                      <div className={['Garlican-Report-Billboard-Box-Secondary-Body'].join(" ")}> { this.state.backtest_report.buy} </div>
                    </div>
                    <div className={['Garlican-Report-Billboard-Box-Secondary-Half'].join(" ")}> 
                    <div className={['Garlican-Report-Billboard-Box-Secondary-Title'].join(" ")}> { this.props.gi18n[this.props.language]['sellhand'] } </div>
                      <div className={['Garlican-Report-Billboard-Box-Secondary-Body'].join(" ")}> { this.state.backtest_report.sell} </div>
                    </div>
                  </div>
                </div>

              
              </div>
            }
            { (Object.keys(this.state.backtest_report).length > 0) &&
              <div className={['Garlican-Report-Billboard-Right'].join(" ")}>
                <div className={['Garlican-Report-Billboard-Row'].join(" ")}> 
                  <div className={['Garlican-Report-Billboard-Col', 'Garlican-Report-Billboard-Col-Third'].join(" ")}> 
                    <div className={['Garlican-Report-Billboard-Col-Title'].join(" ")}> { this.props.gi18n[this.props.language]['max_profit'] } </div>
                    <div className={['Garlican-Report-Billboard-Col-Content'].join(" ")} style={{color : gcolor.gnGreenColor}}> { "$" + Math.round(this.state.backtest_report.maxp*1000)/1000 } </div>
                    <div className={['Garlican-Report-Billboard-Col-Sub'].join(" ")} > { "@ " + service.formatDate( this.state.backtest_report.mxdate ) } </div>
                  </div>
                  <div className={['Garlican-Report-Billboard-Col', 'Garlican-Report-Billboard-Col-Third'].join(" ")}> 
                    <div className={['Garlican-Report-Billboard-Col-Title'].join(" ")}> { this.props.gi18n[this.props.language]['min_profit']  } </div>
                    <div className={['Garlican-Report-Billboard-Col-Content'].join(" ")} style={{color : gcolor.gnRedColor}}> { "$" + Math.round(this.state.backtest_report.minp*1000)/1000 } </div>
                    <div className={['Garlican-Report-Billboard-Col-Sub'].join(" ")} > { "@ " + service.formatDate( this.state.backtest_report.mndate ) } </div>
                  </div>
                  <div className={['Garlican-Report-Billboard-Col', 'Garlican-Report-Billboard-Col-Third'].join(" ")} 
                    style={{
                      backgroundColor : (!this.state.backtest_report.overdraft)?(gcolor.gnGreenColor+50):(gcolor.gnRedColor+50)
                    }}> 
                    <FontAwesomeIcon 
                      icon={ (!this.state.backtest_report.overdraft)?faCheck:faTimes } 
                        className={['Garlican-Report-Billboard-Col-Third-Icon'].join(' ')}/>
                      {
                        this.state.backtest_report.invalidDate &&
                        <div className={['Garlican-Report-Billboard-Col-Sub'].join(" ")} > { "@" + service.formatDate( this.state.backtest_report.overdraft_date ) } </div>
                      }
                  </div>
                </div>

                <div className={['Garlican-Report-Billboard-Row'].join(" ")}>
                  <div className={['Garlican-Report-Billboard-Col','Garlican-Report-Billboard-Col-Twin'].join(" ")}>
                    <div className={['Garlican-Report-Billboard-Col-Title'].join(" ")}> { this.props.gi18n[this.props.language]['volatility'] } </div>
                    <div className={['Garlican-Report-Billboard-Col-Content'].join(" ")}> { Math.round(this.state.backtest_report.std_dev*100000)/1000 + "%" } </div>
                  </div>
                  <div className={['Garlican-Report-Billboard-Col','Garlican-Report-Billboard-Col-Twin'].join(" ")}>
                    <div className={['Garlican-Report-Billboard-Col-Title'].join(" ")}> { this.props.gi18n[this.props.language]['sharpe_ratio'] } </div>
                    <div className={['Garlican-Report-Billboard-Col-Content'].join(" ")}> {  (this.state.backtest_report.std_dev>0)?(Math.round(this.state.backtest_report.mean/this.state.backtest_report.std_dev* 1000)/1000):"--"  } </div>
                  </div>  
                </div>
                <div className={['Garlican-Report-Billboard-Row'].join(" ")}> 
                  <div className={['Garlican-Report-Billboard-Col','Garlican-Report-Billboard-Col-Third'].join(" ")}>
                    <div className={['Garlican-Report-Billboard-Col-Title'].join(" ")}> {this.props.gi18n[this.props.language]['active_strategy']} </div>
                    <div className={['Garlican-Report-Billboard-Col-Content'].join(" ")}> { this.state.backtest_report.strategy_count  } </div>
                  </div> 
                  <div className={['Garlican-Report-Billboard-Col','Garlican-Report-Billboard-Col-Third'].join(" ")}>
                    <div className={['Garlican-Report-Billboard-Col-Title'].join(" ")}> { this.props.gi18n[this.props.language]['symbols'] } </div>
                    <div className={['Garlican-Report-Billboard-Col-Content'].join(" ")}> { this.state.backtest_report.symbol_count } </div>
                  </div> 
                  <div className={['Garlican-Report-Billboard-Col','Garlican-Report-Billboard-Col-Third'].join(" ")}>
                    <div className={['Garlican-Report-Billboard-Col-Title'].join(" ")}> { this.props.gi18n[this.props.language]['action_day'] } </div>
                    <div className={['Garlican-Report-Billboard-Col-Content'].join(" ")}> { this.state.backtest_report.total_trading_days } </div>
                  </div>         
                </div>
              </div>  
            }
        </div>


      <Modal.Body
          className={['Garlican-Report-Body'].join(" ")} 
          >
          
          <div id={"chart_container"}  className={['Garlican-Report-Chart'].join(" ")} >

            { (this.state.selected.key !== 'symbol') &&
              <div className={['Garlican-Report-Chart-Title'].join(" ")} >
                { this.props.gi18n[this.props.language]['profit_by_date']  }
              </div>
            }
            { (this.state.selected.key === 'symbol') &&
              <div className={['Garlican-Report-Chart-Title'].join(" ")} >
                { this.state.selected.value  + " : " + this.props.gi18n[this.props.language]['profit_by_date']  }
              </div>
            }

            <div className={['Garlican-Report-Chart-Chart'].join(" ")} >

            { (this.state.selected.key === 'symbol') && ( this.state.symData.length > 0 ) &&
                <VictoryChart 
                padding={ { top: fix, bottom: fix, left: fix, right : fix } }
                width={ this.state.w  }
                height={ this.state.h }
                containerComponent={
                <VictoryZoomContainer
                  responsive={true} 
                  zoomDimension="x"
                />
              }>
                <VictoryAxis 
                  domainPadding={{x: [0, 100]}}
                  tickFormat={ (t)=>service.formatDate( t )  }
                  style={{
                    axis: { 
                      strokeWidth: 1, 
                      strokeDasharray: 1,
                      stroke: (gcolor.gnGreyColor+"30")
                    },
                    ticks: { padding: 0 },
                    axisLabel : { 
                      padding: (this.state.w>0)?-15:0,
                      fontSize: 9,
                      fontFamily: gcolor.gnFont,
                      fill : gcolor.gnDarkColor,
                    },
                    tickLabels: {
                      fontSize: 9,
                      fontFamily: gcolor.gnFont,
                      fill : gcolor.gnDarkColor,
                      fillOpacity: 1,
                      margin: 0,
                      padding: 0
                    },
                  }}
                />
                <VictoryAxis dependentAxis  
                  domainPadding={{ y:[0, 1000] }}
                  label="Price"
                  style={{
                    axis: { 
                      strokeWidth: 0,
                    },
                    ticks: { padding: -10 },
                    grid: {
                      stroke: ({tick}) => (tick > 0)?(gcolor.gnGreenColor+"60"):(gcolor.gnRedColor+"60"), 
                      strokeDasharray: 10,
                    },
                    axisLabel: {
                      padding: (this.state.h>0)?-20:0,
                      fontSize: 9,
                      fontFamily: gcolor.gnFont,
                      fill : gcolor.gnDarkColor,
                    },
                    tickLabels: {
                      fontSize: 9,
                      fontFamily: gcolor.gnFont,
                      fill : gcolor.gnDarkColor,
                      fillOpacity: 1,
                      margin: 0,
                      padding: 0
                    },
                  }}
                />
                <VictoryCandlestick
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 }
                  }}
                  candleWidth={5}
                  candleColors={{ positive: "#5f5c5b", negative: "#c43a31" }}
                  data={this.state.symData}
                />
                { this.state.points.map( (v,k) => {
                  return(
                    [ 
                        <VictoryLine
                          key={k + "_0"}
                          style={{
                            data: { stroke: (v.profit>0)?gcolor.gnGreenColor:gcolor.gnRedColor },
                            parent: { border: "1px solid #ccc"},
                            labels: {
                              fontSize: 15,
                            }
                          }}
                          labels={({ datum }) => this.props.gi18n[this.props.language]['porfit_name'].replace( "<profit>" , Math.round(v.profit) ) }
                          data={ v.data }
                        />,
                        <VictoryScatter
                          key={k + "_1"}
                          labels={ ({datum})=>"$"+datum.y    }
                          labelComponent={
                            <VictoryTooltip 
                              flyoutStyle={{ fill : gcolor.gnDarkColor }}
                              style={ { fill: gcolor.gnWhiteColor  }  }
                              cornerRadius={ 1 } />
                          }
                          style={{
                            data: {
                              fill: (v.profit>0)?gcolor.gnGreenColor:gcolor.gnRedColor  ,
                              stroke: (v.profit>0)?gcolor.gnGreenColor:gcolor.gnRedColor ,
                              fillOpacity: 1,
                            } ,
                          }}
                          data={v.data} 
                        />
                      ]
                  )
                } )
                }

              </VictoryChart>
            }

            { (this.state.selected.key === 'symbol') && ( this.state.symData.length === 0 ) &&
              <div className={ ['reading'].join(' ') }>
                  <div className={ ['spin'].join(' ') }>
                    <Spinner animation="grow" variant="primary" />
                    <Spinner animation="grow" variant="secondary" />
                    <Spinner animation="grow" variant="success" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="info" />
                    <Spinner animation="grow" variant="light" />
                    <Spinner animation="grow" variant="dark" />
                  </div>
                  <div className={ ['test'].join(' ') }> { this.props.gi18n[this.props.language]['loading'] }  </div>

              </div>
            }


              { (this.state.selected.key !== 'symbol') &&
              <VictoryChart 
                padding={ { top: fix, bottom: fix, left: fix, right : fix } }
                width={ this.state.w  }
                height={ this.state.h }
                containerComponent={
                <VictoryZoomContainer
                  responsive={true} 
                  zoomDimension="x"
                />
              }>
                <VictoryLegend x={5} y={ 5 }
                  orientation="horizontal"
                  gutter={20}
                  style={
                    { border: { 
                                stroke: gcolor.gnDarkColor+"90"
                              },
                      labels : {
                        fontSize : 10,
                      } 
                    }
                  }
                  data={[
                    { name: ('name' in this.props.group)?this.props.group.name:"Portfolio" , symbol: { fill: gcolor.gnFrameColor+"90" } },
                    { name: this.state.branchmark , symbol: { fill: gcolor.gnBadgeColor+"90" } },
                  ]}
                />
                <VictoryAxis 
                  domainPadding={{x: [0, 100]}}
                  tickFormat={ (t)=>service.formatDate( t )  }
                  style={{
                    axis: { 
                      strokeWidth: 1, 
                      strokeDasharray: 1,
                      stroke: (gcolor.gnGreyColor+"30")
                    },
                    ticks: { padding: 0 },
                    axisLabel : { 
                      padding: (this.state.w>0)?-15:0,
                      fontSize: 9,
                      fontFamily: gcolor.gnFont,
                      fill : gcolor.gnDarkColor,
                    },
                    tickLabels: {
                      fontSize: 9,
                      fontFamily: gcolor.gnFont,
                      fill : gcolor.gnDarkColor,
                      fillOpacity: 1,
                      margin: 0,
                      padding: 0
                    },
                  }}
                />
                <VictoryAxis dependentAxis  
                  domainPadding={{ y:[0, 1000] }}
                  label="Profit(%)"
                  style={{
                    axis: { 
                      strokeWidth: 0,
                    },
                    ticks: { padding: -10 },
                    grid: {
                      stroke: ({tick}) => (tick > 0)?(gcolor.gnGreenColor+"60"):(gcolor.gnRedColor+"60"), 
                      strokeDasharray: 10,
                    },
                    axisLabel: {
                      padding: (this.state.h>0)?-20:0,
                      fontSize: 9,
                      fontFamily: gcolor.gnFont,
                      fill : gcolor.gnDarkColor,
                    },
                    tickLabels: {
                      fontSize: 9,
                      fontFamily: gcolor.gnFont,
                      fill : gcolor.gnDarkColor,
                      fillOpacity: 1,
                      margin: 0,
                      padding: 0
                    },
                  }}
                />

                <VictoryLine padding={0}
                  domain={ {y : this.state.domain } }
                  style={{
                    data: { 
                      stroke: ({datum})=>(datum)?((!datum.overdraft)?(gcolor.gnGreyColor+"90"):(gcolor.gnRedColor+"90")):gcolor.gnGreyColor+"90",
                    },
                    parent: { 
                      border: "0px solid #ccc"
                    },
                    labels: { 
                      fill: gcolor.gnFrameColor, 
                      fontSize: 12,
                    }
                  }}
                  labels={() => null}
                  data={ this.state.data }
                  x={ (datum) =>datum.date  }
                  y={ (datum) =>datum.rate  }
                />

                <VictoryLine padding={0}
                  style={{
                    data: { 
                      stroke: gcolor.gnBadgeColor+"90",
                    },
                    parent: { 
                      border: "0px solid #ccc"
                    },
                    labels: { 
                      fill: gcolor.gnFrameColor, 
                      fontSize: 0,
                    }
                  }}
                  labels={() => null}
                  data={ this.state.bm }
                />

                <VictoryScatter
                  domain={ {y : this.state.domain } }
                  size={ ({datum})=> Math.min( Math.max(datum.actions.length, 1) , 10)   }
                  labels={({ datum }) => service.formatDate(datum.date) + " \n" +  Math.round(datum.rate * 1000)/1000 + "% (" + datum.actions.length  + ")" }
                  labelComponent={
                    <VictoryTooltip 
                      flyoutStyle={{ fill : gcolor.gnDarkColor }}
                      style={ { fill: gcolor.gnWhiteColor  }  }
                      cornerRadius={ 1 } />
                  }
                  style={{
                    data: {
                      fill:  ({datum})=>  (datum.date===this.state.showDate)?gcolor.gnFrameColor2:((!datum.overdraft)?gcolor.gnFrameColor:gcolor.gnRedColor) ,
                      stroke: ({datum})=>  (datum.date===this.state.showDate)?gcolor.gnFrameColor2:((!datum.overdraft)?gcolor.gnFrameColor:gcolor.gnRedColor) ,
                      fillOpacity: 1,
                      strokeWidth: ({datum})=> (datum.date===this.state.showDate)?5:1 ,
                      zIndex: ({datum})=> (datum.date===this.state.showDate)?10:1 ,
                    } ,
                  }}
                  data={this.state.data} 
                  x={ (datum) =>datum.date  }
                  y={ (datum) =>datum.rate  }
                  events={[{
                      target: "data",
                      eventHandlers: {
                        onClick: (e) => {
                          return [
                            {
                              target: "data",
                              mutation: (props) => {
                                const fill = props.style && props.style.fill;
                                if( this.state.showDate === props.datum.date ) {
                                  this.setState( {showDate : null} )
                                  return null
                                } else {
                                  this.setState( {showDate : props.datum.date} )
                                  return { style: { fill: gcolor.gnFrameColor2, stroke: gcolor.gnFrameColor2,strokeWidth: 5 } };
                                }
                                
                              }
                            }
                          ];
                        },
                      }
                  }]}
                />
              </VictoryChart>
              }


            </div>
          </div>

          <div className={['Garlican-Report-Chart-2'].join(" ")} >
            <div className={['Garlican-Report-Chart-2-Title'].join(" ")} >
              {  this.props.gi18n[this.props.language]['profit_by_strategy']  }
            </div>
            <div className={['Garlican-Report-Chart-2-Wrapper'].join(" ")} >
                <div className={['Garlican-Report-Chart-2-Bars'].join(" ")}>
                  <div className={['Garlican-Report-Chart-2-Bars-Line'].join(' ') } style={ {top : (this.state.strange.dyd/this.state.strange.l*100) + "%" } }>  </div>
                  {
                    this.state.strategy.map( (v,k) => {
                      var sobj={}
                      sobj.height = (  Math.abs(v.profit)/this.state.strange.l*100) + "%"
                      sobj.width = (1/(this.state.strategy.length) * 100) + "%"
                      if( v.profit >= 0 ) {
                        sobj.top = (100- ((this.state.strange.dyu+v.profit)/this.state.strange.l*100)) + "%"
                        sobj.bottom = (this.state.strange.dyu/this.state.strange.l*100) + "%"
                        sobj.backgroundColor = ((this.state.selected.key==='group')&&(this.state.selected.value===v.group))?gcolor.gnFrameColor:gcolor.gnGreenColor
                      } else {
                        sobj.top = (this.state.strange.dyd/this.state.strange.l*100) + "%"
                        sobj.backgroundColor = ((this.state.selected.key==='group')&&(this.state.selected.value===v.group))?gcolor.gnFrameColor:gcolor.gnRedColor
                      }
                      return(
                        <OverlayTrigger
                            key={k}
                            placement="bottom"
                            overlay={<Tooltip>
                              <div>{"$" + Math.round(v.profit)}</div>
                              <div>{  this.props.gi18n[this.props.language]['sold_hand'] + " :  " + v.sold + " " + this.props.gi18n[this.props.language]['hold_hand'] + " : " + v.onhold} </div>
                            </Tooltip>}>
                            <div
                              onClick={ (e)=>this.sortdata( 'group' , v.group  )  } 
                              className={['Garlican-Report-Chart-2-Bars-Bar'].join(" ")} style={ sobj }> </div>
                        </OverlayTrigger>
                      )
                    } )
                  }
                </div>
                
                <div className={['Garlican-Report-Chart-2-Group'].join(" ")}>
                {
                  this.state.strategy.map( (v,k) => {
                    var sobj={}
                    sobj.width = (1/(this.state.strategy.length) * 100) + "%"
                    return(
                      <OverlayTrigger
                            key={k}
                            placement="top"
                            overlay={
                            <Tooltip>
                              <div>{"$" + Math.round(v.profit)}</div>
                              <div>{this.props.gi18n[this.props.language]['sold_hand'] + " :  " + v.sold + " " + this.props.gi18n[this.props.language]['hold_hand'] + " : " + v.onhold} </div>
                            </Tooltip>
                            }>
                          <div
                            onClick={ (e)=>this.sortdata( 'group' , v.group  )  }  
                            className={['Garlican-Report-Chart-2-Group-Name'].join(" ")} style={sobj}> { this.state.snames[ v.group ] } </div>
                      </OverlayTrigger>
                    )
                  } )
                }
                </div>

            </div>
          </div>

          <div className={['Garlican-Report-Chart-2'].join(" ")}>
            <div className={['Garlican-Report-Chart-2-Wrapper', 'Garlican-Report-Chart-2-Wrapper-B'].join(" ")} >
                <div className={['Garlican-Report-Chart-2-Title-B'].join(" ")} >
                  { this.props.gi18n[this.props.language]['most_gains']  }
                </div>
                {
                  this.state.symbolpw.map( (v,k) => {
                    var sobj = {}
                    sobj.width = (Math.abs(v.profit)/this.state.srange.l*100) + "%"
                    sobj.left = "0px"
                    sobj.backgroundColor = ((this.state.selected.key==='symbol')&&(this.state.selected.value===v.symbol))?gcolor.gnFrameColor:gcolor.gnGreenColor
                    return(
                      <div key={k} className={['Garlican-Report-Chart-2-Wrapper-Bar-Holder', 'Garlican-Report-Chart-2-Wrapper-B-Bar-Holder'].join(" ")} > 
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip>{ "$" + Math.round(v.profit)+ " (" + v.quantity + ")" }</Tooltip>}>
                          <div
                            onClick={ (e)=>this.sortdata( 'symbol' , v.symbol  )  }  
                            className={['Garlican-Report-Chart-2-Wrapper-Bar-Symbol'].join(" ")} style={{left:"0px"}}>{v.symbol}</div>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>{ "$" + Math.round(v.profit)+ " (" + v.quantity + ")" }</Tooltip>}>
                          <div style={{ left:"60px" }} className={['Garlican-Report-Chart-2-Wrapper-Bar-Wrapper'].join(" ")}>
                            <div
                              onClick={ (e)=>this.sortdata( 'symbol' , v.symbol  )  }   
                              className={['Garlican-Report-Chart-2-Wrapper-Bar'].join(" ")} style={sobj}></div>
                          </div>
                        </OverlayTrigger>
                      </div>
                    )
                  } )
                }
            </div>
            <div className={['Garlican-Report-Chart-2-Wrapper', 'Garlican-Report-Chart-2-Wrapper-B'].join(" ")} >
              <div className={['Garlican-Report-Chart-2-Title-B'].join(" ")} >
                { this.props.gi18n[this.props.language]['most_losses']  }
              </div>
                {
                  this.state.symbolpl.map( (v,k) => {
                    var sobj = {}
                    sobj.width = (Math.abs(v.profit)/this.state.srange.l*100) + "%" 
                    sobj.right = "0px"
                    sobj.backgroundColor = ((this.state.selected.key==='symbol')&&(this.state.selected.value===v.symbol))?gcolor.gnFrameColor:gcolor.gnRedColor
                    return(
                      <div key={k} className={['Garlican-Report-Chart-2-Wrapper-Bar-Holder', 'Garlican-Report-Chart-2-Wrapper-B-Bar-Holder'].join(" ")} > 
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>{ "$" + Math.round(v.profit)+ " (" + v.quantity + ")" }</Tooltip>}>
                              <div 
                                onClick={ (e)=>this.sortdata( 'symbol' , v.symbol  )  }  
                                className={['Garlican-Report-Chart-2-Wrapper-Bar-Symbol'].join(" ")} style={{right:"0px"}}>{v.symbol}</div>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>{ "$" + Math.round(v.profit)+ " (" + v.quantity + ")" }</Tooltip>}>
                          <div style={{ right:"60px" }} className={['Garlican-Report-Chart-2-Wrapper-Bar-Wrapper'].join(" ")}>
                                  <div
                                    onClick={ (e)=>this.sortdata( 'symbol' , v.symbol  )  }   
                                    className={['Garlican-Report-Chart-2-Wrapper-Bar'].join(" ")} style={sobj}></div>
                          </div>
                        </OverlayTrigger>
                      </div>
                    )
                  } )
                }
            </div>
          </div>
              
          <div className={['Garlican-Report-List'].join(" ")}>
            <Table striped hover responsive>
              <thead>
                <tr>
                  <td>{ this.props.gi18n[this.props.language]['t_date'] }</td>
                  <td>{ this.props.gi18n[this.props.language]['t_symbol'] }</td>
                  <td>{ this.props.gi18n[this.props.language]['t_price'] }</td>
                  <td>{ this.props.gi18n[this.props.language]['t_decision'] }</td>
                  <td>{ this.props.gi18n[this.props.language]['t_profit'] }</td>
                  <td>{ this.props.gi18n[this.props.language]['t_actions'] }</td>
                </tr>
              </thead>
              <tbody>
                { 
                  this.state.data.map( (v,k) => { 
                    var keys = ['date', 'symbol', 'price', 'decision', 'profit', 'actions']
                    return(
                      [
                        ( (this.state.showDate === null) || (this.state.showDate===v.date))?
                        <tr key={k+"_1"} onClick={
                          (e)=>{
                            if( this.state.showDate === v.date ) {
                              this.setState( { showDate : null  } )
                            } else {
                              this.setState( { showDate : v.date  } )
                            }
                          }
                        }>
                          { keys.map( (v1, k1 ) => {
                            var val = ""
                              if( v1 === 'actions' ) {
                                val = v[v1].length
                              } else if( v1 === 'date' ) {
                                val = service.formatDate( v[v1] )
                              } else if( v1 === 'profit' ) {
                                val = Math.round( v[v1] * 1000 )/1000
                              //} else if (v1 === 'decision') {
                              //  val = ((v[v1]>0)?(this.props.gi18n[this.props.language]['t_buy'] + " : "  ):(this.props.gi18n[this.props.language]['t_sell'] + " : "  ) ) + Math.abs( v[v1] )
                              } else {
                                val = "--"
                              }
                              return(
                                <td key={k1}>
                                  { val  }
                                </td>
                              )
                          } )
                          }
                        </tr>:null
                        ,
                        (this.state.showDate===v.date)?
                        [
                            <tr key={k+ "_2"} colSpan="5" style={{ backgroundColor : gcolor.gnDarkColor, color : gcolor.gnWhiteColor }}> 
                              <td>{ this.props.gi18n[this.props.language]['t_date'] }</td>
                              <td>{ this.props.gi18n[this.props.language]['t_symbol'] }</td>
                              <td>{ this.props.gi18n[this.props.language]['t_price'] }</td>
                              <td>{ this.props.gi18n[this.props.language]['t_decision'] }</td>
                              <td>{ this.props.gi18n[this.props.language]['t_profit'] }</td>
                              <td>{ this.props.gi18n[this.props.language]['t_boardlot'] }</td>
                            </tr>,
                            this.generateChild(v, k)
                        ]:null
                      ]
                    )
                  })
                }
              </tbody>
            </Table>
          </div>
      </Modal.Body>

          
      { this.state.showConfirm && 
          <div className={ ['fade modal show'].join(" ") }>
            <div className={ ['backdrop'].join(" ") } onClick={
              (e)=>{
                this.setState({ showConfirm : false, body : null })
              }
            }></div>
            <ModalDialog
              centered={true}
              className={ ['Garlican-Report-Confirm', "modal-dialog", "modal-lg", "modal-dialog-centere" ].join(' ') } >
              <Modal.Header closeButton onHide={(e)=>this.setState({ showConfirm : false, body : null })}>
                <Modal.Title>{ (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['save_record']:"Do you want to sumbit report summary?" }</Modal.Title>
              </Modal.Header>
              { this.state.body &&
                <Modal.Body className={['error'].join(' ')}>
                  { this.state.body }
                </Modal.Body>
              }
              <Modal.Footer>
                <Button variant="primary" onClick={ (e)=>this.saveReport()  }>
                  { (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['submit']:'Submit'  }
                </Button>
                <Button variant="secondary" onClick={ (e)=>this.exit() }>
                  { (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['cancel']:"Cancel" }
                </Button>
              </Modal.Footer>
            </ModalDialog>
          </div>
      }

      </Modal>
    );
  }
}



