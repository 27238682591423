import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUser, faArrowCircleUp, faArrowCircleDown, faTag, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import service from '../../public/service'

import { Toast } from 'react-bootstrap';
//import { idText } from 'typescript';



class ActivitiesLog extends Component {

    constructor(props) {
        super(props)

        this.state = { 
        };

    }

    componentDidMount() {
      
/*
action: "bought"
board_lot: 1000
id: 11
portfolio: {id: 131, user_id: 3, name: "大價股連跌", description: "恆生指數大藍籌收益股連跌，撈低買入。止賺10%止蝕5%。", win_rate: 19.3548, …}
portfolio_id: 131
price: 4.61
symbol: "3328.HK"
timestamp: "2021-07-12 00:00:00"
unit: 1
user: {user_id: 3, first_name: "Garlican", last_
*/
    }

    componentWillUnmount() {

    }

    render() {
        //console.log(this.props.obj.timestamp )
        return (
          <Toast 
            onClick={ (e)=>{ 
              //console.log( this.props.obj )
              service.getPortfoilioById( this.props.obj.portfolio_id ).then(
                (res) => {
                  //console.log( this.props.obj )
                  if( this.props.enableClick ) {
                    this.props.updateParentValue({ selectedPortfolio : res, showPortfolio :true }) 
                  }
                }
              )
                
            } }
            autohide={true}
            className={['Activities-Log'].join(' ')}>
            <Toast.Header className={[ 'header'  ].join(' ')} closeButton={false}>
                <span className={[(this.props.obj.action==='buy')?"buy":"sell"].join(' ')} >{ ((this.props.obj.action==='buy')?this.props.gi18n[this.props.language][ 'b_notation']:this.props.gi18n[this.props.language][ 's_notation'] )   }</span>
                <span className={["mr-auto", "title"].join(' ')} >{ this.props.obj.symbol  }</span>
                <small className={["date"].join(' ')}>{  service.formatDate( this.props.obj.timestamp ) }</small>
            </Toast.Header>
            <Toast.Header className={["strategy-name"].join(' ')} closeButton={false}>
                <span className={["mr-auto"].join(' ')} >{   this.props.gi18n[this.props.language][ 'portfolio_name' ].replace( "<name>" ,this.props.obj.portfolio.name)  }</span>
            </Toast.Header>
            <Toast.Body className={ ['Activities-Log-Body'].join(' ')  }>
              
              { !this.props.isPortfolio &&
                <div className={["profile"].join(' ')}>
                    { this.props.obj.user.profile_pic && !this.props.isPortfolio && 
                      <img variant="top" src={ this.props.obj.user.profile_pic  } className={['Activities-Log-User-Pic',"rounded", "mr-2" ].join(' ')} />
                    }
                    { !this.props.obj.user.profile_pic && !this.props.isPortfolio &&
                      <FontAwesomeIcon  icon={faUser} className={['Activities-Log-User-Pic',"rounded", "mr-2"].join(' ')}/>
                    }
                </div>
              }
              { !this.props.isPortfolio &&
                <span className={["powered"].join(' ')}>   {  this.props.gi18n[this.props.language]['powered'] + this.props.obj.user.first_name  }  </span>
              }
              { (this.props.obj.action==='buy') &&
                  <div className={["profit"].join(' ')}> 
                      <FontAwesomeIcon  icon={  faTag  } className={[  ].join(' ')}/>
                      <span className={[].join(' ')}> {   "$" + Math.abs(this.props.obj.price).toLocaleString("en-US") + " (" + (this.props.obj.board_lot?this.props.obj.board_lot:this.props.obj.boardlot)*this.props.obj.unit + ")"    } </span> 
                  </div>
              }
              { (this.props.obj.action!=='buy') &&
                <div className={ ["profit", (this.props.obj.profit>0)?"up":"down" ].join(" ") }>
                  <FontAwesomeIcon  icon={  (this.props.obj.profit>0)?faArrowCircleUp:((this.props.obj.profit<0)?faArrowCircleDown:faMinusCircle)  } className={[  ].join(' ')}/>
                  <span className={[].join(' ')}> {   "$" + Math.abs(this.props.obj.profit).toLocaleString("en-US")    } </span>
                </div>
              }
            </Toast.Body>
          </Toast>
        )
    }
}


export default ActivitiesLog;

/*
                  { this.props.obj.user.profile_pic && !this.props.isPortfolio && null
                    //<img variant="top" src={ this.props.obj.user.profile_pic  } className={['Activities-Log-User-Pic',"rounded", "mr-2" ].join(' ')} />
                  }
                  { !this.props.obj.user.profile_pic && !this.props.isPortfolio &&
                    <FontAwesomeIcon  icon={faUser} className={['Activities-Log-User-Pic',"rounded", "mr-2"].join(' ')}/>
                  }


                  //<span className={[].join(' ')}> {   "$" + Math.abs(this.props.obj.price).toLocaleString("en-US") + " (" + (this.props.obj.board_lot?this.props.obj.board_lot:this.props.obj.boardlot) + ")"    } </span>
*/