import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faChevronRight, faChevronLeft, faCartArrowDown } from '@fortawesome/free-solid-svg-icons'

import service from '../../../public/service'
import Api from '../../../Garlican/GarlicanLibrary/apis'


class List extends Component {

    constructor(props) {
        super(props)
 
        //console.log( props.obj  )
        //timestamp
        this.changeVolume = this.changeVolume.bind(this)
    }

    componentWillMount() {

    }

    componentDidMount() {    
    }

    componentWillReceiveProps(props) {
      //console.log(props.selected)
    }

    componentWillUnmount() {

    }

    changeVolume(isAdd) {
      //console.log( this.props.obj )
      var unit = 0, decision=0, match = {unit : 0}

      if(  this.props.obj.action === "sell" ) {
        var o = JSON.parse( JSON.stringify( this.props.forwardGroup ))
        o = o[this.props.gindex]
        if( o.onhold ) {
            for( var i in o.onhold ) {
              if(  (o.onhold[i].symbol === this.props.obj.symbol) ) {
                match = o.onhold[i]
                break
              }
            }
        }
      }

      if( isAdd ) {
        //if( this.props.obj.unit > 0 ) {
        unit = Math.abs( this.props.obj.unit) + 1
        //} else {
        //  unit = Math.min( Math.abs( this.props.obj.unit ) + 1, Math.abs( this.props.obj.onhold )  )
        //}
        decision = Math.round(Math.abs(this.props.obj.tmp_decision)/this.props.obj.tmp_decision) * unit
      } else {
        unit = Math.max(Math.abs( this.props.obj.unit) - 1, 0)
        decision = Math.round(Math.abs(this.props.obj.tmp_decision)/this.props.obj.tmp_decision) * unit
      }

      if(  this.props.obj.action === "sell" ) {
        if(  match.unit >= unit  ) {
          this.props.updateList( this.props.gindex, this.props.index, ["unit", 'decision'], [unit,decision ], false )
        }
      } else {
        this.props.updateList( this.props.gindex, this.props.index, ["unit", 'decision'], [unit,decision ], false )
      }
    
    
    }

    render() {
      if( this.props.obj.symbol !== "cash" ) {

        var onAction = false
        var o = JSON.parse( JSON.stringify( this.props.forwardGroup ))
        o = o[this.props.gindex]
        if( this.props.type==="onhold" ) {
          if( o.action ) {
            for( var i in o.action ) {
              if(  (o.action[i].symbol === this.props.obj.symbol) ) {
                onAction = true
                break
              }
            }
          }
        }

        return (
          <div className={['trade-record', this.props.title?"tc-title":""].join(" ")} >
            <div className={['trade-list'].join(" ")}
              onClick={
                (e) => {
                  if( this.props.title ) {
                    if( this.props.index === this.props.selected ) {
                      this.props.updateValue({  selected : null })
                    } else {
                      this.props.updateValue({  selected : this.props.index })
                    }
                  }
                }
              } 
            >
              { (this.props.type==="action") &&
                <div 
                  className={[ 'box' , 'type', ,this.props.title?"":(this.props.obj.real?"real":"paper")    ].join(" ")}
                  onClick={ (e) => {
                    if( this.props.type==="action" ) {
                      if( this.props.isTLogin) {
                        var real = this.props.obj.real?this.props.obj.real:false
                        this.props.updateList( this.props.gindex, this.props.index, ["real"], [!real], false )
                      }
                    }
                  }  }
                > 
                  { !this.props.title && ( this.props.type==="action" ) &&
                    <span className={[ ].join(" ")}> { this.props.obj.real?"實":"擬" } </span>
                  }
                </div>
              }

              <div 
                className={[ 'box' , 'bs', this.props.title?"":((  (this.props.obj.action === "buy") && !onAction )?"buy":"sell"    ) ].join(" ")}
                onClick={ (e) => {
                  if( this.props.type==="action" ) {
                    if( this.props.isTLogin) {
                      //var real = this.props.obj.real?this.props.obj.real:false
                      //this.props.updateList( this.props.gindex, this.props.index, ["real"], [!real], false )
                    }
                  }
                }  }
              > 
                <span className={[  ].join(" ")}> 
                  { this.props.title?"":( !onAction?((this.props.obj.action === "buy")?"B":"S"):"PS"  ) } 
                </span>
              </div>



              <div className={[ 'box' ,'symbol'].join(" ")} > { this.props.obj.symbol  } </div>
              <div className={[ 'box'].join(" ")} > 
                <div className={[ 'holder'].join(" ")} > 
                  <span className={[ 'price', (this.props.obj.change>0)?'up':((this.props.obj.change<0)?'down':"")  ].join(" ")}>
                      <span> 
                        { 
                          this.props.title?this.props.obj.price.toLocaleString("en-US"):( this.props.obj.price.toLocaleString("en-US")) 
                        }
                      </span>
                      { ( ((this.props.type === "action") && (this.props.obj.action !== 'buy')) || (this.props.type !== "action")  ) &&
                        <span className={['smaller'].join(" ")}> 
                            {
                              (  this.props.obj.change!==null  )?("(" + (Math.round(this.props.obj.change*10000)/100).toLocaleString("en-US") + "%)"):""
                            } 
                        </span> 
                      }
                  </span>
                  { (((this.props.type === "action") && (this.props.obj.action !== 'buy')) || (this.props.type !== "action") ) &&
                    <span className={[ 'buy'].join(" ")}>
                      { 
                        this.props.title?this.props.obj.buy.toLocaleString("en-US"):( this.props.obj.buy.toLocaleString("en-US"))  
                        //this.props.title?this.props.obj.timestamp:service.formatDate(this.props.obj.timestamp)
                      } 
                    </span>
                  }
                </div>
              </div>
              <div className={[ 'box'].join(" ")} > 
                { (this.props.type === "action") && (!this.props.title) &&
                  <FontAwesomeIcon  icon={faChevronLeft} className={['arrow'].join(' ')} onClick={ (e)=>{
                    this.changeVolume(false)
                  } } />
                }
                <div className={[ 'holder'].join(" ")} > 
                  <span className={ ['total-cost'].join(" ") }>
                    {
                      this.props.title?this.props.obj.total_cost:(  (Math.round(this.props.obj.price * this.props.obj.board_lot * this.props.obj.unit * 100)/100).toLocaleString("en-US")) 
                    }
                  </span>
                  { !this.props.title &&
                    <span className={ ['board_lot'].join(" ") }>
                    { 
                      (this.props.obj.board_lot*this.props.obj.unit).toLocaleString("en-US")  
                    } 
                    </span>
                  }
                </div>
                { (this.props.type === "action") && (!this.props.title) &&
                  <FontAwesomeIcon  icon={faChevronRight} className={['arrow'].join(' ')} onClick={ (e)=>{
                    this.changeVolume(true)
                  } }/>
                }
              </div>
           
              {
                (this.props.type==="action") && 
                <div className={[ 'box', "control", this.props.title?"title":''].join(" ")} >
                  { !this.props.title && 
                    <FontAwesomeIcon  icon={faTimes} className={['icon'].join(' ')} onClick={ (e)=>{
                      //updateList( gindex ,index, type, value, isRemove)
                      this.props.updateList( this.props.gindex ,this.props.index, [], [], true)
                    } }/> 
                  }
                </div>
              }

              {
                (this.props.type==="onhold") && 
                <div className={[ 'box', "control", this.props.title?"title":''].join(" ")} onClick={ (e)=>{
                  this.props.moveToAction( this.props.gindex, this.props.obj  )
                } }>
                  { !this.props.title && 
                    <FontAwesomeIcon  icon={faCartArrowDown} className={['icon'].join(' ')} /> 
                  }
                </div>
              }

            </div>
            {
              this.props.column && !this.props.title &&
              <div className={['trade-board' , (this.props.index===this.props.selected)?"show":"hide"].join(" ")}>
                
              </div>
            }

          </div>
        )
      } else {
      return null
    }
  }
}


export default List;




/*
                { !this.props.title && ( this.props.type==="action" ) &&
                  <span className={['smaller' , this.props.obj.real?"real":"paper"].join(" ")}> { this.props.obj.real?"real":"paper" } </span>
                }
*/