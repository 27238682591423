import id from "@xmpp/id";
import { create, json, piDependencies } from "mathjs";
import { servicesVersion } from "typescript";
//import response from "./response.js"

var service = {
    LMAX : 30,
    url : "https://xmpp.garlican.com/",
    //redirect_uri : "http://demo.garlican.com/create",
    redirect_uri : "https://www.garlican.com/create",    
    token : "",
    user : {},
    score_scale : [
       { score :95, rank : "A+" },
       { score : 90, rank  : "A"},
       { score : 85, rank  : "A-"},
       { score : 80, rank  : "B+"},
       { score : 75, rank  : "B"},
       { score : 70, rank  : "B-"},
       { score : 65, rank  : "C+"},
       { score :60, rank  : "C"},
       { score :55, rank  : "C-"},
       { score :50, rank  : "D+"},
       { score :45, rank  : "D"},
       { score :40, rank  : "D-"},
       { score :35, rank  : "E+"},
       { score :30, rank  : "E"},
       { score :25, rank  : "E-"},
       { score :20, rank  : "F+"},
       { score :15, rank  : "F"},
       { score :10, rank  : "F-"},
       { score :0, rank  : "F-"}
    ],
    numberScale : [
        { key : "default", value : 1 , display : '' },
        { key : "thousand", value : 1000 , display : 'K'},
        { key : "million" , value : 1000000 , display : 'M'},
        { key : "billion" , value :1000000000 , display : ',B'},
        { key : "trillion" , value :1000000000000 ,display : 'T' },
    ],
    detect : ()=> {
        //var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        // Firefox 1.0+
        //var isFirefox = typeof InstallTrigger !== 'undefined';
        // Safari 3.0+ "[object HTMLElementConstructor]" 
        //var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
        // Internet Explorer 6-11
        //var isIE = /*@cc_on!@*/false || !!document.documentMode;
        // Edge 20+
        //var isEdge = !isIE && !!window.StyleMedia;
        // Chrome 1 - 79
        //var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        // Edge (based on chromium) detection
        //var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
        // Blink engine detection
        //var isBlink = (isChrome || isOpera) && !!window.CSS;
        var userAgent = navigator.userAgent.toLowerCase();
        var isIE = !/opera|opr\/[\d]+/.test(userAgent) && /(msie|trident)/.test(userAgent);
        return isIE
    },

    login : (username, password) =>{
        var url = service.url + "user/token"
        var body = {  username : username.toLowerCase(), password : password}
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(body)
            }
        ).then(
            (response) => response.json()
        )
    },

    refreshtoken(token) {
        var url = service.url + "user/token/refresh"
        var body = {  refresh : token }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(body)
            }
        ).then(
            (response) => response.json()
        )
    },

    register : (username, password, firstname, lastname) => {
        var url = service.url + "user/register"
        var body = {  username : username.toLowerCase(), password : password, first_name : firstname, last_name : lastname, email : username}
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(body)
            }
        ).then(
            (response) => response.json()
        )

    },

    logout : () =>{
        var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
		storage.setItem("garlican@tokens", null)
        service.user = null
        service.token = ""
        //var url = service.url + "user/logout"
        /*return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        ).then(
            (response) => response.json()
        )*/
    },

    forget : (username)=> {
        var url = service.url + "user/forgetpassword"
        var body = {  username : username.toLowerCase()}
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(body)
            }
        ).then(
            (response) => response.json()
        )
    },

    restore : ( key, username, password ) => {
        var url = service.url + "user/passwordrecovery"
        var body = {  username : username.toLowerCase(), password : password, key : key}
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(body)
            }
        ).then(
            (response) => response.json()
        )
    },

    validateEmail : (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },

    getEjabberdToken : () => {
        var url = service.url + "user/xmpp/token"
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            }
        ).then(
            (response) => response.json()
        )
    },

    getMeetingInfo: (token, name) => {
        var url = service.url + "zoom/zoom/meeting"
        var body = {  zoom_token : token, topic : name }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify(body)
            }
        ).then(
            (response) => response.json()
        )

    },

    getAccessToken: (code) => {
        var url = service.url + "zoom/zoom/token"
        var body = {  code : code, redirect_uri : service.redirect_uri }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    //'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify(body)
            }
        ).then(
            (response) => response.json()
        )

    },

    getUserProfile : (id) => {
        var str = id?parseInt(id, 10):"me"
        var url = service.url + "user/" + str
        return fetch(
            url, 
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            }
        ).then(
            (response) => response.json()
        )
    },

    postUserProfile : (first_name, last_name, desc) => {
        var obj = {}
        if(first_name) {
            obj.first_name = first_name
        }
        if(last_name) {
            obj.last_name = last_name
        }
        if(desc) {
            obj.description = desc
        } else {
            obj.description = ""
        }
        obj.email = ""

        var url = service.url + "user/me"
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify(obj)
            }
        ).then(
            (response) => response.json()
        )
    },

    postUserPicture : (image, isProfile) => {
        var str = "avatar"
        //if( isProfile ) {
        //    str = "cover"
        //}
        var url = service.url + "user/me/" + str
        const formData = new FormData()
        //obj.avator = image
        formData.append('avator', image)
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    //'Accept': 'application/json',
                    //'Content-Type': 'application/json',
                    'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization' : 'Bearer ' + service.token
                },
                body: formData
            }
        ).then(
            (response) => response.json()
        )
    },

    isOwner : ( user_id ) => {
        if(service.user) {
            return user_id === service.user.user_id
        } else {
            return false
        }
    },

    formatDate(d, hms, date) {
        d = d?d:null
        try {
            d = d.split(" ")
            var d1 = "", d2 = ""
            d[0].toString()
            if( d.length < 2 ) {
                d1 = d[0].split("-")
                d = new Date( d1[0], (parseInt(d1[1])-1).toString(), d1[2] )
            } else {
                d1 = d[0].split("-")
                d2 = d[1].split(":") 

                d = new Date( d1[0], (parseInt(d1[1])-1).toString() , d1[2], d2[0], d2[1], d2[2] )
                
            }
            if(date) {
                return d
            }
            
        }
        catch(e) {
            d = d?new Date( d ):new Date()
        }

        var month = '' + (d.getMonth() + 1  ),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = "",
            minute = "",
            second = ""
        if(hms) {
            hour = '' + d.getHours();
            minute = '' + d.getMinutes();
            second = '' + d.getSeconds();
            if (hour.length < 2) 
                hour = '0' + hour;
            if (minute .length < 2) 
                minute  = '0' + minute ;
            if (second  .length < 2) 
                second  = '0' + second  ;
        }
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        
        
        if( hms) {
            var st1 = [year, month, day].join('-');
            var st2 = [hour, minute, second].join(':');
            return st1 + " " + st2
        } else {
            
            return [year, month, day].join('-');
        }
    },

    checkSystemLanguage() {
        var language = window.navigator.userLanguage || window.navigator.language;
        var _lang = 'eng'
        if ('language' in window) {
          if(  window['languages'].length > 0 ) {
            _lang = window['languages'][0].key
          }
          
        }
        if( language ) {
          if( language.indexOf("-CN") > -1 ) {
            return "zhcn"
          } else if( language.indexOf("zh") > -1 ) {
            return "zhtc"
          } else {
            return _lang
          }
        } else {
          return _lang
        }
    },

    getPortfoilioList : (obj) => {
        obj = ( (obj===undefined) || (obj===null))?{}:obj
        //GET /trading/portfolio?type=<latest/bookmark/>&page=<0>&user_id=<id>&search=<criteria>
        var arr = []
        var keys = ['bookmarked','type', 'user_id', 'page', 'access_mode', 'order_by', 'initial_capital_min', 'initial_capital_max', 'win_rate_min', 'win_rate_max', 'sharpe_ratio_min', 'sharpe_ratio_max', 'profit_rate_min', 'profit_rate_max', 'turnover_rate_min' , 'turnover_rate_max' ]
        for (var k in keys) {
            if( keys[k] in obj ) {
                //arr.push( ( k  + '=' + keys[k]) )
                arr.push( ( keys[k]  + '=' + obj[ keys[k] ] ) )
            }
        }
        
        var str = ( arr.length > 0 )?( "?"+arr.join("&") ):""
        var url = service.url + "trading/portfolios" + str
        var header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        //var isOwner = service.isOwner( obj.user.user_id   )

        //if( isOwner && 'user_id' in obj ) {
            if( 'token' in service ) {
                if(service.token) {
                    header['Authorization'] = ('Bearer ' + service.token)
                }
            }
        //}
        return fetch(
            url, 
            {
                method: 'GET',
                headers: header,
            }
        ).then(
           (res) => {
                return res.json()
           }
        )

    },
    getPortfoilioById : (id) => {
        //GET /trading/portfolio?type=<latest/bookmark/>&page=<0>&user_id=<id>&search=<criteria>
        var url = service.url + "trading/portfolio/" + id
        //var url = ''
        var header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'Authorization' : 'Bearer ' + service.token?service.token:null
            //'Authorization' : 'Bearer ' + service.token
        }

        if ( service.token !== "" ) {
            header['Authorization'] = 'Bearer ' + service.token
        }

        return fetch(
            url, 
            {
                method: 'GET',
                headers: header,
            }
        ).then(
           (response) => {
               return response.json()
           }
        )

    },

    getActivitiesByPortfolioId : (id)=> {     
    },

    createPortfolio : (obj) => {
        obj['initial_capital'] = parseFloat( obj['initial_capital'] )
        obj['transaction_fee'] = parseFloat( obj['transaction_fee'] )

        var keys = [ 
            'access_mode', 'description', 'initial_capital', 'input', 'name',
            'partition', 'transaction_fee'
        ]
        var _obj = {}
        for (var i in keys) {
            if( keys[i] in obj )
                _obj[  keys[i]  ] = obj[ keys[i] ]
        }
        obj = _obj
    
        var aurl =  service.url + "trading/portfolio"
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) => res.json()
        )

    },
    
    updatePortfolio : (pid, obj) => {
        obj['initial_capital'] = parseFloat( obj['initial_capital'] )
        obj['transaction_fee'] = parseFloat( obj['transaction_fee'] )

        var keys = [ 
            'access_mode', 'description', 'initial_capital', 'input', 'name',
            'partition', 'profit_rate_rank', 'sharpe_rate_rank' , 'total_portfolios',
            'transaction_fee',  'turnover_rate_rank', 'win_rate_rank' 
        ]
        var _obj = {}
        for (var i in keys) {
            if( obj[ keys[i] ] !== null )
                _obj[  keys[i]  ] = obj[ keys[i] ]
        }
        obj = _obj
        var aurl =  service.url + "trading/portfolio/" + pid
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) => res.json()
        )

    },

    deletePortfolio : (id) => {
        var aurl =  service.url + "trading/portfolio/" + id
        return fetch(
            aurl,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) => res.json()
        )
    },

    createStrategy: (obj) =>{
        var aurl =  service.url + "trading/strategy"
        var keys = [ 
            'name', 'description', 'code', 'portfolio_id', 'filters' , 'strategy_template_id'
        ]
        var _obj = {}
        for (var i in keys) {
            if( (keys[i] in obj) && (  obj[ keys[i] ] !== null ) ) {
                _obj[  keys[i]  ] = obj[ keys[i] ]
            }
        }
        obj = _obj
        //console.log(_obj)
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    updateStrategy: (sid, obj)=> {
        var aurl =  service.url + "trading/strategy/" + sid

        var keys = [ 
            'name', 'description', 'code', 'filters' , 'strategy_template_id'
        ]
        var _obj = {}
        for (var i in keys) {
            if( obj[ keys[i] ] != null ) {
                _obj[  keys[i]  ] = obj[ keys[i] ]
            }
        }
        obj = _obj

        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    deleteStrategy: (sid) => {
        var aurl =  service.url + "trading/strategy/" + sid
        return fetch(
            aurl,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    getStrategyById: ( sid ) => {
        var aurl =  service.url + "trading/strategy/" + sid
        return fetch(
            aurl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    createReport: (pid, obj) => {
        var aurl =  service.url + "trading/portfolio/"+pid+"/report"
        var keys = [ 
            'initial_capital', 'report_type', 'trade_list', 'transaction_fee'
        ]
        var key2 = [ 
            "symbol",
            "decision",
            "date",
            "price",
            "group",
            "profit",
            "boardlot"
        ]
        var _obj = {}
        for (var i in keys) {
            _obj[  keys[i]  ] = obj[ keys[i] ]
            if(  keys[i] === 'trade_list' ) {
                for (var j in obj[ keys[i] ] ) {
                    var _o = {}
                    for (var k in key2) {
                        _o[ key2[k] ] = obj[ keys[i] ][j][  key2[k] ]
                    }
                    _obj[  keys[i]  ][  j  ] = _o
                }
            }
        }
        obj = _obj

        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    getReport: (pid, rid, forward) => {
        var str = forward?"":"?include_trade_list=true"
        var aurl =  service.url + "trading/portfolio/"+pid+"/report/"+rid+str
        return fetch(
            aurl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                //body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    createBookmark: (pid) => {
        var aurl =  service.url + "trading/portfolio/"+pid+"/bookmark"
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    removeBookmark: (pid) => {
        var aurl =  service.url + "trading/portfolio/"+pid+"/bookmark"
        return fetch(
            aurl,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    createTemplate: (obj) => {

        var keys = [ 
            'access_mode', 'code', 'description', 'filters', 'input', 'name'
        ]
        var _obj = {}
        for (var i in keys) {
            _obj[  keys[i]  ] = obj[ keys[i] ]
        }
        obj = _obj

        var aurl =  service.url +"trading/strategy_template"
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    updateTemplate : (id, obj) => {

        var keys = [ 
            'access_mode', 'code', 'description', 'filters', 'input', 'name'
        ]
        var _obj = {}
        for (var i in keys) {
            if( obj[ keys[i] ] !== null )
                _obj[  keys[i]  ] = obj[ keys[i] ]
        }
        obj = _obj

        var aurl =  service.url +"trading/strategy_template/" + id
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    deleteTemplate : (st_id) => {
        /*
{
    "name": "",
    "description": "",
    "code": "",
    "filters": [{}] // json array
}
        */

        var aurl =  service.url +"trading/strategy_template/" + st_id
        return fetch(
            aurl,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    getTemplateById : (st_id) => {
        /*
{
    "name": "",
    "description": "",
    "code": "",
    "filters": [{}] // json array
}
        */

        var aurl = service.url +"trading/strategy_template/" + st_id
        return fetch(
            aurl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    getTemplates : (obj) => {
        //&user_id=(number)&search=(string)&page=(number)
        var keys = [ "user_id", "search", "page" ]
        var str = "", arr = []
        if(obj) {
            for (var k in keys) {
                if( keys[k] in obj ) {
                    arr.push( (keys[k] + "=" + obj[keys[k]]) )
                }
            }
        }
        str = (arr.length>0)?("?"+ arr.join("&") ):""

        var aurl =  service.url +"trading/strategy_templates" + str
        return fetch(
            aurl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    updateTemplateById : (st_id, obj) => {
        var keys = [ 
            'access_mode', 'code', 'description', 'filters', 'input', 'name'
        ]
        var _obj = {}
        for (var i in keys) {
            if( obj[ keys[i] ] !== null )
                _obj[  keys[i]  ] = obj[ keys[i] ]
        }
        obj = _obj
        var aurl = service.url +"trading/strategy_template/" + st_id
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },
////NEW
    getAssetList : (obj) => {
        var str = []
        if( obj ) {
            if( 'asset_type' in obj ) {
                str.push( ("asset_type=" + obj['asset_type'])   )
            }
            if( 'portfolio_id' in obj ) {
                str.push( ("portfolio_id=" + obj['portfolio_id'])   )
            }
            if( 'strategy_id' in obj ) {
                str.push( ("strategy_id=" + obj['strategy_id'])   )
            }
        }
        str = str.join("&")
        var aurl = service.url +"trading/assets" + ((str.length>0)?("?" + str):"")
        return fetch(
            aurl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                //body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    createTradeOrder : (obj) => {
        var keys = [ 
            'action', 'asset_type', 'portfolio_id', 'price', 'quantity', 'strategy_id' , 'symbol'
        ]
        var _obj = {}
        for (var i in keys) {
            if( obj[ keys[i] ] !== null )
                _obj[  keys[i]  ] = obj[ keys[i] ]
        }
        obj = _obj
        var aurl = service.url +"trading/order"
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    createTradeOrders : (obj) => {
        /*var keys = [ 
            'action', 'asset_type', 'portfolio_id', 'price', 'quantity', 'strategy_id' , 'symbol'
        ]
        var _obj = {}
        for (var i in keys) {
            if( obj[ keys[i] ] !== null )
                _obj[  keys[i]  ] = obj[ keys[i] ]
        }
        obj = _obj*/
        var aurl = service.url +"trading/orders"
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    getTradeById : (order_id) => {
        var aurl = service.url +"trading/order/" + order_id
        return fetch(
            aurl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    updateTradeOrderById : (order_id, obj) => {
        var keys = [ 
            'order_number'
        ]
        var _obj = {}
        for (var i in keys) {
            if( obj[ keys[i] ] !== null )
                _obj[  keys[i]  ] = obj[ keys[i] ]
        }
        obj = _obj 
        var aurl = service.url +"trading/order/" + order_id
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
            
        ).then(
            (res) =>  res.json()
        )
    },

    getRecentTradeList: (obj) => {
        var str = []
        if( obj ) {
            str.push(  "order_by=creation_time"   )
            if( 'user_id' in obj ) {
                str.push( ("user_id=" + obj['user_id'])   )
            }
            if( 'portfolio_id' in obj ) {
                str.push( ("portfolio_id=" + obj['portfolio_id'])   )
            }
            if( 'strategy_id' in obj ) {
                str.push( ("strategy_id=" + obj['strategy_id'])   )
            }
            if( 'page' in obj ) {
                str.push( ("page=" + obj['page'])   )
            }
            if( 'action' in obj ) {
                str.push( ("action=" + obj['action'])   )
            }
            if( 'bookmarked' in obj ) {
                str.push( ("bookmarked=" + obj['bookmarked'])   )
            }
        }
        str = str.join("&")
        var aurl =  (service.url + "trading/orders" + ((str.length>0)?("?" + str):"") )
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (service.token) {
            headers['Authorization'] = 'Bearer ' + service.token
        }
        return fetch(
            aurl,
            {
                method: 'GET',
                headers: headers,
            },
        ).then(
            (res) =>  res.json()
        )
    },

    rebuildAsset:() => {
        var aurl =  (service.url + "trading/assets/rebuild"  )
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (service.token) {
            headers['Authorization'] = 'Bearer ' + service.token
        }
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: headers,
            },
        ).then(
            (res) =>  res.json()
        )
    },

////NEW
    getNumberFormat : (value) => {
        value = parseFloat( value, 10 )
        var keys = JSON.parse( JSON.stringify( service.numberScale ) )
        keys.reverse()
        var str = value
        for (var i in keys ) {
          if(  (value/keys[i].value) >= 1 ) {
            str = (Math.round(value * 100/keys[i].value)/100) + keys[i].display
            break
          }
        }
        return str
    },

    encode : (str) => {
        return encodeURIComponent(str)
    },

    decode : (str) => {
        return decodeURIComponent(str)
    },

   
    
}


export default service