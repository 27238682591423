import React, { Component } from 'react';
import './index.scss';
import gglobal from '../GarlicanLibrary/gvars'
import GService from '../GarlicanLibrary/gservice';
import service from '../../public/service'

import apis from '../GarlicanLibrary/apis'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faSearch, faTimesCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Modal, Card, Badge, Accordion, Tabs, Tab, Button, Dropdown, DropdownButton, ListGroup, OverlayTrigger, Popover, FormControl,InputGroup, Overlay, Tooltip  } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
//import gcolor from '../../public/style.scss';
import GarlicanIDE from '../GarlicanIDE'

import GarlicanIDEPop from '../GarlicanIDEPop'

import GarlicanPicker from '../GarlicanPicker'
import GarlicanStrategy from '../GarlicanStrategy'
import GarlicanStrategyUploader from '../GarlicanStrategyUploader'


import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"
import Apis from '../GarlicanLibrary/apis';


const menu1 = [
	{ key : 'general', value : 'general'},
	{ key : 'filter' , value : 'filter' },
	{ key : 'buy' , value : 'buy' },
	{ key : 'sell' , value : 'sell' },
]

const menu2 = [
	{ key : 'general', value : 'general'},
	{ key : 'filter' , value : 'filter' },
	{ key : 'buy' , value : 'buy' },
	{ key : 'sell' , value : 'sell' },
	{ key : 'contribute' , value : 'contribute' },
]

const menu3 = [
	{ key : 'general', value : 'general'},
]


const stringConfig = {
	'number' : [
		{ 'key' : 'gt' , 'value' : '>',  'type' : 'number'},
		//{ 'key' : 'eq' , 'value' : '=',  'type' : 'number' },
		{ 'key' : 'lt' , 'value' : '<',  'type' : 'number'},
		{ 'key' : 'ge' , 'value' : '>=', 'type' : 'number'},
		{ 'key' : 'le' , 'value' : '<=', 'type' : 'number'},
		//{ 'key' : 'ne' , 'value' : '!=', 'type' : 'number'},
		{ 'key' : 'btw' , 'value' : 'btw', 'type' : 'array'},
	],
	'string' : [
		//{ 'key' : 'eq' , 'value' : '=' , 'type' : 'string'},
		{ 'key' : 'in' , 'value' : 'in', 'type' : 'array'},
		{ 'key' : 'nin' , 'value' : 'nin', 'type' : 'array'},
		
	]
}

const numberScale = [
	{ key : "default", value : 1 , display : '' },
	{ key : "thousand", value : 1000 , display : ',000'},
	{ key : "million" , value : 1000000 , display : ',000,000'},
	{ key : "billion" , value :1000000000 , display : ',000,000,000'},
	{ key : "trillion" , value :1000000000000 ,display : ',000,000,000,000' },
]


class GarlicanBoard extends Component {
  	
  	constructor(props) {
  		super(props);

  		this.state = {
			menu : menu1,
			selected : menu1[0].key,
			fconfig : [],
			tempfconfig:[],
			currentType : 'advance',
			selectedCat : null, 
			filters : ('filters' in props.selectedStrategy)?props.selectedStrategy.filters:[],
			id : ('id' in props.selectedStrategy)?props.selectedStrategy.id:-1,
			group : JSON.parse(  JSON.stringify(props.group)),
			showFilters : false,
			showBuyTemplate : false,
			showSellTemplate : false,
			showSellSTemplate : false,
			showBuySTemplate : false,
			hasUpdate : false,
			selectedDisplayFilter : null,
			serachFilter : "",
			isIDEShow : false,
			isTemplateOwner : false,
			usedTemplate : null,
		}

		this.editorB = React.createRef()
		this.editorS = React.createRef()
		this.templates = React.createRef()
		this.hideAll = this.hideAll.bind(this)
		this.hideFilter = this.hideFilter.bind(this)
		this.setMultiSelections = this.setMultiSelections.bind(this)
		this.removeFilter = this.removeFilter.bind(this)
		this.updateValue = this.updateValue.bind(this)
		this.validFilter = this.validFilter.bind(this)
		this.createFilterConfig = this.createFilterConfig.bind(this)
		this.search = this.search.bind(this)
		this.reset = this.reset.bind(this)
		this.updateTemplateChecker = this.updateTemplateChecker.bind(this)
  	}

	componentWillMount() {
		this.updateTemplateChecker(this.props)
	}

	componentDidMount() {
		//GService.gi18n = this.props.gi18n
		this.createFilterConfig( this.props.filterconfig )
	}

	componentWillReceiveProps(props) {
		if ( 
				(this.state.hasUpdate) ||
				(this.state.currentType !== props.type) || 
				(this.state.id !== props.selectedStrategy.id) ||
				( JSON.stringify(this.state.group) !== JSON.stringify( props.group ) )
		 ) {
			var obj = {
				currentType : props.type,
				filters : ('filters' in props.selectedStrategy)?props.selectedStrategy.filters:[],
				id : ('id' in props.selectedStrategy)?props.selectedStrategy.id:-1,
				group : JSON.parse(  JSON.stringify(props.group)),
			}
			this.setState(obj)
			this.updateTemplateChecker(props)
		}
	}

	updateTemplateChecker(props) {
		if(   props.selectedStrategy.strategy_template_id  ) {
			//console.log("a")
			service.getTemplateById(  props.selectedStrategy.strategy_template_id  ).then(
				(res) => {
					if(   props.selectedStrategy.user_id ===  res.user_id   ) {
							var stra = JSON.parse(  JSON.stringify( props.selectedStrategy)  )
							//console.log(  this.props.selectedStrategy )
							var obj = {
								isTemplateOwner : true, 
								menu : menu2,
								usedTemplate : res,
								filters : ('filters' in stra)?stra.filters:[],
								id : ('id' in stra)?stra.id:-1,
								group : JSON.parse(  JSON.stringify( props.group)),
							}
							this.setState(obj)
							//}
					} else {
						//console.log( res )
						var obj = {  isTemplateOwner : false, menu : menu3, usedTemplate : res  }
						this.setState(obj)
					}
				}
			)

		} else {
			var obj = {  isTemplateOwner : false, menu : menu2  }
			this.setState(obj)
		}
	}

	createFilterConfig( c ) {
		if( this.state.fconfig.length === 0 ) {
			var arr = [], keys = Object.keys(c)
			for (var k in c) {
				if( (k !== 'date') && (k !== 'data') ) {
					var obj = c[k]
					obj.name = k
					arr.push( obj )
				}
			}
			this.setState({
				fconfig : arr,
				tempfconfig : arr
			})
		}
	}

	addFilter(parent,table, key, v) {
		var obj = {}
		if(  table !== 'history' ) {
			obj['key'] = table
		} else {
			obj['key'] = 'symbol'
		}
		obj['type'] = key
		obj['opr'] = stringConfig[  v['vtype']  ][0].value
		if(  v['vtype'] === 'string' ) {
			if( typeof(v['value'][0]) === 'object'  ) {
				obj['value'] = v['value'][0].value
			} else {
				obj['value'] = v['value'][0]
			}
			
		} else if(  v['vtype'] === 'number' ) {
			if( typeof(v['value'][0]) === 'object'  ) {
				obj['value'] = (v['value'][0].value[0] + v['value'][0].value[1] )/2
			} else {
				obj['value'] = (v['value'][0] + v['value'][1])/2
			}			
		}
		if( parent ) {
			obj['parent'] = parent
		}

		var fil = this.state.filters
		fil.push(obj)
		var s = this.props.group
		for( var i in s.strategy_list) {
			if( s.strategy_list[i].id === this.state.id ) {
				this.props.editStrategy( i, 'filters', fil )
				break;
			}
		}
		/*apis.getsymbolfromfilter( fil ).then(
			(res) => {
				console.log(res)
			}
		)*/
	}

	getFilterParameters( f ) {
		var v = {}
		if( f.parent ) {
			var o = {}
			if( f.parent === 'finstat' ) {
				o = this.props.filterconfig[ f.parent+ "_" + f.key ].value
			} else {
				o = this.props.filterconfig[ f.parent ].value
			}
			for (var i in o) {
				if( o[i].key === f.type ) {
					v = o[i]
					if( v.vtype === "number" ) {
						v.numberScale = numberScale
						v.selectedNumberScale = numberScale[1]
					}
					break
				}
			}
		} else {
			if( f.key === 'history' ) {
				v = this.props.filterconfig['symbol']
			} else {
				v = this.props.filterconfig[f.key]
			}
			if( v.vtype === "number" ) {
				v.numberScale = numberScale
				v.selectedNumberScale = numberScale[1]
			}
		}
		//console.log(v)
		return v
	}

	updateFilter(index, type, value, f) {
		var fil = JSON.parse(JSON.stringify(this.state.filters))
		var _this = this, original = {}
		function _value(filter, idx, val ) {
			var f = _this.getFilterParameters(filter[idx])
			if( f.vtype === 'string' ) {
				if( (filter[idx].opr !== 'in') &&  (filter[idx].opr !== 'nin') ) {
					if( val.length > 0 ) {
						filter[idx]['value'] = val[0].key
					} else {
						filter[idx]['value'] = ''
					}
				} else {
					var arr = []	
					for (var i in val) {
						if( (val[i].key) ) {
							arr.push( val[i].key )
						}
					}
					filter[idx]['value'] = arr.join(",")
				}
			} else {
				//console.log(val)
				filter[idx]['value'] = val
			}
			return filter
		}

		if( type === 'value' ) {
			fil = _value(fil, index, value )
		} else {
			fil[index][type] = value
			var _f = _this.getFilterParameters(fil[index])
			if( _f.vtype === 'string' ) {
				if( ( f.value.indexOf(",") === 0 ) && (type === 'opr') && ( (value === "in") || (value === "nin") ) ) {
					fil = _value(fil, index, [{ key : f.value }] )
				} else {
					var arr = []
					var fvalue = f.value.split(",")
					for (var j in fvalue) {
						arr.push( {key : fvalue[j] } )
					}
					fil = _value(fil, index, arr )
				}
			} else {

				if( (type === 'opr') && (value === "btw")  ) {
					fil[index]['value'] = [fil[index].value, _f.value[1]].join( ',')
				} else if(type === 'selectedNumberScale') {
					fil[index][type] = value
				} else if( fil[index].value.toString().indexOf(",") > -1 ) {
					fil[index]['value'] = fil[index].value.split(",")[0]
				} else {
					//console.log( value )
				}


			}

			

		}
		var s = this.props.group
		for( var i in s.strategy_list) {
			if( s.strategy_list[i].id === this.state.id ) {
				this.props.editStrategy( i, 'filters', fil )
				break;
			}
		}
		this.setState({hasUpdate : true})
	}

	hideFilter() {
		this.setState( { 
			showFilters : false, 
			selectedFilter : -1,
			//selected : menu[0].key,
			//filters : [],
			/*id : -1,
			group : {},
			currentType : 'basic',
			selectedCat : null, 
			showFilters : false,
			showBuySTemplate : false,
			showBuySTemplate : false,
			showBuyTemplate : false,
			showSellTemplate : false,
			selectedDisplayFilter : null,
			tempfconfig : this.state.fconfig,*/
		} )
	}

	hideAll() {
		this.setState( { 
			showFilters : false, 
			selectedFilter : -1,
			selected : menu1[0].key,
			filters : [],
			id : -1,
			group : {},
			//currentType : 'general',
			currentType : 'advance',
			selectedCat : null, 
			showFilters : false,
			showBuySTemplate : false,
			showBuySTemplate : false,
			showBuyTemplate : false,
			showSellTemplate : false,
			selectedDisplayFilter : null,
			tempfconfig : this.state.fconfig,
		} )
		if( this.editorB.current ) {
			this.editorB.current.hideAll()
		}
		if( this.editorS.current ) {
			this.editorS.current.hideAll()
		}

		if( this.templates.current ) {
			this.templates.current.hideAll()
		}

		//if( (this.state.selected === 'buy') || (this.state.selected === 'sell')  ) {
		if (this.state.selected === 'buy') {
			if( this.editorB.current ) {
				this.editorB.current.updateCode(null, null)
			}
		} else {
			if( this.editorS.current ) {
				this.editorS.current.updateCode(null, null)
			}
		}
		//}

	}

	setMultiSelections(e, k, f) {
		this.updateFilter(k, 'value', e, f)
	}

	validFilter() {
		this.props.updateValue( { showLoading : true })
		apis.getsymbolfromfilter(this.state.filters).then(
			(res) => {
				//console.log( typeof(res) )
				if( typeof(res) === 'object' ) {
					var uniques = res['symbol']
					this.props.updateValue({
						showLoading : false,
						showAlert : true,
						showAlertBtn : false,
						alertMsg :  (this.props.language in this.props.gi18n)?this.props.gi18n[ this.props.language ]['success_filter'].replace("<count>", uniques.length):"<count> symbols are found.".replace("<count>", uniques.length) ,
					})
				} else {
					this.props.updateValue({
						showLoading : false,
						showAlert : true,
						showAlertBtn : false,
						alertMsg :  (this.props.language in this.props.gi18n)?this.props.gi18n[ this.props.language ]['error_filter']:"No symbol is found in these criteria." ,
					})
				}
			}
		).catch(
			(e)=> {
				this.props.updateValue({
					showLoading : false,
					showAlert : true,
					showAlertBtn : false,
					alertMsg : (this.props.language in this.props.gi18n)?this.props.gi18n[ this.props.language ]['error_filter']:"No symbol is found in these criteria." ,
				})
			}
		)
	}

	removeFilter(idx) {
		var fil = this.state.filters
		fil.splice( idx, 1 )
		var s = this.props.group
		for( var i in s.strategy_list) {
			if( s.strategy_list[i].id === this.state.id ) {
				this.props.editStrategy( i, 'filters', fil )
				break;
			}
		}
	}

	updateValue(obj) {
		//console.log(obj)
		this.setState( obj )
	}

	search(str){
		console.log(str)
		var t = JSON.parse( JSON.stringify( this.state.fconfig ) )
		var match = ""
		var _arr = []
		for (var i in t) {
			var valid = false
			var _tarr = []
			if( 'value' in t[i] ) {
				for (var j in t[i].value) {
					try {
						if ( 'lang' in t[i].value[j] ) {
							match = Object.values( t[i].value[j].lang )
							match = "|"+match.join("|") + "|"
							console.log( match + "|" + str )
							if ( match.toLowerCase().indexOf(str.toLowerCase()) > -1 ) {
								_tarr.push( t[i].value[j] )
								console.log( t[i] )
								valid = true
							}
						}
					}
					catch(e) {
						
					}
				}
			}
			if ( 'lang' in t[i] ) {
				match = Object.values( t[i].lang )
				match = "|"+match.join("|") + "|"
				//console.log( match + "|" + str )
				if ( match.toLowerCase().indexOf(str.toLowerCase()) > -1 ) {
					if(valid) {
						t[i].value = _tarr
					}
					_arr.push( t[i] )
				} else {
					if(valid) {
						t[i].value = _tarr
						_arr.push( t[i] )
					}
				}
			} else {
				if(valid) {
					t[i].value = _tarr
					_arr.push( t[i] )
				}
			}
		}
		this.setState({
			tempfconfig : _arr
		})
	}

	reset() {
		this.setState({
			serachFilter : "",
			tempfconfig : this.state.fconfig
		})
	}

  	render() {
  		return (
			<div className={ ['Garlican-Board'].join(' ') } >
				
				<Tabs 
					activeKey={   this.state.selected   }
					onSelect={(k) => {
						if(this.props.hasError === false) {
							var obj = { 
								showBuyTemplate : false,
								showSellTemplate : false,
								showSellSTemplate : false,
								showBuySTemplate : false,
								showFilters : false,
							}
							obj['selected'] = k
							this.setState(obj)  
							if( this.editorB.current ) {
								this.editorB.current.hideAll()
								if (this.state.selected === 'buy') {
									this.editorB.current.updateCode()
								}
							}
							if( this.editorS.current ) {
								this.editorS.current.hideAll()
								if (this.state.selected === 'sell') {
									this.editorS.current.updateCode()
								}
							}
						} else {
							
							if(  (k === "buy")  ||  (k === "sell") ) {
								var obj = { 
									showBuyTemplate : false,
									showSellTemplate : false,
									showSellSTemplate : false,
									showBuySTemplate : false,
									showFilters : false,
								}
								obj['selected'] = k
								this.setState(obj)
							}
							/*
							obj['selected'] = k
							this.setState(obj)
							*/
						}
					}}
					className={ ['Garlican-Board-Accordion-Menu'].join(' ') } >

					{ this.state.menu.map( (v,k) => {
						return (
							<Tab 
								eventKey={v.key} title={ (k+1) + "." + this.props.gi18n[this.props.language][v.value] }
								className={ [ 'Garlican-Board-Accordion-Holder'   ].join(' ') } key={ v.key} >
								<div className={ ['Garlican-Board-Accordion-Content'].join(' ') } >
									<h1>{ this.props.gi18n[this.props.language][v.value] }</h1>
									<div className={ ['Garlican-Board-Accordion-Content-Box'].join(' ') }>

										{  (v.key === 'general' ) &&
											<GarlicanStrategy {...this.props} parentType={this.state.currentType} currentType={this.state.selected} updateValue={this.updateValue} updateParentValue={this.props.updateValue} obj={this.state.group} id={this.state.id} ref={this.templates} reload={this.props.reload} addStrategy={ this.props.addStrategy} isTemplateOwner={this.state.isTemplateOwner} convertToTemplate={this.props.convertToTemplate} usedTemplate={this.state.usedTemplate} /> 
										}

										{ (v.key.indexOf('filter') > -1 ) &&
											<div className={ ['Garlican-Board-Accordion-Content-Controls'].join(' ') }>
												<Button className={['valid'].join(' ')} onClick={
													(e)=>this.validFilter()
												}>{  this.props.gi18n[this.props.language]['check_filter']  }</Button>

												<Button variant="secondary" onClick={
														(e)=>{
															this.setState({ showFilters : !this.state.showFilters })
														}
												}>{  (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['filter']:'' }</Button>
											</div>
										}

										{ (v.key.indexOf('filter') > -1 ) &&
											<div className={['Garlican-Board-Filter-Container' , this.state.showFilters?"show":"hide"].join(' ')}>
												
												<InputGroup className={["Garlican-Board-Filter-Search", "mb-3"].join(' ')}>
													<FormControl
													placeholder={ this.props.gi18n[this.props.language]['search_filter'] }
													aria-label={ this.props.gi18n[this.props.language]['search_filter'] }
													aria-describedby="basic-addon2"
													value={ this.state.serachFilter }
													onChange={ (e)=>{
														if( e.target.value.length === 0 ) {
															this.reset()
														} else {
															this.search( e.target.value )
														}
														this.setState({
															serachFilter : e.target.value
														})
													}}
													/>
													<Button variant="outline-secondary" 
														className={["fa", 'reset'].join(' ')}
														onClick={
															(e) => {
																this.reset(  )
															}
														}>
														{ this.props.gi18n[this.props.language]['reset_filter'] }
													</Button>
													<Button variant="outline-secondary" 
														onClick={
															(e) => {
																this.search( this.state.serachFilter )
															}
														}>
														<FontAwesomeIcon 
															className={["fa"].join(' ')}
															icon={ faSearch } 
														/>
													</Button>
												</InputGroup>

													{
														this.state.tempfconfig.map( (v1,k1) => {
															var count = 0
															for (var i in this.state.filters) {
																if(  (this.state.filters[i].key === v1.key) || (this.state.filters[i].key === v1.table) || ( (this.state.filters[i].key==='symbol') && (v1.table==='history' )  ) ) {
																	count += 1
																}
															}
															if( v1.type === 'object' ) {
																return(
																	<div
																		onClick={ (e)=>{
																			var val = null
																			if(  (this.state.selectedCat !== v1.key) && (this.state.selectedCat!==null) ) {
																				val = v1.key
																			} else if ( this.state.selectedCat===null ) {
																				val = v1.key
																			}
																			this.setState({
																				selectedCat : val
																			})
																			
																		} }
																		className={["Garlican-Board-Accordion-Content-Holder"].join(' ')} key={k1}  >
																		<header onClick={
																			(e)=>{
																				if( this.state.selectedDisplayFilter !== k1 ) {
																					this.setState({
																						selectedDisplayFilter : k1
																					})
																				} else {
																					this.setState({
																						selectedDisplayFilter : null
																					})
																				}
																			}
																		}>	<span>{ (('lang' in v1)?v1.lang[this.props.language]:v1.name)  + "[" + v1.value.length + "]"+ " (" + count +")" }</span>
																			<span>
																				<FontAwesomeIcon 
																					className={["Garlican-Board-Accordion-Content-Fa"].join(' ')}
																					icon={ (this.state.selectedDisplayFilter===k1)?faChevronUp:faChevronDown } 
																				/>
																			</span>
																		</header>
																		<div className={["Garlican-Board-Accordion-Content-Container", (this.state.selectedDisplayFilter===k1)?"cshow":"chide"].join(' ')}>
																			{
																				v1.value.map( (v2, k3) => {
																					return(
																						<Badge pill
																							className={["Garlican-Board-Accordion-Content-Badge"].join(' ')}
																							onClick={ (e)=> {
																								this.hideFilter()
																								this.addFilter( v1.parent, v1.key, v2.key, v2  )
																							} }
																							key={k3 +"_" + 0} >
																							{  ('lang' in v2)?(v2['lang'][this.props.language]):v2.key  }
																						</Badge>
																					)
																				})
																			}
																		</div>
																	</div>
																)		
															} else {	
																return (
																	<div
																		onClick={ (e)=>{
																			var val = null
																			if(  (this.state.selectedCat !== v1.table) || (this.state.selectedCat===null ) ) {
																				val = v1.table		
																			}																				
																			this.setState({
																				selectedCat : val
																			})
																		} }
																		className={["Garlican-Board-Accordion-Content-Holder"].join(' ')} 
																		key={k1} >
																		<header
																			onClick={ (e)=> {
																				this.hideFilter()
																				this.addFilter( null, v1.table, v1.type, v1  )
																			} } >
																				{  (('lang' in v1)?v1.lang[this.props.language]:v1.name) + " (" + count + ")" }
																		</header>
																	</div>
																)
															}
														} )																
													}
											</div>
										}


										{ (v.key.indexOf('filter') > -1 ) &&
											<h4 className={['Garlican-Board-Accordion-Content-Header'].join(' ')}> { (this.props.language in this.props.gi18n)?(this.props.gi18n[this.props.language]['selected_filters'].replace('<NUM>', this.state.filters.length )):''  } </h4>
										}

										{ (v.key.indexOf('filter') > -1 ) &&
											<ListGroup className={ ['Garlican-Board-Accordion-Content-Filters'].join(' ') }>
												{
													this.state.filters.map( (v,k) => {
														var pname = "", ptype = ""
														if( v.parent ) {
															if( v.parent === 'finstat' ) {
																pname = this.props.filterconfig[ v.parent + "_" + v.key ]['lang'][this.props.language]
																ptype = this.props.filterconfig[ v.parent + "_" + v.key ]['type']
															} else {
																pname = this.props.filterconfig[ v.parent ]['lang'][this.props.language]
																ptype = this.props.filterconfig[ v.parent ]['type']
															}
														}
														v = JSON.parse(JSON.stringify(v))
														var f = this.getFilterParameters(v)
														var oprs = stringConfig[f.vtype]

														var values = [], sobj=[]
														if( f.vtype === 'string' ) {
															for (var idx in f.value) {
																if( typeof(f.value[idx]) === 'object' ) {
																	if ('lang' in f.value[idx]) {
																		f.value[idx].name = f.value[idx].lang[this.props.language]
																		values.push(f.value[idx])
																	} 
																	if( v.value.indexOf(",") > -1 ) {
																		var str = "|" + v.value.split(",").join("|") + "|"
																		if( str.indexOf( "|" + f.value[idx].key + "|" ) > -1 ) {
																			sobj.push(f.value[idx])
																		}
																	} else {
																		if( f.value[idx].key === v.value ) {
																			sobj.push(f.value[idx])
																		}
																	}
																} else {
																	values.push( {key :  f.value[idx] , name : f.value[idx], value : f.value[idx] } )
																	if( v.value.indexOf(",") > -1 ) {
																		var str = "|" + v.value.split(",").join("|") + "|"
																		if( str.indexOf( "|" +f.value[idx] + "|" ) > -1 ) {
																			sobj.push({key :  f.value[idx] , name : f.value[idx], value : f.value[idx] })
																		}
																	} else {
																		if( f.value[idx] === v.value ) {	
																			sobj.push({key :  f.value[idx] , name : f.value[idx], value : f.value[idx] })
																		}
																	}
																}
															}
														} else {
															sobj = v.value.toString().split(",")
														}

														

														return(
															<ListGroup.Item 
																key={k} 
																className={ ['Garlican-Board-Accordion-Content-Filters-List'].join(' ') }> 
																	<InputGroup >
																		<InputGroup.Prepend>
																			{ v.parent &&
																				<InputGroup.Text>{ pname }</InputGroup.Text>
																			}
																			<InputGroup.Text>{f.lang[this.props.language]}</InputGroup.Text>
																			<DropdownButton
																				as={InputGroup.Prepend}
																				key={f.key}
																				id={"dropdown-variants-bottom"}
																				variant={"bottom"}
																				className={ ['Garlican-Board-Accordion-Content-Filters-List-Opr'].join(' ') }
																				title={ (v.opr in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v.opr]:v.opr } 
																			>
																				{
																					oprs.map( (v1,k1) => {
																						return(
																							<Dropdown.Item 
																								onClick={
																									(e)=> {
																										this.updateFilter( k, 'opr', v1.value, v  )
																									}
																								}
																								eventKey={v1.key} 
																								key={k1} >
																								{ (v1.value in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v1.value]:v1.value }
																							</Dropdown.Item>
																						)
																					} )
																				}
																			</DropdownButton>	
																		</InputGroup.Prepend>

																		{  ( ( (f.vtype === 'string'))  ) &&
																			<Typeahead
																				className={ ['Garlican-Board-Multiple'].join(" ") }
																				id="basic-typeahead-single"
																				labelKey={"name"}
																				multiple={  true  }
          																		onChange={(e)=>this.setMultiSelections(e, k, v) }
																				selected={  sobj  }
																				options={  values }
																				placeholder="Choose a state..."
																		  />
																		}

																		{  (f.vtype !== 'string')  &&	
																			<div className={ ['slider'].join(' ') }>
																			<InputRange
																				draggableTrack={true}
																				formatLabel={value => `${ Math.round(parseFloat(value, 10)*10/( ("selectedNumberScale" in v)?v.selectedNumberScale.value:f.selectedNumberScale.value))/10 }`}
																				minValue={ parseFloat(f.value[0], 10) }
																				maxValue={ parseFloat(f.value[1], 10) }
																				value={  (v.opr==='btw')?
																							{min : parseFloat( sobj[0], 10), max : parseFloat( sobj[1], 10)}
																							:( ((v.opr==='>')||(v.opr==='>='))?{min : parseFloat( sobj[0], 10), max : parseFloat( f.value[1], 10)}:{min : parseFloat(f.value[0], 10), max : parseFloat(sobj[0], 10)}  )   
																						}
																				onChange={ (value) => {
																					if(v.opr==='btw') {
																						var _v = (value['min']+","+value['max'])
																						this.updateFilter( k, 'value', _v, v  )
																					} else if ((v.opr==='>')||(v.opr==='>=')) {
																						var _v = value['min'].toString()
																						this.updateFilter( k, 'value', _v, v  )
																					} else {
																						var _v = value['max'].toString()
																						this.updateFilter( k, 'value', _v, v  )
																					}
																				}} />	
																			</div>
																		}
																		<InputGroup.Append>
																			{ ('numberScale' in f) &&
																				<DropdownButton
																					as={InputGroup.Prepend}
																					id={"dropdown-variants-bottom"}
																					variant={"bottom"}
																					className={ ['Garlican-Board-Accordion-Content-Filters-List-Opr'].join(' ') }
																					title={ ("selectedNumberScale" in v)? this.props.gi18n[this.props.language][v.selectedNumberScale.key]:this.props.gi18n[this.props.language][f.selectedNumberScale.key] } >
																					{
																						f.numberScale.map( (vc,kc) => {
																							return(
																								<Dropdown.Item 
																									onClick={
																										(e)=> {
																											this.updateFilter( k, 'selectedNumberScale', vc , v  )
																										}
																									}
																									eventKey={vc.key} 
																									key={kc} >
																									{ this.props.gi18n[this.props.language][vc.key] }
																								</Dropdown.Item>
																							)
																						} )
																					}
																				</DropdownButton>	
																			}
																			<Button 
																				onClick={ (e)=>this.removeFilter(k) }
																				className={ ['Garlican-Board-Close'].join(" ") }
																				variant="outline-secondary">
																				<FontAwesomeIcon 
																					icon={ faTimesCircle } 
																				/>
																			</Button>
																		</InputGroup.Append>

																	</InputGroup>
															</ListGroup.Item>
														)
													} )
												}
											</ListGroup>								
										}
										
										{ ( (v.key === 'buy' && (this.state.currentType === 'advance') ) ) && ( this.state.selected === v.key ) &&
											<GarlicanIDEPop {...this.props} ref={this.editorB} show={  true  } parentType={this.state.currentType} currentType={this.state.selected} type={'buy'} showBuyTemplate={this.state.showBuyTemplate} updateValue={this.updateValue} updateParentValue={this.props.updateValue} showEditor={this.props.showEditor} hasError={this.props.hasError} isIDEShow={this.state.isIDEShow} /> 
										}
										{ ( (v.key === 'sell' && (this.state.currentType === 'advance') ) ) && ( this.state.selected === v.key ) &&
											<GarlicanIDEPop {...this.props} ref={this.editorS} show={  true  } parentType={this.state.currentType} currentType={this.state.selected} type={'sell'} showSellTemplate={this.state.showSellTemplate} updateValue={this.updateValue} updateParentValue={this.props.updateValue} showEditor={this.props.showEditor} hasError={this.props.hasError} isIDEShow={this.state.isIDEShow}/> 
										}

										{ ( (v.key === 'buy' && (this.state.currentType === 'basic') ) ) &&
											<GarlicanPicker {...this.props} show={  true  } parentType={this.state.currentType} currentType={this.state.selected} type={'buy'} showBuySTemplate={this.state.showBuySTemplate} updateValue={this.updateValue} updateParentValue={this.props.updateValue} showEditor={this.props.showEditor} hasError={this.props.hasError}/> 
										}
										{ ( (v.key === 'sell' && (this.state.currentType === 'basic') ) ) &&
											<GarlicanPicker {...this.props} show={  true  } parentType={this.state.currentType} currentType={this.state.selected} type={'sell'} showSellSTemplate={this.state.showSellSTemplate} updateValue={this.updateValue} updateParentValue={this.props.updateValue} showEditor={this.props.showEditor} hasError={this.props.hasError}/> 
										}

										{ (v.key === 'contribute') &&
											<GarlicanStrategyUploader {...this.props} show={  true  } parentType={this.state.currentType} currentType={this.state.selected} updateValue={this.updateValue} updateParentValue={this.props.updateValue} hasError={this.props.hasError} contribute={ this.props.contribute } updateContribute={this.props.updateContribute } template={this.state.usedTemplate} selectedStrategy={this.props.selectedStrategy} /> 
										}


									</div>
								</div>
							</Tab>
						)
						})
	  				}

				</Tabs>



			</div>
	  	);
  	}

}


export default GarlicanBoard;


