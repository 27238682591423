import React from "react";
import gglobal from '../../GarlicanLibrary/gvars'

import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faFileImport, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import GService  from '../../GarlicanLibrary/gservice'
import Opcode from '../../GarlicanLibrary/opcode'

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/python";
import CustomNode from './customNode.js'
import { setCompleters } from 'ace-builds/src-noconflict/ext-language_tools';

import {  Button   } from 'react-bootstrap';


var options = {}, dictionary=[], temp =""


export default class IDE extends React.Component {


  constructor(props) {
    super(props);  
    this.editor= React.createRef()
    
    var lineNum = props.startline;
    
    options = {
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      showLineNumbers: true,
      tabSize: 2,
      firstLineNumber: lineNum,
    }


    this.state = {
      currentBuy : this.props.isBuy?"buy_strategy":'sell_strategy',
      showTemplate:false,
      templateList : [],
      parameters : null,
      updated:true,
      isVertical : window.innerWidth < window.innerHeight,
      isIDE : true,
      searchResult : [],
      isBuy : this.props.isBuy,
      originalCode : this.props.isBuy? this.props.buy:this.props.sell, 
      selectedObj : null,
    } 

    this.selectedLine = this.selectedLine.bind(this)

    this.changeCode = this.changeCode.bind(this)
    this.updateAC = this.updateAC.bind(this)
    this.pasteCode = this.pasteCode.bind(this)
    this.updateDistionary = this.updateDistionary.bind(this)
  }

  componentWillMount() {
  }

  componentDidMount() {
    this.updateDistionary()
  }

  updateDistionary() {
    const customMode = new CustomNode();
    this.editor.current.editor.getSession().setMode(customMode);

    dictionary=[]
    var obj = {}, i = 0;
    var cases = GService.defaults.concat( GService.exit  )
    for (i in cases) {
      obj={}
      obj.name = GService.defaults[i]
      obj.value = GService.defaults[i]
      obj.caption = GService.defaults[i] + " [" + ((GService.defaults[i] in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][GService.defaults[i]]:GService.defaults[i]) + "]"
      obj.meta = null
      obj.score = 1000  
      dictionary.push( obj )
    }
    for (i in GService.structure) {
      if(GService.structure[i].display) {
        obj={}
        obj.name = i
        obj.value = GService.structure[i].stm
        obj.caption = i + " [" + ((i in this.props.gi18n[gglobal.language])?this.props.gi18n[gglobal.language][i]:i) + "]"
        obj.meta = null
        obj.score = 1000  
        dictionary.push( obj )
      }
    }
    for (i in  window.func) {
      if( window.func[i].display) {
        obj={}
        obj.name =  window.func[i].name
        obj.value =  window.func[i].format
        obj.caption =  window.func[i].name + " [" +  window.func[i]['desc'][gglobal.language] + "]"
        obj.meta = null
        obj.score = 1000 
        dictionary.push( obj )
      }
    }

    for (i in Opcode.op_func ) {
      obj={}
      obj.name = i
      obj.value = Opcode.op_func[i].value
      obj.caption = i + " [" +  Opcode.op_func[i]['desc'][gglobal.language] + "]"
      obj.meta = null
      obj.score = 1000  
      dictionary.push( obj )
    }
    this.updateAC( dictionary )
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props) {
  }

  componentWillReceiveProps(props) {
    var _this = this;
    var lineNum = props.startline;
    if( _this.editor.current ) {
      var options = _this.editor.current.editor.getOptions()
      if (options.firstLineNumber !== lineNum) {
        options.firstLineNumber =  lineNum
        _this.editor.current.editor.setOptions(options)
      }
      var s = gglobal.stores, _td = [], obj={}
      for (var i in s) {
        obj={}
        obj.name = s[i].value
        obj.value = s[i].value
        obj.caption = s[i].value + ( (i!==s[i].value)?(" | "+i):"")
        obj.meta = null
        obj.score = 1000  
        _td.push( obj )
      }
      _td = _td.concat( dictionary )
      this.updateAC( _td )

      if ( 'start' in props.selectedLineNumber ) {
          var pos = _this.editor.current.editor.session.selection.toJSON();
          var options = _this.editor.current.editor.getOptions()
          pos.start.row = props.selectedLineNumber.start.row+(props.selectedLineNumber.firstLineNumber-options.firstLineNumber)
          pos.start.column = 0
          pos.end.row = props.selectedLineNumber.end.row+(props.selectedLineNumber.firstLineNumber-options.firstLineNumber) - ((props.selectedLineNumber.end.column===0)?1:0)
          pos.end.column = 999
          _this.editor.current.editor.session.selection.fromJSON(pos);
          _this.props.updateValue({ selectedLineNumberC : {} })
      }

      if( props.error_line !== null ) {      
        var a =[
          {
            row: ( parseInt( props.error_line  , 10)-1 ),
            column: 10, // must be 0 based
            text: props.error_msg,
            type: "error"
          }
        ]
        _this.editor.current.editor.getSession().setAnnotations(a)
      } else {
        _this.editor.current.editor.getSession().setAnnotations([])
      }
      this.updateDistionary()
    }

    if( this.props.locked === "ide" ) {

    }

  }

  updateAC(dict) {
    setCompleters([{
      getCompletions: function(editor, session, pos, prefix, callback) {
          callback(null, dict);
      },
    }]);
  }

  changeCode(e) {

      var _this = this
      this.props.updateValue( { error_msg : null, error_line : null, error_opvar : null  } )
      temp = e
      this.props.updateCode(  e , false )

      if( !this.props.isIDEShow ) {
        setTimeout( ()=>{
          if( _this.props.locked === "ide" ) {
            if( temp === e ) {
              console.log( "ONCHANGECOMPLETE" )
              _this.props.updateCode(  e , true )
            }
          }
        }, 3000)
      }

  }

  selectedLine() {
    if( this.props.locked === "ide" ) {
      var sR = this.editor.current.editor.getSelectionRange();
      var options = this.editor.current.editor.getOptions()
      sR.firstLineNumber = options.firstLineNumber
      this.props.updateValue({ selectedLineNumberT : sR })
    }
  }

  pasteCode(e) {
    var txt = e.text
    if( this.props.locked === "ide" ) {
      var _this = this;
      var _t = txt.split("\\n")
      txt = _t.join("\n")
      _t = txt.split("\\t")
      txt = _t.join("\t")
      _t = txt.split("\\r")
      txt = _t.join("\r")
      if(  txt.indexOf( GService.split_cond )>-1 ) {
        _this.props.updateCode(  txt , false )
        //var _t = txt.split( GService.split_cond+ "\n" )
        //_this.props.updateValue( { code:txt, error_msg : null, error_line : null, error_opvar : null, buy:_t[0], sell:_t[1], overwrite:true  } )
        _this.props.updateValue( { error_msg : null, error_line : null, error_opvar : null, overwrite:true  } )
      } else {
        if( this.props.isBuy && txt.toLowerCase().indexOf( 'condition_short' )>-1 )  {
          var code = this.props.buy + "\n" + GService.split_cond + "\n" + txt
          _this.props.updateCode(  code , false )
          //_this.props.updateValue( { code:txt, error_msg : null, error_line : null, error_opvar : null, sell:txt, buy:this.props.buy, overwrite:true  } )
          _this.props.updateValue( { error_msg : null, error_line : null, error_opvar : null, overwrite:true  } )
        } else if( !this.props.isBuy && txt.toLowerCase().indexOf( 'condition_long' )>-1 )  {
          var code = txt + "\n" + GService.split_cond + "\n" + this.props.sell
          _this.props.updateCode(  code , false )
          //_this.props.updateValue( { code:code, error_msg : null, error_line : null, error_opvar : null, buy:txt, sell:this.props.sell, overwrite:true  } )
          _this.props.updateValue( { error_msg : null, error_line : null, error_opvar : null, overwrite:true  } )
        }
      }
    }


  }

  render() {

    return (
      <div 
        onMouseEnter={  (e)=> {
          if( this.props.locked !== "ide" ){
            this.props.updateValue({
              locked : "ide"
            })
          }
        } }
        onMouseLeave={  (e)=> {
          if( !this.props.isIDEShow ) {
            this.pasteCode(  {text : this.props.code}  )
          }
          //this.props.updateValue({
          //  locked : "null"
          //})
        } }
        className={['Garlican-IDE-Modal', this.props.isIDEShow?'Garlican-IDE-Full':'Garlican-IDE-Half' ].join(' ')} >
        { !this.props.error_msg &&
          <div className={['control'].join(" ") } >
          </div>
        }
        { this.props.error_msg &&
          <div className={['control', 'alert'].join(" ") } >
              { this.props.error_msg }
          </div>
        }

        <AceEditor
          width="auto"
          height="auto"
          onSelectionChange={ (e)=>{
            //console.log( "IDE : SELECTEION")
            this.selectedLine()
          }}
          onLoad={ (e) => {
            e.renderer.setScrollMargin( 0,280,0,20 );
          }}
          ref={this.editor} 
          fontSize={12}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={true}
          onPaste={ this.pasteCode }
          onBlur={ (e)=>{
            this.props.updateValue({locked:null}) 
            this.props.updateCode( this.props.code, true )
          }}
          onFocus={ (e)=>{
            this.props.updateValue({locked:'ide'}) 
          }}
          mode="python"
          theme="monokai"
          className={ ['textarea'].join(' ') } 
          onChange={ this.changeCode }
          name="GARLICAN"
          value={ this.props.code }  
          editorProps={{ $blockScrolling: true }}
          setOptions={options}
        />

        {this.props.error_msg && 
          <div 
            className={['error'].join(' ')} 
              onClick={ (e)=>{
                var r = window.confirm(this.props.error_msg); 
                if(r) {
                  this.props.updateValue( { error_msg : null, error_line : null, error_opvar : null  } )
                  this.props.updateCode( this.props.restoreCode )
                }
              } 
            } >
               {this.props.gi18n[gglobal.language]['error']} 
          </div> 
        }
  
      </div>
    );
  }
}




