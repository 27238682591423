import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Image, Button, Tabs, Tab, FormControl } from 'react-bootstrap'

import logoB from '../../public/img/garlican_blue_logo.png'

import service from '../../public/service'
import Api from '../../Garlican/GarlicanLibrary/apis'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faClone, faPlus, faShoppingCart  } from '@fortawesome/free-solid-svg-icons'


import Draggable from 'react-draggable'; // The default
import { Resizable } from 'react-resizable';

//import List from './list'
import Docker from './docker'
import TLogin from './tlogin'
import TSummary from './tsummary'
import TInsert from './insert'

import sptrader from './service/sp'
import GService from '../../Garlican/GarlicanLibrary/gservice';
import { symbol, symbolCross } from 'd3-shape';
import { group } from 'd3-array';
import { index, LN10 } from 'mathjs';

const MIN_WIDTH = 320
const MIN_HEIGHT = 500

class Trader extends Component {

    constructor(props) {
        super(props)

        var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
		    var obj  = storage.getItem("garlican@spobj")
        if(obj) {
          if( obj.length > 0 ) {
            sptrader.obj = JSON.parse( obj )
          }
        }

        var isTLogin = false

        var tabs = this.getTab(isTLogin)

        this.state = {
          height : (!this.props.disable?(window.innerHeight-35):window.innerHeight  ),
          width: (!this.props.disable?MIN_WIDTH:window.innerWidth ) ,
          show : false,
          group : [],
          page : 0,
          isTLogin : isTLogin,
          //username : "APIA2",
          //password : "1234",
          username : "",
          password : "",
          symbols : [],
          showLogin : false,
          selected : null,
          selectedTab : props.page?props.page:tabs[0].key,
          tabs : tabs,
          fg : [],
          price : {},
          isReading : false,
          isAdd : false,
          sporders : [],
          lock  : false,

        }
        this.handleStart = this.handleStart.bind( this )
        this.handleDrag = this.handleDrag.bind( this )
        this.handleStop = this.handleStop.bind( this )
        this.onresize = this.onresize.bind(this)
        //this.pop = this.pop.bind(this)
        this.show = this.show.bind(this)
        this.updateValue = this.updateValue.bind(this)
        this.login = this.login.bind(this)

        this.initialize = this.initialize.bind(this)
        this.updateRealPrice = this.updateRealPrice.bind(this)
        this.getPrice = this.getPrice.bind(this)
        this.updateList = this.updateList.bind(this)
        this.updateGroup = this.updateGroup.bind(this)
        this.moveToAction = this.moveToAction.bind(this)
        this.getSymbol = this.getSymbol.bind(this)
        this.updatePriceFromState = this.updatePriceFromState.bind(this)

        this.selectKey = this.selectKey.bind(this)
        this.getTab = this.getTab.bind(this)
        this.onClose = this.onClose.bind(this)
        this.insert = this.insert.bind(this)

        this.order = this.order.bind(this)

        this.mcupdate = this.mcupdate.bind(this)
        this.mcmessage = this.mcmessage.bind(this)
        this.getSPOrders = this.getSPOrders.bind(this)


        this.run = this.run.bind(this)
    }

    componentWillMount() {
    }

    componentDidMount() {
      window.document.trader = this
      if(  this.props.disable   ) {
        if( window.opener !== null ) {
          window.opener.call_app( "page" , "call_parent" , "Connected"   )  

          //console.log( this.state.selectedTab )
          if( this.state.selectedTab === 'onhold' ) {
            this.selectKey( this.state.selectedTab, this.props.forwardGroup )
          }

          var _this = this
          window.addEventListener("resize" , (e) => {
            var w = Math.max(window.innerWidth, MIN_WIDTH)
            var h = Math.max(window.innerHeight, MIN_HEIGHT)
            _this.setState({
              width : w,
              height : h
            })
          } )
          window.addEventListener("beforeunload", (ev) => {  
              ev.preventDefault();
              window.opener.call_app( "page" , "call_parent" , "Disconnected"   )
              window.opener.call_app( "page" , "unpop" , ""   )
              return ev.returnValue = 'Are you sure you want to close?';
          });
        } else {
          window.location.href = window.location.origin
        }
      }    
    }

    getTab( BOOL ) {
      var tabs = []
      /*if( !BOOL) {
        tabs = [
          {key : 'onhold', value: "onhold"},
          {key : 'action' , value : 'action'},
        ]
      } else {*/
        tabs = [
          {key : 'onhold', value: "onhold"},
          {key : 'summary' , value : 'summary'},
          {key : 'action' , value : 'action'},
          
        ]
      //}
      return tabs
    }

    show(msg) {
      alert(msg)
    }

    readList(obj, list, type) {
  
      /*
      service.getAssetList(obj).then(
        (res) => {
          var list = res.list
            var seen = new Set();
            var symbols = list.filter(el => {
              const duplicate = seen.has(el.symbol);
              seen.add(el.symbol);
              return !duplicate;
            });
            symbols = [...seen]
            for (var i in list) {
              list[i].buy = list[i].price 
              list[i].change = 0
              list[i].board_lot = list[i].quantity
              //MAKE DECSISION
              list[i].unit = 1
            }
            var group = [
              { group: {name : 'all'} , list : list, onhold : [] }
            ]

            if ( this.state.selectedTab==="onhold") {
              this.setState({
                page : obj.page,
              })
              for (var i in group) {
                var symbols = this.getSymbol( group[i].list )              
                if( !this.state.isTLogin  ) {   
                  if( symbols.length > 0 ) {       
                    this.getPrice( symbols, group[i].group, group[i].list, i, type )
                  } else {
                    this.setState({
                      isReading : false,
                      group : group,
                    })
                  } 
                } else {
                  if( symbols.length > 0 ) {
                    this.updateRealPrice(symbols, group[i].group, group[i].list, i, type)
                  } else {
                    this.setState({
                      isReading : false,
                      group : group,
                    })
                  }
                }
              }
            }
          //}
        }
      )
      */
    }

    componentWillReceiveProps(props) {   
      //console.log( props.forwardGroup )   
      var _this = this
      if( props.page !== _this.state.selectedTab ) {
        this.setState({ lock :false})
      }
      setTimeout( (e)=> {
        if( props.showTrader ) {
          if( !this.state.isTLogin ) {  
            if( Object.keys( sptrader.obj ).length > 0 ) {
              sptrader.marginCheckerOpen( this.mcupdate, this.mcmessage )
            }
          }
          if( !this.state.lock ) {
            var page = props.page?props.page:_this.state.selectedTab
            if( page === 'onhold' ) {
              _this.selectKey( _this.state.selectedTab,  props.forwardGroup ,true )
            } else {
              _this.selectKey( page, props.forwardGroup, true )
            }
            var obj = {}
            _this.setState( obj )
          }
          //}
        } else {
          _this.initialize()
        }
      }, 500 )
    }

    componentWillUnmount() {
      if( window.opener !== null ) {
        window.opener.call_app( "page" , "test" , "CLOSE"   )
      } else {
        //console.log( this.state.list )
        //this.setState( {
        //  list : []
        //})
      }
    }

    handleStart(e) {
      //console.log(e)
    }

    handleDrag(e) {

    }

    handleStop(e) {

    }

    onresize(event, {element, size, handle}) {
      //console.log( element )
      //console.log(size)
      if( !this.props.disable )
        this.setState({width: Math.max(size.width, MIN_WIDTH), height: Math.max(size.height, MIN_HEIGHT)});
    }

    updateValue(obj) {
      this.setState(obj)
    }

    getPrice( symbols, group, lists, index, type) {
      var _this = this
      var gp = JSON.parse( JSON.stringify( this.state.group ) )
      var prices = JSON.parse( JSON.stringify( _this.state.price ) )

      var boardlot = {}

      if( symbols.length > 0 ) {
        var syms = []
        for (var idx in symbols) {
          for(var j in symbols[idx]) {
            syms = syms.concat( symbols[idx][j] )
          }
        }

        if( syms.length > 0 ) { 
          Api.getlateststockprice(  syms  ).then(
            (res) => {
              if( res.value.length > 0 ) {
                for (var i in res.value) {
                  prices[ res.value[i].symbol ] = res.value[i].price
                  boardlot[ res.value[i].symbol ] = res.value[i].boardlot
                }
              }
              for (var idx in symbols) {
                var value = _this.updatePriceFromState( lists[idx], prices, boardlot )
                var list = value.list
                var updates = value.updates
                var obj = { group : group[idx], list : list, type : type }
                if( index[idx] ) {
                  gp[ index[idx] ] = obj
                } else {
                  gp.push( obj )
                }
              }
              if ( _this.state.selectedTab===type) {
                _this.setState({
                  isReading : false,
                  group : gp,
                  prices : prices
                })  
              }
            }
          )
        } else {
          _this.setState({
            isReading : false,
            group : gp,
          }) 
        }


      } else {
        _this.setState({
          isReading : false,
          group : gp,
          prices : prices
        }) 
      }
    }

    updateRealPrice(symbols, group, lists, index, type) {
      var _this = this
      var gp = JSON.parse( JSON.stringify( this.state.group ) )
      var prices = JSON.parse( JSON.stringify( _this.state.price ) )

      if( symbols.length > 0 ) {
        var syms = []
        for (var idx in symbols) {
          for(var j in symbols[idx]) {
            syms = syms.concat( symbols[idx][j] )
          }
        }

        if( syms.length > 0 ) { 
          sptrader.price( syms  ).then(
            (res) => {
              if( res.data.prodList.length > 0 ) {
                for (var i in res.data.prodList) {
                  prices[ res.data.prodList[i].prodCode ] = res.data.prodList[i].lastPrice
                }
              }
              for (var idx in symbols) {
                var value = _this.updatePriceFromState( lists[idx], prices )
                var list = value.list
                var updates = value.updates
                var obj = { group : group[idx], list : list, type : type }
                if( index[idx] ) {
                  gp[ index[idx] ] = obj
                } else {
                  gp.push( obj )
                }
              }
              if ( _this.state.selectedTab===type) {
                _this.setState({
                  isReading : false,
                  group : gp,
                  prices : prices
                })  
              }
            }
          )
        } else {
          _this.setState({
            isReading : false,
            group : gp,
          }) 
        }


      } else {
        _this.setState({
          isReading : false,
          group : gp,
          prices : prices
        }) 
      }

    }

    updatePriceFromState(list, prices, bl) {
      var updates = []
      for (var i in list) {
        if (   list[i].symbol in prices  ) {
          list[i].price = prices[ list[i].symbol  ]
          list[i].change =  ( prices[ list[i].symbol  ] - list[i].buy ) / list[i].buy 
          if( bl ) {
            if( list[i].board_lot !== bl[ list[i].symbol  ] ) {
              console.log( list[i].symbol + " : " +  list[i].board_lot + " to " +  bl[ list[i].symbol  ] )
              list[i].new_bl = bl[ list[i].symbol  ]
              updates.push( JSON.parse(  JSON.stringify(list[i]) ) )
            } else {
              //console.log( list[i].symbol + " : " +  list[i].board_lot + " eq " +  bl[ list[i].symbol  ] )
            }
          }
        }
      }
      return { list : list, updates : updates }
    }

    login(username, password) {
      sptrader.login( username, password ).then(
        (res) => {
          if( "data" in res) {
            sptrader.obj = res.data
            
            
            //var tabs = this.getTab( true )
            //this.setState({
            //  isTLogin : true,
            //  tabs : tabs
            //})
            sptrader.marginCheckerOpen( this.mcupdate, this.mcmessage )

            var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
		        storage.setItem("garlican@spobj", JSON.stringify(res.data) )

            var group = JSON.parse(  JSON.stringify(this.state.group)  )
            //console.log( group )
            
            var gps = [], syms = [], lists =[], indexs= []
            for (var i in group) {
              var symbols = this.getSymbol( group[i].list )
              syms.push( symbols )
              lists.push( group[i].list )
              gps.push( group[i].group )
              indexs.push( i )
              //this.updateRealPrice(symbols, group[i].group , group[i].list, i)
            }

            this.updateRealPrice(syms, gps , lists, indexs)
            //this.updateRealPrice( 0, group[0] )
            
          }
        }
      )
    }

    initialize() {
      this.setState({
        symbols : {},
        group : [],
        page : 0,
        show : false,
        selectedTab : this.state.tabs[0].key,
        fg : [],
        isReading : false,
        lock : false,
      })
    }

    updateList( gindex ,index, type, value, isRemove) {
      //NEED FIX
      //var group = JSON.parse(  JSON.stringify( this.state.group ) )
      var group = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
      //var fg = []
      //if( group[gindex].type  )
      var _tp = group[gindex].type?group[gindex].type:this.state.selectedTab
      var list = group[gindex][ _tp ]
      if( !isRemove) {
        for (var i in type) {
          list[index][ type[i] ] = value[i]
        }
      } else {
        list.splice( index, 1 )
      }

      //group[ gindex ].list = list
      group[gindex][group[gindex].type] = list
      //fg = group
      //this.setState( {
      //  group : group,
      //  fg : fg
      //} )
      //console.log( list )
      this.props.updateValue( {
        tpage : this.state.selectedTab,
        forwardGroup : group
      } )
    }

    moveToAction( gindex , obj ) {
      this.setState( {
        lock : true,
      })
      var fg = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
      var o = fg[gindex]
      
      obj = JSON.parse(  JSON.stringify( obj ) )
      obj.action = "sell"
      obj.type = "action"
      obj.entrytime = obj.timestamp
      obj.boardlot = obj.board_lot
      //obj.price = obj.buy
      obj.entryprice = obj.buy
      obj.group = obj['strategy_id']
      obj.decision = -obj.unit
      obj.tmp_decision = -obj.unit
      var valid = true
      if( !( "action" in o ) ) {
        o.action = []
      } else {
        for ( var j in o.action ) {
          if( (o.action[j]["symbol"] === obj.symbol) && (o.action[j]["strategy_id"] === obj.strategy_id)  ) {
            valid = false
            break
          }
        }
      }
      if(valid) {  
        o.action.push(  obj   )
        fg[gindex] = o
        //console.log(o)
        this.props.updateValue({
          forwardGroup : fg
        })
      }

    }

    updateGroup(gindex, value, isRemove) {
      var fg = []
      //var group = JSON.parse(  JSON.stringify( this.state.group ) )
      var group = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
      if( isRemove ) {
        group.splice( gindex, 1 )
      } else {
        var o = group[ gindex ]
        if( o.type === "action" ) {
          group[ gindex ][o.type] = value
        }
        //group[ gindex ] = value
        //if(  )
      }
      fg = group
      //this.setState( {
      //  group : group,
      //  fg : fg
      //} )

  
      this.props.updateValue( {
        tpage : this.state.selectedTab,
        forwardGroup : group
      } )
    }

    selectKey(k, fg, override) {
      if( override || (( k !== this.state.selectedTab) || ( this.state.group.length === 0 )) ) {
        //console.log("WHY")
        //console.log( fg )
        var obj = {
          isReading : true,
          selectedTab : k,
          lock :false,
          //group : [],
        }
        if(fg) {
          obj.fg = fg
        }
        this.setState(obj)
        if ("user_id" in service.user) {
          //fg = ((fg)?fg:this.state.fg)
          fg = ((fg)?fg:JSON.parse(JSON.stringify(this.props.forwardGroup)))
          switch( k ) {
            case "onhold":
                var gp = JSON.parse( JSON.stringify( this.state.group ) )
                var syms = [], lists=[], indexs=[], gps = []
                for (var j in fg) {
                  var symbols = []
                  var list = JSON.parse(JSON.stringify(  fg[j].onhold ) )
                  //console.log( list )
                  for( var i in list) {
                    if( list[i].symbol !== "cash" ) {
                      symbols.push( list[i].symbol )
                      list[i].buy = Math.round( list[i].price * 1000)/1000
                      list[i].change = 0
                      //list[i].action = "bought"
                      list[i].action = "buy"
                      list[i].timestamp = list[i].last_purchase_time  
                    }
                  }
                  var o = {}
                  symbols = [...new Set(symbols)];
                  symbols = symbols.filter(
                    (a)=>{
                      return a!=="cash"
                    }
                  )    

                  syms.push( symbols )
                  lists.push( list )
                  indexs.push( j ) 
                  gps.push( fg[j].group )
                }

                //console.log( this.state.isTLogin  )
                if ( !this.state.isTLogin ) {
                  this.getPrice( syms, gps, lists , indexs ,k  )
                } else {
                  //console.log("HEre")
                  this.updateRealPrice( syms, gps, lists , indexs ,k )
                }

              break;
            case "action": 
              for (var j in fg) {
                var symbols = []
                var list = JSON.parse( JSON.stringify(  fg[j].action  ) )
                var onhold = JSON.parse(JSON.stringify(  fg[j].onhold ) )
                var onholds = {}
                for (var _k in onhold ) {
                  if( onhold[_k]['symbol'] !== "cash" ) {
                    if( !(onhold[_k]['strategy'] in onholds) ) {
                      onholds[ onhold[_k]['strategy'] ] = {}
                    }
                    onholds[   onhold[_k]['strategy']  ][ onhold[_k]['symbol']  ] = onhold[_k]['unit']
                  }
                }
                for( var i in list) {
                  symbols.push( list[i].symbol )
                  if( list[i].decision > 0 ) {
                    list[i].buy = Math.round( list[i].price * 1000)/1000
                    list[i].change = 0
                  } else if( list[i].decision < 0 ) {
                    list[i].buy = Math.round( list[i].entryprice * 1000)/1000
                    list[i].change = Math.round((list[i].price - list[i].entryprice)*100/list[i].entryprice)/100
                  }
                  list[i].board_lot = list[i].boardlot
                  //list[i].action = ((list[i].decision > 0)?"bought":'sold')
                  list[i].action = ((list[i].decision > 0)?"buy":'sell')
                  list[i].timestamp = list[i].date  
                  list[i].unit = Math.abs(  list[i].decision  ) 
                  list[i].onhold = 0
                  if (  !list[i]['group'] ) {
                    list[i]['group'] = list[i]['straregy']
                  }
                  if(  list[i]['group'].toString() in onholds ) {
                    if(  list[i]['symbol'] in onholds[list[i]['group']] ) {
                      list[i].onhold = onholds[ list[i]['group'].toString() ][ list[i]['symbol'] ]
                    }
                  }    
                }
                fg[j].list = list
                fg[j].type = k
                //symbols = [...new Set(symbols)];
                //if ( !this.state.isTLogin ) {
                //  this.getPrice( symbols, fg[j].group, list  )
                //} else {
                //  this.updateRealPrice(symbols, fg[j].group , list)
                //}
              }
              this.setState({
                group : fg,
                isReading : false,
              })  
              break;
            case "summary":
              //console.log(k)
              var gp = []
              var obj = {}
              obj.group = {
                name : this.props.gi18n[this.props.language]['t_no_manage'] ,
                id : -1
              }
              obj.list = this.state.sporders
              obj.type = k
              gp.push( obj )

              this.setState({
                group : gp,
                isReading : false,
              }) 
              break;            
            default:
              console.log( k )
              this.setState({
                isReading : false,
              })
              break;
              //this.initialize()
          }
        }

      }
    }

    onClose() {
      this.initialize()
      sptrader.marginCheckerClose()
      this.setState({
        isTLogin : false,
      })
      this.props.updateValue({
        showTrader:false,
        forwardGroup : [],
        tpage : null,
      })
    }

    getSymbol( lt ) {
      var symbols = []
      for (var i in lt) {
        symbols.push( lt[i].symbol )
      }
      symbols = [...new Set(symbols)]
      return symbols
    }

    insert(o) {
      var  _this = this
      function _update(o, price, _this) {
        var _o = {
          symbol : o.symbol[0],
          price : price.price,
          buy : price.price,
          change : 0,
          decision : 1,
          tmp_decision :1,
          boardlot : price.boardlot,
          unit : 1,
          action : "buy",
        }
        var fg = JSON.parse(  JSON.stringify( _this.props.forwardGroup )  )
        if(  o.botfolio.id ) {
          _o.group = o.bot.id
          _o.portfolio = o.botfolio.id
          var obj = {}
          for (var i in fg) {
            if( fg[i].group.id === o.botfolio.id  ) {
              for(var j in fg[i].group.strategy_list ) {
                if( fg[i].group.strategy_list[j].id === o.bot.id  ) {
                  //obj = fg[i]
                  fg[i].action.push( _o )
                  _this.props.updateValue({
                    forwardGroup : fg
                  })
                  break
                }
              }
            }
          }
        } else {
          _o.group = -1
          _o.portfolio = -1
          var index = -1
          var obj = {}
          obj.group = {
            name : _this.props.gi18n[_this.props.language]['t_no_manage'] ,
            id : -1
          }
          obj.onhold = []
          obj.action = []
          for (var i in fg) {
            if( fg[i].group.id === -1 ) {
              index = i
              obj.group = fg[i].group
              obj.onhold = fg[i].onhold
              obj.action = fg[i].action
              break
            }
          }
          obj.action.push( _o )
          if( index === -1 ) {
            fg.splice( 0, 0, obj )
          } else {
            fg[index] = obj
          }
          _this.props.updateValue({
            forwardGroup : fg
          })
        }
      }

         
        Api.getlateststockprice(  o.symbol  ).then(
          (r1) => {
            if( !this.state.isTLogin  ) {   
              _update(o, r1.value[0], _this)
            } else {
              sptrader.price( o.symbol  ).then(
                    (res) => {
                      var obj = r1.value[0]
                      obj.price = res.data.prodList[0].lastPrice
                      _update(o, obj, _this)
                    }
              )
            }
          }
        )
    }

    order() {
      var fg = JSON.parse(  JSON.stringify(  this.props.forwardGroup  )  )
      try {
        var trader_list = []
        
        for( var i in fg) {
          var _ra = []
          
          for( var j in fg[i]["action"] ) {
            if( !fg[i]["action"][j].real ) {
              
              var obj = {}
              if( fg[i]["action"][j].decision > 0 ) {
                obj['action'] = "buy"
              } else {
                obj['action'] = "sell"
              }
              obj['asset_type'] = "paper"
              obj['portfolio_id'] = fg[i]['group'].id
              obj['price'] = fg[i]["action"][j].price
              obj['unit'] = Math.abs(fg[i]["action"][j].decision)
              obj['board_lot'] = fg[i]["action"][j].boardlot
              obj['strategy_id'] = parseInt( fg[i]["action"][j].group , 10)
              obj['symbol'] = fg[i]["action"][j].symbol
              trader_list.push( obj )
              _ra.push( j )

            }
          }
          for (var k = _ra.length -1; k >= 0; k--)
            fg[i]["action"].splice(_ra[k],1);

        }

        this.props.updateValue({
          showLoading  : true
        })

        if( trader_list.length > 0 ) {
          service.createTradeOrders( { orders : trader_list } ).then(  
            (res) => {
              var obj = {}
              obj.header = ""
              obj.body = this.props.gi18n[ this.props.language ]['submit_success']
              
              this.props.updateValue( {
                //forwardGroup : fg,
                showAlert : true,
                alertMessage : obj,
                showLoading  : false,
                //tpage : "onhold"
              })

              this.setState({
                lock : false,
                group : [],
              })

              var _this = this
              setTimeout( (e) => {
                _this.props.rebuildAsset()
                //_this.props.updateValue({ tpage : "onhold" })
              } , 300)
            }
          )
        } else {
          this.props.updateValue({
            showLoading : false,
            showAlert : true,
            alertMessage : {
              header :  this.props.gi18n[this.props.language]['t_no_trade_record'] ,
              body : this.props.gi18n[this.props.language]['t_no_trade_record']
            },
          })
        }
      
      }
      catch(e) {
        this.props.updateValue({
            showLoading  : false
        })
      }
    }

    mcupdate(BOOL) {
      var tabs = this.getTab(BOOL)
      if( BOOL ) {

        this.getSPOrders()

        /*var group = JSON.parse(  JSON.stringify(this.state.group)  )
        var gps = [], syms = [], lists =[], indexs= []
        for (var i in group) {
          var symbols = this.getSymbol( group[i].list )
          syms.push( symbols )
          lists.push( group[i].list )
          gps.push( group[i].group )
          indexs.push( i )
        }

        console.log("WHY")
        console.log(group)
        this.updateRealPrice(syms, gps , lists, indexs)*/
        //console.log("WHY")
        this.selectKey( "onhold" , this.props.forwardGroup, true )
      } else {

      }
      this.setState( {
        isTLogin : BOOL,
        tabs : tabs,
      })
      
    }

    mcmessage(obj) {
      var o = JSON.parse(  obj.data  )
      console.log( o )
    }

    getSPOrders() {
  
      //Done
      //sptrader.getActiveOrder().then(
      sptrader.getHistoryOrder().then(
      //sptrader.getAccountOrder().then(
      //sptrader.getDoneTrade().then(
        (res) => {
          /*
0 : Sending
1 : Working
2 : Inactived
3 : Pending
4 : Adding
5 : Changing
6 : Deleting
9 : Traded
          */
          var fg = JSON.parse( JSON.stringify( this.props.forwardGroup ) )
          var _arr = []
          for(var j in fg) {
            var obj = {}
            for (var k in fg[j].onhold ) {
              obj.gindex = j
              obj.index = k
              obj.value = fg[j].onhold[k]
              _arr.push(obj )
            }
          }
          console.log( _arr )


          if( "data" in res ) {
            if(  "recordData" in res['data']  ) {
              var mt = [0,1,2,3,4,5,6,9]
              var list = res['data']['recordData'], trade_list = []
              list = list.filter( (e)=>{
                return mt.includes(e.status)
              })


              for (var i in list) {
                //accOrderNo


                var  obj = {}
                obj['symbol'] = list[i]['prodCode']
                obj['action'] = (list[i]['buySell'] === "B")?"buy":"sell"
                obj['decision'] = (list[i]['buySell'] === "B")?1:-1
                obj['board_lot'] = list[i]['ordTradedQty']?list[i]['ordTradedQty']:list[i]['tradedQty']
                obj['unit'] = 1
                obj['last_purchase_time'] = list[i]["tradeTimeStr"]?list[i]["tradeTimeStr"]:list[i]["timeStampStr"]
                obj['price'] = list[i]['avgPrice']?list[i]['avgPrice']:list[i]['orderPrice'] //orderPrice
                obj['buy'] = list[i]['avgPrice']?list[i]['avgPrice']:list[i]['orderPrice'] //orderPrice
                obj['staus'] = list[i]['status']
                obj['accOrderNo'] = list[i]['accOrderNo']
                obj['change'] = 0
                trade_list.push( obj )
              }
              this.setState( {
                sporders : trade_list
              })
              
              var valid = false
              for (var i in fg) {
                if( fg[i].group.id === -1 ) {
                  fg[i].summary = trade_list
                  valid = true
                } else {
                  fg[i].summary = []
                }
              }

              if( !valid ) {
                //his.props.gi18n[this.props.language]['t_no_manage']
                var gp = { 
                  "group" : 
                    {"name" : this.props.gi18n[this.props.language]['t_no_manage'] }, 
                  "summary" : trade_list, 
                  "action" : [] , 
                  "onhold" : [] 
                }
                fg.splice( 0, 0, gp )
              }
              this.props.updateValue({
                forwardGroup : fg
              })
              //var gp = { "group" : {"name" : "All" }, "list" : trade_list }
            }
          }
/*
accNo: "APIA2"
accOrderNo: 12
aeCode: "APIAD1"
avgPrice: 46.1
avgTradedPrc: 46.1
brokerId: ""
buySell: "B"
channel: "C"
counterId: ""
counterParty: ""
decInPrc: 3
doneTradeDate: 1634486400000
doneTradeTime: 1634543290183
extOrderNo: "F0C3D2E1:B487952E"
fillId: ""
gatewayCode: "SEHK_OCG"
initiator: "SPADMIN"
instCode: "HKMAIN"
marketCode: ""
options: 16
ordTradedQty: 400
orderNo: -1097802017310010100
orgGatewayCode: ""
orgTradeNo: 0
prodCode: "0005.HK"
recNo: 2891186
ref: ""
remarked: false
status: 0
systemId: "FPIUAT_S"
timeStamp: 1634543290.192
totalAmount: 18440
totalTrdPrc: 18440000
tradeDate: 1634486400
tradeDateStr: "2021-10-18"
tradeNo: 4
tradePrice: 46100
tradeQty: 400
tradeRef: ""
tradeTime: 1634543290.183
tradeTimeStr: "2021-10-18 15:48:10"
*/
          
        }
      )

    }

    run(  idx ) {
      var gp = JSON.parse(   JSON.stringify( this.state.group )   )
      var asset_list = gp[idx]
      var params = {}
      for (var i in asset_list.onhold) {
          asset_list.onhold[i].unit = 1
          if(  "unit" in  asset_list.onhold[i] ) {
            asset_list.onhold[i].strategy = asset_list.onhold[i].strategy_id.toString()
            //console.log( asset_list.onhold[i]   )
            if(  !(asset_list.onhold[i].symbol in params)  ){
              params[ asset_list.onhold[i].symbol ] = {}
            }
            if(  !( asset_list.onhold[i].strategy_id in params[ asset_list.onhold[i].symbol ])  ) {
              params[ asset_list.onhold[i].symbol ][   asset_list.onhold[i].strategy_id   ] = {}
            }
            if(  "unit" in  asset_list.onhold[i] ) {
              var d = '27070740', duration = 0
              if( asset_list.onhold[i].last_purchase_time ) {
                d = service.formatDate( asset_list.onhold[i].last_purchase_time, null, true  )
                d = d.getTime()/1000
                var now = new Date()
                now = now.getTime()/1000
                duration = (now - d)
              }
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy] = {}
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['betsizeonhold'] = asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['betsize'] =  asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['shortbetsize'] = asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['onstock'] = asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['marketposition'] = asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['onmarket'] = asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['entryprice'] = asset_list.onhold[i].price.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['entrytime'] = d/60/60/24
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['last_purchase_time'] = asset_list.onhold[i].last_purchase_time
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['barssinceentry'] = duration.toString()
            }
          }
      }
      //console.log( params )

      var strategy = {}, partition = 100
      var group = asset_list.group
      for (var s in group.strategy_list) {
        strategy[ group.strategy_list[s].id ] = group.strategy_list[s]
        partition -= parseFloat(  group.partition[ group.strategy_list[s].id ] , 10  )
      }
      if( partition > 0) {
        strategy['cash'] = { id:'cash', name:this.props.gi18n[ this.props.language ]['cash']}
      }
      var _obj = {}, transaction_fee = 0
      //console.log( this.props.forwardGroup )
      //_obj.forwardGroup = (this.props.forwardGroup.length > 0)?this.props.forwardGroup:[]
      _obj.showLoading = true
      _obj.tpage = "action"
      this.props.updateValue(_obj)

      Api.forwardtestingS( group, params, parseFloat(transaction_fee, 10) ).then(
        (res) => {
          var obj = {}
          obj['action'] = []
          if( typeof(res) !== "string" ) {
            if( 'value' in res ) {
              for (var s in res['value']) {
                res['value'][s]['tmp_decision'] = res['value'][s]['decision']
              }
              obj['action'] = res['value']
            } 
          }
          obj['group'] = group
          obj['onhold'] = asset_list.onhold
          
          var valid = true
          var fg = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
          for( var i in fg) {
            if( fg[i].group.id === obj.group.id ) {
              fg[i] = obj
              valid = false
              break
            }
          }
          if( valid ) {
            fg.push( obj )
          }

          this.props.updateValue({
            forwardGroup : fg,
            showLoading : false,
            tpage : "action",
            //showTrader : true,
          })
        }
      ).catch( (e) => {
        this.props.updateValue({
          showLoading : false,
          showAlert : true,
          alertMessage : {
            header :  this.props.gi18n[this.props.language]['error'] ,
            body : this.props.gi18n[this.props.language]['backtesting_error']
          },
        })
      } )

    }

    render() {
      if( Object.keys(this.props.gi18n).length > 0  ) {   
        return (
          <Draggable
            handle={".handle"}
            axis="both"
            defaultPosition={{x: (!this.props.disable?0:0), y: (!this.props.disable?0:0)}}
            position={  null }
            scale={1}
            disabled={ this.props.disable }
            onStart={this.handleStart}
            onDrag={this.handleDrag}
            onStop={this.handleStop}>
            <Resizable height={this.state.height} width={this.state.width} onResize={ (e,{element, size, handle} )=>  this.onresize( e,{element, size, handle}  ) }>
              <div className="box" style={{width: this.state.width + 'px', height: this.state.height + 'px'}} className={ [ "trader", this.props.showTrader?"trader-show":"trader-hide", this.props.disable?"trader-full":""  ].join(" ") } >
                <div className={ [ "handle" ].join(" ") } >
                  
                  <Image className={['logo'].join(" ")} src={logoB} />
                  <div className={['name'].join(" ")} > GARLICAN </div>

                  { !this.props.disable && <FontAwesomeIcon  icon={faClone} className={['clone', 'icon'].join(' ')} onClick={ (e)=>this.props.pop() }/> }
                  { !this.props.disable && <FontAwesomeIcon  icon={faTimes} className={['close', 'icon'].join(' ')} onClick={ (e)=>{
                      this.onClose()
                    }}/> 
                   }
                </div>

                <div className={ [ (this.state.width >= 700)?"content-half":"content-full", "content" ].join(" ") }>
                  
                  <div className={[ this.state.isReading?"load":"unload" ] }> </div>
                  
                  <div className={ [ "order"  ].join(" ") }>
                        { !this.state.isTLogin && ( (this.state.width <= 700)  ) &&
                          <div className={ [ "btn-holder"  ].join(" ") } >
                            <Button 
                              onClick={ (e)=> {
                                this.setState({
                                  showLogin : !this.state.showLogin
                                })
                              } }
                              className={ [ "login"  ].join(" ") }> 
                              {
                                this.state.showLogin?this.props.gi18n[this.props.language]["t_hide"]:this.props.gi18n[this.props.language]["login"]
                              } 
                            </Button>
                          </div>
                        }
                        {
                          this.state.isTLogin && 
                          <TSummary login={this.login} updateValue={this.updateValue} />
                        }
                        { !this.state.isTLogin && ( (this.state.width > 700) || ( (this.state.width <= 700) && this.state.showLogin )  ) &&
                          <TLogin {...this.props} username={this.state.username} password={this.state.password} updateValue={this.updateValue} login={this.login} />
                        }
                  </div>
                  <div className={ [ "record" ].join(" ") }>
                    
                  <Tabs 
                    onSelect={ (e) => this.selectKey(e) }
                    activeKey={ this.state.selectedTab }
                    defaultActiveKey={  this.state.tabs[0].key  } 
                    id="trader-tab">
                    { this.state.tabs.map( (v, k) => {

                      var ln = this.props.forwardGroup, ct=0 
                      for (var i in ln ) {
                        if( v.key  in ln[i] ) {
                          if( v.key in ln[i]) {
                            ct += ln[i][ v.key ].length
                          }
                        }
                      }
                      
                      return (
                        <Tab key={k} eventKey={v.key} title={   this.props.gi18n[ this.props.language][ "trader_" +v.value].replace( "<num>", ct )  } >
                          <div className={ [ "record-content" ].join(" ") }>
                            { (v.key === "action") &&
                              <div className={ [ "panel" ].join(" ") }>
                                <div className={['switch'].join(" ")}>

                                  <div className={['btn'].join(' ')}  onClick={ (e)=>{
                                      this.setState({
                                        isAdd : !this.state.isAdd
                                      })
                                    }}>
                                    <FontAwesomeIcon  icon={faPlus}  />
                                    <span> { this.props.gi18n[this.props.language]['t_insert'] } </span>
                                  </div>

                                  <div className={['btn'].join(' ')} onClick={ (e)=>{
                                      this.order()
                                    }}>
                                    <FontAwesomeIcon  icon={faShoppingCart}  />
                                    <span> { this.props.gi18n[this.props.language]['t_trade'] } </span>
                                  </div>

                                </div>
                                <TInsert isAdd={ this.state.isAdd} group={this.state.group} insert={this.insert} {...this.props} />
                              </div>                              
                            }

                            { this.state.group.map( (v1,k1) => {
                              return(
                                  <Docker 
                                    {...this.props} 
                                    key={k1} vobj={v1} 
                                    isReading={this.state.isReading} 
                                    type={this.state.selectedTab} 
                                    list={v1.list} 
                                    gindex={k1} 
                                    botfolio={v1.group} 
                                    updateList={this.updateList} 
                                    column={ (this.state.width <= 700) } 
                                    selected={ this.state.selected } 
                                    updateValue={this.updateValue} 
                                    updateGroup={this.updateGroup} 
                                    isTLogin={this.state.isTLogin} 
                                    moveToAction={ this.moveToAction }
                                    run={this.run} />
                                )
                              } )
                            }
                          </div>
                        </Tab>
                        )
                      } )
                    }
                  </Tabs>
                  </div>
                    
                </div>

                  
              </div>

              
            </Resizable>
        </Draggable>
        )
      } else {
        return null
      }
    } 
}


export default Trader;


/*
                            <List obj={this.state.title} title={true} updateList={this.updateList} column={ (this.state.width <= 700) } selected={ this.state.selected } updateValue={this.updateValue} />
                            {
                              this.state.list.map(  (v,k) => {
                                return(
                                  <List key={k} obj={v} index={k} updateList={this.updateList} column={ (this.state.width <= 700) } selected={ this.state.selected } updateValue={this.updateValue}  />
                                )
                              } )
                            }
*/
