import React from "react";
import './index.scss'

import service from '../../public/service'
import {  Button, ButtonGroup   } from 'react-bootstrap';

export default class GarlicanStrategyUploader extends React.Component {

  constructor(props) {
    super(props);  
    this.editor= React.createRef()
    
    this.state = {
      isExist : this.props.selectedStrategy.strategy_template_id?true:false,
      type : 'protected'  //"public" "protected"
    } 
    this.contribute = this.contribute.bind(this)
    this.update = this.update.bind(this)
  }

  componentWillMount() {
   
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(props) {
    this.setState({
      isExist : props.selectedStrategy.strategy_template_id?true:false
    })
  }

  contribute() {
    /*var obj = {}
    obj.name = this.props.selectedStrategy.name
    obj.description = this.props.selectedStrategy.description 
    obj.code = this.props.selectedStrategy.code
    obj.filters = this.props.selectedStrategy.filters
    obj.input = this.props.selectedStrategy.input
    obj.access_mode = this.state.type*/
    this.props.contribute(  this.props.selectedStrategy  )
  }

  update() {
    var obj = {}
    obj.name = this.props.selectedStrategy.name
    obj.description = this.props.selectedStrategy.description 
    obj.code = this.props.selectedStrategy.code
    obj.filters = this.props.selectedStrategy.filters
    obj.input = this.props.selectedStrategy.input
    obj.access_mode = this.state.type
    //console.log( this.props.selectedStrategy )
    //console.log( this.props.template )
    //this.props.updateContribute(   this.props.selectedStrategy.strategy_template_id  , obj  )
    this.props.updateContribute(   this.props.template.id  , obj  )
  }

  render() {

    return (
      <div className={['Garlican-Strategy-Uploader'  ].join(' ')} >

        { this.props.gi18n[this.props.language]['contribute_msg_'+this.state.type].split("\n").map(
          (v,k) => {
            return (
              <h4 className={['message'  ].join(' ')} key={k}> { v } </h4>
            )
          }
        )}

        <ButtonGroup aria-label="pp" className={['group'  ].join(' ')}>
          <Button className={  ((this.state.type==="protected")|(this.state.type==="private")) ?'enable':'disable'  } variant="secondary" onClick={ (e)=>this.setState({ type : 'protected' }) } >{  this.props.gi18n[this.props.language]["protected_mode"]  }</Button>
          <Button className={  this.state.type==="public"?'enable':'disable'   } variant="secondary" onClick={ (e)=>this.setState({ type : 'public' }) } >{  this.props.gi18n[this.props.language]["public_mode"]  }</Button>
        </ButtonGroup>

        { !this.state.isExist &&
          <Button className={[ 'button'  ].join(' ')} onClick={ (e)=>{
            this.contribute()
          } } >  { this.props.gi18n[this.props.language]["contribute"]  } </Button>
        }
        { this.state.isExist &&
          <Button className={[ 'button'  ].join(' ')} onClick={ (e)=>{
            this.update()
          }}>  { this.props.gi18n[this.props.language]["update"] } </Button>
        }

      </div>
    );
  }
}



