import React from "react";
import gglobal from '../GarlicanLibrary/gvars'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import IDE from './ide'
import TEXT from './text'

import Apis from '../GarlicanLibrary/apis'
import GService from '../GarlicanLibrary/gservice'

import {  Button, Toast, FormControl, InputGroup, Modal   } from 'react-bootstrap';

import './index.scss'



const templates = window.templates


export default class GarlicanIDEPop extends React.Component {

  constructor(props) {
    super(props);  
    var codeObj = null, code = null, buy = null, sell = null

    codeObj = GService.getBSCode( props.selectedStrategy.code)
    code = codeObj['code'].replace(/\r/g, "")
    buy = codeObj['buy'].replace(/\r/g, "")
    sell = codeObj['sell'].replace(/\r/g, "")

    this.state = {
      //isIDEShow : false,
      savedObj : props.selectedStrategy,
      isBuy : (props.type==="buy"),
      selectedLineNumberT : {},
      selectedLineNumberC : {},
      fullcode : code,
      code : (props.type==="buy")?buy:sell,
      startline : (props.type==="buy")?codeObj['start_buy']:codeObj['start_sell'],
      buy : buy,
      sell : sell,
      treemap : null,
      id : null,
      currentObj : null,
      isError : false,
      error_msg : null,
      error_line : null,
      error_opvar : null,
      res:null,
      isLoading : false,
      ori_o : {},
      overrides : {},
      templates : [],
      tempTemplates : [],
      selectedTemplate : null,
      showEditor : false,
      locked : null,
    } 

    this.modal = React.createRef()
    this.textmodal = React.createRef()
    this.updateValue = this.updateValue.bind(this)
    this.insertLine = this.insertLine.bind(this)
    this.updateCode = this.updateCode.bind(this)
    this.updateCodeTemplate = this.updateCodeTemplate.bind(this)
    this.getObj = this.getObj.bind(this)
    this.checkErrorObj = this.checkErrorObj.bind(this)
    this.showLoading = this.showLoading.bind(this)
    this.hideAll = this.hideAll.bind(this)
    this.reset = this.reset.bind(this)
    this.search = this.search.bind(this)
    this.selectTemplate = this.selectTemplate.bind(this)
    this.changeCode = this.changeCode.bind(this)

    this.treemaping = this.treemaping.bind(this)
    this.error = this.error.bind(this)
  }

  componentDidMount() {
    window.document.pop= this;
    this.treemaping(this.state.fullcode)
    var temp = []
    if(  this.props.type === 'buy' ) {
      temp = window.templates['long_strategy']
    } else {
      temp = window.templates['short_strategy']
    }
    this.setState( {
      templates : temp,
      tempTemplates : temp,
    })
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.resize() )
  }

  componentWillUpdate() {
    
  }

  componentWillReceiveProps(props) {
    /*
    var codeObj = null, code = null, buy = null, sell = null
    codeObj = GService.getBSCode( props.selectedStrategy.code)
    code = codeObj['code']
    buy = codeObj['buy']
    sell = codeObj['sell']
    this.setState({
      buy : buy,
      sell : sell,
      fullcode : code,
      code : (props.type==="buy")?buy:sell,
    })
    this.treemaping(code)*/
  }
  
  showLoading(Bool) {
    this.setState({
      isLoading : Bool,
    })
  }


  treemaping(code) {
    var type = this.props.type
    Apis.getTreemapNewFromCode(code).then(
      (res) => {
        if( 'message' in res ) {
          this.error( this.props.gi18n[this.props.language]['error_msg'].replace("<strat>", ""), null, null, type )
        } else{ 
          var a = GService.reshape(res['treeobj'] , null, this.error , type )
          if(  typeof(a) === 'object' ) {
            for (var i in this.props.group.strategy_list ) {
              if( this.props.group.strategy_list[i].id === this.props.selectedStrategy.id ) {
                this.props.editStrategy( i, ['input', 'code'], [res.input, code] )
                this.props.updateParentValue({ hasError : false })
                var codeObj = null, fullcode = null, buy = null, sell = null
                codeObj = GService.getBSCode( code)
                fullcode = codeObj['code']
                buy = codeObj['buy']
                sell = codeObj['sell']
                this.setState({
                  code : (type==="buy")?buy:sell,
                  fullcode : code,
                  show : true,
                  treemap : res['treeobj'],
                  res : a,
                  buy : buy,
                  sell : sell,
                  startline : (this.props.type==="buy")?codeObj['start_buy']:codeObj['start_sell'],
                })
                break;
              }
            }
          }
        }
      } 
    ).catch(
      (err)=>{
        this.error( this.props.gi18n[this.props.language]['error_msg'].replace("<strat>", ""), null, null , type )
        this.setState({
          //code : (this.props.type==="buy")?this.state.buy:this.state.sell,
          show : true,
        })
      }
    )
  }
  
  updateCodeTemplate(code) {

    var codes = code.split("\n"+ GService.split_cond +"\n")
    if ( codes.length === 1 ) {
      var codeObj = GService.updateExitStrategy(  code   )
      code = codeObj['code']
    }
    this.updateCode(code, true)

  }

  updateCode(e, update) {
    if(e) {
      e = e?e:this.state.code
      e = e.replace(/\r/g,"")
      this.setState(  { error_msg : null }  )
      var code = ""
      if( this.props.type === 'buy' ) {
        code = this.props.selectedStrategy.code.split( GService.split_cond )[1]
        if( encodeURI(e).substr( (encodeURI(e).length-3) , 3) !== "%0A" ) {
          e =  e + "\n"
        }
        code = (e + GService.split_cond + this.state.sell)
      } else if( this.props.type === 'sell' ) {
        code = this.props.selectedStrategy.code.split( GService.split_cond )[0]
        if( encodeURI(code).substr( (encodeURI(code).length-3) , 3) !== "%0A" ) {
          code =  code + "\n"
        }
        code = (this.state.buy + GService.split_cond + e)
      }
      code = code.replace(/\r/g,"")
      if( update ) {
        for (var i in this.props.group.strategy_list ) {
          if( this.props.group.strategy_list[i].id === this.props.selectedStrategy.id ) {
            //this.props.editStrategy( i, 'strategy_template_id', null )
            this.treemaping(code) 
            break
          }
        }
        //this.treemaping(code) 
      } else {
        //this.treemaping(code) 
        this.setState( { code : e, fullcode : code, show : true, } )
      }
    }


  }

  checkErrorObj(msg, line, index) {
    if (!this.state.error_msg ) {
      this.setState({ 
        isError : msg?(this.textmodal.current?false:true):false , 
        error_msg : msg,  
        error_line : line,
        error_opvar : index,
      })
      this.showLoading(false)


    } 
  }

  getObj() {
    return {treemap: this.state.treemap, res : this.state.res }
  }

  insertLine(line, add, value, replace) {
    var code = this.state.fullcode;
    var codes = code.split( "\n" + GService.split_cond + "\n"  )
    
    if (line) {
      line = (line-1)
    } else if ( this.state.isBuy ) {
      line = 0
    } else {
      var buy = codes[0].split("\n")
      line = buy.length+2
    }
    var _dummy = value
    if(!_dummy) {
      var _dummy = GService.dummy_code.name+Math.round(Math.random(1)*1000)
      _dummy += GService.dummy_code.body
    }
    var lines = code.split("\n")
    var obj = null, _t = "", valid =false
    for (var i=line; i>0; i--) {
      obj = this.state.treemap[ i ]
      if(obj && add ) {
        var keys = Object.keys(this.state.treemap)
        for ( var j=(i+1); j< keys[ keys.length-1 ]  ;j++ ) {
          if(j in this.state.treemap) {
            var _o = this.state.treemap[j]
            var cond1 = _o.start_else & (_o.if > obj.if)
            var cond2 = _o.start_condition & !_o.start_else
            var cond3 = _o.start_loop 

            if( !cond1 && !cond2 && !cond3 ) {
              obj = this.state.treemap[j]
              //this.treemaping
            } else {

            }
            break
          }
        }
        break
      } else {
        break
      }

    }

    if(obj) {
      _t = Array.from( {length: (obj.if+obj.for)  }, (x,i) => "\t" )
      _t = _t.join("") 
    } else {
      var tpp = JSON.stringify(this.state.treemap)
      obj = GService.getFuzzyObject(  JSON.parse(tpp), line, false   )
      _t = Array.from( {length: (obj.if+obj.for)  }, (x,i) => "\t" )
      _t = _t.join("") 
    }


    var _dd = _dummy.split("\n")
    for(var i in _dd) {
      _dd[i] = (_t+_dd[i])
    }


    if(replace) {
      lines.splice( line, 1  )
    }

    lines.splice( line, 0, _dd.join("\n")   )

    
    code = lines.join("\n")

    //console.log(code)

    //this.updateCodeTemplate(code)
    //this.setState({ overwrite:true })
    this.updateCode( code, true )
  }

  updateValue( obj ) {
    this.setState( obj )
  }

  reset() {
    this.setState({
      serachTemplate : "",
      tempTemplates : this.state.templates
    })
  }

  search(str) {
    var t = JSON.parse(  JSON.stringify( this.state.templates )  )
    var _arr = []
    for (var i in t) {
      var nmatch = Object.values( t[i].name )
      var dmatch = Object.values( t[i].description )
      nmatch = nmatch.join("|")
      dmatch = dmatch.join("|")
      if( (nmatch.toLowerCase().indexOf(str.toLowerCase()) > -1 ) || (dmatch.toLowerCase().indexOf(str.toLowerCase()) > -1 ) ) {
        _arr.push(  t[i]   )
      }
    }
    this.setState({
      tempTemplates : _arr
    })
  }

  hideAll() {
    //console.log("HIDE")
  }

  selectTemplate(v) {
    //console.log(v)
    this.setState({
      selectedTemplate : JSON.parse( JSON.stringify(v) ), 
      showEditor : true,
    })

  }

  changeCode(e) {
      this.setState({
        error_msg : null,
        showEditor : false, 
      })
      this.props.updateValue({
        showBuyTemplate : false,
        showSellTemplate : false,
      })
      this.updateCode(e, true)
  }

  error(e, _i, o, type) {
    var _this = this
    setTimeout( ()=> {
      _this.props.updateValue( {
        selected : type,
      })
      _this.setState(  { error_msg : e }  )
      _this.props.updateParentValue(
        { showEditor : true, hasError : true, }
      )
    }, 500  )
  }

  render() {
    return (
      <div id={"Garlican-Container"} className={[ 'Garlican-Container' ].join(' ')}>
        
        <div className={['control'].join(" ") } >
            <Button className={[ 'left' ].join(' ')}  onClick={(e)=> {
              //this.setState({
              //  isIDEShow : !this.state.isIDEShow
              //})
              this.props.updateValue({
                isIDEShow : !this.props.isIDEShow
              })
            } }>  { this.props.isIDEShow?this.props.gi18n[this.props.language]["half"]:this.props.gi18n[this.props.language]["full"] }  </Button>

						<Button variant="secondary" onClick={
								(e)=>{
									var obj = {  }
                  if( this.props.type === "sell" ) {
                    obj = { showSellTemplate : !this.props.showSellTemplate }
                  } else {
                    obj = { showBuyTemplate : !this.props.showBuyTemplate }
                  }
                  this.props.updateValue(obj)
								}
						}>{  (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['sample']:'' }</Button>
        </div>

        <div className={['template' ,  ((this.props.type==="sell")?this.props.showSellTemplate:this.props.showBuyTemplate)?"show":"hide"].join(' ')}>
          
          <InputGroup className={["search", "mb-3"].join(' ')}>
							<FormControl
								placeholder={ this.props.gi18n[this.props.language]['search_template'] }
								aria-label={ this.props.gi18n[this.props.language]['search_template'] }
								aria-describedby="basic-addon2"
								value={ this.state.serachTemplate }
								onChange={ (e)=>{
										if( e.target.value.length === 0 ) {
											this.reset()
										} else {
											this.search( e.target.value )
										}
										this.setState({
											serachTemplate : e.target.value
										})
								}}
							/>
								<Button variant="outline-secondary" 
									className={["fa", 'reset'].join(' ')}
										onClick={
											(e) => {
												this.reset(  )
											}
										}>
									{ this.props.gi18n[this.props.language]['reset_template'] }
								</Button>
								<Button variant="outline-secondary" 
									onClick={
										(e) => {
											this.search( this.state.serachTemplate)
										}
									}>
									<FontAwesomeIcon 
											className={["fa"].join(' ')}
											icon={ faSearch } 
									/>
								</Button>
						</InputGroup>
            
            {
              this.state.tempTemplates.map( (v,k) => {
                return(
                  <Toast
                    className={['list'  ].join(' ')}
                    key={k}
                    onClick={ (e)=>{
                      this.props.updateValue({
                        showBuySTemplate : false,
                        showSellSTemplate : false,
                      })
                      this.selectTemplate(v)
                    }  } 
                  > 
                    <Toast.Header closeButton={false}> 
                      <strong className="me-auto">{  v.name[ this.props.language ]  }</strong> 
                    </Toast.Header>
                    <Toast.Body>
                        {  v.description[ this.props.language ]  }
                    </Toast.Body>
                  </Toast>
                )
              })
            }
        </div>

        <Modal 
          centered
          className={['Garlican-IDE-Modal'  ].join(' ')}
          show={this.state.showEditor} onHide={ (e)=>this.setState({  showEditor : false }) }>
          <Modal.Header closeButton>
            <Modal.Title>  {  this.state.selectedTemplate?this.state.selectedTemplate.name[this.props.language]:'NAME' }   </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{  this.state.selectedTemplate?this.state.selectedTemplate.description[this.props.language]:'NAME' } </p>
              { this.state.selectedTemplate &&
                Object.keys(this.state.selectedTemplate.vars).map(  (v,k) => {
                  return(
                    <InputGroup className="mb-3" key={k}>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">   {v}  </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder=""
                        aria-label="Value"
                        aria-describedby="basic-addon1"
                        value={ this.state.selectedTemplate.vars[v] }
                        onChange={
                          (e) => {
                            var o = this.state.selectedTemplate
                            o.vars[ v ] = e.target.value
                            this.setState( {  selectedTemplate : o } )
                          }
                        }
                      />
                    </InputGroup>
                  )
                } )
                
              }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={ (e) => {
              var code = this.state.selectedTemplate.code
              for (var i in this.state.selectedTemplate.vars) {
                code = code.replace( "<" + i + ">"  ,  this.state.selectedTemplate.vars[i]  )
              }

              for (i in this.state.selectedTemplate.comments) {
                code = code.replace( "<" + i + ">"  ,  this.state.selectedTemplate.comments[i][this.props.language]  )
              }
              var _this = this
              this.setState({
                show : false
              })
              this.hideAll()
              setTimeout(
                (e)=>{
                  _this.changeCode(code)
                }, 500
              )
            } } >
              {  (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['save']:'SAVE'  }
            </Button>
          </Modal.Footer>
        </Modal>


        <div className={[ 'holder' ].join(' ')}>
          <IDE 
            locked={this.state.locked}
            isIDEShow={this.props.isIDEShow} 
            fullcode={this.state.fullcode}
            code={this.state.code} 
            startline={this.state.startline}
            updateCode={this.updateCode} 
            values={this.state} 
            updateValue={this.updateValue} 
            error_msg={this.state.error_msg} 
            error_line={this.state.error_line} 
            error_opvar={this.state.error_opvar} 
            isError={this.state.isError} 
            isBuy={this.state.isBuy}  
            error_line={this.state.error_line} 
            error_opvar={this.state.error_opvar} 
            selectedLineNumber={this.state.selectedLineNumberC} 
            gi18n={this.props.gi18n} 
            language={this.props.language}
            savedObj={this.props.savedObj}   />

            { !this.props.isIDEShow && 
            <TEXT 
              locked={this.state.locked}
              ref={this.textmodal} 
              isIDEShow={this.props.isIDEShow} 
              error_msg={this.state.error_msg} 
              error_line={this.state.error_line } 
              error_opvar={this.state.error_opvar} 
              treemap={this.state.treemap} 
              res={this.state.res} 
              code={this.state.code} 
              fullcode={this.state.fullcode}
              checkErrorObj={this.checkErrorObj} 
              insertLine={this.insertLine} 
              updateValue={this.updateValue} 
              isBuy={ this.state.isBuy } 
              updateCode={this.updateCode} 
              isError={ this.state.isError} 
              selectedLineNumber={this.state.selectedLineNumberT} 
              error_line={this.state.error_line} 
              error_opvar={this.state.error_opvar} 
              gi18n={this.props.gi18n} 
              language={this.props.language} /> 
          }



        </div>




      </div>
    );
  }
}

/*
          { !this.state.isIDEShow && 
            <TEXT 
              locked={this.state.locked}
              ref={this.textmodal} 
              isIDEShow={this.state.isIDEShow} 
              error_msg={this.state.error_msg} 
              error_line={this.state.error_line } 
              error_opvar={this.state.error_opvar} 
              treemap={this.state.treemap} 
              res={this.state.res} 
              code={this.state.code} 
              checkErrorObj={this.checkErrorObj} 
              insertLine={this.insertLine} 
              updateValue={this.updateValue} 
              isBuy={ this.state.isBuy } 
              updateCode={this.updateCode} 
              isError={ this.state.isError} 
              selectedLineNumber={this.state.selectedLineNumberT} 
              error_line={this.state.error_line} 
              error_opvar={this.state.error_opvar} 
              gi18n={this.props.gi18n} 
              language={this.props.language} /> 
          }
          */
