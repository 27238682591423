import React, { Component } from 'react';
import './App.scss';

//import { w3cwebsocket as W3CWebSocket } from "websocket";

import service from './public/service'
import GService from './Garlican/GarlicanLibrary/gservice';
import Api from './Garlican/GarlicanLibrary/apis'
import gcolor from './public/style.scss';

import logoB from './public/img/garlican_blue_logo.png'
import Login from './Login/'
import User from './User'
import Controller from './Controller/'
import Footer from './Footer'

import Alert from './Components/Alert'
//import OffCanvas from './Components/OffCanvas'
import CreatePanel from './Components/CreatePanel'
import Trader from './Components/Trader'

import Garlican from './Garlican/'
import Overview from './Overview/'
import Portfolio from './Portfolio/'

import Manager from './Manager/'


import PrivacyScreen from './Other/Privacy/PrivacyScreen'
import TermsScreen from './Other/Terms/TermsScreen'
import SupportScreen from './Other/Support/SupportScreen'

import { Modal, Spinner, Image } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'


//import celebrity from './public/celebrity'
//import { isConstructorDeclaration } from 'typescript';

var MAX_ATTP = 5
const defaultColor = gcolor.gnBadgeColor
var colors = []
var ColorScheme = require('color-scheme');
var scheme = new ColorScheme;
scheme.from_hue(50)        // Start the scheme 
    .distance(0.25)
	.scheme('analogic')     // Use the 'triade' scheme, that is, colors
                             // selected from 3 points equidistant around
                             // the color wheel.
	.variation('soft')   // Use the 'soft' color variation
colors = scheme.colors(defaultColor);
colors.splice( 0, 0 , defaultColor.replace("#", "") )
colors =  Array.from( {length: colors.length  }, (x,i) => "#" + colors[i] )


const RETRY = 60000

const pages = [
	{ key : '/',value : 'overview',comp : Overview },
	{ key : 'me',value : 'overview2', comp : Overview },
	{ key : 'bookmark',value : 'overview2', comp : Overview },
	{ key : 'garlican',value : 'garlican', comp : Garlican, type : 'popup' },
	{ key : 'manager',value : 'manager', comp : Manager, frame : 'dark'  },
	{ key : 'privacy',value : 'privacy', comp : PrivacyScreen},
	{ key : 'terms',value : 'terms', comp : TermsScreen },
	{ key : 'support',value : 'support', comp : SupportScreen },
	{ key : 'trader',value : 'trader', comp : Trader, type : 'pop' },
	{ key : ':user_id',value : 'overview2', comp : Overview },
	{ key : ':user_id/:portfolio_id',value : 'overview2', comp : Overview },
	{ key : 'forget_password',value : 'overview', comp : Overview },
	{ key : '/*' , comp : Redirect }
]

class App extends Component {
  	
  	constructor(props) {
  		super(props);
		
		var isSimulate = true, isHost = true, zcode=null, isCallBack=false
		var language = service.checkSystemLanguage()
		var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
		var _lang  = storage.getItem("garlican@language")
		if ( _lang ) {
			for ( var i in window.map) {
				if( window.map[i].key === _lang ) {
					language = _lang
					break
				}
			}			
		} 

		var refs = {}
		for (var p in pages) {
			refs[ pages[p].value ] = React.createRef()
		}

		this.state = {
			gi18n : {},
			languages : [],
			alertMessage : '',
			scrollTop : 0,
			selectedPortfolio : null,
			showLogin : false,
			showSignup : false,
			showUser : false,
			showAlert : false,
			showGarlican : false,
			showPortfolio : false,
			editedPortfolio : {},
			isCallBack : isCallBack,
			isSimulate : isSimulate,
			language : language,
			isLogin :  false,
			display_name : "",
			token : "",
			e_token : "e_token",
			refresh_token : "refresh",
			username : service.detect()?'test1':'test2',
			password : 'password',
			rooms : [],
			roomname : 'test1',
			isHost: isHost,
			jid : null,
			isYoutube : null,
			showLoading : false,
			showSelection : false,
			showCanvas : false,
			selectedUser : null,
			filterconfig : {},
			retrieve_key : null,
			refs : refs,
			showTrader : false,
			child : null,
			tpage : null,
			forwardGroup : [],
			onhold : {},
			sym_names : {},
		}
		if( !isCallBack ) {
			var tokens = storage.getItem("garlican@tokens")
			//console.log(  tokens )
			var obj = {}
			if( tokens ) {
				try {
					tokens = JSON.parse(tokens)
					obj.refresh = tokens.refresh
					obj.username = tokens.username
					obj.id = tokens.id
					if( obj.refresh ) {
						this.getRefresh( obj.username , obj.refresh, obj.id )
					}
				}
				catch(e) {
					//console.log(e)
				}
			}
		}

		this.updateValue = this.updateValue.bind(this)
		this.connected = this.connected.bind(this)
		this.hasLogin = this.hasLogin.bind(this)
		this.getRefresh = this.getRefresh.bind(this)
		this.scroll = this.scroll.bind(this)
		this.logout = this.logout.bind(this)
		this.openAlert = this.openAlert.bind(this)
		this.closeAlert = this.closeAlert.bind(this)
		this.getPortfolioDetailById = this.getPortfolioDetailById.bind(this)
		this.createLangaugePack = this.createLangaugePack.bind(this)
		this.callList = this.callList.bind(this)

		this.app = React.createRef()
		//this.xmpp = null
		this.room = React.createRef()

		this.pop = this.pop.bind(this)
		this.unpop = this.unpop.bind(this)
		this.call_parent = this.call_parent.bind(this)

		this.getOnholdAsset = this.getOnholdAsset.bind(this)
		this.rebuildAsset = this.rebuildAsset.bind(this)
		this.updateFG = this.updateFG.bind(this)
  	}

	componentWillMount() {

		Api.getFilterConfig().then(
			(res) => {
				var sym_names = res['names']
				delete res['names']
				this.setState({ filterconfig : res, sym_names : sym_names })
				this.createLangaugePack( {},  0)
			}
		)
	}

	componentDidMount() {
		window.document.page = this
		window.addEventListener("beforeunload", (ev) => {  
			ev.preventDefault();
			if( this.state.child ) {
				//window.opener.call_app( "page" , "test" , "CLOSE"   )
				this.state.child.close()
			}
			return ev.returnValue = 'Are you sure you want to close?';
		});

	}

	call_parent(msg, bool) {
		alert(msg)
		//if(this.state.child) {
		//	this.state.child.call_app("trader", "show", "Connected")
		//}
	}

	componentWillUnmount() {
		//this.xmpp.destroyRoom(this.state.roomname + "_room" );
		//this.disconnect()
	}
	 
	componentWillReceiveProps(props) {
		
	}

	createLangaugePack( g,  attempt) {
		
		if(  Object.keys( g ).length !== window.map.length ) {
			g = {}
			if( attempt < MAX_ATTP ) {
				var _this = this
				setTimeout( (e)=>{
					for (var l in window.map) {
						if( window.map[l].key in window )
							g[ window.map[l].key ] = window[ window.map[l].key ]
					}
					if(  Object.keys( g ).length > 0 ) {
						for (var m in window.map ) {
							for (var f in window.func) {
								var key = Object.keys( window.func[f].desc )
								var o_obj = window.func[f].desc[  key[0]  ]
								if(  !( window.map[m].key in window.func[f].desc)  ) {
									window.func[f].desc[  window.map[m].key] = o_obj
								}
							}
							var typ = ['long_strategy', 'short_strategy', 'full_strategy']
							for( var t in typ) {
								for (f in window.templates[typ[t]] ) {	
									key = Object.keys( window.templates[typ[t]][f].description )
									var o_obj = window.templates[  typ[t]  ][f].description[  key[0]  ]
									if(  !( window.map[m].key in window.templates[typ[t]][f].description)  ) {
										window.templates[typ[t]][f].description[  window.map[m].key] = o_obj
									}
								}
							}

						}

						_this.setState({
							languages : window.map,
							gi18n : g
						})
						
					}else {
						_this.createLangaugePack( g, attempt )
					}
				} , 500 )
			} else {
				return 
			}
		} else {
			return 
		}
	}

	getPortfolioDetailById(obj) {
		// //this.props.updateValue({ editedPortfolio : this.state.obj, showGarlican : true  })
		/*service.getPortfolioDetailById(  obj.id  ).then(
			(res)=> {
				console.log( res )
				this.setState({ editedPortfolio : res, showGarlican : true  })
			}
		)*/
		var ed = JSON.parse( JSON.stringify(obj) )
		this.setState({ editedPortfolio : ed, showGarlican : true  })
	}

	getRefresh(username, token, id) {
		//console.log(token)
		service.refreshtoken(token).then(
			(res)=> {
				if(  'access' in res ) {
					var obj = {}
					service.user.user_id = parseInt(id, 10)
					obj.refresh = token
					obj.access = res.access
					obj.username = username
					service.token = res.access
					//service.getEjabberdToken().then(
					//	(r) => {
							service.getUserProfile().then(
								(user) => {
									//obj.e_token = r.token
									obj.id = user.user_id
									var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
									storage.setItem("garlican@tokens", JSON.stringify(obj) )
									service.user = user
									obj.isLogin = true
									obj.jid = user.user_id
									obj.id = user.user_id
									obj.showLogin = false
									obj.selectedUser = user
									// SKIP
									//this.connect(obj.username, obj.e_token)
									this.hasLogin(obj.username, obj.e_token)
									this.setState( obj ) 
								}
							)

							 
					/*	}
					).catah(
						(e) => {
							service.logout()
							this.setState({
								isLogin : false,
								showLogin : false
							})
						}
					)*/
				} else {
					service.logout()
					this.setState({
						isLogin : false,
						showLogin : false
					})
				}
			}
		).catch(
			(e) => {
				service.logout()
				this.setState({
					isLogin : false,
					showLogin : false
				})
			}
		)
	}

	hasLogin(username, password) {
		//WAIT
		//this.connect( username , password )
		//this.discoverChatRooms()
		//console.log("A")
		this.connected(true)
		//console.log("Here")
	}

	disconnect() {
		if(this.xmpp !== undefined) {
			this.xmpp.destroyRoom(this.state.roomname );
			this.xmpp.stop();
			//this.setState({xmpp : this.xmpp } )
		}
	}

	updateValue(obj) {
		/*if( 'type' in obj) {
			if( this.state.isLogin ) {
				this.discoverChatRooms()
			}
		}*/
		if ( 'language' in obj ) {
			var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
			storage.setItem( "garlican@language" , obj['language'] )
		}

		//if( "forwardGroup" in obj ) {
		//	console.log( obj )
		//}

		this.setState(obj)
	}

	connect(username, password) {
		//this.xmpp = new Client("wss://xmpp.garlican.com:5280/ws", "xmpp.garlican.com", username, password, this.connected);
		//this.xmpp.start();  
		//var a = new Client()
	}

	connected(BOOL) {
		/*
		var obj = {
			"userId": 'APIA1',
			"password": "1234"
		}
		service.sptest(obj).then(
			(res) => {
				//console.log(res)
				if( 'data' in res ) {
					const spServerKey = res['data']['spServerKey']
					const systemId = res['data']['systemId']
					const session_token = res['data']['sessionToken']
					const session_time = res['data']['sessionTime']
					var st = "4104,0,"+obj['userId']+","+ spServerKey +",3,8.7,1.0,1.0," + systemId + ","+ session_time +",0\n\r"
					
					const client = new W3CWebSocket('ws://118.140.87.173:8084');

					

					client.onopen = () => {
						console.log('WebSocket Client Connected');
						client.send( st ) 
					};

					client.onmessage = (message) => {
						console.log(message);
						setTimeout( (e) => {
							var st2 = "4107,3,0005.HK,0,1,0\n\r"
							client.send( st2 ) 
						}, 1000)
					};


					
				}
			}
		)
		*/
			

		if(BOOL) {
			this.setState( { showLoading : false  } )

			this.rebuildAsset()
			
		} else {
			var _this= this
		}
	}

	scroll(e) {
		/*if( (e.currentTarget.scrollTop <= 160) || this.state.scrollTop>=160  ) {
			console.log( e.currentTarget.scrollTop  )
			var val = (e.currentTarget.scrollTop>=160)?160:e.currentTarget.scrollTop
			this.setState({ scrollTop : val })
		}*/
	}

	logout() {
		service.logout()
		this.openAlert( { header : this.state.gi18n[this.state.language]['logout'], body : this.state.gi18n[this.state.language]['logout_message']  } )
		this.setState({ isLogin : false, showTrader : false })
	}

	openAlert(msg) {
		this.setState({ showAlert : true,  alertMessage : msg })
	}

	closeAlert() {
		this.setState({ showAlert : false, alertMessage : '' })
	}

	callList() {
		var keys = ['overview', 'overview2']
		for (var i in keys ) {
			if( this.state.refs[  keys[i]  ] ) {
				if( this.state.refs[ keys[i] ].current ) {
					this.state.refs[ keys[i] ].current.getPortfolio()
				}
			}
		}
	}

	pop(){
		if( !this.state.child ) {
			this.setState({showTrader:false})
			//var windowFeatures = "menubar=no,location=no,resizable=yes,scrollbars=no,status=no";
			//',resizable=yes,scrollbars=yes,toolbar=no,titlebar=no,location=no,directories=no,status=no,menubar=no,copyhistory=no'
			var windowFeatures = "directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=yes,copyhistory=no,width=800,height=600"
			var url = window.location.origin + "/trader"
			var child = window.open( url, "garlican" , windowFeatures   )
			this.setState({
				child : child
			})
		} else {
			this.state.child.close()
			this.setState({
				child : null
			})
		}

		//setTimeout( () => {
		//	console.log( child )
		//}, 2000 )
    }

	unpop() {
		this.setState({
			showTrader:false,
			forwardGroup : [],
		})
	}

	rebuildAsset() {
		this.setState({
			forwardGroup : [],
		})
		service.rebuildAsset().then( 
			(res) => {
			  //console.log(res)
			  //this.getOnholdAsset()
			  var obj = {}
			  for (var i in res.list) {
				  if( !(res.list[i].portfolio_id in obj) ) {
					  obj[res.list[i].portfolio_id] = []
				  }
				  obj[res.list[i].portfolio_id].push(  res.list[i]   )
			  }
			  this.updateFG(obj)
			}
		)
	}

	getOnholdAsset() {
		service.getAssetList().then( 
			(res) => {
				var obj = {}
				for (var i in res.list) {
					if( !(res.list[i].portfolio_id in obj) ) {
						obj[res.list[i].portfolio_id] = []
					}
					obj[res.list[i].portfolio_id].push(  res.list[i]   )
				}
				this.updateFG(obj)
				//this.setState( {
				//	onhold : obj
				//})
			}
		)
	}

	updateFG( obj ) {
		var _this = this
		var keys = Object.keys( obj )
		var fg = JSON.parse(   JSON.stringify( _this.state.forwardGroup ) )
		function _read(idx) {
		  var pid = parseInt( keys[idx] , 10 )
		  service.getPortfoilioById( pid  ).then(
			(res) => {
			  var o = {}
			  o['action'] = []
			  o['onhold'] = obj[  keys[idx] ]
			  o['group'] = res
			  var valid = true
			  for (var i in fg) {
				if( (fg[i].group.id === pid) || (fg[i].group.id ===  keys[idx]) ) {
				  valid = false
				  break
				}
			  }
			  if( valid ) {
				fg.push(  o )
			  }
			  if( idx < keys.length-1 ) {
				//console.log( idx + "|" + keys.length )
				idx += 1
				_read(  idx  )
			  } else {  
				//console.log(fg)
				_this.setState({
					forwardGroup : fg,
					onhold : obj,
					tpage : "onhold"
				})
			  }
			}
		  )
		}
		_read(0)
	}


  	render() {
  		return (
    		<div ref={this.app} className={["App", "App-Container"].join(' ')} id={"app-content"} onScroll={(e)=>{this.scroll(e)} } style={  { /*top : Math.max(120-this.state.scrollTop, 50) + "px"*/  }  }>

			
			{ Object.keys(  this.state.gi18n  ).length > 0 && (Object.keys(  this.state.filterconfig  ).length > 0) &&
				<Router >
						<Switch>
							{ pages.map(  (v,k) => { 
								if( v.key !== "/*" ) {
									return(
										<Route key={k} exact path={ "/" + v.key }  
											render={ (p) => {
											return([
												( v.type !== "pop" ) && 
													<Controller key={99} type={ v.key } updateValue={this.updateValue} language={ this.state.language } disconnect={this.disconnect} scrollTop={this.state.scrollTop} isLogin={this.state.isLogin} showUser={this.state.showUser} gi18n={this.state.gi18n} frame={ v.frame } manager={ v.key==="manager"} />
												,
												(v.type !== 'popup') && 
													<div key={k+1000} className={[ (  (v.key === "garlican") | (v.type==="pop") | (v.key === "manager") )?"app-holder-garlican":"app-holder"].join(' ')} >
														<v.comp ref={ this.state.refs[v.value] } callList={this.callList} language={ this.state.language } xmpp={this.xmpp} username={this.state.username} isSimulate={this.state.isSimulate} jid={this.state.jid} updateValue={this.updateValue} isLogin={this.state.isLogin} showLogin={this.state.showLogin} openAlert={this.openAlert}  showGarlican={this.state.showGarlican} gi18n={this.state.gi18n} showPortfolio={this.state.showPortfolio} showUser={this.state.showUser} colors={colors} selectedPortfolio={this.state.selectedPortfolio} {...p}  showTrader={ v.type==="pop" } disable={ v.type==="pop" } page={ this.state.tpage?this.state.tpage:"onhold"} onhold={this.state.onhold} filterconfig={this.state.filterconfig} sym_names={this.state.sym_names} />	
													</div>	
												,
												this.state.showLogin && !this.state.isLogin && ( v.type !== "pop" ) &&
													<Login key={100} updateValue={this.updateValue} language={this.state.language} hasLogin={this.hasLogin} showSignup={this.state.showSignup} showLogin={this.state.showLogin} openAlert={this.openAlert} gi18n={this.state.gi18n} retrieve_key={this.state.retrieve_key}/> 
												,
												this.state.isLogin && this.state.showUser && ( v.type !== "pop" ) &&
													<User key={101} updateValue={this.updateValue} language={this.state.language} hasLogin={this.hasLogin} showUser={this.state.showUser} logout={this.logout} openAlert={this.openAlert} gi18n={this.state.gi18n} user={ this.state.selectedUser } /> 
												,
												( v.type !== "pop" ) && <Modal key={102}
														show={  this.state.showLoading  }
														className={ ['app-Loading'].join(' ') }>
														<Modal.Header className={ ['app-Loading-Header'].join(' ') }>
															<Image className={['app-Loading-Header-Logo'].join(" ")} src={logoB} />
															<div className={['app-Loading-Header-Title'].join(" ")} >GARLICAN</div>
														</Modal.Header>
														<Modal.Body className={ ['app-Loading-Frame'].join(' ') } >
															{
																Array.from({length: 8}, (_, i) => i + 1).map( 
																	(v,k) => {
																		return(
																			<Spinner className={ ['app-Loading-Spinner'].join(' ') } key={k} animation="grow" variant="primary" />
																		)
																	}
																)
															}
														</Modal.Body>
														<Modal.Footer className={ ['app-Loading-Word'].join(' ') }>
															{ this.state.gi18n[this.state.language]['loading'] }
														</Modal.Footer>
												</Modal>
												,
												( v.type !== "pop" ) &&<Garlican key={103} language={ this.state.language } xmpp={this.xmpp} username={this.state.username} isSimulate={this.state.isSimulate} jid={this.state.jid} updateValue={this.updateValue} isLogin={this.state.isLogin} showLogin={this.state.showLogin} openAlert={this.openAlert}  getPortfolioDetailById={this.getPortfolioDetailById} showGarlican={this.state.showGarlican} editedPortfolio={this.state.editedPortfolio} colors={colors} gi18n={this.state.gi18n} showCanvas={this.state.showCanvas} filterconfig={this.state.filterconfig} callList={this.callList} frame={ v.frame } sym_names={this.state.sym_names}/>
												,
												( v.type !== "pop" ) &&<Portfolio key={104} language={ this.state.language } callList={this.callList}  xmpp={this.xmpp} username={this.state.username} isSimulate={this.state.isSimulate} jid={this.state.jid} updateValue={this.updateValue} isLogin={this.state.isLogin} showLogin={this.state.showLogin} openAlert={this.openAlert} getPortfolioDetailById={this.getPortfolioDetailById} showGarlican={this.state.showGarlican} showPortfolio={this.state.showPortfolio} selectedPortfolio={this.state.selectedPortfolio} colors={colors} gi18n={this.state.gi18n} showCanvas={this.state.showCanvas} forwardGroup={this.state.forwardGroup} onhold={this.state.onhold} sym_names={this.state.sym_names}/>
												,
												( v.type !== "pop" ) &&<Alert key={105} showAlert={this.state.showAlert} closeAlert={this.closeAlert} message={ this.state.alertMessage} language={this.state.language} gi18n={this.state.gi18n} manager={ v.key==="manager"}/>
												,
												( v.type !== "pop" ) &&<Footer key={106} type={ this.state.type } updateValue={this.updateValue} language={ this.state.language } gi18n={this.state.gi18n} showTrader={this.state.showTrader} child={this.state.child} pop={this.pop} isLogin={this.state.isLogin} frame={ v.frame } manager={ v.key==="manager"} /> 
												,
												( v.type !== "pop" ) &&<CreatePanel key={107} updateValue={this.updateValue} language={ this.state.language } gi18n={this.state.gi18n} showSelection={this.state.showSelection} refs={this.state.refs} isLogin={this.state.isLogin} />										
												,
												//( v.type !== "pop" ) &&<OffCanvas key={108} show={this.state.showCanvas} updateValue={this.updateValue} selectedPortfolio={ this.state.selectedPortfolio } gi18n={this.state.gi18n} language={this.state.language} filterconfig={this.state.filterconfig} openAlert={this.openAlert}  />
												//,
														
											])
										}}>
										</Route>
									)
								} else {
									return(
										<Route key={k} path="/*">
											<Redirect to="/" /> 
										</Route>
									)
								}
							})
	  					}
					</Switch>
				</Router>

			}
				<Trader 
					updateValue={this.updateValue} 
					language={ this.state.language } 
					gi18n={this.state.gi18n} 
					showTrader={this.state.showTrader} 
					refs={this.state.refs} 
					isLogin={this.state.isLogin} 
					disable={false} 
					pop={this.pop} 
					page={ this.state.tpage?this.state.tpage:"onhold"} 
					selectedPortfolio={ this.state.selectedPortfolio } 
					gi18n={this.state.gi18n} language={this.state.language} 
					filterconfig={this.state.filterconfig} 
					sym_names={this.state.sym_names}
					openAlert={this.openAlert} 
					onhold={ this.state.onhold }
					rebuildAsset={this.rebuildAsset}
					forwardGroup={this.state.forwardGroup} />	
			</div>
	  	);
  	}

}

export default App;

/*
<div className={[ (v.key === "garlican")?"app-holder-garlican":"app-holder"].join(' ')} >*/