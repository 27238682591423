"use strict";

import { ifError } from 'assert';
import service from '../../public/service'

require('es6-promise').polyfill();
require('isomorphic-fetch');

var Apis =  {
    usrt_id : 1, 
    transformUTF : function(s, e) {
        if(e) {
            return unescape(encodeURIComponent( s ) );
        } else {
            return decodeURIComponent(escape(s));
        }
    },
    getCodeFromSignalID: function(id) {
        var form = {}
        if(id)
            form = {'stra' : id}
        var url = window.config.api + "getsignal"
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(  form  ),
            }
        ).then(
            (response) => response.json()
        )
    },
    
    getTreemapNewFromCode : function(code, isConnectedCpp) {
        var _this = this
        if( !isConnectedCpp ) {
            //var url = window.config.api + "treemapping2"
            var url = window.config.apil + "treemapping"
            var form = {}
            if(code != null & code.length > 0) {
                form = {  "code" : code  }
            }
            return fetch(
                url, 
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + service.token
                    },
                    body: JSON.stringify( form )
                }
            ).then(
                (response) => response.json()  
            )
        } else {
            return fetch(
                "", 
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
              ).then(
                (response) => {
                    var obj = null
                    if( 'getMap' in window.external ) {
                        var txt = window.external.getMap( code )
                        obj = JSON.parse(   _this.transformUTF(txt,false)  )
                    }
                   return obj;
                }
            )
        }
    },

    updateCodeFromSignalID : function(id, code) {
        var form = {}
        if (id != null) {
            form = { "stra" :id, "code" : code}
        } else {
            //FOR TESING 'SAMPLE'
            form = { "stra" :'SAMPLE', "code" : code}
        }
        var url = window.config.api  + "updatesignal"
        return fetch(
                url, 
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'content-Type': 'application/json',
                    },
                    body: JSON.stringify(form),
                }
        ).then(
            (response) => response.json()
        ) 
    },

    transplier: function(info, params, symbols, code) {
        var url = window.config.api + "transplier"


        if (info.country==='CHN') {
            info = {"country":"CHN","exchange":"A","start_date":"","end_date":"","resolution":"m1" }
        }


        var form = { information : info, params : params, symbols: symbols, code : code }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    backtesting: function(info, params, symbols, code, ords) {
        var url = window.config.api + "backtesting2"
        if (info.country==='CHN') {
            info = {"country":"CHN","exchange":"A","start_date":"","end_date":"","resolution":"m1" }
        }

        info['initial'] = 100000
        info['safety'] = 0.3

        var form = { information : info, params : params, symbols: symbols, code : code }

        if (ords) {
            form['overrides'] = ords
        }

        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    backtesting3: function(info, params, symbols, code, ords) {
        var url = window.config.api + "backtesting3"
        if (info.country==='CHN') {
            info = {"country":"CHN","exchange":"A","start_date":"","end_date":"","resolution":"m1" }
        }
        params['initial'] = ('initial' in params)?params['initial']:100000
        params['safety'] = ('safety' in params)?params['safety']:0.3

        var form = { information : info, params : params, symbols: symbols, code : code }
        if (ords) {
            form['overrides'] = ords
        }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    //backtestingS: function( group ,info, params) {
    backtestingS: function( group , transaction_fee) {
        //var url = window.config.api + "backtestingS"
        var url = window.config.apil + "backtesting"
        var gp = JSON.parse( JSON.stringify(group) )
        var info = {"country": "HK", "exchange": "HKEX", "start_date": "20170101", "end_date": "20171231", "resolution": "d1" }
        var params = {"transaction_fee": parseFloat(transaction_fee) , "leverage":1,"boardlot":100,'scale' :60000000000, 'initial':100000, 'safety':0.3 }
        var form = { information : info, params : params, group : gp }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    forwardtestingS: function( group , symbol_params ,transaction_fee) {
        //var url = window.config.api + "forwardtestingS"
        var url = window.config.apil + "forwardtesting"
        var gp = JSON.parse( JSON.stringify(group) )
        var info = {"country": "HK", "exchange": "HKEX", "start_date": "20170101", "end_date": "20171231", "resolution": "d1" }
        var params = {"transaction_fee": parseFloat(transaction_fee) , "leverage":1,"boardlot":100,'scale' :60000000000, 'initial':100000, 'safety':0.3 }
        var form = { information : info, params : params, group : gp, symbol_params : symbol_params, diff : 400 }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getlateststockprice : (symbol) => {
        var url = window.config.api + "getlateststockprice"
        var form = { symbol: symbol }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getquote : (symbol) => {
        var url = window.config.api + "getquote"
        var form = { symbol: symbol }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getDataFromSymbol : (symbol) => {
        var url = window.config.api + "getdatafromsymbol"
        var form = { symbol: symbol }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
        
    },

    transplier2: function(info, params, symbols, code) {
        var url = window.config.api + "transplier2"
        if (info && info.country==='CHN') {
            info = {"country":"CHN","exchange":"A","start_date":"","end_date":"","resolution":"m1" }
        }

        var form = { information : info, params : params, symbols: symbols, code : code }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getticker: function( filter ) {
        var url = window.config.api + "gettickers"
        var form = { filter : filter }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getFilterConfig: function( ) {
        var url = window.config.api + "getfilterconfig"
        return fetch(
            url, 
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getsymbolfromfilter : function(filter) {
        var url = window.config.api + "getsymbolfromfilter"
        var form = { filter : filter }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getdatafromsymbol : (symbol, date) => {
        var url = window.config.api + "getdatafromsymbol"
        var form = { symbol : symbol, date : date }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getFilterTable: ( filter, report_type, symbol) => {
        var url = window.config.apil + "filter"
        var form = { filter : filter }
        form.full = true
        if(report_type) {
            form.report_type = report_type
        } else {
            form.report_type = "f"
        }
        form.symbol = []
        if(symbol) {
            form.symbol = symbol
        }
        //console.log( url )
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    //'Authorization' : 'Bearer ' + service.token
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    

}


export default Apis