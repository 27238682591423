import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import logoB from '../../../public/img/garlican_blue_logo.png'

import {  } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp, faArrowAltCircleDown, faMinusCircle  } from '@fortawesome/free-solid-svg-icons'

import service from '../../../public/service'
import Api from '../../../Garlican/GarlicanLibrary/apis'
import sptrader from '../service/sp'


class TSummary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cash : 0,
            change : 0,
            percent : 0,
            accountNumber : "-",
            //companyname : "GARLICAN"
        }

    }

    componentWillMount() {
        //console.log("A")
        sptrader.summary().then(
            (res) => {
                if( res.result_msg === "ACCESS UNAUTHORIZED" ) {
                    //console.log("OUT")
                    //sptrader.logout()
                    sptrader.obj = {}
                    this.props.updateValue({
                        isTLogin : false
                    })
                } else {
                    this.setState({
                        cash : res['data']['cashBal'],
                        change :  res['data']['cashBal'] - res['data']['cashBf']  ,
                        percent : ( (res['data']['cashBal'] - res['data']['cashBf'])/res['data']['cashBf'] * 100)  ,
                        accountNumber : res['data']['accNo'], 
                    })
                }
            }
        ).catch( (e) => {
            sptrader.logout()
            this.props.updateValue({
                isTLogin : false
            })
        })
    }

    componentDidMount() {    
    }

    componentWillReceiveProps(props) {
      
    }

    componentWillUnmount() {

    }

    render() {
        return (
          <div className={['trade-summary'].join(" ")} >
            <div className={['taccount'].join(" ")}>
                { "Account # : " +  this.state.accountNumber }
            </div>
            <div className={['tcashflow'].join(" ")}>
                <div className={['tbox'].join(" ")}>
                    <span className={['tcash'].join(" ")}> {  "$" + Math.abs( Math.round(this.state.cash*100)/100 ).toLocaleString("en-US")  } </span>
                </div>
                <div className={['tbox'].join(" ")}>
                    <span className={['tchange', ((this.state.change>0)?"up":((this.state.change<0)?'down':"")) ].join(" ")}> 
                        <FontAwesomeIcon  icon={  ((this.state.change>0)?faArrowCircleUp:((this.state.change<0)?faArrowCircleUp:faMinusCircle))   } className={['icon'].join(' ')} /> 
                        <span>
                            {  
                                (Math.abs( Math.round(this.state.change*100)/100).toLocaleString("en-US") )  
                            }
                        </span>
                    </span>
                    <span className={['tchange' , ((this.state.change>0)?"up":((this.state.change<0)?'down':"")) ].join(" ")}> 
                        <FontAwesomeIcon  icon={  ((this.state.change>0)?faArrowCircleUp:((this.state.change<0)?faArrowCircleUp:faMinusCircle))   } className={['icon'].join(' ')} />  
                        <span> 
                            { 
                                Math.abs( Math.round(this.state.percent*100)/100 ) + "%"   
                            }  
                        </span>
                    </span>
                </div>
            </div>

          </div>
        )
    }
}

//<FontAwesomeIcon  icon={faClone} className={['clone', 'icon'].join(' ')} onClick={ (e)=>this.props.pop() }/> 
export default TSummary;
