import React, { Component } from 'react';
import './index.scss';

//import service from '../../public/service'
//import logoB from '../../public/img/garlican_blue_logo.png'

import { Pagination, Button, InputGroup, FormControl, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faTimes, faPlaneArrival, faPlaneDeparture, faSpaceShuttle } from '@fortawesome/free-solid-svg-icons'
//import gservice from '../../Garlican/GarlicanLibrary/gservice'

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Api from '../../Garlican/GarlicanLibrary/apis'
import service from '../../public/service';

import Code from './Code'
import id from '@xmpp/id';

const menus = [
  { 'key' : 'sector' },
  { 'key' : 'marketcap'},
  //{ 'key' : 'constituent'},
  { 'key' : 'basic' },
  { 'key' : 'cashflow'},
  { 'key' : 'debt'},
  { 'key' : 'profit'},
  
]

const numberScale = [
	{ key : "default", value : 1 , display : '' , short : "" },
	{ key : "thousand", value : 1000 , display : ',000' , short : "K"},
	{ key : "million" , value : 1000000 , display : ',000,000' , short : "M"},
	{ key : "billion" , value :1000000000 , display : ',000,000,000' , short : "B"},
	{ key : "trillion" , value :1000000000000 ,display : ',000,000,000,000', short : "T" },
]

const stringConfig = {
	'number' : [
		{ 'key' : 'gt' , 'value' : '>',  'type' : 'number'},
		//{ 'key' : 'eq' , 'value' : '=',  'type' : 'number' },
		{ 'key' : 'lt' , 'value' : '<',  'type' : 'number'},
		{ 'key' : 'ge' , 'value' : '>=', 'type' : 'number'},
		{ 'key' : 'le' , 'value' : '<=', 'type' : 'number'},
		//{ 'key' : 'ne' , 'value' : '!=', 'type' : 'number'},
		{ 'key' : 'btw' , 'value' : 'btw', 'type' : 'array'},
	],
	'string' : [
		//{ 'key' : 'eq' , 'value' : '=' , 'type' : 'string'},
		{ 'key' : 'in' , 'value' : 'in', 'type' : 'array'},
		{ 'key' : 'nin' , 'value' : 'nin', 'type' : 'array'},
		
	]
}

const icons = [
  { key : 'template', value : 'use_template', icon : faSpaceShuttle },
  { key : 'buy', value : 'long_strategy', icon : faPlaneDeparture },
  { key : 'sell', value : 'short_strategy', icon : faPlaneArrival },
]

const RANGE = 50
class Filter extends Component {

    constructor(props) {
        super(props)
        var tables = {}
        var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
        var table  = storage.getItem("@garlican/tables")
        //console.log(table)
        var _d =  service.formatDate()
        if( table ) {
          var table = JSON.parse(  table  )
          if( table.date === _d ) {
            tables = table.tables
          }
        }
        this.state = { 
          selectedStrategy : {},
          obj : [],
          display : [],
          max : 0,
          page : 0,
          lang : {},
          asccending : true,
          tables : tables,
          selectSortCat : null,
          selectedTable : 'sector',
          show : false,
          board_filters : [],
          filters : {},
          read_sym : {},
          //showboard : this.props.isFilter, 
          showboard : false, 
          icons : icons,
          forward : [],
        };
        this.getTable = this.getTable.bind(this)
        this.sort = this.sort.bind(this)
        this.sortby = this.sortby.bind(this)
        this.page = this.page.bind(this)

        this.search = this.search.bind(this)
        this.filter = this.filter.bind(this)

        this.read = this.read.bind(this)
        this.updateFilterValue = this.updateFilterValue.bind(this)

        //this.getFilterParameters = this.getFilterParameters.bind(this)
    }

    componentDidMount() {
      var tables = JSON.parse( JSON.stringify( this.state.tables ) )
      var filter = [
        { 'key' : 'constituent' , 'type':'type', 'opr' : "=" , 'value' : "*" }
		  ]
      var _this = this
      function _call() {
        var filter = [
          { 'key' : 'sector' , 'type':'type', 'opr' : "=" , 'value' : "*" }
        ]
        _this.getTable( filter, "sector" )
      }
      if( tables[ 'constituent' ]) {
        _call()
      } else {
          Api.getFilterTable( filter, "all").then(
            (res) => {
              var _t = []
              var _keys = Object.keys( res )
              var index = res[ _keys[0] ]
              for (var i in index) {
                var o = {}
                for (var j in _keys) {
                  o[  _keys[j] ] = res[ _keys[j] ][i]
                }
                _t.push(  o  )
              }
              res = _t 
              if( typeof(res) === "object" ) {  
                
                tables[ 'constituent' ] = res
              }
              var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
              var _d =  service.formatDate()
              var oj = { "date" : _d , "tables" : tables }
              storage.setItem("@garlican/tables", JSON.stringify(oj)  )
              _call()
            }
          )
      }


      
		  
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(props) {
      if( props.isFilter ) {
        this.setState({
          icons : [icons[0]]
        })
      } else {
        this.setState({
          icons : icons
        })
      }
      
      if( "id" in  props.selectedStrategy ) {
        if( props.selectedStrategy.id !== this.state.selectedStrategy.id ) {
          this.setState({
            selectedStrategy : props.selectedStrategy
          })
          var filters = JSON.parse( JSON.stringify(   props.selectedStrategy.filters )  )
          filters = filters.map( (v,k) => {
            if( v.key === "constituent" ) {
              v.table = "constituent"
            } 
            return v
          } )

          this.getTable( filters, null, true  )
          //var res = JSON.parse( JSON.stringify ( this.state.tables[ this.state.selectedTable ] ) )
          //var _o = this.read( this.state.selectedTable, res, this )
          //this.filter( _o['obj'], filters)
        }
      }

    }

    read(k, res, _this) {
      if(  (k === 'basic') || (k === "cashflow") || (k === 'debt') || (k === 'profit') ) {
        k = "finstat_" + k
      }
        var lang = {}
        if( k !== 'marketcap') {
          for (var j in _this.props.filterconfig[k]['value'] ) {
          if( 'lang' in  _this.props.filterconfig[k]['value'][j] ) {
            lang[_this.props.filterconfig[k]['value'][j]['key'] ] = _this.props.filterconfig[k]['value'][j]['lang']
          }
          for (var z in _this.props.filterconfig[k]['value'][j].value ) {
            try{
              if( 'lang' in  _this.props.filterconfig[k]['value'][j].value[z] ) {
                lang[  _this.props.filterconfig[k]['value'][j].value[z]['key']  ] = _this.props.filterconfig[k]['value'][j].value[z]['lang']
              }
            } catch(e) {
              
            }
          } 
          } 
        } else {
          lang[ _this.props.filterconfig[k].type ] = _this.props.filterconfig[k]['lang']
        }
        var _res = []
        for (var i in res) {
          var valid =true
          if( "trade_status" in res[i] ) {
            if( res[i].trade_status !== "N" ) {
              valid = false
            }
            delete res[i].trade_status
          }
          if( "type" in res[i] ) {
              delete res[i].type
          }
          if( "symbol" in res[i] ) {
            res[i].sname = _this.props.sym_names[  res[i]['symbol'] ]
            if( res[i].sname ) {
              res[i].sname.value = res[i].sname.eng
            }
          }

          var _date = ""
          if('date' in res[i]) {
            _date = res[i]['date'].substring(2,10)
          }

          if( (res[i]['symbol'] in _this.props.sym_names) ) {
            var keys = Object.keys( res[i] )
            for (var j in keys) {
              if( ( res[i][ keys[j] ] in lang )  ) {
                  if( (res[i][ keys[j] ] !== null) ) {
                    var a = res[i][ keys[j] ]
                    res[i][ keys[j] ] = lang[  res[i][ keys[j] ]  ] 
                    res[i][ keys[j] ].value = a
                  } else {
                    valid = false
                  } 
              } else {
                if( (res[i][ keys[j] ] === _date) && (keys[j] !== 'date')  ) {
                  delete res[i][ keys[j] ]
                } 
              }

              if( k!=="marketcap" ) {
                if( !(keys[j] in lang) ) {
                    if( (keys[j] !== 'symbol') && (keys[j] !== 'date') && (keys[j] !== 'sname') && (keys[j] !== 'name')  ) {
                      delete res[i][ keys[j] ]
                    }
                }
              }

            }
            if( valid ) {
              _res.push( res[i] )
            }
          }
          
        }
        res = _res
      return { obj : res, lang : lang }
    }

    getTable(filter, key, nosym) {
      //console.log(filter)
      //var oriF = JSON.parse( JSON.stringify( filter ) )
      this.props.updateValue({
        showLoading : true,
      })

      if( this.state.selectedTable in this.state.tables ) {
        var res = JSON.parse( JSON.stringify ( this.state.tables[this.state.selectedTable] ) )
        var read_sym = this.filter( res, this.state.filters, null, true  )
        var syms = []
        for (var s in read_sym) {
          if( read_sym[s].length > 0 ) {
            if( syms.length === 0 ) {
              syms  = read_sym[s]
            } else {
              syms = syms.filter( (v,k) => {
                return read_sym[s].includes(v)
              })
            }
          }
        }
      }
      
      if( !key) {
          key = this.state.selectedTable?this.state.selectedTable:filter[0]['key']
      }

      if(  (key === 'basic') || (key === "cashflow") || (key === 'debt') || (key === 'profit') ) { 
        var board_filters = this.props.filterconfig[ "finstat_" + key ].value
      } else if ( key === "marketcap") {
        var _arr = [ {key : 'marketcap' , type : 'marketcap' , lang : this.props.filterconfig[ key ].lang, vtype: "number" } ]
        var board_filters =  _arr
      } else {
        var board_filters = this.props.filterconfig[key].value
        var constituent = this.props.filterconfig['constituent']
        var o = { key : "constituent" , table : constituent.table , vtype : constituent.vtype, value : constituent.value , lang : constituent.lang }
        board_filters.push( o  )
        var symbol = this.props.filterconfig['symbol']
        var o = { key : "symbol" , table : symbol.table , vtype : symbol.vtype, value : symbol.value , lang : symbol.lang }
        board_filters.push( o  )

      }
      if( key in this.state.tables  ) {
        var res = JSON.parse( JSON.stringify ( this.state.tables[key] ) )
        var _o = this.read( key, res, this )
        if(nosym) {
          var nojb = this.filter( _o['obj'], filter )
        } else {
          var nojb = this.filter( _o['obj'], filter, syms )
        }
        if( key !== "sector" ) {
          if( nojb.length > 0 ) {
            var a = Object.keys(nojb[0])
            board_filters = board_filters.filter( (v,k) =>{
              return a.includes( v.key  )
            } )
          }
        }
        this.setState({
          obj : nojb,
          page : 0,
          max : Math.floor( nojb.length/RANGE ),
          lang : _o['lang'],
          selectSortCat : "symbol",
          selectedTable : key,
          board_filters : board_filters,
          show : false, 
        })
        var _this = this
        setTimeout( () =>{
          _this.sortby( "symbol" )
          _this.props.updateValue({
            showLoading : false,
          }) 
        }, 300 )
        
      } else {
        Api.getFilterTable( filter, "all").then(
          (res) => {
            if( typeof(res) === "object" ) {           
              var _t = []
              var _keys = Object.keys( res )
              var index = res[ _keys[0] ]

              for (var i in index) {
                var o = {}
                for (var j in _keys) {
                  o[  _keys[j] ] = res[ _keys[j] ][i]
                }
                _t.push(  o  )
              }
              res = _t              
              var tables = this.state.tables
              tables[ key ] = JSON.parse( JSON.stringify( res ) )
              
              var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
              var _d =  service.formatDate()
              var oj = { "date" : _d , "tables" : tables }
              storage.setItem("@garlican/tables", JSON.stringify(oj)  )
              
              var _o = this.read( key, res, this )
              if( _o['obj'].length > 0 ) {
                var a = Object.keys(_o['obj'][0])
                board_filters = board_filters.filter( (v,k) =>{
                  return a.includes( v.key  )
                } )
              }
              if(nosym) {
                var nobj = this.filter( _o['obj'], this.state.filters  )
              } else {
                var nobj = this.filter( _o['obj'], this.state.filters, syms  )
              }
              
              this.setState({
                obj : nobj,
                page : 0,
                max : Math.floor( nobj.length/RANGE ),
                lang : _o['lang'],
                tables : tables,
                selectSortCat : "symbol",
                selectedTable : key,
                board_filters : board_filters,
                show : false, 
              })
              var _this = this
              setTimeout( () =>{
                _this.sortby( "symbol" )
                _this.props.updateValue({
                  showLoading : false,
                }) 
              }, 300 )
            }
          }
          ).catch( (e)=>{
            this.props.updateValue({
              showLoading : false,
              show : false, 
            })
          }  )
      }
    }

    sort(obj, key, asc=true) {
      obj = obj.sort( (p,f) => {
        if( asc ) {
          if( typeof(p[key]) !== "object" ) {
            if(  isNaN(p[key]) === true ) {
              return ( p[key] > f[key] )?1:(( p[key] < f[key] )?-1:0)
            } else {
              return ( parseFloat(p[key], 10) > parseFloat(f[key], 10) )?1:-1
            }
          } else {
            return ( parseFloat(p[key].value, 10) > parseFloat(f[key].value, 10) )?1:-1
          }
        } else {
          if( typeof(p[key]) !== "object" ) {
            if(  isNaN( p[key] ) === true ) {
              return (f[key] < p[key] )?1:(( p[key] > f[key] )?-1:0)
            } else {
              return ( parseFloat(p[key], 10) < parseFloat(f[key], 10) )?1:-1
            }
          } else {
            return ( parseFloat(p[key].value, 10) < parseFloat(f[key].value, 10) )?1:-1
          }
        }
      }  )
      return obj
    }

    sortby( key, asc=true, forward ) {
      var obj = JSON.parse(  JSON.stringify( this.state.obj )  )
      if( !forward) {
        obj = this.sort( obj , key, asc )
      } else {
        obj = obj.sort((x, y) => forward.indexOf(y.symbol) - forward.indexOf(x.symbol)   )
      }

      var display = JSON.parse(  JSON.stringify( obj ) )
      display = display.slice(  0, RANGE   )
      var obj = {
        selectSortCat : key,
        obj : obj,
        display : display,
        asccending : asc,
      }
      if( forward) {
        obj.forward = forward
      }

      this.setState(obj)

    }

    page( next , from ) {
      var display = JSON.parse(  JSON.stringify( this.state.obj ) )
      if( from === "first" ) {
        display = display.slice(  0, RANGE   )
        this.setState({
          page : 0,
          display : display
        })
      } else if( from === "last" ) {
        display = display.slice(  (this.state.max)*(RANGE), ( (this.state.max+1) * RANGE)   )
        this.setState({
          page : this.state.max,
          display : display
        })
      } else {
        if( next ) {
          if( this.state.page !== this.state.max ) {
            var page = this.state.page + 1
            display = display.slice(  (page)*(RANGE), ( (page+1) * RANGE)   )
            this.setState({
              page : page,
              display : display
            })
          }
        } else {
          if( this.state.page !== 0 ) {
            var page = this.state.page -1
            display = display.slice(  (page)*(RANGE), ( (page+1) *RANGE)   )
            this.setState({
              page : page,
              display : display
            })
          }
        }
      }

      

    }

    getFilterParameters( f ) {
      var v = {}
      if( f.parent ) {
        var o = {}
        if( f.parent === 'finstat' ) {
          o = this.props.filterconfig[ f.parent+ "_" + f.key ].value
        } else {
          o = this.props.filterconfig[ f.parent ].value
        }
        for (var i in o) {
          if( o[i].key === f.type ) {
            v = o[i]
            if( v.vtype === "number" ) {
              v.numberScale = numberScale
              v.selectedNumberScale = numberScale[1]
            }
            break
          }
        }
      } else {
        if( f.key === 'history' ) {
          v = this.props.filterconfig['symbol']
        } else {
          v = this.props.filterconfig[f.key]
        }
        if( v.vtype === "number" ) {
          v.numberScale = numberScale
          v.selectedNumberScale = numberScale[1]
        }
      }
      //console.log(v)
      return v
    }
  
    search(filters) {
      var _filters = []
      for(var f in filters){
        var k = JSON.parse(  JSON.stringify(  filters[f] ) )
        if( typeof(k.value) === "object" ) {
          k.value = k.value.join(",")
        }
        _filters.push(  k   )
      }
      
      var obj = this.read( this.state.selectedTable , this.state.tables[   this.state.selectedTable   ], this)['obj']
      var nobj = this.filter( obj, filters )

      var display = JSON.parse( JSON.stringify( nobj ) )
      display = display.slice( 0, RANGE )

      var obj = {
        obj : nobj,
        display : display,
        filters : filters,
        page : 0,
        max : Math.floor( nobj.length/RANGE ),
      }
      this.setState( obj )
    }

    filter( res, filters, syms, isSym ) {
      var df = JSON.parse(  JSON.stringify(res) )
      var read_sym = JSON.parse(  JSON.stringify( this.state.read_sym )  )
      if(  !syms  ) {
        console.log(df)
        console.log( filters )
        for( var f in filters) {
          if( filters[f].key === this.state.selectedTable ) {
            df = JSON.parse(  JSON.stringify(res) )
            if( filters[f].value !== "*" ) {
              if(  ((typeof( filters[f].value ) === "object") && ( filters[f].value.length === 0 )) || ((  typeof( filters[f].value ) === "string") && (  filters[f].value === ""  ))    ) {
                //df = this.read( this.state.selectedTable , this.state.tables[   this.state.selectedTable   ], this)['obj']
              } else {  
                //console.log( filters[f]  )
                //console.log(df)
                if( filters[f].opr === "in" ) {
                  df = df.filter( (v,k) => {
                    if(  filters[f].type !== "symbol"  ) {
                      return filters[f].value.includes( v[  filters[f].type  ].value  )
                    } else {
                      return filters[f].value.includes( v[  filters[f].type  ]  )
                    }
                  }  )
                } else if( filters[f].opr === "nin" ) {
                  df = df.filter( (v,k) => {
                    if(  filters[f].type !== "symbol"  ) {
                      return !filters[f].value.includes( v[  filters[f].type  ].value  )
                    } else {
                      return !filters[f].value.includes( v[  filters[f].type  ]  )
                    }
                  }  )
                } else if ( filters[f].opr === ">" ) {
                  df = df.filter( (v,k) => {
                    return parseFloat( v[  filters[f].type  ], 10) > parseFloat(filters[f].value, 10)
                  }  )
                } else if ( filters[f].opr === ">=" ) {
                  df = df.filter( (v,k) => {
                    return  parseFloat(v[  filters[f].type  ], 10) >= parseFloat(filters[f].value, 10)
                  }  )
                } else if ( filters[f].opr === "<" ) {
                  df = df.filter( (v,k) => {
                    return parseFloat(v[  filters[f].type  ], 10) < parseFloat(filters[f].value, 10)
                  })
                } else if ( filters[f].opr === "<=" ) {
                  df = df.filter( (v,k) => {
                    return parseFloat(v[  filters[f].type  ], 10)  <= parseFloat(filters[f].value, 10)
                  }  )
                } else if ( filters[f].opr === "btw" ) {
                  var vals = filters[f].value.split(",")
                  df = df.filter( (v,k) => {
                    return ( parseFloat(v[  filters[f].type  ], 10)  >= parseFloat(vals[0], 10) ) && ( parseFloat(v[  filters[f].type  ], 10)  <= parseFloat(vals[1], 10) )
                  }  )
                } 
              }

            }
            var _sym = df.map( (v,k) => {
              return v.symbol
            })
            read_sym[ filters[f].type ] = _sym
          } else if( filters[f].key === "constituent" )  {
            var tables = JSON.parse(  JSON.stringify( this.state.tables )  )
            var _df = tables["constituent" ]
            var csym = _df.filter( (v,k) => {
              if(  filters[f].opr === "in" ) {
                return( filters[f].value.includes(  v.type )   ) 
              } else if(  filters[f].opr === "nin" ) {
                return( !filters[f].value.includes(  v.type )   ) 
              }
            } )
            console.log(csym)
            csym = csym.map( (v,k) => {
              return v.symbol
            } )
            read_sym['constituent'] = csym
          }
        }
        syms = []
        for (var s in read_sym) {
          if( read_sym[s].length > 0 ) {
                if( syms.length === 0 ) {
                  syms  = read_sym[s]
                } else {
                  syms = syms.filter( (v,k) => {
                    return read_sym[s].includes(v)
                  })
                }
              }
        }
        if( syms.length > 0 ) {
          df = JSON.parse(  JSON.stringify(res) )
          df = df.filter( (v,k) => {
            return syms.includes(v.symbol)
          } )
        }
      } else {
        if( syms.length > 0 ) {
          df = df.filter( (v,k) => {
            return syms.includes( v[  'symbol'  ]  )
          } )
        }
      }
      this.setState( { read_sym : read_sym  } )
      if( !isSym) {  
        return df
      } else {
        return read_sym
      }
    }

    updateFilterValue(obj) {
      if( "forward" in obj ) {
        this.sortby( null, true, obj.forward )
      } else {
        this.setState( obj )
      }
    }

    render() {
        return (
          <div className={['Filter'].join(" ") }>

            <div className={['main'].join(" ") }>
              <div className={['panel'].join(" ") }>
                <div className={['holder'].join(" ") }>
                {
                  menus.map( (v,k) => {
                    var key = v.key

                    var filters = JSON.parse( JSON.stringify( this.state.filters ) )

                    var _f = []
                    for (var j in filters) {
                      _f.push( filters[j] )
                    }

                    var _ct = _f.filter( (val,key) => {
                      return ( val.key === v.key )
                    } )
                    _ct = _ct.length

                    if( (v.key === "debt") || (v.key === "profit") || (v.key === "basic") || (v.key === "cashflow") ) {
                      key = "finstat_" + key
                    }
                    return (
                      <Button className={['opt', (this.state.selectedTable===v.key)?"selected":"" ].join(" ") } onClick={
                        (e)=>{
                          if( v.key === this.state.selectedTable ) {
                            this.setState( { show : !this.state.show} )
                          } else {
                            var filter = [ { 'key' : v.key , 'type':'type', 'opr' : "=" , 'value' : "*" }  ]
                            this.getTable( filter, v.key )
                          }
                        }
                      }
                       key={k}>
                        { (this.state.selectedTable === v.key) &&
                          <FontAwesomeIcon icon={ !this.state.show?faChevronDown:faChevronUp } className={['showme'].join(' ')}/> 
                        }
                        <span> {  ((key in this.props.filterconfig)?this.props.filterconfig[   key      ]['lang'][this.props.language]:key)  + "(" + _ct + ")"    } </span>
                      </Button>
                    )
                  } )
                }
                </div>
                <div className={['board', this.state.show?"appear":"" ].join(" ") }>
                  <Button className={['search' ].join(" ") } onClick={ (e)=>{
                      this.search( this.state.filters )
                    } }>  { this.props.gi18n[this.props.language]['search']  }  
                  </Button>
                  <div className={['contain'].join(" ") }>
                    {
                      this.state.board_filters.map( (v,k) => {
                          var values = [], sobj=[]
                          var filters = JSON.parse( JSON.stringify( this.state.filters ) )
                          var _selected = filters[   v.key  ]
                          var oprs = stringConfig[v.vtype]
                          for (var idx in v.value) {
                            if( typeof(v.value[idx]) === 'object' ) {
                              if (  'lang' in v.value[idx]  ) {
                                v.value[idx].name = v.value[idx].lang[this.props.language]
                                values.push(v.value[idx])
                              } 

                              if( _selected ) {
                                if(  _selected.value.includes(  v.value[idx].value ) ) {
                                  sobj.push(  v.value[idx] )
                                }
                              }
                            } else {

                              if( v.vtype === "string" ) {
                                //console.log( _selected )
                                var obj = {  name : v.value[idx], key : v.value[idx]  , value : v.value[idx] , table : v.table  }
                                values.push( obj )
                                if( _selected ) {
                                  if( _selected.value.includes( v.value[idx] ) ) {
                                    sobj.push(  obj  )
                                  }
                                }
                              } else {
                                if( _selected ) {
                                  values.push( {key :  v.key , name : v.lang[this.props.language], value : _selected.value} )
                                  sobj.push(  v.value[idx] )
                                }
                              }
                              

                              
                            }
                          }

                          if( v.vtype === "string" ) {
                            return(
                                <InputGroup size="sm" className="mb-3" key={k} className={ ["typeahead",(v.key in this.state.filters)?"selected":""].join(" ")} >
                                  <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-sm"> {v.lang[ this.props.language ]} </InputGroup.Text>
                                    <DropdownButton
                                          as={InputGroup.Prepend}
                                          key={v.key}
                                          id={""}
                                          variant={"bottom"}
                                          className={ ['Opr'].join(' ') }
                                          title={ 
                                              ( v.key in this.state.filters)?
                                                (  
                                                  ("opr" in this.state.filters[ v.key ])?
                                                  ((this.state.filters[ v.key ].opr in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][this.state.filters[ v.key ].opr]:this.state.filters[ v.key ].opr):
                                                  (this.props.gi18n[this.props.language]["in"])  
                                                )
                                              :(this.props.gi18n[this.props.language]["in"]) 
                                          } 
                                        >
                                          {
                                            oprs.map( (v1,k1) => {
                                              return(
                                                <Dropdown.Item 
                                                  onClick={
                                                    (e)=> {
                                                      var filters = JSON.parse( JSON.stringify( this.state.filters ) )
                                                      var o = {}
                                                      if( v.key in filters) {
                                                        o = filters[ v.key ]
                                                        o.opr = v1.value
                                                      } else {
                                                        o.key = this.state.selectedTable
                                                        o.opr = v1.value
                                                        o.value = []
                                                        o.type = v.key
                                                      }
                                                      filters[ v.key ] = o
                                                      this.setState({
                                                        filters : filters
                                                      }) 
                                                    }
                                                  }
                                                  eventKey={v1.key} 
                                                  key={k1} >
                                                  { (v1.value in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v1.value]:v1.value }
                                                </Dropdown.Item>
                                              )
                                            } )
                                          }
                                        </DropdownButton>

                                  </InputGroup.Prepend>
                                
                                  <Typeahead
                                      id="symbol_picker"
                                      className={ ['input' ].join(" ") }
                                      labelKey={"name"}
                                      multiple={  true }
                                      paginate={ true }
                                      clearButton={true}
                                      onChange={(e)=>{ 
                                        var filters = JSON.parse( JSON.stringify( this.state.filters ) )
                                        var read_sym = JSON.parse( JSON.stringify( this.state.read_sym ) )
                                        if(e.length === 0) {
                                          if( v.key in filters) {
                                            //console.log( v.key )
                                            delete filters[v.key ]
                                            delete read_sym[ v.key ]
                                          }
                                        } else {
                                          var o = {}
                                          if( v.key in filters) {
                                            o = filters[v.key]
                                          } else {
                                            if( v.key !== "constituent" ) {  
                                              o.key = this.state.selectedTable
                                            } else {
                                              o.key = "constituent"
                                            }
                                            o.opr = "in"
                                            o.type = v.key
                                          }


                                          o.value = e.map( (v,k) => v.value )
                                          filters[ v.key ] = o
                                        }
                                        
                                        this.setState({
                                          filters : filters,
                                          read_sym : read_sym,
                                        })                                  
                                      } }
                                      selected={  sobj  }
                                      options={  values }
                                      placeholder={ v.lang[ this.props.language ] }
                                  />
                                  
                                </InputGroup>
                            )
                          } else {
                            var filters = JSON.parse( JSON.stringify( this.state.filters ) )
                            return (
                              <InputGroup size="sm" className="mb-3" key={k} className={ ["typeahead", (v.key in this.state.filters)?"selected":"" ].join(" ")} >
                                    <InputGroup.Prepend >
                                      <InputGroup.Text id="inputGroup-sizing-sm"> {v.lang[ this.props.language ]} </InputGroup.Text>
                                      <DropdownButton
                                            as={InputGroup.Prepend}
                                            key={v.key}
                                            id={""}
                                            variant={"bottom"}
                                            className={ ['Opr'].join(' ') }
                                            title={ 
                                                ( v.key in this.state.filters)?
                                                  (  
                                                    ("opr" in this.state.filters[ v.key ])?
                                                    ((this.state.filters[ v.key ].opr in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][this.state.filters[ v.key ].opr]:this.state.filters[ v.key ].opr):
                                                    (">")  
                                                  )
                                                :(">") 
                                            } 
                                          >
                                            {
                                              oprs.map( (v1,k1) => {
                                                return(
                                                  <Dropdown.Item 
                                                    onClick={
                                                      (e)=> {
                                                        var filters = JSON.parse( JSON.stringify( this.state.filters ) )
                                                        var o = {}
                                                        if( v.key in filters) {
                                                          o = filters[ v.key ]
                                                          if( (v1.value === "btw") && o.opr !== "btw" ) {
                                                            o.value = "0,1"
                                                          } else if( (v1.value !== "btw") && o.opr === "btw" ) {
                                                            o.value = "0"
                                                          }
                                                          o.opr = v1.value
                                                        } else {
                                                          o.key = this.state.selectedTable
                                                          o.opr = v1.value
                                                          if( v1.value === "btw" ) {
                                                            o.value = "0,1"
                                                          } else {
                                                            o.value = "0"
                                                          }
                                                          o.type = v.key
                                                        }
                                                        filters[ v.key ] = o
                                                        this.setState({
                                                          filters : filters
                                                        }) 
                                                      }
                                                    }
                                                    eventKey={v1.key} 
                                                    key={k1} >
                                                    { (v1.value in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v1.value]:v1.value }
                                                  </Dropdown.Item>
                                                )
                                              } )
                                            }
                                      </DropdownButton>

                                    </InputGroup.Prepend>

                                      <FormControl 
                                        value={ (v.key in filters)?filters[v.key].value:"0" } 
                                        onChange={
                                          (e)=> {
                                            var o = {}
                                            if( v.key in filters) {
                                                o = filters[ v.key ]     
                                            } else {
                                                o.key = this.state.selectedTable
                                                if( this.state.asccending ) {
                                                  o.opr = ">"
                                                } else {
                                                  o.opr = "<"
                                                }
                                                o.type = v.key
                                            }
                                            o.value = e.target.value
                                            filters[ v.key ] = o
                                            this.setState({
                                              filters : filters
                                            }) 
                                          }
                                      } />

                                      <InputGroup.Append>
                                        <FontAwesomeIcon onClick={
                                                          (e)=>{
                                                            var filters = JSON.parse( JSON.stringify( this.state.filters ) )
                                                            delete filters[ v.key ]
                                                            var read_sym = JSON.parse( JSON.stringify( this.state.read_sym ) )
                                                            delete read_sym[ v.key ]
                                                            this.setState({
                                                              filters : filters,
                                                              read_sym : read_sym
                                                            })
                                                            this.search( filters )
                                                          }
                                        } icon={ faTimes } className={['remove'].join(' ')}/> 
                                      </InputGroup.Append>

                              </InputGroup>
                            )
                          }
                      } )
                    }
                  </div>
                </div>
              </div>
              
              <div className={['cont'].join(" ") }>
                
                {
                  (this.state.display.length > 0) &&
                    <div className={['list', "title"].join(" ")} > 
                        <div className={['box', "action" ].join(" ")} > </div>
                        {
                          Object.keys(  this.state.display[0]  ).sort((x, y) => ['symbol', 'sname','date'].reverse().indexOf(y) - ['symbol', 'sname','date'].reverse().indexOf(x)   ).map(
                            (v1,k1) => {
                              return(
                                <div className={['box', v1==="symbol"?'symbol':"" ].join(" ")} key={k1} onClick={ (e)=>{
                                  this.sortby( v1 , !this.state.asccending )
                                } } > 
                                  <span>{ (v1 in this.state.lang)?this.state.lang[v1][this.props.language]:( (v1 in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v1]:v1  )  }</span>
                                  {
                                    (this.state.selectSortCat === v1) &&
                                    <FontAwesomeIcon icon={ !this.state.asccending?faChevronDown:faChevronUp } className={['fa'].join(' ')}/> 
                                  }
                                </div>
                              )
                            }
                          )
                        }
                    </div>
                }
                
                
                {
                  this.state.display.map( (v,k) => {
                    var keys = Object.keys(  v  )
                    var priors = [ 'symbol', 'sname', 'date' ].reverse()
                    keys.sort((x, y) => priors.indexOf(y) - priors.indexOf(x)   );
                    var scale = JSON.parse(  JSON.stringify(  numberScale ) )
                    scale.reverse()
                    var txt = "", val = 0,  _t = 0
                    var forward = JSON.parse( JSON.stringify(this.state.forward) ).reverse()
                    //console.log(forward)
                    
                    return (
                      <div className={['list'].join(" ")} key={k}> 
                        <div className={['box', "action" ].join(" ")} > 
                          {
                            (forward.includes( v.symbol ) || (forward.length===0) ) && <span className={["buy"].join(" ")}>B</span>
                          }
                        </div>
                        {
                          keys.map(
                            (v1,k1) => {
                              if(  typeof(v[v1]) === "object"  ) {
                                txt = v[v1][this.props.language]
                              } else {
                                if(  (v1 !== "date") && (v1 !== "symbol")  ) {
                                  try {
                                    if( isNaN(v[v1]) === false ) {
                                      val = Math.round((  parseFloat( v[v1] , 10) * 10000) )/10000
                                      txt = val.toLocaleString("en-US")  
                                      if( val !== 0 ) { 
                                        for (var i in scale ) {
                                          _t = val/scale[i].value
                                          if( _t >= 1 ) {
                                            txt = _t.toLocaleString("en-US")  + ((scale[i].key!=="default")?scale[i].short:"") 
                                            break;
                                          }  
                                        }
                                      }
                                    } else {
                                      txt = v[v1]
                                    }
                                  } catch(e) {
                                    txt = v[v1]
                                  }
                                } else {
                                  txt = v[v1]
                                }
                              }
                              return(
                                <div className={['box', (v1==="symbol")?"symbol":'' ].join(" ")} key={k1} onClick={
                                  (e)=>{
                                    //console.log( v )
                                    //console.log(  v1  )
                                    var f = {}
                                    f.key = this.state.selectedTable
                                    f.type = v1
                                    if( isNaN(v[v1]) !== false ) {
                                      f.opr = "in"
                                      f.value = [ v[v1].value ]
                                    } else {
                                      f.opr = ">"
                                      f.value =  v[v1]
                                    }
                                    //console.log(  f )
                                    var filters = JSON.parse( JSON.stringify( this.state.filters ) )
                                    filters[v1] = f
                                    //console.log( filters )
                                    this.search( filters )

                                  }
                                }> 
                                  { txt }
                                </div>
                              )
                            }
                          )
                        }
                      </div>
                    )
                  } )
                }
              </div>


              <Pagination className={['page'].join(" ")} >
                <Pagination.First onClick={ (e)=>{
                    this.page(false, "first")
                  } }/>
                { (this.state.max !== 0) &&
                  <Pagination.Prev onClick={ (e)=>{
                    this.page(false)
                  } } />
                }

                { (this.state.max !== this.state.page) &&
                  <Pagination.Item active>{ this.state.page+1 }</Pagination.Item>
                }
                { (this.state.max > 1) &&
                  <Pagination.Ellipsis />
                }
                { (this.state.max !== 0) && 
                  <Pagination.Item active={ (this.state.max === this.state.page)  } onClick={ (e)=>{
                    this.page(false, "last")
                  } }>{this.state.max+1}</Pagination.Item>
                }
                { (this.state.max !== 0) &&
                  <Pagination.Next onClick={ (e)=>{
                    this.page(true)
                  } }/>
                }
                { (this.state.max !== 0) && 
                  <Pagination.Last onClick={ (e)=>{
                    this.page(false, "last")
                  } }/>
                }
              </Pagination>
            </div>

            <div className={['pboard', this.state.showboard?"appear":""].join(" ") }>
              <Code {...this.props} updateParent={this.props.updateParent} showboard={this.state.showboard} read_sym={this.state.read_sym} list={this.props.list} updateFilterValue={this.updateFilterValue} getList={this.props.getList} /> 
            </div>

            { (this.props.isFilter || ( !this.props.isFilter && ( this.props.selectedBoard === 'strategy' )   )) && 
            <div className={['controller'].join(" ") }>
              { 
                this.state.icons.map( (v,k) => {
                return(
                    <OverlayTrigger
                      key={k}
                      placement={"left"}
                      overlay={
                        <Tooltip >
                          <strong>{ v.value  }</strong>
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon className={['icon'].join(" ") } icon={v.icon} onClick={ (e)=> {
                        this.setState( {
                          showboard : !this.state.showboard
                        })
                      } } />
                    </OverlayTrigger>
                )
               } )
              }
            </div>
            }

          </div>
        )
    }
}


export default Filter;
